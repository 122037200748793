import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import Session from 'supertokens-auth-react/recipe/session';

export const AuthGuard: CanActivateFn = async (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
): Promise<boolean | UrlTree> => {
    const router = inject(Router);
    const sessionExists = await Session.doesSessionExist();
    return sessionExists ? true : router.createUrlTree(['auth']);
};