import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AreaSideDetailsComponent } from './guides/components/areas/area-side-details/area-side-details.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'areas',
    pathMatch: 'full'
  },
  /*{
    path: "areainfo", component: AreaSideDetailsComponent, outlet: 'info'
  }*/,
  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },

  //{ path: "areas", loadChildren: () => import("./guides/guides.module").then((m) => m.GuidesModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
