import { Component, EventEmitter, Input, OnInit, Output, ViewChild, } from '@angular/core';
import { combineLatest } from 'rxjs';
import { Area, Place, Address } from 'src/app/guides/model/interfaces';
import * as _ from 'lodash';
import { _DEBUG } from 'src/app/app.component';


@Component({
  selector: 'app-place-summary',
  templateUrl: './place-summary.component.html',
  styleUrls: ['./place-summary.component.css'],
  host: { 'style': 'max-width:1000px;margin-left:auto;margin-right:auto', 'class': 'w-75 pt-5' }
})
export class PlaceSummaryComponent implements OnInit {

  // @Input() place!: Place | any;
  @Input() place: Place = new Place();
  @Output() placeChange: EventEmitter<Place> = new EventEmitter<Place>();

  placeSummary = [
    { 
      title: 'Positions',
      description: 'Searching google address automatically adds up neccesary details of place such as place name, googleId, oogle name'
    },
    {
      title: 'Images',
      description: 'Upload cover image and more images of the selected place.'
    },
    {
      title: 'Options',
      description: 'About Arrival Point, Suitable for spare time, Can sleep over and Visit Duration'
    },
    {
      title: 'Details',
      description: 'Some more information of place in detail'
    },
    { 
      title: 'AI',
      description: 'Sets AI to active'
    }
  ]

  constructor() {
  }

  ngOnInit(): void {

    // console.log('place get data', this.place);

  };

}