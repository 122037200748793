import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { DeleteStoredImgsService } from 'src/app/common/services/delete-stored-imgs.service';
import { StoreImgsService } from 'src/app/common/services/store-imgs.service';
import { NgxImageCompressService, DataUrl, UploadResponse } from "ngx-image-compress";
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { environment } from 'src/environments/environment';
import { _DEBUG } from 'src/app/app.component';

@Component({
  selector: 'app-multi-img-modal',
  templateUrl: './multi-img-modal.component.html',
  styleUrls: ['./multi-img-modal.component.css']
})
export class MultiImgModalComponent implements OnInit, AfterViewInit {
  @Input('uploadDir') uploadDir: string = '';
  @Input('imagesBaseDir') imagesBaseDir: string = environment.IMAGES;

  // @Input() imgFolder: string;  
  @Input() multiImage: boolean = true;
  @Input() images: string[] = [];
  @Output() imagesChange = new EventEmitter<string[]>();

  activeSlide: number = 0;
  selectedFiles?: FileList;
  previews: string[] = [];
  imgResultMultiple: UploadResponse[] = [];
  imgResultAfterCompress: DataUrl = '';
  addedImagesIndex: any[] = [];
  // imagePrefix = environment.imagesPrefix;

  constructor(public activeModal: NgbActiveModal,
    private storeImagesSvc: StoreImgsService,
    private deleteImageSvc: DeleteStoredImgsService,
    private imageCompress: NgxImageCompressService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    console.log('upload directory', this.uploadDir);
  }

  ngAfterViewInit(): void {
    if (_DEBUG) console.log('all images array', this.images);

  }

  setActiveSlide(i: number) {
    this.activeSlide = i;
  }

  imgsInputClick() {
    this.uploadMultipleFiles();
  }

  cancel() {
    this.addedImagesIndex.forEach(i => {
      this.deleteImg(i);
    })
    if (this.multiImage == false && this.images.length == 0) {
      this.activeModal.dismiss('EMPTY');
    } else {
      this.activeModal.dismiss();
    }
  }

  deleteImg(i?: number) {
    console.log('deletedimage index ???', i, this.images[i]);
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.alertTarget = 'image';
    modalRef.result.then(() => {
      console.log('confirmed')
      // let files = i ? [this.images[i]] : this.images;
      let files = i !== undefined ? [this.images[i]] : this.images;
      console.log('files after condition', files);
      const deleteimg$ = this.deleteImageSvc.deleteImgs([...files])
        .pipe(take(1));
      console.log('deleteimg$>>>>', deleteimg$);
      deleteimg$.subscribe(data => {
        console.log(`delete image msg: ${data}`);
        this.images.splice(i, 1);
        this.setActiveSlide(i == 0 ? 0 : i - 1);
        this.addedImagesIndex.splice(this.addedImagesIndex.indexOf(i), 1);
        this.imagesChange.emit(this.images);
      });
    })
  }

  uploadMultipleFiles() {
    return this.imageCompress
      .uploadMultipleFiles()
      .then((multipleOrientedFiles: UploadResponse[]) => {
        this.imgResultMultiple = multipleOrientedFiles;
        console.warn(`${multipleOrientedFiles.length} files selected`);
        this.imgResultMultiple.forEach((img) => {
          if (_DEBUG) console.log(this.getImgProps(img.image))
          console.log(this.getImgProps(img.image))
          console.warn(
            'Size in bytes was:',
            this.imageCompress.byteCount(img.image)
          );
          this.imageCompress
            .compressFile(img.image, img.orientation, 50, 50)
            .then((result: DataUrl) => {
              this.imgResultAfterCompress = result;
              console.warn(
                'Size in bytes is now:',
                this.imageCompress.byteCount(result)
              );
              const saveImgs$ = this.storeImagesSvc.saveImgsToPath({ files: [result], path: this.uploadDir }).pipe(take(1));
              saveImgs$.subscribe(filePath => {
                if (_DEBUG) console.log(<any[]>filePath);
                filePath.forEach(fp => {
                  this.addedImagesIndex.push(this.images.push(fp['filePath']) - 1);
                  console.log('images array??', this.images);
                  if (this.multiImage == false) {
                    this.imagesChange.emit(this.images);
                  }
                })
              })
            });
        });
      });
  }

  getImgProps(base64: string) {
    let props = base64.substring(0, base64.indexOf(';'));
    let type = props.substring(props.indexOf(':') + 1, props.indexOf('/'))
    let ext = props.substring(props.indexOf('/') + 1);
    return { type: type, ext: ext }
  }

}
