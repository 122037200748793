import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GuidesRoutingModule } from './guides-routing.module';
import { PlacesComponent } from './components/places/places.component';
import { ItinerariesComponent } from './components/itineraries/itineraries.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PlaceItemComponent } from './components/places/place-item/place-item.component';
import { AddPlaceComponent } from './components/places/add-place/add-place.component';
import { PositionComponent } from './components/places/position/position.component';
import { OptionsComponent } from './components/places/options/options.component';
import { CustomComponentsModule } from '../common/components/custom-components.module';
import { ItineraryItemComponent } from './components/itineraries/itinerary-item/itinerary-item.component';
import { AddItineraryComponent } from './components/itineraries/add-itinerary/add-itinerary.component';
import { GuidebookProductComponent } from './components/guidebooks/guide-switch/guidebook-product/guidebook-product.component';
import { GuideSwitchComponent } from './components/guidebooks/guide-switch/guide-switch.component';
import { GuidebookApiStringComponent } from './components/guidebooks/guide-switch/guidebook-api-string/guidebook-api-string.component';
import { GuidebookListComponent } from './components/guidebooks/guide-switch/guidebook-list/guidebook-list.component';
import { GuidebookExperienceComponent } from './components/guidebooks/guide-switch/guidebook-experience/guidebook-experience.component';
import { GuidebookExternalServiceComponent } from './components/guidebooks/guide-switch/guidebook-external-service/guidebook-external-service.component';
import { GuidebookContactComponent } from './components/guidebooks/guide-switch/guidebook-contact/guidebook-contact.component';
import { GuidebookActivityComponent } from './components/guidebooks/guide-switch/guidebook-activity/guidebook-activity.component';
import { GuidebookEventComponent } from './components/guidebooks/guide-switch/guidebook-event/guidebook-event.component';
import { FaModule } from '../common/fa/fa.module';
import { PeopleComponent } from './components/people/people.component';
import { AddPersonComponent } from './components/people/add-person/add-person.component';
import { PersonItemComponent } from './components/people/person-item/person-item.component';
import { AreasComponent } from './components/areas/areas.component';
import { AreaItemComponent } from './components/areas/area-item/area-item.component';
import { GuidebooksComponent } from './components/guidebooks/guidebooks.component';
import { SettingsComponent } from './components/settings/settings.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StateService } from '../services/state.service';
import { NgxGpAutocompleteModule } from "@angular-magic/ngx-gp-autocomplete";
import { SelectedAreaComponent } from './components/areas/selected-area/selected-area.component';
import { AreaSideDetailsComponent } from './components/areas/area-side-details/area-side-details.component';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { NgStepperModule } from 'angular-ng-stepper';
import { DetailsComponent } from './components/places/details/details.component';
import { ImageUploadComponent } from './components/places/image-upload/image-upload.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DescriptionComponent } from './components/itineraries/description/description.component';
import { PlaceTabComponent } from './components/itineraries/place-tab/place-tab.component';
import { ItineraryImageUploadComponent } from './components/itineraries/itinerary-image-upload/itinerary-image-upload.component';
//import { TagInputModule } from 'ngx-chips';
import { PlaceSummaryComponent } from './components/places/place-summary/place-summary.component';
import { ItinerariesSummaryComponent } from './components/itineraries/itineraries-summary/itineraries-summary.component';
import { AiActiveComponent } from './components/places/ai-active/ai-active.component';
import { AiSubjectsComponent } from './components/ai-subjects/ai-subjects.component';
import { SubjectItemComponent } from './components/ai-subjects/subject-item/subject-item.component';
import { AddSubjectsComponent } from './components/ai-subjects/add-subjects/add-subjects.component';
import { AiSubjectActiveComponent } from './components/ai-subjects/ai-subject-active/ai-subject-active.component';
import { AddAreaComponent } from './components/areas/add-area/add-area.component';
import { AreaDataComponent } from './components/areas/area-data/area-data.component';
import { AreaImageUploadComponent } from './components/areas/area-image-upload/area-image-upload.component';
import { AreaAiActiveComponent } from './components/areas/area-ai-active/area-ai-active.component';
import { AreaSummaryComponent } from './components/areas/area-summary/area-summary.component';
import { ItineraryAiActiveComponent } from './components/itineraries/itinerary-ai-active/itinerary-ai-active.component';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { Loader } from '@googlemaps/js-api-loader';
import { EventItemComponent } from './components/guidebooks/guide-switch/guidebook-event/components/event-item/event-item.component';
import { CreateEventComponent } from './components/guidebooks/guide-switch/guidebook-event/components/create-event/create-event.component';
import { TaLibraryModule } from 'projects/ngx-ta-ui-library/src/lib/ngx-ta-ui-library.module';
import { environment } from 'src/environments/environment';
import { ActivityItemComponent } from './components/guidebooks/guide-switch/guidebook-activity/components/activity-item/activity-item.component';
import { CreateActivityComponent } from './components/guidebooks/guide-switch/guidebook-activity/components/create-activity/create-activity.component';
import { ExperienceItemComponent } from './components/guidebooks/guide-switch/guidebook-experience/components/experience-item/experience-item.component';
import { CreateExperienceComponent } from './components/guidebooks/guide-switch/guidebook-experience/components/create-experience/create-experience.component';
import { CreateProductComponent } from './components/guidebooks/guide-switch/guidebook-product/components/create-product/create-product.component';
import { PendingShopsComponent } from './components/places/pending-shops/pending-shops.component';
import { RequestsComponent } from './components/places/requests/requests.component';

@NgModule({ declarations: [
        AreasComponent,
        GuidebooksComponent,
        AreaItemComponent,
        PlacesComponent,
        ItinerariesComponent,
        PlaceItemComponent,
        AddPlaceComponent,
        ItineraryItemComponent,
        AddItineraryComponent,
        GuideSwitchComponent,
        GuidebookApiStringComponent,
        GuidebookListComponent,
        GuidebookExperienceComponent,
        GuidebookExternalServiceComponent,
        GuidebookContactComponent,
        GuidebookActivityComponent,
        GuidebookEventComponent,
        GuidebookProductComponent,
        PeopleComponent,
        AddPersonComponent,
        PersonItemComponent,
        SettingsComponent,
        SelectedAreaComponent,
        AreaSideDetailsComponent,
        PositionComponent,
        OptionsComponent,
        DetailsComponent,
        ImageUploadComponent,
        DescriptionComponent,
        PlaceTabComponent,
        ItineraryImageUploadComponent,
        PlaceSummaryComponent,
        ItinerariesSummaryComponent,
        AiActiveComponent,
        AiSubjectsComponent,
        SubjectItemComponent,
        AddSubjectsComponent,
        AiSubjectActiveComponent,
        AddAreaComponent,
        AreaDataComponent,
        AreaImageUploadComponent,
        AreaAiActiveComponent,
        AreaSummaryComponent,
        ItineraryAiActiveComponent,
        EventItemComponent,
        CreateEventComponent,
        ActivityItemComponent,
        CreateActivityComponent,
        ExperienceItemComponent,
        CreateExperienceComponent,
        CreateProductComponent,
        RequestsComponent,
        PendingShopsComponent
    ], imports: [CommonModule,
        GuidesRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        CustomComponentsModule,
        FaModule,
        NgbModule,
        NgMultiSelectDropDownModule.forRoot(),
        NgxGpAutocompleteModule,
        CdkStepperModule,
        NgStepperModule,
        TaLibraryModule], providers: [
        StateService,
        {
            provide: Loader,
            useValue: new Loader({
                apiKey: 'AIzaSyCV6gh1nQmAZUtmjrxOLLix8LSE02SyfbI',
                libraries: ['places']
            })
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class GuidesModule { }
