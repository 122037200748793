import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, tap } from 'rxjs';
import { DeleteStoredImgsService } from 'src/app/common/services/delete-stored-imgs.service';
import { StoreImgsService } from 'src/app/common/services/store-imgs.service';
import { environment } from 'src/environments/environment';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
    }),
};

@Injectable({
    providedIn: 'root',
})
export class SettingsService {
    constructor(private http: HttpClient) { }

    getSettings(): Observable<any> {
        return this.http.get<any>(`${environment.API}rest/settings/default`).pipe();
    }

    getAllSettings(): Observable<any> {
        return this.http.get<any>(`${environment.API}rest/settings`).pipe();
    }
}