import { Component, OnInit, ViewChild, } from '@angular/core';
import { combineLatest } from 'rxjs';
import { Area, Place, Latlng, AISubject } from 'src/app/guides/model/interfaces';
import { PlaceService } from 'src/app/guides/services/place.service';
import { Location } from '@angular/common';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as _ from 'lodash';
import { BreadcrumbService } from '@luhuiguo/xng-breadcrumb';
import { StateService } from 'src/app/services/state.service';
import { Observable, tap } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MultiImgModalComponent } from 'src/app/common/components/multi-img-modal/multi-img-modal.component';
import { environment } from 'src/environments/environment';
import { CdkStepper } from '@angular/cdk/stepper';
import { _DEBUG } from 'src/app/app.component';
import { v4 as uuidv4 } from 'uuid';
import { AIService } from 'src/app/guides/services/ai.service';

@Component({
  selector: 'app-add-subjects',
  templateUrl: './add-subjects.component.html',
  styleUrls: ['./add-subjects.component.css'],
  host: { 'style': 'max-width:1000px;margin-left:auto;margin-right:auto', 'class': 'w-75 h-100 pt-5' }
})
export class AddSubjectsComponent implements OnInit {

  aiSubject$: Observable<AISubject | undefined>;
  aiSubject: AISubject = new AISubject();

  updating: boolean = false;
  onUpdate: boolean = false;


  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private aiService: AIService,
    private breadcrumbService: BreadcrumbService,
    private stateService: StateService,
    private modalService: NgbModal,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    let params$: Observable<Params> = this.route.params;
    let area$: Observable<Area> = this.stateService.selectedArea$;
    combineLatest([params$, area$]).subscribe(([params, area]) => {
      console.log('params in add -sub', params);
      if (params.id === 'new') {
        this.breadcrumbService.set('areas/:id/aisubjects/:id', `Aggiungi Soggetto`);
      } else {
        this.onUpdate = true;
        this.breadcrumbService.set('areas/:id/aisubjects/:id', `Soggetti AI`);
        this.aiService.getAiById(params.id).subscribe((ai) => {
          this.breadcrumbService.set('areas/:id/aisubjects/:id', `Assistente Virtuale: ${ai.name}`);
          this.aiSubject = ai;
          console.log('ai Subject', this.aiSubject);
        })
        this.position();
      }
    });
  }

  position() {
    console.log('in parent AI', this.aiSubject);
  }

  saveData() {
    console.log('ai data', this.aiSubject);
    if (this.onUpdate) {
      this.updating = true;
      this.aiService.updateAI(this.aiSubject, this.aiSubject._id).subscribe({
        next: (result) => {
          this.stateService.message$.next({ text: "Informazioni salvate correttamente", type: environment.MESSAGES.SUCCESS });
        },
        error: ({ error }) => {
          console.log('err', error);
          this.stateService.message$.next({ text: `Ops.. qualcosa è andato storto..(${error.message})`, type: environment.MESSAGES.ERROR });
        },
        complete: () => {
          this.updating = false;
          this.router.navigate([`/areas/:id/aisubjects`])
        }
      });
    } else {
      this.updating = true;
      this.aiService.saveAI(this.aiSubject).subscribe({
        next: (result) => {
          console.log('result', result);
          this.stateService.message$.next({ text: "Informazioni salvate correttamente", type: environment.MESSAGES.SUCCESS });
        },
        error: ({ error }) => {
          console.log('err', error);
          this.stateService.message$.next({ text: `Ops.. qualcosa è andato storto..(${error.message})`, type: environment.MESSAGES.ERROR });
        },
        complete: () => {
          this.updating = false;
          this.router.navigate([`/areas/:id/aisubjects`])
        }
      })
    }
  }
}