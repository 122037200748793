import { Component, ViewChild } from '@angular/core';
import packageJson from '../../package.json';
import { StateService } from './services/state.service';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { tap } from 'rxjs';
import { Area } from './guides/model/interfaces';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth/auth.service';

import { SuperTokensConfig } from "src/app/supertokens/config";

import * as SuperTokens from "supertokens-auth-react";
import * as Session from "supertokens-auth-react/recipe/session";
import { ConfigService } from './guides/services/config.service';

SuperTokens.init(SuperTokensConfig);
SuperTokens.changeLanguage("it");


export const _DEBUG = !environment.production;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public version: string = packageJson.version;
  title = 'tourismappAdmin';

  activeRoute: string;
  isAreaSelected: boolean = false;
  selectedArea: Area | undefined = undefined;

  staticAlertClosed = false;
  message: { text: string, type: string } | undefined = undefined;// {text:"prova",type:"success"};

  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert!: NgbAlert;


  constructor(
    public configService: ConfigService,
    public stateService: StateService,
    private auth: AuthService) {
    this.configService.loadSettings();
  }

  ngOnInit(): void {
    /*setInterval(()=>{
      if(this.message==null){
        this.message={text:"prova",type:"success"};
      }else{
        //this.message=null
      }
    },500)*/
    this.stateService.selectedArea$.subscribe((area) => {
      this.isAreaSelected = area._id ? true : false;
      this.selectedArea = area;
    })
    this.stateService.message$.pipe(
      tap(() => {
        setTimeout(() => {
          if (this.selfClosingAlert) {
            this.selfClosingAlert.close();
          }
        }, 2500)
      }
      )
    ).subscribe((message) => {
      this.message = message;
    });
  }

  /*ngOnInit(): void {
    this.router.events
      .pipe(
      filter(event => event instanceof NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
          this.activeRoute = event.url;
          console.log(this.activeRoute);
          //this.isHome = event.url === "/"; // Toggle a boolean based on url
      });
  }*/

  unselectArea() {
    this.isAreaSelected = false;
  }

  async logout() {
    await Session.signOut();
    window.location.reload();
  }

}
