import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from '@luhuiguo/xng-breadcrumb';
import { combineLatest } from 'rxjs';
import { SharedEventService } from 'src/app/common/services/shared-event.service';
import { ListGuidebook } from 'src/app/guides/model/interfaces';
import { GuidebookService } from 'src/app/guides/services/guidebook.service';
import { ItinerariesService } from 'src/app/guides/services/itineraries.service';
import { PeopleService } from 'src/app/guides/services/people.service';
import { PlaceService } from 'src/app/guides/services/place.service';
import { StateService } from 'src/app/services/state.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-guidebook-list',
  templateUrl: './guidebook-list.component.html',
  styleUrls: ['./guidebook-list.component.css'],
})
export class GuidebookListComponent implements OnInit {
  //guidebooks: [];
  //guidebookType: string;
  params$ = this.route.queryParams;
  area$ = this.stateService.selectedArea$;
  settings$ = this.sharedService.configLoadedEvent;
  guidebookListSettings: any;
  guidebookContent: string;
  guidebookContentCatetory: string;
  imagesBaseDir = environment.imagesPrefix;
  itemsList = [];
  listName: string;
  listLabel: string;
  guidebook: any;

  constructor(private guidebookService: GuidebookService,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private stateService: StateService,
    private sharedService: SharedEventService,
    private placesService: PlaceService,
    private itinerariesService: ItinerariesService,
    private peopleService: PeopleService) { }


  ngOnInit(): void {
    combineLatest([this.params$, this.area$, this.settings$]).subscribe(([params, area, settings]) => {
      this.guidebookListSettings = settings.ux.guidebookGroups.categories.find((c) => c.id === params.catid).guidebooks.find((g) => g.id === params.gid);
      this.guidebookContent = this.guidebookListSettings.specs.content;
      this.guidebookContentCatetory = this.guidebookListSettings.specs.category == undefined ? '' : this.guidebookListSettings.specs.category;
      switch (this.guidebookContent) {
        case 'places': {
          this.placesService.getAreaPlaces(area._id).subscribe((places: any) => {
            this.itemsList = places;
            if (this.guidebookContentCatetory != '') {
              this.itemsList = this.itemsList.filter(
                item => {
                  return item.details.class.find(
                    (c: any) => {
                      return c.id === this.guidebookContentCatetory
                    })
                }
              );
            }
            this.populateSortableList();
            this.populalateGuidebooks(area._id, 'places');
          });
          this.listName = 'Luoghi';
          this.listLabel = 'Seleziona i luoghi';
          break;
        }
        case 'itineraries': {
          this.itinerariesService.getItineraries().subscribe((itineraries: any) => {
            this.itemsList = itineraries;
            this.populateSortableList();
            this.populalateGuidebooks(area._id, 'itineraries');
          });
          this.listName = 'Itinerari';
          this.listLabel = 'Seleziona gli itinerari';
          break
        }
        case 'people': {
          this.peopleService.getPeopleByArea(area._id).subscribe((people: any) => {
            this.itemsList = people;
            this.populateSortableList();
            this.populalateGuidebooks(area._id, 'people');
          })
          this.listName = 'Persone';
          this.listLabel = 'Seleziona le persone';
          break
        }
      }
    });
  }

  populalateGuidebooks(area: string, contentType: string) {
    this.guidebookService.getAllGuidebooks().subscribe((guidebooks: any) => {
      this.guidebook = guidebooks.find((g: any) => {
        return (g.type === this.guidebookListSettings.type &&
          g.contentType === this.guidebookListSettings.specs.content &&
          g.contentId === this.guidebookListSettings.id)
      })
      if (this.guidebook == undefined) {
        this.guidebook = new ListGuidebook(area, this.guidebookListSettings.languages.it, contentType, this.guidebookListSettings.id);
      }
      //this.breadcrumbService.set('areas/:id/guidebooks/dyn', `${this.guidebook.name}`);
    });
  }

  populateSortableList() {
    this.itemsList.map((item) => {
      item.isChecked = false
      item.isHidden = false;
    });
    this.itemsList.sort((a, b) => {
      if (a.isChecked && !b.isChecked) {
        return -1; // `a` comes before `b`
      } else if (!a.isChecked && b.isChecked) {
        return 1; // `b` comes before `a`
      } else {
        // If isChecked properties are the same, sort alphabetically
        return a.name.localeCompare(b.name);
      }
    });
  }

  save() {
    if (this.guidebook._id) {
      this.update();
    } else {
      this.guidebookService.createGuidebook(this.guidebook).subscribe((res) => {
        this.guidebook._id = res._id;
        this.stateService.message$.next({ text: `Guida Creata.`, type: environment.MESSAGES.SUCCESS });
      });
    }
  }

  update() {
    this.guidebookService.updateGuidebook(this.guidebook).subscribe((res) => {
      this.stateService.message$.next({ text: `Guida Aggiornata.`, type: environment.MESSAGES.SUCCESS });
    });
  }

  tagWarning() {
    this.stateService.message$.next({ text: "Tag Rimosso", type: `${environment.MESSAGES.ERROR}` })
  }

  tagError(error) {
    this.stateService.message$.next({ text: `Errore dal Suggeritore AI: ${error.statusText}`, type: `${environment.MESSAGES.ERROR}` });
  }

  onImageWarning(state: string) {
    if (state == 'cancelled') {
      this.stateService.message$.next({ text: `Operazione Annullata.`, type: environment.MESSAGES.WARNING });
    }
  }

  onImageSuccess(state: string) {
    this.save();
    this.stateService.message$.next({ text: `Immagine Salvata.`, type: environment.MESSAGES.SUCCESS });
  }

  onImageDeleted() {
    this.stateService.message$.next({ text: `Immagine Eliminata.`, type: environment.MESSAGES.WARNING });
    this.save();
  }
}
