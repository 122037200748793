import { Component, EventEmitter, Input, OnInit, Output, ViewChild, } from '@angular/core';
import { combineLatest } from 'rxjs';
import { Area, Place, Latlng, PlaceClassType } from 'src/app/guides/model/interfaces';
import { PlaceService } from 'src/app/guides/services/place.service';
import { Location } from '@angular/common';
import { ActivatedRoute, Params } from '@angular/router';
import * as _ from 'lodash';
import { BreadcrumbService } from '@luhuiguo/xng-breadcrumb';
import { StateService } from 'src/app/services/state.service';
import { Observable, tap } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { _DEBUG } from 'src/app/app.component';
import { v4 as uuidv4 } from 'uuid';
//import { Tag } from 'src/app/common/model/interfaces';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css', './multiselect.scss'],
  host: { 'style': 'max-width:1000px;margin-left:auto;margin-right:auto', 'class': 'w-75 pt-5' }
})
export class DetailsComponent implements OnInit {

  settings = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    enableCheckAll: false,
    allowSearchFilter: true,
    limitSelection: -1,
    clearSearchFilter: true,
    maxHeight: 250,
    itemsShowLimit: 3,
    searchPlaceholderText: 'Cerca',
    noDataAvailablePlaceholderText: 'Nessun risultato',
    closeDropDownOnSelection: false,
    showSelectedItemsAtTop: false,
    defaultOpen: false,
  };

  // @Input() place!: Place | any;
  @Input() place: Place = new Place();
  @Output() placeChange: EventEmitter<Place> = new EventEmitter<Place>();

  placeTypeList = [];
  placeClassList = [];

  place$: Observable<Place | undefined>;
  // place: Place | any = {};

  placeSelected: boolean = false;

  updating: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private placeService: PlaceService,
    private breadcrumbService: BreadcrumbService,
    private stateService: StateService,
    private modalService: NgbModal
  ) {
  }

  ngOnInit(): void {
    this.getDropDownOptions();
  }

  onItemSelect(item: any) {
    console.log('onItemSelect', item);
    console.log('onItemSelect', this.place.details.type);
    this.placeChange.emit(this.place);
  }

  // Method to handle changes in the description textarea
  onDescriptionChange(event: Event) {
    this.place.details.description = (event.target as HTMLTextAreaElement).value;
    // this.place.details = this.details;
    this.placeChange.emit(this.place);
  }

  getDropDownOptions() {
    this.placeService.getSettings().subscribe((data: any) => {

      const placeArray = data.ui.components.places.enums.placeTypes;
      const classArray = data.ui.components.places.enums.placeClasses;
      for (const placeType of placeArray) {
        this.placeTypeList.push({ id: placeType.id, name: placeType.languages.it });
      }
      for (const classType of classArray) {
        this.placeClassList.push({ id: classType.id, name: classType.languages.it });
      }
      console.log('place list array', this.placeTypeList);
    })
  }

  // Method to handle changes in the notes textarea
  onNotesChange(event: Event) {
    this.place.details.notes = (event.target as HTMLTextAreaElement).value;
    this.placeChange.emit(this.place);
  }

  // Method to handle changes in the type select
  onTypeChange(event: Event) {
    const selectedOptions = Array.from(
      (event.target as HTMLSelectElement).selectedOptions
    ).map((option) => option.value);
    this.place.details.type = selectedOptions;
    this.placeChange.emit(this.place);
  }

  // Method to handle changes in the class select
  onClassChange(event: Event) {
    const selectedOptions = Array.from(
      (event.target as HTMLSelectElement).selectedOptions
    ).map((option) => option.value);
    this.place.details.class = selectedOptions;
    this.placeChange.emit(this.place);
  }

  // Method to handle changes in the Tags input (assuming you have app-tags-input component)
  /*onTagsChange(tags: Tag[]) {
    this.place.tags = tags.map((tag) => tag.name);
    this.placeChange.emit(this.place);
  }*/

  showAlert($event) {
    // console.log('place deta', this.details);
    //TODO ALERT
  }

}

