import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SettingsService } from '../../services/settings.service';
import { BreadcrumbService } from '@luhuiguo/xng-breadcrumb';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { tap } from 'rxjs';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.css'
})
export class SettingsComponent implements OnInit {

  settings$ = this.settingsService.getAllSettings().pipe(
    tap((res) => {
      this.data = res[0];
    }));
  public editorOptions: JsonEditorOptions;
  public data: any;
  constructor(private settingsService: SettingsService,
    private breadcrumbService: BreadcrumbService) {
    this.editorOptions = new JsonEditorOptions()
    this.editorOptions.modes = ['code', 'text', 'tree', 'view'];
  }

  ngOnInit() {
    this.breadcrumbService.set('settings', 'Impostazioni');
  }

  @ViewChild(JsonEditorComponent, { static: false }) editor: JsonEditorComponent;

  setJsonEditorData(json: any, el) {
    this.data = json;
    //set active class and disable from siblings
    for (let i = 0; i < el.parentElement.children.length; i++) {
      el.parentElement.children[i].classList.remove('active');
    }
    el.classList.add('active');
  }
}
