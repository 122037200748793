import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild, inject } from '@angular/core';
import { NgbActiveModal, NgbCalendar, NgbDate, NgbDateAdapter, NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FilesStorageService, ImagesStorageService, NgxTaTagsService, NgxTaUiLibraryConfigService, OpenAIService } from './ngx-ta-ui-library.service';
import { Observable, catchError, take } from 'rxjs';
import { NgxImageCompressService, DataUrl, UploadResponse } from 'ngx-image-compress';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry, NgxFileDropModule } from 'ngx-file-drop';
import { TaPhonePipe } from './ngx-ta-ui-library.pipes';
import { ContactInfos, Image, Media, Schedule, Selectable, Timeframe } from './ngx-ta-ui-library.model';


const colors = ["red", "orange", "indigo", "purple", "pink", "yellow", "green", "teal", "cyan", "blue", "primry", "secondary", "success", "info", "warning", "danger", "dark"]//"light",
const colorsMixed = colors.sort(() => Math.random() - 0.5);
export const getColor = (i: number) => { return colorsMixed[i % colors.length]; }

@Component({
  selector: 'ta-input',
  template: `
  <div [class]="'form-floating p-1 '+inputGroupClass">
    <input type="text" [ngModel]="value" (ngModelChange)="onModelChange($event)"
      [id]="id" [name]="name" [disabled]="disabled" [type]="type" [placeholder]="placeholder"
      class="form-control form-control-sm border border-0 border-bottom" >
    <label [for]="id">{{label}}</label>
  </div>
  `,
  styles: [``]
})
export class TaInputComponent {
  @Input() id: string;
  @Input() name: string = '';
  @Input() label: string = '';
  @Input() type = 'text';
  @Input() placeholder = '';
  @Input() disabled = false;
  @Input() value: any;
  @Input() inputGroupClass: string = '';
  @Output() valueChange = new EventEmitter<any>();

  constructor() {
    if (this.id === undefined || this.id === '') {
      this.id = this.getRandomId();
    }
    if (this.name === '') {
      this.name = this.id;
    }
  }
  getRandomId() {
    return Math.random().toString(36).substring(7);
  }

  onModelChange(event: any) {
    this.valueChange.emit(event);
  }
}

@Component({
  selector: 'ta-labeled-badge',
  template: `
  <span *ngIf="label!==''" class="badge text-{{color}} border border-{{color}} fs-6" 
  style="border-radius: 0.375rem 0 0 0.375rem;color:var(--bs-{{color}})!important;border-color:var(--bs-{{color}})!important;"
  >{{label}}</span>
  <span class="badge text-bg-{{color}} border border-{{color}} me-1 mb-1 fs-6"
    style="color:white!important;background-color:var(--bs-{{color}})!important;border-color:var(--bs-{{color}})!important;"
    [style.border-radius]="label!==''?'0 0.375rem 0.375rem 0':''"    
    >{{content}}
   <i *ngIf="deletable" class="bi bi-x-lg" (click)="onDeleteEvent()"></i>
  </span>
  `,
  styles: [``],
  //providers: [{ provide: NgbDateParserFormatter, useClass: TaMomentDateFormatter }]
})
export class TaLabeledBadgeComponent {
  @Input() label: string = '';
  @Input() content: string = '';
  @Input() color: string = 'primary';
  @Input() deletable: boolean = true;
  @Output() onDelete = new EventEmitter<void>();

  onDeleteEvent() {
    this.onDelete.emit();
  }
}

@Component({
  selector: 'ta-period-picker',
  template: `<form class="d-flex">
	<div class="">
		<div class="dp-hidden">
			<div class="input-group">
				<input
					name="datepicker"
					class="form-control"
					ngbDatepicker
					#datepicker="ngbDatepicker"
					[autoClose]="'outside'"
					(dateSelect)="onDateSelection($event)"
					[displayMonths]="2"
					[dayTemplate]="t"
					outsideDays="hidden"
					[startDate]="fromDate!"
					tabindex="-1"
				/>
				<ng-template #t let-date let-focused="focused">
					<span
						class="custom-day"
						[class.focused]="focused"
						[class.range]="isRange(date)"
						[class.faded]="isHovered(date) || isInside(date)"
						(mouseenter)="hoveredDate = date"
						(mouseleave)="hoveredDate = null"
					>
						{{ date.day }}
					</span>
				</ng-template>
			</div>
		</div>
		<div class="input-group">
			<input
				#dpFromDate
				class="form-control"
				placeholder="DD/MM/YYYY"
				name="dpFromDate"
				[value]="formatter.format(fromDate)"
				(input)="fromDate = validateInput(fromDate, dpFromDate.value)"
			/>
			<!--button class="btn btn-outline-secondary bi bi-calendar3" (click)="datepicker.toggle()" type="button"></button-->
		</div>
	</div>
	<div class="">
		<div class="input-group">
			<input
				#dpToDate
				class="form-control"
				placeholder="DD/MM/YYYY"
				name="dpToDate"
				[value]="formatter.format(toDate)"
				(input)="toDate = validateInput(toDate, dpToDate.value)"
			/>
		</div>
	</div>
  <div class="col">
    <button class="btn btn-outline-secondary bi bi-calendar3" (click)="datepicker.toggle()" type="button"></button>
  </div>
</form>`,
  styles: [`
  .dp-hidden {
    width: 0;
    margin: 0;
    border: none;
    padding: 0;
    height: 0px;
    border: none;
  }
  .custom-day {
    text-align: center;
    padding: 0.185rem 0.25rem;
    display: inline-block;
    height: 2rem;
    width: 2rem;
  }
  .custom-day.focused {
    background-color: #e6e6e6;
  }
  .custom-day.range,
  .custom-day:hover {
    background-color: rgb(2, 117, 216);
    color: white;
    border-radius: 1rem;
  }
  .custom-day.faded {
    background-color: rgba(2, 117, 216, 0.5);
  }
`]
})

export class TaPeriodPickerComponent implements OnInit {
  calendar = inject(NgbCalendar);
  formatter = inject(NgbDateParserFormatter);
  adapter = inject(NgbDateAdapter<string>);
  @Input() period: { start: string, end: string };
  @Output() periodChange: EventEmitter<{ start: string, end: string }> = new EventEmitter<{ start: string, end: string }>();
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  // TODO: implement locale change, wrap with formatDate to [value] in inputs, remove custom formatter from injection in the module
  //datePipe: DatePipe; 
  //locale = 'it-IT';
  hoveredDate: NgbDate | null = null;
  rangeAlreadySet: boolean = true;

  ngOnInit(): void {
    this.fromDate = this.period.start ? NgbDate.from(this.adapter.fromModel(this.period.start)) : this.calendar.getToday();
    //this.toDate = this.period.end ? NgbDate.from(this.adapter.fromModel(this.period.end)) : this.calendar.getNext(this.calendar.getToday(), 'd', 3);
    this.toDate = this.period.end ? NgbDate.from(this.adapter.fromModel(this.period.end)) : this.calendar.getToday();
    // TODO: implement locale change
    //this.datePipe = new DatePipe('en-US'); TODO: implement locale change
    let start = this.adapter.toModel(this.fromDate);
    let end = this.adapter.toModel(this.toDate);
    this.periodChange.emit({ start: start.toString(), end: end.toString() });
  }
  // TODO: implement locale change
  /*formatDate(string) { 
    if (this.locale === 'it-IT') {
      return this.datePipe.transform(string, 'dd/MM/yyyy');
    } else {
      return this.datePipe.transform(string, 'yyyy-MM-dd');
    }
  }*/

  onDateSelection(date: NgbDate) {
    if (this.rangeAlreadySet) {
      this.rangeAlreadySet = false
      this.fromDate = date;
      this.toDate = null;
    } else if (this.rangeAlreadySet == false) {
      if (date.before(this.fromDate)) {
        this.fromDate = date;
        this.rangeAlreadySet = false
      } else {
        this.toDate = date;
        this.rangeAlreadySet = true
      }
    }
    let start = this.adapter.toModel(this.fromDate);
    let end = this.adapter.toModel(this.toDate);
    this.periodChange.emit({ start: start.toString(), end: end.toString() });
  }

  isHovered(date: NgbDate) {
    return (
      !this.rangeAlreadySet && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }
}

@Component({
  selector: 'ta-advanced-period-picker',
  template: `<form class="d-flex">
	<div class="">
		<div class="dp-hidden">
			<div class="input-group">
				<input
					name="datepicker"
					class="form-control"
					ngbDatepicker
					#datepicker="ngbDatepicker"
					[autoClose]="'outside'"
					(dateSelect)="onDateSelection($event)"
					[displayMonths]="2"
					[dayTemplate]="t"
					outsideDays="hidden"
					[startDate]="fromDate!"
					tabindex="-1"
          
				/>
				<ng-template #t let-date
        let-focused="focused"
        let-off=off>
					<span
						class="custom-day"
						[class.focused]="focused"
						[class.range]="isRange(date)"
						[class.faded]="isHovered(date) || isInside(date)"
            [class.off]="isDisabled(date, { month: date.month })"
						(mouseenter)="hoveredDate = date"
						(mouseleave)="hoveredDate = null"
            (click)="disableDate($event)"
            >
						{{ date.day }}
					</span>
				</ng-template>
			</div>
		</div>
		<div class="input-group">
			<input
				#dpFromDate
				class="form-control"
				placeholder="DD/MM/YYYY"
				name="dpFromDate"
				[value]="formatter.format(fromDate)"
				(input)="fromDate = validateInput(fromDate, dpFromDate.value)"
			/>
			<!--button class="btn btn-outline-secondary bi bi-calendar3" (click)="datepicker.toggle()" type="button"></button-->
		</div>
	</div>
	<div class="">
		<div class="input-group">
			<input
				#dpToDate
				class="form-control"
				placeholder="DD/MM/YYYY"
				name="dpToDate"
				[value]="formatter.format(toDate)"
				(input)="toDate = validateInput(toDate, dpToDate.value)"
			/>
		</div>
	</div>
  <div class="col">
    <button class="btn btn-outline-secondary bi bi-calendar3" (click)="datepicker.toggle()" type="button"></button>
  </div>
</form>`,
  styles: [`
  .dp-hidden {
    width: 0;
    margin: 0;
    border: none;
    padding: 0;
    height: 0px;
    border: none;
  }
  .custom-day {
    text-align: center;
    padding: 0.185rem 0.25rem;
    display: inline-block;
    height: 2rem;
    width: 2rem;
  }
  .custom-day.focused {
    background-color: #e6e6e6;
  }
  .custom-day.range,
  .custom-day:hover {
    background-color: rgb(2, 117, 216);
    color: white;
    border-radius: 1rem;
  }
  .custom-day.faded {
    background-color: rgba(2, 117, 216, 0.5);
  }
  .custom-day.off {
			background-color: orange;
			color: white;
		}
`]
})

export class TaAdvancedPeriodPickerComponent implements OnInit {
  calendar = inject(NgbCalendar);
  formatter = inject(NgbDateParserFormatter);
  adapter = inject(NgbDateAdapter<string>);
  @Input() period: { start: string, end: string, off: string[] };
  @Output() periodChange: EventEmitter<{ start: string, end: string, off: string[] }> = new EventEmitter<{ start: string, end: string, off: string[] }>();
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  offDates: NgbDate[] = [];
  // TODO: implement locale change, wrap with formatDate to [value] in inputs, remove custom formatter from injection in the module
  //datePipe: DatePipe; 
  //locale = 'it-IT';
  hoveredDate: NgbDate | null = null;
  rangeAlreadySet: boolean = true;
  dayOffMode = false;

  ngOnInit(): void {
    this.fromDate = this.period.start ? NgbDate.from(this.adapter.fromModel(this.period.start)) : this.calendar.getToday();
    //this.toDate = this.period.end ? NgbDate.from(this.adapter.fromModel(this.period.end)) : this.calendar.getNext(this.calendar.getToday(), 'd', 3);
    this.toDate = this.period.end ? NgbDate.from(this.adapter.fromModel(this.period.end)) : this.calendar.getToday();
    this.offDates = this.period.off.map(d => {
      return NgbDate.from(this.adapter.fromModel(d));
    });
    // TODO: implement locale change
    //this.datePipe = new DatePipe('en-US'); TODO: implement locale change
    let start = this.adapter.toModel(this.fromDate);
    let end = this.adapter.toModel(this.toDate);
    let off = this.offDates.map(d => {
      return this.adapter.toModel(d);
    });
    this.periodChange.emit({ start: start.toString(), end: end.toString(), off: [...off] });
  }
  // TODO: implement locale change
  /*formatDate(string) { 
    if (this.locale === 'it-IT') {
      return this.datePipe.transform(string, 'dd/MM/yyyy');
    } else {
      return this.datePipe.transform(string, 'yyyy-MM-dd');
    }
  }*/

  onDateSelection(date: NgbDate) {
    if (this.rangeAlreadySet) {
      if (this.dayOffMode) {
        this.offDates.push(date);
      }
      else {
        this.rangeAlreadySet = false
        this.fromDate = date;
        this.toDate = null;
        this.offDates = [];
      }
    } else if (this.rangeAlreadySet == false) {
      if (date.before(this.fromDate)) {
        this.fromDate = date;
        this.rangeAlreadySet = false
      } else {
        this.toDate = date;
        this.rangeAlreadySet = true
      }
    } else {
      this.toDate = date;
      this.rangeAlreadySet = true
    }
    //let start = new Date(this.fromDate ? this.formatter.format(this.fromDate) : null).getTime();
    //let end = new Date(this.toDate ? this.formatter.format(this.toDate) : null).getTime();
    let start = this.adapter.toModel(this.fromDate);
    let end = this.adapter.toModel(this.toDate);
    let off = this.offDates.map(d => {
      return this.adapter.toModel(d);
    });
    this.periodChange.emit({ start: start.toString(), end: end.toString(), off: [...off] });
    //this.periodChange.emit({ start: this.fromDate ? this.formatter.format(this.fromDate) : '', end: this.toDate ? this.formatter.format(this.toDate) : '' });
  }

  isHovered(date: NgbDate) {
    return (
      !this.rangeAlreadySet && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  isDisabled = (date: NgbDate, current: { month: number }) => {
    //convert period.odd to NgbDate array adn check if date is in the array
    /*const d = this.period.off.map(d => {
      return NgbDate.from(this.adapter.fromModel(d));
    })*/
    const isoff = this.offDates.find(d => d.equals(date)) ? true : false;
    return isoff;
  }

  disableDate(event: any) {
    if (event.metaKey) {
      this.dayOffMode = true;
    } else {
      this.dayOffMode = false;
    }
  }
}

@Component({
  selector: 'ta-schedule',
  template: `
  <div class="d-flex flex-column w-100">
    <div class="btn-group" role="group">
      <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" 
            [(ngModel)]="selectedFrequency" (change)="switchIntervalView()" value="weekly">
      <label class="btn btn-outline-primary" for="btnradio1">Settimanale</label>
      
      <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" 
            [(ngModel)]="selectedFrequency" (change)="switchIntervalView()"  value="monthly">
      <label class="btn btn-outline-primary" for="btnradio2">Mensile</label>
      
      <!--input type="radio" class="btn-check" name="btnradio" id="btnradio3" autocomplete="off" 
            [(ngModel)]="selectedFrequency" (change)="switchIntervalView()"  value="annual">
      <label class="btn btn-outline-primary" for="btnradio3">Annuale</label-->
    </div>
    <div style="min-height: 100px;" class="w-100">
      <div #collapse="ngbCollapse" [(ngbCollapse)]="isDaysCollapsed">
        <div class="card" style="width: 100%">
          <div class="card-body d-flex justify-content-around">
            @for(day of weekDays;track day){
            <div class="weekday">
              <input class="form-check-input" type="checkbox" id="{{day}}" value="{{day}}" [checked]="selectedDays.includes($index.toString())" (click)="toggleSelection($index)">
              <label class="form-check-label" for="{{day}}">{{day}}</label>
            </div>
            }
          </div>
        </div>
      </div>
      <div #collapse="ngbCollapse" [(ngbCollapse)]="isNumbersCollapsed" >
        <div class="">
          <div class="card">
            <div class="card-body d-flex flex-wrap">
              <!-- Days of the month -->
              <div *ngFor="let day of daysInMonth; let i = index"
                   class="day-square"
                   [class.selected]="selectedDays.includes(i.toString())"
                   (click)="toggleSelection(i)">
                {{ day }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  `,
  styles: [`
    .day-square {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #ccc;
      margin: 2px;
      cursor: pointer;
    }
    .day-square.selected {
      background-color: #007bff;
      color: white;
    }

    .weekday {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .weekday input {
      margin-bottom: 5px;
    }
  `],
  host: { class: 'w-100', style: 'max-width:500px' }
})
export class TaScheduleComponent implements OnInit {
  @Input() schedule: Schedule;
  @Output() scheduleChange = new EventEmitter<Schedule>();
  isDaysCollapsed = false;
  isNumbersCollapsed = true;
  selectedFrequency: 'weekly' | 'monthly' | 'annual' = 'weekly';
  isCollapsed = true
  monthDays = Array.from({ length: 31 }, (_, i) => i + 1);

  weekDays = ['Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab', 'Dom'];

  daysInMonth: number[] = Array.from({ length: 31 }, (_, i) => i + 1);
  selectedDays: string[] = [];

  ngOnInit(): void {
    this.selectedFrequency = this.schedule.frequency || 'weekly';
    this.selectedDays = this.schedule.selected;
    this.setView();
  }

  toggleSelection(index: number) {
    if (this.selectedDays.includes(index.toString())) {
      this.selectedDays = this.selectedDays.filter(dayIndex => dayIndex !== index.toString());
    } else {
      this.selectedDays.push(index.toString());
    }
    this.schedule.frequency = this.selectedFrequency;
    this.schedule.selected = this.selectedDays;
    this.scheduleChange.emit(this.schedule);
  }

  switchIntervalView() {
    this.selectedDays = [];
    this.setView();
  }

  setView() {
    if (this.selectedFrequency === 'weekly') {
      this.isNumbersCollapsed = true
      setTimeout(() => {
        this.isDaysCollapsed = false;
      }, 500);
    } else {
      this.isDaysCollapsed = true;
      setTimeout(() => {
        this.isNumbersCollapsed = false;
      }, 500);
    }
    this.schedule.frequency = this.selectedFrequency;
    this.schedule.selected = this.selectedDays;
    this.scheduleChange.emit(this.schedule);
  }
}

@Component({
  selector: 'ta-cost',
  template: `
<div class="d-flex flex-row align-items-center">
  <ta-input class="" style="max-width:38%;" label="Categoria" [(value)]="l"></ta-input>
  <ta-input class="" style="max-width:20%;" label="Prezzo" [(value)]="c"></ta-input>
  <button type="button" class="btn btn-sm btn-primary position-relative ms-3" style="max-width:20%;bottom:-12px;" (click)="addCost();">Aggiungi</button>
</div>
<div class="d-flex flex-column">
  <div class="bg-light rounded p-1">
    <div class="p-1 lead fs-6">Prezzi</div>
    <div *ngIf="costs;else empty" class="d-flex flex-wrap">
      <ta-labeled-badge *ngFor="let c of costs|keyvalue;let i = index" 
        [label]="c.key" [content]="c.value" [color]="gc(i)" 
        (onDelete)="removeCost(c)"></ta-labeled-badge>
    </div>
  </div>
</div>
<ng-template #empty>
  <div class="p-3 d-flex fs-6">
    <img class="m-auto" src="assets/emptybox.png">
  </div>
</ng-template>
`
})
export class TaCostComponent {
  @Input() costs: { [key: string]: string };
  @Output() costsChange: EventEmitter<{ [key: string]: string }> = new EventEmitter<{ [key: string]: string }>();
  l: string;
  c: string;
  gc = getColor;
  addCost() {
    this.costs[this.l] = this.c;
    this.onModelChange();
  }

  removeCost(c) {
    delete this.costs[c.key];
    this.onModelChange();
  }

  onModelChange() {
    this.costsChange.emit(this.costs);
  }
}

@Component({
  selector: 'ta-timeframe-picker',
  template: `
  <div class="d-flex flex-row">
    <div class="">
      <label for="floatingInput">Inizio</label>
      <ngb-timepicker [spinners]="false" size="small" [(ngModel)]="timeframe.start" (ngOnChanges)="onModelChange()"></ngb-timepicker>
    </div>
    <div class="ms-2">
      <label for="floatingInput">Fine</label>  
      <ngb-timepicker [spinners]="false" size="small" [(ngModel)]="timeframe.end" (ngOnChanges)="onModelChange()" ></ngb-timepicker>
    </div>  
  </div>
  `,
  styles: [``]
})
export class TaTimeFramePickerComponent {
  @Input() timeframe: Timeframe;
  @Output() timeframeChange: EventEmitter<Timeframe> = new EventEmitter<Timeframe>();

  onModelChange() {
    this.timeframeChange.emit(this.timeframe);
  }
}

@Component({
  selector: 'ta-labeled-timeframe-picker',
  template: `
  <div class="d-flex flex-row align-items-center">
    <ta-input class=""  label="Etichetta" [(value)]="l"></ta-input>
    <div class="first">
      <label for="floatingInput">Inizio</label>
      <ngb-timepicker [spinners]="false" size="small" [(ngModel)]="t.start"></ngb-timepicker>
    </div>
    <div class="ms-2">
      <label for="floatingInput">Fine</label>  
      <ngb-timepicker [spinners]="false" size="small" [(ngModel)]="t.end" ></ngb-timepicker>
    </div>  
    <button type="button" class="btn btn-primary btn-sm position-relative ms-3" style="max-width:20%;bottom:-12px;" (click)="addTimeFrame();">Aggiungi</button>
  </div>
  <div class="d-flex flex-column">
    <div class="bg-light rounded p-1">
      <div class="p-1 lead fs-6">Orari</div>
      <div class="d-flex flex-wrap" *ngIf="labeledTimeframe as values">
        @for (v of values|keyvalue;track v;let i = $index) {
          <ta-labeled-badge (onDelete)="removeTimeFrame(v)" [label]="v.key"
          [content]="getContentString(v.value)" [color]="gc(i)"></ta-labeled-badge>
        }
      </div>
      </div>
    </div>
  `,
  styles: [`
    .first ngb-timepicker ::ng-deep fieldset ::ng-deep .ngb-tp-meridian {display:none}
  `]
})
export class TaLabeledTimeFramePickerComponent {
  @Input() labeledTimeframe: { [key: string]: Timeframe } = {};
  @Output() labeledTimeframeChange: EventEmitter<{ [key: string]: Timeframe }> = new EventEmitter<{ [key: string]: Timeframe }>();
  l: string = '';
  t: Timeframe = { start: { hour: 0, minute: 0 }, end: { hour: 0, minute: 0 } };
  meridian = true;
  gc = getColor;
  onModelChange() {
    this.labeledTimeframeChange.emit(this.labeledTimeframe);
  }

  getContentString(tf: Timeframe) {
    //add leading zero to minutes and hours
    return (tf.start.hour < 10 ? '0' + tf.start.hour : tf.start.hour) + ':' + (tf.start.minute < 10 ? '0' + tf.start.minute : tf.start.minute) + '-' + (tf.end.hour < 10 ? '0' + tf.end.hour : tf.end.hour) + ':' + (tf.end.minute < 10 ? '0' + tf.end.minute : tf.end.minute);
  }

  addTimeFrame() {
    this.labeledTimeframe[this.l] = { start: this.t.start, end: this.t.end };
    this.onModelChange();
  }

  removeTimeFrame(tf) {
    delete this.labeledTimeframe[tf.key];
    this.onModelChange();
  }
}

@Component({
  selector: 'ta-checkbox',
  template: `
  <div class="w-100 mx-auto d-flex align-items-center justify-content-start" [class]="inputGroupClass">
    <div class="form-check">
      <input class="form-check-input" style="max-width: 16px; max-height: 16px" type="checkbox"
      [ngModel]="value" (ngModelChange)="onModelChange($event)" [id]="id" [name]="name" [disabled]="disabled">
    </div>
    <label class="form-check-label" [for]="id">
      <div class="d-flex flex-row align-items-center">
        <div class="">{{label}}</div>
        <div class="ms-2 me-auto position-relative"
          [style.color]="value?checkedColor:uncheckedColor">
          <i class="fs-4 bi" [class]="value?checkedIcon:uncheckedIcon"></i>
        </div>
      </div>
    </label>
  </div>
  `,
  styles: [``]
})
export class TaCheckboxComponent {
  @Input() id: string;
  @Input() name: string = '';
  @Input() label: string = '';
  @Input() disabled = false;
  @Input() value: boolean;
  @Input() inputGroupClass: string = '';
  @Input() checkedColor: string = 'red';
  @Input() uncheckedColor: string = 'orange';
  @Input() checkedIcon: string = 'bi-question-lg';
  @Input() uncheckedIcon: string = 'bi-question-lg';
  @Output() valueChange = new EventEmitter<boolean>();

  constructor() {
    if (this.id === undefined || this.id === '') {
      this.id = this.getRandomId();
    }
    if (this.name === '') {
      this.name = this.id;
    }
  }
  getRandomId() {
    return Math.random().toString(36).substring(7);
  }

  onModelChange(event: any) {
    this.valueChange.emit(event);
  }
}

@Component({
  selector: 'ta-tags',
  providers: [NgxTaTagsService, OpenAIService],
  template: `
  <div [formGroup]="form">
    <!--div *ngFor="let t of tags">
      <div>{{ t }}</div>
    </div-->
    <div class="tag-input" (click)="focusTagInput()">
      <input list="codes" placeholder="Aggiungi un Tag" #tagInput type="text" (keyup)="onKeyUp($event)"
        formControlName="tag" (change)="saveTag($event)" />
      <datalist id="codes">
        <option *ngFor="let tag of suggestedTags$ | async">{{ tag }}</option>
      </datalist>
      <div class="btn btn-sm btn-outline-danger float-end me-1 " (click)="removeTags()"><i class="bi bi-trash2"></i></div>
    </div>
    <div class="d-flex flex-row flex-wrap mt-1">
      <!--span class="badge fs-6 me-1 mb-1" style="background-color:#39ca69" *ngFor="let tag of tags">
        {{ tag }}
        <i class="bi bi-x-lg" (click)="removeTag(tag)"></i>
      </span-->
      <ta-labeled-badge *ngFor="let tag of tags;let i=index" [content]="tag" [color]="gc(i)" (onDelete)="removeTag(tag)"></ta-labeled-badge>
    </div>
  </div>
  `,
  styles: [`.tag-input {  
    cursor: text;
    font-size: 16px;
    padding: 10px 0px 10px 10px;
    background: white;
    border-bottom: 1px #cccccc solid;
  
    & input {
      margin: 0;
      padding-left: 5px;
      border: none;
      outline: none;
    }
  }
  
  [codes]::-w {
    display: none;
  }`
  ]
})
export class TaTagsComponent implements OnInit {
  @Input() tags: string[];
  @Output() tagsChange: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() onForbiddenTag = new EventEmitter<void>();
  @Output() onError = new EventEmitter<string>();
  @ViewChild('tagInput') tagInputRef: ElementRef;

  form: UntypedFormGroup;
  public suggestedTags$: Observable<string[]>;
  public blacklistedTagsList: string[] = [];
  gc = getColor;

  constructor(private fb: UntypedFormBuilder,
    private tagsService: NgxTaTagsService,
    private openAIService: OpenAIService) {
    this.suggestedTags$ = this.openAIService.suggestTags(this.tags).pipe(
      catchError((err) => {
        this.onError.emit(err);
        return [];
      })
    );
  }

  ngOnInit() {
    this.tagsService.getBlacklistedTags().subscribe((data) => {
      this.blacklistedTagsList = data.split('\n');
    });
    this.form = this.fb.group({
      tag: [undefined],
    });
  }

  focusTagInput(): void {
    this.tagInputRef.nativeElement.focus();
  }

  onKeyUp(event: KeyboardEvent): void {
    const inputValue: string = this.form.controls.tag.value;
    if (event.code === 'Backspace' && !inputValue) {
      this.removeTag();
      return;
    } else {
      if (event.code === 'Comma') {//|| event.code === 'Space') {
        this.addTag(inputValue);
        this.form.controls.tag.setValue('');
      }
    }
  }

  addTag(tag: string): void {
    tag = tag.toLowerCase();
    if (tag[tag.length - 1] === ',') {//|| tag[tag.length - 1] === ' ') {
      tag = tag.slice(0, -1);
    }
    if (
      (!this.blacklistedTagsList.includes(tag)) &&
      (tag.length > 0 && (!this.tags.includes(tag)))
    ) {
      this.tags.push(tag);
      this.tagsChange.emit(this.tags);
      this.tagsService.analyzeTags(this.tags);
      this.suggestedTags$ = this.openAIService.suggestTags(this.tags).pipe(
        catchError((err) => {
          this.onError.emit(err);
          return [];
        })
      );;
    } else {
      if (this.blacklistedTagsList.includes(tag)) {
        this.onForbiddenTag.emit();
      }
    }
  }

  public saveTag(e): void {
    this.addTag(e.target.value);
    e.target.value = '';
    this.focusTagInput();
  }

  removeTags() {
    this.tags.length = 0;
  }

  removeTag(tag?: string): void {
    if (!!tag) {
      this.tags.splice(this.tags.indexOf(tag), 1);
      //pull(this.tags, tag);
    } else {
      this.tags.splice(-1);
    }
  }
}

@Component({
  selector: 'ta-contact-infos',
  template: `
    <div class="d-flex flex-row align-items-center">
      <ta-input class="" style="max-width:38%;" label="Contatto" [(value)]="contact"></ta-input>
      <ta-input class="" style="max-width:20%;" label="Etichetta" [(value)]="label"></ta-input>
      <select [(ngModel)]="type" class="form-select form-select-sm border border-0 me-1 position-relative" style="max-width:18%;bottom:-12px" aria-label="Small select example">
        <option value="" selected>Tipo</option>
        <option value="email">Email</option>
        <option value="phone">Telefono</option>
        <option value="social">Social</option>
        <option value="website">Sito Web</option>
      </select>
      <button type="button" class="btn btn-primary btn-sm position-relative" style="max-width:20%;bottom:-12px" (click)="addContact()">Aggiungi</button>
    </div>
    <div class="d-flex flex-column px-3 pt-1">
      <div class="bg-light rounded p-1 mb-2">
        <div class="p-1 lead fs-6">Email</div>
        <div *ngIf="value.emails.length;let i=index;else empty" class="d-flex flex-wrap">
          <ta-labeled-badge *ngFor="let e of value.emails" 
            [label]="e.label" [content]="e.address" color="indigo"
            (onDelete)="removeEmail(i)"></ta-labeled-badge>
        </div>
      </div>
      <div class="bg-light rounded p-1 mb-2">
        <div class="p-1 lead fs-6">Telefono</div>
        <div *ngIf="value.phones.length;let i=index;else empty" class="d-flex flex-wrap">
          <ta-labeled-badge *ngFor="let p of value.phones" 
            [label]="p.label" [content]="transformPhoneNum(p.number)" color="teal"
            (onDelete)="removePhone(i)"></ta-labeled-badge>
        </div>
      </div>
      <div class="bg-light rounded p-1 mb-2">
        <div class="p-1 lead fs-6">Social</div>
        <div *ngIf="value.socials.length;let i=index;else empty" class="d-flex flex-wrap">
          <ta-labeled-badge *ngFor="let s of value.socials" 
            [label]="s.label" [content]="s.link" color="green"
            (onDelete)="removeSocial(i)"></ta-labeled-badge>
        </div>
      </div>
      <div class="bg-light rounded p-1 mb-2">
        <div class="p-1 lead fs-6">Sito Web</div>
        <div *ngIf="value.websites.length;let i=index;else empty" class="d-flex flex-wrap">
          <ta-labeled-badge *ngFor="let w of value.websites" 
            [label]="w.label" [content]="w.url" color="yellow"
            (onDelete)="removePhone(i)"></ta-labeled-badge>
        </div>
      </div>
    </div>
    <ng-template #empty>
      <div class="p-3 d-flex fs-6">
        <img class="m-auto" src="assets/emptybox.png">
      </div>
    </ng-template>
  `,
  styles: [``]
})
export class TaContactInfosComponent {
  @Input() value: ContactInfos = { emails: [], phones: [], websites: [], socials: [] };
  @Output() valueChange = new EventEmitter<ContactInfos>();
  @Output() onInputError = new EventEmitter<string>();
  contact: string = '';
  label: string = '';
  type: '' | 'email' | 'phone' | 'website' | 'social' = '';

  constructor() {
  }
  getRandomId() {
    return Math.random().toString(36).substring(7);
  }

  transformPhoneNum(num: string): string {
    return TaPhonePipe.prototype.transform(num);
  }

  addContact() {
    if (this.contact === '') {
      this.onInputError.emit('Inserire un contatto');
      return;
    }
    if (this.type === '') {
      this.onInputError.emit('Selezionare un tipo di contatto');
      return;
    }
    switch (this.type) {
      case 'email':
        if (this.isEmail(this.contact)) {
          if (this.value.emails.find(e => e.address === this.contact)) {
            this.onInputError.emit('Email già presente');
            return;
          }
          this.value.emails.push({ address: this.contact, label: this.label });
          this.valueChange.emit(this.value);
          this.contact = '';
          this.label = '';
          break;
        } else {
          this.onInputError.emit('Inserire un indirizzo email valido');
          return;
        }
      case 'phone':
        if (this.isPhone(this.contact)) {
          if (this.value.phones.find(p => p.number === this.contact)) {
            this.onInputError.emit('Numero di telefono già presente');
            return;
          }
          this.value.phones.push({ number: this.contact, label: this.label });
          this.valueChange.emit(this.value);
          this.contact = '';
          this.label = '';
          break;
        } else {
          this.onInputError.emit('Inserire un numero di telefono valido');
          return;
        }
      case 'website':
        if (this.isLink(this.contact)) {
          if (this.value.websites.find(w => w.url === this.contact)) {
            this.onInputError.emit('Sito web già presente');
            return;
          }
          this.value.websites.push({ url: this.contact, label: this.label });
          this.valueChange.emit(this.value);
          this.contact = '';
          this.label = '';
          break;
        } else {
          this.onInputError.emit('Inserire un sito web valido');
          return;
        }
      case 'social':
        if (this.isLink(this.contact)) {
          if (this.value.socials.find(s => s.link === this.contact)) {
            this.onInputError.emit('Profilo social già presente');
            return;
          }
          this.value.socials.push({ link: this.contact, label: this.label });
          this.valueChange.emit(this.value);
          this.contact = '';
          this.label = '';
          break;
        } else {
          this.onInputError.emit('Inserire un link valido');
          return;
        }
    }
  }

  isEmail(email: string): boolean {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  isPhone(phone: string): boolean {
    const re = /^\d{10}$/;
    return re.test(phone);
  }

  isLink(link: string): boolean {
    const re = /^(http|https):\/\/[^ "]+$/;
    return re.test(link);
  }

  removeEmail(i: number) {
    this.value.emails.splice(i, 1);
    this.valueChange.emit(this.value);
  }

  removePhone(i: number) {
    this.value.phones.splice(i, 1);
    this.valueChange.emit(this.value);
  }

  removeSocial(i: number) {
    this.value.socials.splice(i, 1);
    this.valueChange.emit(this.value);
  }

  removeWebsite(i: number) {
    this.value.websites.splice(i, 1);
    this.valueChange.emit(this.value);
  }

  onModelChange(event: any) {
    this.valueChange.emit(event);
  }
}

@Component({
  selector: 'ta-confirm-modal',
  providers: [],
  template: `
  <div class="modal-header">
  <h4 class="modal-title" id="modal-title">Sei sicuro?</h4>
  <button type="button" class="btn-close" aria-describedby="modal-title"
    (click)="modal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  <p><strong>Stai per cancellare {{message}}.</strong></p>
  <p><span class="text-danger">Questa operazione non è reversibile.</span></p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">Cancel</button>
  <button type="button" class="btn btn-danger" (click)="modal.close('Ok click')">Ok</button>
</div>
  `,
  styles: [``]
})
export class TaConfirmModalComponent {
  @Input('alertTarget') alertTarget!: string;
  message: string = '';
  constructor(public modal: NgbActiveModal) { }

  ngOnInit(): void {
    switch (this.alertTarget) {
      case 'test':
        this.message = 'test';
      case 'file':
        this.message = 'questo file';
        break;
      case 'image':
        this.message = 'questa immagine';
        break;
      case 'shop':
        this.message = 'questa attività';
        break;
      case 'city':
        this.message = 'questa città';
        break;
      case 'place':
        this.message = 'questo luogo';
        break;
      case 'itinerary':
        this.message = 'questo itinerary';
      case 'aisubjects':
        this.message = 'questo aisubjects';
      case 'event':
        this.message = 'questo evento';
      case 'product':
        this.message = 'questo prodotto';
    }
  }
}

@Component({
  selector: 'ta-images-selector',
  providers: [NgbModal],
  template: `
  <div class="modal-header">
    <h4 class="modal-title">Scegli le immagini:</h4>
    <button [disabled]="!images.length" class="btn btn-light ms-auto me-2" type="button" aria-label="Close" (click)="activeModal.close(images)">
        <i class="bi bi-cloud-upload fs-1 m-auto"></i>
    </button>
    <button class="btn btn-light" ngbAutofocus type="button" aria-label="Dismiss" (click)="cancel()">
        <i class="bi bi-x-square fs-1 m-auto"></i>
    </button>
</div>
<div class="modal-body">
    <div class="card">
        <div *ngIf="images.length" id="imgsDetails" class="carousel slide" data-bs-ride="carousel" data-bs-interval="false">
            <div class="carousel-inner">

                <div *ngFor="let image of images;let i=index;" [ngClass]="{'active': i == activeSlide}"
                    class="carousel-item">
                    <img class="d-block w-100" style="height: 35vh;object-fit: cover;" [src]="imagesBaseDir+image" alt=""
                    onerror="this.onerror=null;this.src='assets/placeholder.jpg';">
                    <button class="btn bg-white btn-light position-absolute top-0 end-0 m-1"
                        (click)="deleteImg(i);$event.stopPropagation()" type="button" style="z-index: 999;">
                        <i class="bi bi-trash2"></i>
                    </button>
                    <div class="">
                        <p>{{i + 1}}/{{images.length}}</p>
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#imgsDetails" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#imgsDetails" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>

            </div>
        </div>
        <div class="d-flex flex-row mw-100 p-1" style="height: 100px;overflow-x: scroll;overflow-y: hidden;">
            <img *ngFor='let preview of images; let i = index' [src]="imagesBaseDir+preview" class="me-1"
                style="min-width:94px;width:94px;max-width:94px;height:94px;object-fit:cover;"
                (click)="setActiveSlide(i)"
                onerror="this.onerror=null;this.src='assets/placeholder.jpg';">

            <div *ngIf="multiImage||images.length==0" class="d-flex align-items-center bg-white" style="height: 94px;width: 94px;"
                (click)="imgsInputClick()">
                <i class="bi bi-plus-square fs-1 m-auto"></i>
            </div>
        </div>
    </div>
</div>
  `,
  styles: [``]
})
export class TaImagesSelectorComponent implements OnInit {
  @Input('uploadDir') uploadDir: string = '';
  @Input('imagesBaseDir') imagesBaseDir: string = '';

  @Input() multiImage: boolean = true;
  @Input() images: string[] = [];
  @Output() imagesChange = new EventEmitter<string[]>();
  @Output() imageDeleted = new EventEmitter<string[]>();

  activeSlide: number = 0;
  selectedFiles?: FileList;
  previews: string[] = [];
  imgResultMultiple: UploadResponse[] = [];
  imgResultAfterCompress: DataUrl = '';
  addedImagesIndex: any[] = [];

  constructor(public activeModal: NgbActiveModal,
    private imageStorageService: ImagesStorageService,
    private imageCompress: NgxImageCompressService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
  }

  setActiveSlide(i: number) {
    this.activeSlide = i;
  }

  imgsInputClick() {
    this.uploadMultipleFiles();
  }

  cancel() {
    this.addedImagesIndex.forEach(i => {
      this.deleteImg(i);
    })
    if (this.multiImage == false && this.images.length == 0) {
      this.activeModal.dismiss('EMPTY');
    } else {
      this.activeModal.dismiss();
    }
  }

  deleteImg(i?: number) {
    const modalRef = this.modalService.open(TaConfirmModalComponent);
    modalRef.componentInstance.alertTarget = 'image';
    modalRef.result.then(() => {
      // let files = i ? [this.images[i]] : this.images;
      let files = i !== undefined ? [this.images[i]] : this.images;
      const deleteimg$ = this.imageStorageService.deleteImages([...files])
        .pipe(take(1));
      deleteimg$.subscribe(data => {
        this.images.splice(i, 1);
        this.setActiveSlide(i == 0 ? 0 : i - 1);
        this.addedImagesIndex.splice(this.addedImagesIndex.indexOf(i), 1);
        this.imageDeleted.emit(this.images);
      });
    })
  }

  uploadMultipleFiles() {
    return this.imageCompress
      .uploadMultipleFiles()
      .then((multipleOrientedFiles: UploadResponse[]) => {
        this.imgResultMultiple = multipleOrientedFiles;
        console.warn(`${multipleOrientedFiles.length} files selected`);
        this.imgResultMultiple.forEach((img) => {
          console.warn(
            'Size in bytes was:',
            this.imageCompress.byteCount(img.image)
          );
          this.imageCompress
            .compressFile(img.image, img.orientation, 50, 50)
            .then((result: DataUrl) => {
              this.imgResultAfterCompress = result;
              console.warn(
                'Size in bytes is now:',
                this.imageCompress.byteCount(result)
              );
              const saveImgs$ = this.imageStorageService.saveImages([result], this.uploadDir).pipe(take(1));
              saveImgs$.subscribe(filePath => {
                filePath.forEach(fp => {
                  this.addedImagesIndex.push(this.images.push(fp['filePath']) - 1);
                  if (this.multiImage == false) {
                    this.imagesChange.emit(this.images);
                  }
                })
              })
            });
        });
      });
  }
}

@Component({
  selector: 'ta-single-image',
  providers: [NgbModal],
  template: `
    <div class="d-flex flex-column align-content-between w-50 mx-auto">
        <div class="h-100 w-100">
            <div class="d-grid gap-2">
                <button class="btn btn-light" type="button" (click)="loadImage()"><i
                        class="bi bi-image"></i><span class="ms-2">Seleziona un'immagine</span></button>
            </div>
            <div *ngIf="image" class="h-100" [class]="image ?'':'d-none'">
                <img [src]="config.imagesEndpoint+image.url" class=""
                    style="width: 100%;height: 100%;object-fit: cover;aspect-ratio:1"
                    onerror="this.onerror=null;this.src='assets/placeholder.jpg';"
                    (error)="setImgLoadError($event)">
            </div>
        </div>
    </div>
  `,
  styles: [``]
})
export class TaSingleImageComponent {
  @Input() image: Image = { name: '', url: '' };
  @Input() uuid;
  @Output() imageChange = new EventEmitter<any>();
  @Output() onImageWarning = new EventEmitter<string>();
  @Output() onImageSuccess = new EventEmitter<void>();
  @Output() onImageDeleted = new EventEmitter<void>();
  imageLoadError = false;
  constructor(private modalService: NgbModal,
    private config: NgxTaUiLibraryConfigService) {
  }

  loadImage() {
    const modalRef = this.modalService.open(TaImagesSelectorComponent);
    let images = []
    if (!this.imageLoadError) {
      images.push(this.image.url);
    }
    modalRef.componentInstance.images = images;
    modalRef.componentInstance.uploadDir = this.uuid;
    modalRef.componentInstance.imagesBaseDir = this.config.imagesEndpoint
    modalRef.componentInstance.multiImage = false;
    modalRef.componentInstance.imageDeleted.subscribe((imagesUpdated: any) => {
      this.image = imagesUpdated.length ? this.getImageDetails(imagesUpdated[0]) : { name: '', url: '' };
      this.onImageDeleted.emit();
      this.imageChange.emit(this.image);
      this.imageLoadError = false;
    });
    modalRef.dismissed.subscribe((state) => {
      if (state == 'EMPTY') {
        this.image = { name: '', url: '' };
        this.imageChange.emit(this.image);
      }
      this.onImageWarning.emit('cancelled');
    })
    modalRef.result.then((imagesUpdated) => {
      this.emitSuccessImage(imagesUpdated);
    });
    modalRef.componentInstance.imagesChange.subscribe((imagesUpdated: any) => {
      this.emitSuccessImage(imagesUpdated);
    })
  }

  emitSuccessImage(images: string[]) {
    this.image = images.length ? this.getImageDetails(images[0]) : { name: '', url: '' };
    this.onImageSuccess.emit();
    this.imageChange.emit(this.image);
    this.imageLoadError = false;
  }

  getImageDetails(image: string) {
    const segments = image.split('/');
    const imageName = segments[segments.length - 1];
    return { name: imageName, url: image };
  }

  setImgLoadError($event) {
    this.imageLoadError = true;
  }
}

@Component({
  selector: 'ta-multi-images',
  providers: [NgbModal],
  template: `
    <div class="d-flex flex-column align-content-between w-50 mx-auto">
        <div class="h-100 w-100">
            <div class="d-grid gap-2">
                <button class="btn btn-light" type="button" (click)="loadImages()"><i
                        class="bi bi-image"></i><span class="ms-2">Seleziona le immagini</span></button>
            </div>
            @if(images){
            <ngb-carousel class="h-100" [class]="images.length ?'':'d-none'">
              @for(i of images;track i){
              <ng-template ngbSlide>
                  <div class="picsum-img-wrapper overflow-hidden h-100" style="background-color: black;">
                      <img [src]="config.imagesEndpoint+i.url" class="" style="width: 100%;height: 100%;object-fit: cover;aspect-ratio:1"
                          onerror="this.onerror=null;this.src='assets/placeholder.jpg';">
                  </div>
              </ng-template>
              }
            </ngb-carousel>
            }
        </div>
    </div>
  `,
  styles: [`
    ngb-carousel .picsum-img-wrapper {
	position: relative;
	height: 0;
	padding-top: 55%; /* Keep ratio for 900x500 images */
}

ngb-carousel .picsum-img-wrapper > img {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}`]
})
export class TaMultiImagesComponent {
  @Input() images: Image[] = [];
  @Input() uuid;
  @Output() imagesChange = new EventEmitter<Image[]>();
  @Output() onImageWarning = new EventEmitter<string>();
  @Output() onImageSuccess = new EventEmitter<void>();
  @Output() onImageDeleted = new EventEmitter<void>();
  coverImageLoadError = false;
  constructor(private modalService: NgbModal,
    private config: NgxTaUiLibraryConfigService) {
  }

  loadImages() {
    const modalRef = this.modalService.open(TaImagesSelectorComponent);
    modalRef.componentInstance.images = this.images.map((i => i.url));
    modalRef.componentInstance.uploadDir = this.uuid;
    modalRef.componentInstance.imagesBaseDir = this.config.imagesEndpoint
    modalRef.componentInstance.multiImage = true;
    modalRef.componentInstance.imageDeleted.subscribe((imagesUpdated: any) => {
      this.onImageDeleted.emit();
      this.imagesChange.emit(imagesUpdated);
      this.coverImageLoadError = false;
    });
    modalRef.dismissed.subscribe(() => {
      this.onImageWarning.emit('cancelled');
    })
    modalRef.result.then((imagesUpdated: string[]) => {
      this.emitSuccessImage(imagesUpdated);
    });
    modalRef.componentInstance.imagesChange.subscribe((imagesUpdated: any) => {
      this.emitSuccessImage(imagesUpdated);
    })
  }

  emitSuccessImage(images: string[]) {
    this.imagesChange.emit(this.getImageDetails(images));
    this.onImageSuccess.emit();

  }

  getImageDetails(images: any) {
    let imageArray = [];
    for (const image of images) {
      const segments = image.split('/');
      const imageName = segments[segments.length - 1];
      imageArray.push({ name: imageName, url: image });
    }
    return imageArray;
  }

  setCoverImgLoadError($event) {
    this.coverImageLoadError = true;
  }
}

@Component({
  selector: 'ta-modify-delete',
  template: `
  <div class="card-button overflow-hidden position-relative">
    <div class="btn btn-sm flex-grow-1 text-white" [routerLink]="target" (click)="$event.stopPropagation()">
        <span class="me-1">Modifica</span>
        <i class="bi bi-pencil"></i>
    </div>
    <div class="btn btn-sm text-white bg-danger position-absolute end-0"
        (click)="emitDeleteAction();$event.stopPropagation()">
        <i class="bi bi-trash2"></i>
    </div>
</div>
  `,
  styles: [`
  .card-button {
    display: flex;
    justify-content:center;
    align-content: center;
    width: 100%;
    background-color: #39ca69;
    color: #fff;
    border-radius: 0 0 0px 0px;
  }`]
})
export class TaModifyDeleteComponent {
  @Input() target: string[];
  @Output() onDelete = new EventEmitter();

  emitDeleteAction() {
    this.onDelete.emit();
  }
}

@Component({
  selector: 'ta-card',
  template: `
    <div class="card-sl mb-1 d-flex align-items-start flex-column p-0 position-relative overflow-hidden bg-white">
  <div class="carousel-box card-image w-100 ">
    <div class="position-relative">
      <div class="row">
        <div style="border-width: 0px !important;" class="col-md-5 map-container border h-100 opacity-gradient">
          <ngb-carousel *ngIf="value.coverImage || value.images || value.image">
            <!-- Show coverImage slide -->
            <ng-template ngbSlide *ngIf="value.coverImage || value.image">
              <div class="row g-0">
                <div class="col-md-12">
                  <img class="card-img-top img-fluid" [src]="config.imagesEndpoint + (value.coverImage?.url?value.coverImage.url:value.image.url)"
                    style="height: 12rem; object-fit: cover" loading="lazy"
                    onerror="this.onerror=null;this.src='assets/placeholder.jpg';" />
                </div>
              </div>
            </ng-template>
            <!-- Show image slide -->
            <ng-template ngbSlide *ngFor="let img of value.images">
              <div class="row g-0">
                <div class="col-md-12">
                  <img class="card-img-top img-fluid" [src]="config.imagesEndpoint+img.url"
                    style="height: 12rem; object-fit: cover" loading="lazy"
                    onerror="this.onerror=null;this.src='assets/placeholder.jpg';" />
                </div>
              </div>
            </ng-template>
          </ngb-carousel>
        </div>
        <div class="card-body position-relative col-md-7" style="z-index:0; height: 12rem">
          <h3 class="card-title fw-semibold pt-2"><span class="me-1">{{ value.name | titlecase }}</span><span *ngIf="entity=='person'">{{value.surname|titlecase}}</span></h3>
          <!-- <h4 class="card-subtitle fw-medium">{{ place.address.street }}</h4> -->
          
          @if(value.details && value.details.description){
            <h4 class="card-text">{{ value.details.description }}</h4>
          }
          @else if(value.address && value.address.street){
            <h4 class="card-text">{{ value.address.street}}</h4>
          }
          
          @if(value.address && value.address.city){
          <strong class="card-text">{{ value.address.city }}</strong>
          }

          
          <div *ngIf="value.tags" class="d-flex w-100 mb-2">
            <div class="d-flex flex-wrap">
              @for(tag of value.tags;track tag) {
              <ta-labeled-badge [content]="tag" color="success" [deletable]="false"></ta-labeled-badge>
              }
            <div>  
          </div>
          
        
        </div>
      </div>
    </div>
    <ta-modify-delete [target]="target?target:value._id" (onDelete)="onDeleteEvent()"></ta-modify-delete>
  </div>
  `,
  styles: [`
  .card-sl {
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.card-image img {
  width: 100%;
  height: auto;
  border-radius: 8px 8px 0px 0;
}

.card-text {
  padding: 10px 15px;
  background: #fff;
  font-size: 14px;
  color: #636262;
}

@media (max-width: 770px) {
  .card-body {
    padding-left: 20px !important;
  }
}
  `]
})
export class TaCardComponent {
  @Input() value: any;
  @Input() entity: string;
  @Input() target: string[]
  @Output() onDelete = new EventEmitter<void>();

  constructor(private modalService: NgbModal,
    private config: NgxTaUiLibraryConfigService) {
  }

  onDeleteEvent() {
    const modalRef = this.modalService.open(TaConfirmModalComponent);
    modalRef.componentInstance.alertTarget = this.entity;

    modalRef.result.then(() => {
      this.onDelete.emit();
    })
  }
}

@Component({
  selector: 'ta-drop-area',
  providers: [FormBuilder],
  template: `
    <div class="p-md-5 mt-3">
    <div class="w-100 m-auto">
        <ngx-file-drop class="" dropZoneLabel="Trascina qui i tuoi file, oppure" (onFileDrop)="dropped($event)" 
        (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">
            <ng-template class="p-2 h-100" ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">  
                <span class="me-2">Trascina qui i tuoi file, oppure</span>
                <button type="button" class="btn btn-light" (click)="openFileSelector()">Apri cartella</button>
            </ng-template>
        </ngx-file-drop>
    </div>
</div>

  `,
  styles: [`
  .custom-ngx-file-drop__drop-zone {
    height:100px;
    margin:auto;
    border:2px dotted;
    border-radius:30px
}

.custom-ngx-file-drop__drop-zone--over {
    background-color:#93939380
}

.custom_ngx-file-drop__content{
    display:flex;
    align-items:center;
    justify-content:center;
    height:100px;
    color: black !important;
}

.custom_ngx-file-drop__drop-zone-label {
    text-align:center
}

.custom_ngx-file-drop__file-input {
    display:none
}
   
:host ::ng-deep .ngx-file-drop__drop-zone {
    height: 70%!important;
    border: 2px dotted #6db155!important;
}

:host ::ng-deep .ngx-file-drop__drop-zone ::ng-deep .ngx-file-drop__content {
    color: #6db155;
}
`]
})
export class TaDropAreaComponent {
  @Input() files: any;
  @Input() uploadDir: string = '';
  @Input() validFilesName: string[] | undefined = undefined;
  @Output() filesChange = new EventEmitter<any>();
  @Output() onError = new EventEmitter<string>();
  @Output() onFileSaved = new EventEmitter<string>();

  state: string = '';
  constructor(private fb: FormBuilder,
    private filesStorageService: FilesStorageService,
  ) { }
  public uploadedFiles: NgxFileDropEntry[] = [];

  public dropped(files: NgxFileDropEntry[]) {

    console.log(files)
    //this.files = files;
    for (const droppedFile of files) {

      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {

          // Here you can access the real file
          if (!this.validFilesName || (this.validFilesName.includes(file.name) && !this.files.find(f => { return f.name == file.name }))) {
            this.filesStorageService.saveFiles([file], this.uploadDir).subscribe((result: any[]) => {
              result.forEach(({ filePath }) => {
                if (filePath.includes(file.name)) {
                  this.files.push({ name: file.name, url: filePath });
                  this.filesChange.emit(this.files);
                  this.onFileSaved.emit(`Hai allegato ${file.name}`);
                }
              })
            })
          }

          else {
            let message = '';
            if (this.files.find(f => { return f.name == file.name })) {
              message = 'Il file è gia presente.'
            } else {
              message = 'Il nome del file non è corretto.';
            }
            this.onError.emit(message);
          }


        });
      } else {
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
  }

  public fileOver(event: any) {

  }

  public fileLeave(event: any) {

  }

  public fileUploadSubmit() {
    this.state = 'done';
  }

}

@Component({
  selector: 'ta-file-drop',
  template: `
<div *ngIf="validFilesName;else noFileConstrantsInstructions" class="p-2 w-100 d-flex flex-column">
    <h4 class="mx-auto mt-5">Allega i seguenti documenti:</h4>
    <div class="mt-5">
        <div *ngFor="let f of validFilesName">
          <h5>Scansiona il file {{f}}</h5>
          <p>Rinomina la scansione come "{{f}}"</p>
        </div>
    </div>
</div>
<ta-drop-area [uploadDir]="uuid"
               [validFilesName]="validFilesName"
               [(files)]="files" (onFileSaved)="onFileSavedEvent($event)"
                class="" (onError)="onErrorEvent($event)"></ta-drop-area>
<div *ngIf="validFilesName;else noFileConstraintsList" class="d-flex flex-row mt-3 fs-4">
    <div *ngFor="let f of validFilesName" class="d-flex justify-content-center">
      <i class="ms-2 bi" [class]="match(f)?'bi-hand-thumbs-up-fill green':'bi-hand-thumbs-down-fill red'"></i>
      <span>{{f}}</span>
      <i class="ms-2 bi bi-trash2 red" [class]="match(f)?'':'d-none'" (click)="deleteFile(f)"></i>
    </div>
</div>
<ng-template #noFileConstraintsList>
    <div class="d-flex flex-row mt-3 fs-4">
        <div *ngFor="let f of files" class="d-flex justify-content-center">
          <a [href]="config.filesEndpoint+f.url">{{f.name}}</a>
          <i class="ms-2 bi bi-trash2 red" (click)="deleteFile(f.name)"></i>
        </div>
    </div>
</ng-template>
<ng-template #noFileConstrantsInstructions>
    <div class="p-2 w-100 d-flex flex-column">
        <h4 class="mx-auto mt-5">Trascina Qui i tuoi file.</h4>
    </div>
</ng-template>
  `,
  styles: [`
    .green {
      color:#6db155;
    }

    .red {
      color:red;
    }
  `]
})
export class TaFileDropComponent {
  @Input() files: any;
  @Input() uuid: string;
  @Input() validFilesName: string[] | undefined = undefined;
  @Output() filesChange: EventEmitter<Media[]> = new EventEmitter<any>();
  @Output() onFileRemoved: EventEmitter<string> = new EventEmitter<string>();
  @Output() onFileSaved: EventEmitter<string> = new EventEmitter<string>();
  @Output() onError: EventEmitter<string> = new EventEmitter<string>();

  constructor(private config: NgxTaUiLibraryConfigService,
    private filesStorageService: FilesStorageService) { }

  ngOnInit(): void {
  }

  deleteFile(name: string) {
    let filePath = this.files.find((f: Media) => {
      return f.name == name
    })?.url;
    this.filesStorageService.deleteFiles([filePath ? filePath : '']).subscribe((deletedPath: string[]) => {
      if (deletedPath[0] == filePath) {
        this.files?.splice(this.files.map(f => { return f.name }).indexOf(name));
        this.onFileRemoved.emit(`Il file ${name} è stato rimosso`);
        this.filesChange.emit(this.files);
      } else {
        this.onErrorEvent(`Ops.. qualcosa è andato storto..`);
      }
    })
  }

  match(name: string): boolean {
    return !!this.files.find((f: Media) => {
      return f.name == name;
    })
  }

  onErrorEvent(message: string) {
    this.onError.emit(message);
  }

  onFileSavedEvent(message: string) {
    this.onFileSaved.emit(message)
  }
}

/*@Component({
  selector: 'ta-filtered-area-list',
  template: `
    <div class="mb-5 overflow-auto">
      <div class="p-3 text-center sticky-top bg-light rounded">
        <div class="input-group input-group-sm w-25 position-absolute" style="left:1rem;">
          <input type="text" (input)="filterAreas($event)" class="form-control" placeholder="Filtra...">
        </div>
        <div class="lead fw-bold justify-self-center">Seleziona le Aree</div>
      </div>
      <div *ngFor="let a of selectableAreas" class="border-bottom p-2" [hidden]="a.isHidden">
        <div class="w-100 d-flex flex-row">
          <div #target class="">{{a.name | titlecase}}</div>
          <div class="ms-auto form-check">
            <input class="form-check-input" type="checkbox" [value]="a._id" id="flexCheckChecked"
              [checked]="a.isChecked" (change)="onCheckboxChange(a._id)">
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [``]
})
export class TaFilteredAreaListComponent implements AfterViewInit {
  @Input() areas: string[] = [];
  @Input() selectableAreas: Area[] = [];
  @Output() areasChange = new EventEmitter<string[]>();

  constructor() { }

  ngAfterViewInit() {
    this.selectableAreas.map((sArea) => {
      if (this.areas.includes(sArea._id)) {
        sArea.isChecked = true;
      } else {
        sArea.isChecked = false;
      }
      sArea.isHidden = false;
    });
    this.selectableAreas.sort((a, b) => {
      if (a.isChecked && !b.isChecked) {
        return -1; // `a` comes before `b`
      } else if (!a.isChecked && b.isChecked) {
        return 1; // `b` comes before `a`
      } else {
        // If isChecked properties are the same, sort alphabetically
        return a._id.localeCompare(b._id);
      }
    })
  }

  filterAreas(event: any) {
    this.selectableAreas.map((sArea) => {
      if (sArea.name.toLowerCase().includes(event.target.value.toLowerCase())) {
        sArea.isHidden = false;
      } else {
        sArea.isHidden = true;
      }
    });
  }

  onCheckboxChange(area: string) {
    if (this.areas.includes(area)) {
      // If item already selected, remove it
      this.areas = this.areas.filter(a => a !== area);
    } else {
      // If item not selected, add it
      this.areas.push(area);
    }
    this.areasChange.emit(this.areas);
  }
}*/

@Component({
  selector: 'ta-filterable-list',
  template: `
    <div class="mb-5 overflow-auto">
      <div class="p-3 text-center sticky-top bg-light rounded">
        <div class="input-group input-group-sm w-25 position-absolute" style="left:1rem;">
          <input type="text" (input)="filter($event)" class="form-control" placeholder="Filtra...">
        </div>
        <div class="lead fw-bold justify-self-center">{{label}}</div>
      </div>
      <div *ngFor="let s of selectable;let i = index" class="border-bottom p-2" [hidden]="s.isHidden">
        <div class="w-100 d-flex flex-row">
          <div #target class="">{{s.name | titlecase}}</div>
          <div class="ms-auto form-check">
            <input class="form-check-input" type="checkbox" [value]="s._id" id="flexCheckChecked{{i}}"
              [checked]="s.isChecked" (change)="onCheckboxChange(s._id)">
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [``]
})
export class TaFilterableListComponent implements AfterViewInit {
  @Input() label: string = 'Seleziona';
  @Input() selected: string[] = [];
  @Input() selectable: Selectable[] = [];
  @Output() selectedChange = new EventEmitter<string[]>();

  constructor() { }

  ngAfterViewInit() {
    this.selectable.map((s) => {
      if (this.selected.includes(s._id)) {
        s.isChecked = true;
      } else {
        s.isChecked = false;
      }
      s.isHidden = false;
    });
    this.selectable.sort((a, b) => {
      if (a.isChecked && !b.isChecked) {
        return -1; // `a` comes before `b`
      } else if (!a.isChecked && b.isChecked) {
        return 1; // `b` comes before `a`
      } else {
        // If isChecked properties are the same, sort alphabetically
        return a._id.localeCompare(b._id);
      }
    })
  }

  filter(event: any) {
    this.selectable.map((s) => {
      if (s.name.toLowerCase().includes(event.target.value.toLowerCase())) {
        s.isHidden = false;
      } else {
        s.isHidden = true;
      }
    });
  }

  onCheckboxChange(selected: string) {
    if (this.selected.includes(selected)) {
      // If item already selected, remove it
      this.selected = this.selected.filter(a => a !== selected);
    } else {
      // If item not selected, add it
      this.selected.push(selected);
    }
    this.selectedChange.emit(this.selected);
  }
}

@Component({
  selector: 'ta-x-drop',
  template: `
  `,
  styles: [``]
})
export class TaPushArrayComponent {
  @Input() value: string;
  @Output() valueChange = new EventEmitter<any>();

  onModelChange(event: any) {
    this.valueChange.emit(event);
  }
}