import { ModuleWithProviders, NgModule } from "@angular/core";
import { NgxTaUiLibraryConfigService } from "./ngx-ta-ui-library.service";
import {
    TaAdvancedPeriodPickerComponent,
    TaCardComponent, TaCheckboxComponent, TaConfirmModalComponent, TaContactInfosComponent, TaCostComponent, TaDropAreaComponent, TaFileDropComponent, TaFilterableListComponent,
    //TaFilteredAreaListComponent, 
    TaImagesSelectorComponent, TaInputComponent, TaLabeledBadgeComponent, TaLabeledTimeFramePickerComponent, TaModifyDeleteComponent, TaMultiImagesComponent, TaPeriodPickerComponent,
    TaScheduleComponent,
    //TaPushArrayComponent, 
    TaSingleImageComponent, TaTagsComponent, TaTimeFramePickerComponent
} from "./ngx-ta-ui-library.component";
import { CommonModule } from "@angular/common";
import { FormBuilder, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbDateAdapter, NgbDateParserFormatter, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { RouterModule } from "@angular/router";
import { NgxFileDropModule } from "ngx-file-drop";
import { TaLibraryConfig } from "./ngx-ta-ui-library.model";
import { TaDateAdapter, TaDateFormatter, TaPhonePipe } from "./ngx-ta-ui-library.pipes";

@NgModule({
    declarations: [
        TaInputComponent,
        TaLabeledBadgeComponent,
        TaPeriodPickerComponent,
        TaAdvancedPeriodPickerComponent,
        TaScheduleComponent,
        TaCostComponent,
        TaTimeFramePickerComponent,
        TaLabeledTimeFramePickerComponent,
        TaCheckboxComponent,
        TaTagsComponent,
        //    TaPushArrayComponent,
        TaContactInfosComponent,
        TaConfirmModalComponent,
        TaImagesSelectorComponent,
        TaSingleImageComponent,
        TaMultiImagesComponent,
        TaModifyDeleteComponent,
        TaCardComponent,
        TaDropAreaComponent,
        TaFileDropComponent,
        //TaFilteredAreaListComponent,
        TaFilterableListComponent,
        TaPhonePipe
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        NgbModule,
        NgxFileDropModule,
        NgbModule
    ],
    exports: [
        TaInputComponent,
        TaLabeledBadgeComponent,
        TaPeriodPickerComponent,
        TaAdvancedPeriodPickerComponent,
        TaScheduleComponent,
        TaCostComponent,
        TaTimeFramePickerComponent,
        TaLabeledTimeFramePickerComponent,
        TaCheckboxComponent,
        TaTagsComponent,
        //    TaPushArrayComponent,
        TaContactInfosComponent,
        TaConfirmModalComponent,
        TaImagesSelectorComponent,
        TaSingleImageComponent,
        TaMultiImagesComponent,
        TaModifyDeleteComponent,
        TaCardComponent,
        TaDropAreaComponent,
        TaFileDropComponent,
        //TaFilteredAreaListComponent,
        TaFilterableListComponent,
        TaPhonePipe
    ],
    providers: [FormBuilder,
        // TODO: remove this and replace with pipes in the html
        { provide: NgbDateParserFormatter, useClass: TaDateFormatter },
        { provide: NgbDateAdapter, useClass: TaDateAdapter }
    ]
})
export class TaLibraryModule {
    static forRoot(config: TaLibraryConfig): ModuleWithProviders<TaLibraryModule> {
        return {
            ngModule: TaLibraryModule,
            providers: [
                { provide: NgxTaUiLibraryConfigService, useValue: config },
            ],
        };
    }
}