import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Guidebook, Setting } from 'src/app/guides/model/interfaces';
import { GuidebookService } from 'src/app/guides/services/guidebook.service';
import { EMPTY, Observable, ReplaySubject, switchMap, take, tap } from 'rxjs';
import { GameService } from 'src/app/contest/services/game.service';
import { BreadcrumbService } from '@luhuiguo/xng-breadcrumb';
import { SharedEventService } from 'src/app/common/services/shared-event.service';

@Component({
  selector: 'app-guidebooks',
  templateUrl: './guidebooks.component.html',
  styleUrls: ['./guidebooks.component.css'],
})
export class GuidebooksComponent implements OnInit {

  configLoaded$: ReplaySubject<any>;
  settings: any[] = [];
  area: string = '';
  guidebooks: Guidebook[] = [];
  itineraries: [];
  games = [];
  loading: boolean = false;
  menuVisible: boolean = true;


  constructor(
    private breadcrumbService: BreadcrumbService,
    private sharedEvent: SharedEventService
  ) {
    this.configLoaded$ = this.sharedEvent.configLoadedEvent;
    this.configLoaded$.subscribe((data) => {
      this.settings = data;
    })
  }

  ngOnInit(): void {
    this.breadcrumbService.set('areas/:id/guidebooks', `Guide`);
  }

  toggleMenu() {
    this.menuVisible = !this.menuVisible;
  }

}


/*SET @@group_concat_max_len = value_numeric;*/