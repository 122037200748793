//import { Tag } from 'src/app/common/model/interfaces';
import { v4 as uuidv4 } from 'uuid';

export class Area {
  constructor() {
    this.uuid = uuidv4();
  }
  _id?: string;
  uuid: string;
  name: string;
  code: string;
  description: string;
  image: { name: string, url: string } = { name: '', url: '' };
  aiSubject?: AISubject | string;
  latlng: { lat: number; lng: number };
  active: boolean;
}

export interface Intent {
  name: string;
  answer: string;
  active: boolean;
}

//type vals<Type> = Type | Type[];

export interface Setting {
  param: string;
  type: number;
  area: string;
  vals: any[];
  valsArray?: string[];
  //vals: vals<string>
}

export interface Cost {
  key: string;
  value: string;
}
//export interface LatLong { lat:number; long: number}

export interface Address {
  country: { code: string, name: string };
  street: string;
  city: string;
  zip: string
  latlng: Latlng
}

export interface Latlng {
  lat: string;
  lng: string;
}

export interface PlaceDetails {
  description: string;
  notes: string;
  type: string[];
  class: string[];
  cost: { [key: string]: string }[]
}

export interface Intent {
  name: string;
  answer: string;
  active: boolean;
}

// export interface AISubject {
//   constructor() {
//     this.intents = {
//       name: '',
//       answer: '',
//       active: false,
//     }
//   }
//   _id?: string;
//   uuid: string;
//   name: string;
//   intents: Intent[];
//   active: boolean;
//   subSubjects: string[];
// }
export class AISubject {
  constructor() {
    this.intents = [];
    this.subSubjects = [];

  }
  _id?: string;
  name: string;
  intents: Intent[] = [];
  subSubjects: [] = [];
  active: boolean;
}

export interface PlaceClassType {
  id: string;
  languages: {
    it: string;
    en: string;
  };
}

// export interface AiSubject {
//   name: string;
//   intents: Intent[];
//   active: boolean;
//   subSubjects: string[];
// }

export interface Options {
  arrivalPoint: boolean;
  suitableForSpareTime: boolean;
  canSleepOver: boolean
  visitDuration: { hours: number, minutes: number };
}

export class Place {
  constructor() {
    this.uuid = uuidv4();
    this.address = {
      country: { code: '', name: '' },
      street: '',
      city: '',
      zip: '',
      latlng: {
        lat: '',
        lng: '',
      }
    }
    // this.details = {} as PlaceDetails;
    this.details = {
      description: '',
      type: [],
      class: [],
      notes: '',
      cost: []
    }
    this.options = {
      arrivalPoint: false,
      visitDuration: { hours: 0, minutes: 0 },
      suitableForSpareTime: false,
      canSleepOver: false,
    }
    // this.options = {} as options;
    // this.options.costs = [];
    this.images = [];
    // this.address = {} as Address;
    // this.address.latlng = {} as Latlng;
  }
  _id?: string;
  uuid: string;
  area: any;
  googleId: string;
  name: string;
  googleName: string;
  aiSubject: any | undefined;
  shop: any
  coverImage: Image;
  images: Image[] = [];
  options: Options;
  details: PlaceDetails;
  contactInfos: any;
  address: Address;
  tags: string[] = [];
  active: boolean = true;
}

export class Itinerary {
  constructor() {
    this.uuid = uuidv4();
  }
  _id?: string;
  uuid: string;
  area: string;
  name: string;
  description: string;
  coverImage: Image;
  images: Image[] = [];
  type: string;
  notes: string;
  imgPath: string;
  imgFile?: string;
  tags: string[];
  active: boolean;
  places: string[] = [];
  selectedPlaces?: { id?: string; placeId: string, ordinal: number }[];
  placesToRemove?: string[];
  placesToAdd?: { placeId: string, ordinal: number }[];
}

export interface Guidebook {
  id?: number;
  area: number;
  name: string;
  type: string;
  iconPath: string;
  coverImagePath: string;
  description: string;
  images: Image;
  notes: string;
  specs?: {
    [spec: string]: boolean;
  }
}

export interface Image {
  name?: string;
  url: string;
  title?: string;
  desc?: string;
}

export class Person {
  constructor(area: string) {
    this.uuid = uuidv4();
    this.areas.push(area)
  }
  _id?: string;
  uuid: string;
  name: string = '';
  surname: string = '';
  user: string = null;
  areas: string[] = [];
  activities: string[] = [];
  isProfessional: boolean = false;
  isBookable: boolean = false;
  coverImage: Image = { name: '', url: '' };
  images: Image[] = [];
  notes: string = '';
  contacts: ContactInfos = { emails: [], phones: [], websites: [], socials: [] };
  tags: string[] = [];
  active: boolean = true;
}

export interface ContactInfos {
  emails: Email[];
  phones: Phone[];
  websites: Website[];
  socials: Social[];
}

export interface Contact {
  label: string;
}
export interface Phone extends Contact {
  number: string;
}
export interface Email extends Contact {
  address: string;
}
export interface Social extends Contact {
  url: string;
}
export interface Website extends Contact {
  url: string;
}

export class Experience {
  constructor(area: string) {
    this.uuid = uuidv4();
    this.area = area;
    this.name = '';
    this.type = 'experience';
    this.description = '';
    this.coverImage = { name: '', url: '' };
    this.images = [];
    this.notes = '';
    this.tags = [];
    this.places = [];
    this.duration = {};
    this.period = {};
    this.timeframes = [{ start: { hour: 0, minute: 0, second: 0 }, end: { hour: 0, minute: 0, second: 0 } }];
    this.active = true;
    this.contacts = [];
  }
  _id?: string;
  uuid: string;
  area: string;
  name: string;
  type: string;
  description: string;
  coverImage: Image;
  images: Image[];
  notes: string;
  tags: string[];
  places: string[];
  duration: { [key: string]: string };
  period: { [key: string]: string };
  timeframes: { start: { hour: number, minute: number, second: number }, end: { hour: number, minute: number, second: number } }[];
  active: boolean;
  contacts: string[];
}

export class Activity {
  constructor(area: string) {
    this.uuid = uuidv4();
    this.area = area;
    this.name = '';
    this.type = 'activity';
    this.description = '';
    this.coverImage = { name: '', url: '' };
    this.images = [];
    this.notes = '';
    this.tags = [];
    this.places = [];
    this.duration = {};
    this.period = {};
    this.timeframes = [{ start: { hour: 0, minute: 0, second: 0 }, end: { hour: 0, minute: 0, second: 0 } }];
    this.active = true;
  }
  _id?: string;
  uuid: string;
  area: string;
  name: string;
  type: string;
  description: string;
  coverImage: Image;
  images: Image[];
  notes: string;
  tags: string[];
  places: string[];
  duration: { [key: string]: string };
  period: { [key: string]: string };
  timeframes: { start: { hour: number, minute: number, second: number }, end: { hour: number, minute: number, second: number } }[];
  active: boolean;
}

export class Event {
  constructor(area: string) {
    this.uuid = uuidv4();
    this.area = area;
    this.name = '';
    this.type = 'event';
    this.description = '';
    this.coverImage = { name: '', url: '' };
    this.images = [];
    this.notes = '';
    this.tags = [];
    this.places = [];
    this.duration = {};
    this.costs = {};
    this.period = {} as { start: string, end: string };
    this.timeframes = [{ start: { hour: 0, minute: 0, second: 0 }, end: { hour: 0, minute: 0, second: 0 } }];
    this.links = [{ url: '', name: '' }];
    this.multiEvent = false;
    this.active = true;
    this.eventtypes = [];
    this.files = [];
    this.contacts = [];
    this.contactInfos = { emails: [], phones: [], websites: [], socials: [] };
    this.eventdays = [];
  }
  _id?: string;
  uuid: string;
  area: string;
  name: string;
  type: string;
  description: string;
  coverImage: Image;
  images: Image[];
  notes: string;
  tags: string[];
  places: string[];
  duration: { [key: string]: string };
  costs: { [key: string]: string };
  period: { start: string, end: string };
  timeframes: { start: { hour: number, minute: number, second: number }, end: { hour: number, minute: number, second: number } }[];
  links: { url: string, name: string }[];
  multiEvent: boolean;
  active: boolean;
  eventtypes: string[];
  files: Media[];
  contacts: string[];
  contactInfos: ContactInfos;
  eventdays: string[];
}

export class ListGuidebook {
  constructor(area: string, name: string, contentType: string, contentId: string) {
    this.uuid = uuidv4();
    this.area = area;
    this.name = name;
    this.type = 'list';
    this.contentType = contentType;
    this.contentId = contentId;
    this.iconPath = '';
    this.coverImage = { name: '', url: '' };
    this.description = '';
    this.notes = '';
    this.content = [];
    this.tags = [];
  }
  _id?: string;
  uuid: string;
  area: string;
  name: string;
  type: string;
  contentType: string;
  contentId: string;
  content: [];
  iconPath: string;
  coverImage: Image;
  description: string;
  images: Image;
  notes: string;
  tags: string[];
}

export class Product {
  constructor(area: string) {
    this.uuid = uuidv4();
    this.area = area;
    this.name = '';
    this.type = 'product';
    this.market = 'food';
    this.coverImage = { name: '', url: '' };
    this.images = [];
    this.description = '';
    this.history = '';
    this.notes = '';
    this.producers = [];
    this.resellers = [];
    this.tags = [];
  }
  _id?: string;
  uuid: string;
  area: string;
  name: string;
  type: string;
  market: string;
  coverImage: Image;
  images: Image[];
  description: string;
  history: string;
  notes: string;
  producers: string[];
  resellers: string[];
  tags: string[];
}

export interface Media {
  name?: string;
  url: string;
  mimeType?: string;
  size?: string;
}

export interface Image extends Media { }
export interface File extends Media { }
