import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
  selector: 'app-modify-delete',
  templateUrl: './modify-delete.component.html',
  styleUrls: ['./modify-delete.component.css'],
  host: { 'class': 'w-100' }
})
export class ModifyDeleteComponent {
  @Input() target: string[];
  @Output() onDelete = new EventEmitter();

  emitDeleteAction() {
    this.onDelete.emit();
  }
  /*checkFirstWord(input: string): boolean {
    const words = input.split('/');
    const firstWord = words[0];
    this.areaTarget = words[1];
    return firstWord === 'add';
  }*/
}
