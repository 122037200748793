

<div class="container d-flex justify-content-center align-items-center m-3">

  <div cdkDropList cdkDropListOrientation="horizontal" class="progresses w-100" (cdkDropListDropped)="drop($event)">
  <ng-container *ngIf="places.steps.length">
    <ng-container *ngFor="let step of places.steps; let i = index; let f=first;let l = last" >
      <div class="d-flex flex-column justify-content-center" [style.width.%]="step.duration.values.hours*10" cdkDrag [cdkDragDisabled]="f||l"
      >
        <div class="steps align-self-center" [style.border-color]="color" >
          <span class="font-weight-bold list-sorted__item" [style.color]="color">{{ step.placeId }}</span>
        </div>
        <span class="line " [style.background-color]="color"></span>
        <!--span class="line " [ngStyle]="l?{'visibility':'collapse'}:''"></span-->
        <!--ng-container *ngIf="!l">
          <span class="line w-100"></span>
        </ng-container-->
      </div>
    </ng-container>
  </ng-container>
  </div>
</div>
<!--p class="small">{{steps | json}}</p-->
<!--div cdkDropList cdkDropListOrientation="horizontal" class="example-list" (cdkDropListDropped)="drop($event)">
  <div class="example-box" *ngFor="let timePeriod of timePeriods" cdkDrag>{{timePeriod}}</div>
</div-->