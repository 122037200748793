import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from 'src/app/common/components/confirm-modal/confirm-modal.component';
import { Person } from 'src/app/guides/model/interfaces';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-person-item',
  templateUrl: './person-item.component.html',
  styleUrls: ['./person-item.component.css'],
})
export class PersonItemComponent implements OnInit {
  @Input() person: Person;
  @Output() onDeletePerson: EventEmitter<Person> = new EventEmitter();
  
  imgpref = environment.imagesPrefix;

  constructor(private router: Router,
    private modalService: NgbModal) {}

  ngOnInit(): void {
    //console.log(this.person);
    //this.person.tags = <string[]>JSON.parse(<string>this.person.tags)
  }

  onDelete(person: Person) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.alertTarget = 'luogo';
    
    modalRef.result.then(() => {
      this.onDeletePerson.emit(person);  
    })
  }
}
