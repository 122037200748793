import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Place } from 'src/app/guides/model/interfaces';
import { NavigationExtras, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from 'src/app/common/components/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-place-item',
  templateUrl: './place-item.component.html',
  styleUrls: ['./place-item.component.css'],
})
export class PlaceItemComponent implements OnInit {
  @Input() place: Place;
  imagesBaseDir = environment.IMAGES;
  @Output() onDeletePlace: EventEmitter<Place> = new EventEmitter();

  constructor(private router: Router,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    // console.log(this.place)
  }

  onDelete(place) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.alertTarget = 'place';

    modalRef.result.then(() => {
      this.onDeletePlace.emit(place);
    })
  }
}
