import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { Setting } from '../model/interfaces';
import { concatMap, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SharedEventService } from 'src/app/common/services/shared-event.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private settingParamsApi = `${environment.dbApiBaseDir}settings/default`;

  constructor(private http: HttpClient, private sharedEvent: SharedEventService) { }

  loadSettings(): boolean {
    let url = `${this.settingParamsApi}`;
    this.http.get(url).subscribe((data: any) => {
      this.sharedEvent.configLoadedEvent.next(data);
    });
    console.log(sessionStorage);
    return true;
  }

  getSettingParams(area?: string, param?: string[]): Observable<Setting[]> {
    let url = `${this.settingParamsApi}${area ? '?filter=area,eq,' + area : ''}${param ? '&filter=param,in,' + [...param] : ''}`;
    console.log(url);
    return this.http.get<any>(url).pipe(
      map(({ records }) => {
        let settings: Setting[] = records;
        /*settings.map(s=>{
          s.vals = JSON.parse(<string><any>s.vals);
        })*/
        return settings;
      })
    );
  }



  splitPairToKeyValue(pair: string, separator: string): { k: string, v: string } {
    let str: string[] = pair.split(separator);
    return { k: str[0], v: str[1] }
  }
}
