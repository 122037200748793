import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from '@luhuiguo/xng-breadcrumb';
import { combineLatest } from 'rxjs';
import { Experience } from 'src/app/guides/model/interfaces';
import { GuidebookService } from 'src/app/guides/services/guidebook.service';
import { PeopleService } from 'src/app/guides/services/people.service';
import { PlaceService } from 'src/app/guides/services/place.service';
import { StateService } from 'src/app/services/state.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-experience',
  templateUrl: './create-experience.component.html',
  styleUrl: './create-experience.component.css'
})
export class CreateExperienceComponent {
  params$ = this.route.params;
  area$ = this.stateService.selectedArea$;
  tooltipImage: string = '';
  imagesBaseDir = environment.imagesPrefix;
  experience: Experience;
  places: any;
  people: any;

  constructor(private guidebookService: GuidebookService,
    private route: ActivatedRoute,
    private placeService: PlaceService,
    private peopleService: PeopleService,
    private breadcrumbService: BreadcrumbService,
    private stateService: StateService) {
  }

  ngOnInit(): void {
    combineLatest([this.params$, this.area$]).subscribe(([params, area]) => {
      if (params.id == 0) {
        this.breadcrumbService.set('areas/:id/guidebooks/dyn/experience/:id', `Aggiungi Esperienza`);
        this.experience = new Experience(area._id);
        this.populatePlaces(area._id);
        this.populatePeople(area._id);

      } else {
        this.guidebookService.getGuidebookById(params.id).subscribe((experience: any) => {
          this.experience = experience;
          this.breadcrumbService.set('areas/:id/guidebooks/dyn/experience/:id', `Esperienza: ${experience.name}`);
          this.populatePlaces(area._id);
          this.populatePeople(area._id);
        });
      }
    });
  }

  populatePlaces(area: string) {
    this.placeService.getMinPlaces(area).subscribe((places: any) => {
      places.map((place) => {
        place.isChecked = this.experience.places.includes(place._id);
        place.isHidden = false;
      });
      places.sort((a, b) => {
        if (a.isChecked && !b.isChecked) {
          return -1; // `a` comes before `b`
        } else if (!a.isChecked && b.isChecked) {
          return 1; // `b` comes before `a`
        } else {
          // If isChecked properties are the same, sort alphabetically
          return a.name.localeCompare(b.name);
        }
      });
      this.places = places;
    })
  }

  populatePeople(area: string) {
    this.peopleService.getPeopleByArea(area).subscribe((people: any) => {
      people.map((person) => {
        person.name = `${person.name} ${person.surname}`;
        person.isChecked = this.experience.contacts.includes(person._id);
        person.isHidden = false;
      });
      people.sort((a, b) => {
        if (a.isChecked && !b.isChecked) {
          return -1; // `a` comes before `b`
        } else if (!a.isChecked && b.isChecked) {
          return 1; // `b` comes before `a`
        } else {
          // If isChecked properties are the same, sort alphabetically
          return a.name.localeCompare(b.name);
        }
      });
      this.people = people;
    });
  }

  setTooltipImage(imageUrl, tooltip) {
    tooltip.container = 'body';
    this.tooltipImage = `${this.imagesBaseDir}${imageUrl}`;
  }

  removeTooltipImage(tooltip) {
    tooltip.close(false);
    this.tooltipImage = '';
  }
}
