import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SettingsComponent } from "./components/settings/settings.component";
import { AuthGuard } from "../auth/auth.guard";
import { UiLibraryComponent } from "./components/ui-library/ui-library.component";

const routes: Routes = [
    {
        path: "settings", component: SettingsComponent,
        canActivate: [AuthGuard]
    },
    {
        path: "library", component: UiLibraryComponent,
        canActivate: [AuthGuard]
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class SettingsRoutingModule { }