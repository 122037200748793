import { NgModule } from '@angular/core';
import { RouterModule, Routes, mapToCanActivate } from '@angular/router';
import { PlacesComponent } from './components/places/places.component';
import { ItinerariesComponent } from './components/itineraries/itineraries.component';
import { AreasComponent } from './components/areas/areas.component';
import { GuidebooksComponent } from './components/guidebooks/guidebooks.component';
import { PeopleComponent } from './components/people/people.component';
import { AddPersonComponent } from './components/people/add-person/add-person.component';
import { SettingsComponent } from './components/settings/settings.component';
import { AddPlaceComponent } from './components/places/add-place/add-place.component';
import { GuideSwitchComponent } from './components/guidebooks/guide-switch/guide-switch.component';
import { AddItineraryComponent } from './components/itineraries/add-itinerary/add-itinerary.component';
import { SelectedAreaComponent } from './components/areas/selected-area/selected-area.component';
import { AreaSideDetailsComponent } from './components/areas/area-side-details/area-side-details.component';
import { AiSubjectsComponent } from './components/ai-subjects/ai-subjects.component';
import { AddSubjectsComponent } from './components/ai-subjects/add-subjects/add-subjects.component';
import { AddAreaComponent } from './components/areas/add-area/add-area.component';
import { AuthGuard } from '../auth/auth.guard';
import { CreateEventComponent } from './components/guidebooks/guide-switch/guidebook-event/components/create-event/create-event.component';
import { CreateActivityComponent } from './components/guidebooks/guide-switch/guidebook-activity/components/create-activity/create-activity.component';
import { CreateExperienceComponent } from './components/guidebooks/guide-switch/guidebook-experience/components/create-experience/create-experience.component';
import { CreateProductComponent } from './components/guidebooks/guide-switch/guidebook-product/components/create-product/create-product.component';


const routes: Routes = [
  {
    path: "areas", component: AreasComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  },
  {
    path: "areas/add/:id", component: AddAreaComponent, canActivate: [AuthGuard],
  },
  {
    path: "areainfo", component: AreaSideDetailsComponent, outlet: 'info'
  },
  {
    path: "areas/:id", component: SelectedAreaComponent, canActivate: [AuthGuard],
    children: [
      {
        path: 'places', component: PlacesComponent,
        children: [
        ]
      },
      {
        path: 'places/:id', component: AddPlaceComponent
      },
      {
        path: 'itineraries', component: ItinerariesComponent,
        children: [
        ]
      },
      {
        path: 'itineraries/:id', component: AddItineraryComponent
      },
      {
        path: 'people', component: PeopleComponent,
        children: [
          {
            path: ':id', component: AddPersonComponent
          }
        ]
      },
      {
        path: 'guidebooks', component: GuidebooksComponent,
        children: [
          {
            path: "dyn", component: GuideSwitchComponent,
            children: [
              {
                //path: "event/:id", loadComponent: () => import('./components/guidebooks/guide-switch/guidebook-event/components/create-event/create-event.component').then(c => c.CreateEventComponent)
                path: "event/:id", component: CreateEventComponent
              },
              {
                //path: "event/:id", loadComponent: () => import('./components/guidebooks/guide-switch/guidebook-event/components/create-event/create-event.component').then(c => c.CreateEventComponent)
                path: "activity/:id", component: CreateActivityComponent
              },
              {
                //path: "event/:id", loadComponent: () => import('./components/guidebooks/guide-switch/guidebook-event/components/create-event/create-event.component').then(c => c.CreateEventComponent)
                path: "experience/:id", component: CreateExperienceComponent
              },
              {
                //path: "event/:id", loadComponent: () => import('./components/guidebooks/guide-switch/guidebook-event/components/create-event/create-event.component').then(c => c.CreateEventComponent)
                path: "product/:id", component: CreateProductComponent
              },
            ]
          },
        ]
      },
      {
        path: 'aisubjects', component: AiSubjectsComponent,
        children: [
        ]
      },
      {
        path: 'aisubjects/:id', component: AddSubjectsComponent
      },
      /*{
        path: "guidebooks/dyn/event/:id", loadComponent: () => import('./components/guidebooks/guide-switch/guidebook-event/components/create-event/create-event.component').then(c => c.CreateEventComponent)
      }*/
    ]
  },
  // {
  //   path: "guidebooks", component: GuidebooksComponent,
  //   children: [
  //     //{path: "guidebookConfig", component: GuideModalComponent},
  //   ]
  // },
  // {
  //   path: "guidebooks/guidebookConfig", component: GuideSwitchComponent,
  //   children: []
  // },
  // {
  //   path: "guidebooks/:sectionUuid/:guideUuid", component: GuideSwitchComponent,
  //   children: []
  // },
  /*{
    path: 'places', component: PlacesComponent,
    children: [
      //{path: "sessions", component: SessionsComponent},
    ]
  },
  {
    path: 'places/:uuid', component: AddPlaceComponent,
    children: [
      //{path: "sessions", component: SessionsComponent},
    ]
  },*/
  // {
  //   path: "itineraries", component: ItinerariesComponent,
  //   children: [
  //     //{path: "details/:id", component: QuestionDetailsComponent},
  //   ]
  // },
  // {
  //   path: 'itineraries/:uuid', component: AddItineraryComponent,
  //   children: [
  //     //{path: "sessions", component: SessionsComponent},
  //   ]
  // },
  // {
  //   path: "people", component: PeopleComponent,
  //   children: [
  //     //{path: "details/:id", component: QuestionDetailsComponent},
  //   ]
  // },
  // {
  //   path: "people/:uuid", component: AddPersonComponent,
  //   children: [
  //     //{path: "details/:id", component: QuestionDetailsComponent},
  //   ]
  // },
  // {
  //   path: "addPerson", component: AddPersonComponent,
  //   children: [
  //     //{path: "details/:id", component: QuestionDetailsComponent},
  //   ]
  // },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class GuidesRoutingModule { }