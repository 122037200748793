<div class="p-5">
    <div class="d-flex flex-column border rounded p-1">
        <label class="form-label">Input</label>
        <div class="d-flex flex-row">
            <ta-input label="Input" [(value)]="input"></ta-input>
        </div>
        <div>input:{{input|json}}</div>
        <markdown class="mb-1" [data]="getMarkdown([{lang:'html',code:inputCode},{lang:'oggetto',code:inputVar}])">
        </markdown>
    </div>
    <br>
    <div class="d-flex flex-column border rounded p-1">
        <label class="form-label">Etichette</label>
        <div class="d-flex flex-row">
            <ta-labeled-badge label="label" content="content" color="pink"></ta-labeled-badge>
            <ta-labeled-badge label="label" content="content" color="indigo"></ta-labeled-badge>
        </div>
        <markdown class="mb-1" [data]="getMarkdown([{lang:'html',code:labeledBadgeCode}])"></markdown>
    </div>
    <br>
    <div class="d-flex flex-column border rounded p-1">
        <label class="form-label">Periodo</label>
        <div class="d-flex flex-row">
            <ta-period-picker [(period)]="period"></ta-period-picker>
        </div>
        <div>period:{{period|json}}</div>
        <markdown class="mb-1" [data]="getMarkdown([{lang:'html',code:periodCode},{lang:'oggetto',code:periodVar}])">
        </markdown>
    </div>
    <br>
    <div class="d-flex flex-column border rounded p-1">
        <label class="form-label">Periodo | Advanced (tenere premuto ctrl su pc/command su mac per inserire giorni
            off)</label>
        <div class="d-flex flex-row">
            <ta-advanced-period-picker [(period)]="advPeriod"></ta-advanced-period-picker>
        </div>
        <div>period:{{advPeriod|json}}</div>
        <markdown class="mb-1"
            [data]="getMarkdown([{lang:'html',code:advPeriodCode},{lang:'oggetto',code:advPeriodVar}])">
        </markdown>
    </div>
    <br>
    <div class="d-flex flex-column border rounded p-1">
        <label class="form-label">Schedule</label>
        <div class="d-flex flex-row">
            <ta-schedule [(schedule)]="schedule"></ta-schedule>
        </div>
        <div>schedule:{{schedule|json}}</div>
        <markdown class="mb-1"
            [data]="getMarkdown([{lang:'html',code:scheduleCode},{lang:'oggetto',code:scheduleVar}])">
        </markdown>
    </div>
    <br>
    <div class="d-flex flex-column border rounded p-1">
        <label class="form-label">Costo</label>
        <div class="d-flex flex-row">
            <ta-cost [(costs)]="costs"></ta-cost>
        </div>
        <div>costs:{{costs|json}}</div>
        <markdown class="mb-1" [data]="getMarkdown([{lang:'html',code:costsCode},{lang:'oggetto',code:costsVar}])">
        </markdown>
    </div>
    <br>
    <div class="d-flex flex-column border rounded p-1">
        <label class="form-label">Orario</label>
        <div class="d-flex flex-row">
            <ta-timeframe-picker [(timeframe)]="timeframe"></ta-timeframe-picker>
        </div>
        <div>timeframe:{{timeframe|json}}</div>
        <markdown class="mb-1"
            [data]="getMarkdown([{lang:'html',code:timeframeCode},{lang:'oggetto',code:timeframeVar}])"></markdown>
    </div>
    <br>
    <div class="d-flex flex-column border rounded p-1">
        <label class="form-label">Orario con etichetta</label>
        <div class="d-flex flex-row">
            <ta-labeled-timeframe-picker [(labeledTimeframe)]="labeledTimeframe"></ta-labeled-timeframe-picker>
        </div>
        <div>labeledTimeframe:{{labeledTimeframe|json}}</div>
        <markdown class="mb-1"
            [data]="getMarkdown([{lang:'html',code:labeledTimeframeCode},{lang:'oggetto',code:labeledTimeframeVar}])">
        </markdown>
    </div>
    <br>
    <div class="d-flex flex-column border rounded p-1">
        <label class="form-label">Checkbox</label>
        <div class="d-flex flex-row">
            <ta-checkbox [(value)]="checkbox" label="checkbox" checkedColor="green" uncheckedColor="red"
                checkedIcon="bi-flag" uncheckedIcon="bi-trash"></ta-checkbox>
        </div>
        <markdown class="mb-1"
            [data]="getMarkdown([{lang:'html',code:checkboxCode},{lang:'oggetto',code:checkboxVar}])"></markdown>
    </div>
    <br>
    <div class="d-flex flex-column border rounded p-1">
        <label class="form-label">Tags</label>
        <div class="d-flex flex-row">
            <ta-tags [(tags)]="tags"></ta-tags>
        </div>
        <div>tags:{{tags|json}}</div>
        <markdown class="mb-1" [data]="getMarkdown([{lang:'html',code:tagsCode},{lang:'oggetto',code:tagsVar}])">
        </markdown>
    </div>
    <br>
    <div class="d-flex flex-column border rounded p-1">
        <label class="form-label">Contatti</label>
        <div class="d-flex flex-row">
            <ta-contact-infos [(value)]="contacts"></ta-contact-infos>
        </div>
        <div>contacts:{{contacts|json}}</div>
        <markdown class="mb-1"
            [data]="getMarkdown([{lang:'html',code:contactsCode},{lang:'oggetto',code:contactsVar}])"></markdown>
    </div>
    <br>
    <div class="d-flex flex-column border rounded p-1">
        <label class="form-label">Messaggio di conferma</label>
        <div class="d-flex flex-row">
            <div class="btn btn-primary" (click)="openConfirmModal()">Apri Conferma</div>
        </div>
        <markdown class="mb-1"
            [data]="getMarkdown([{lang:'html',code:confirmModalCode},{lang:'typescript',code:confirmModalVar}])">
        </markdown>
    </div>
    <br>
    <div class="d-flex flex-column border rounded p-1">
        <label class="form-label">Seleziona Immagine</label>
        <div class="d-flex flex-row">
            <ta-single-image [(image)]="singleImage"></ta-single-image>
        </div>
        <markdown class="mb-1"
            [data]="getMarkdown([{lang:'html',code:singleImageCode},{lang:'oggetto',code:singleImageVar}])"></markdown>
    </div>
    <br>
    <div class="d-flex flex-column border rounded p-1">
        <label class="form-label">Seleziona Immagini</label>
        <div class="">
            <ta-multi-images [(images)]="multiImage"></ta-multi-images>
        </div>
        <markdown class="mb-1"
            [data]="getMarkdown([{lang:'html',code:multiImageCode},{lang:'oggetto',code:multiImageVar}])"></markdown>
    </div>
    <br>
    <div class="d-flex flex-column border rounded p-1">
        <label class="form-label">Person Card</label>
        <div class="d-flex flex-row">
            <ta-card entity="person" [(value)]="personCard"></ta-card>
        </div>
        <markdown class="mb-1"
            [data]="getMarkdown([{lang:'html',code:personCardCode},{lang:'oggetto',code:personCardVar}])">
        </markdown>
    </div>
    <br>
    <div class="d-flex flex-column border rounded p-1">
        <label class="form-label">City Card</label>
        <div class="d-flex flex-row">
            <ta-card entity="city" [(value)]="cityCard"></ta-card>
        </div>
        <markdown class="mb-1"
            [data]="getMarkdown([{lang:'html',code:cityCardCode},{lang:'oggetto',code:cityCardVar}])">
        </markdown>
    </div>
    <br>
    <div class="d-flex flex-column border rounded p-1">
        <label class="form-label">Place Card</label>
        <div class="d-flex flex-row">
            <ta-card entity="place" [(value)]="placeCard"></ta-card>
        </div>
        <markdown class="mb-1"
            [data]="getMarkdown([{lang:'html',code:placeCardCode},{lang:'oggetto',code:placeCardVar}])">
        </markdown>
    </div>
    <br>
    <div class="d-flex flex-column border rounded p-1">
        <label class="form-label">File Drop</label>
        <div class="d-flex flex-row">
            <ta-file-drop></ta-file-drop>
        </div>
        <div>files:</div>
        <markdown class="mb-1" [data]="getMarkdown([{lang:'html',code:''},{lang:'oggetto',code:''}])"></markdown>
    </div>
    <br>
    <div class="d-flex flex-column border rounded p-1">
        <label class="form-label">Lista Filtro</label>
        <div class="d-flex flex-row">
            <ta-filterable-list></ta-filterable-list>
        </div>
        <div>lista:</div>
        <markdown class="mb-1" [data]="getMarkdown([{lang:'html',code:''},{lang:'oggetto',code:''}])"></markdown>
    </div>
    <br>
</div>