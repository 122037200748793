import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Location, TitleCasePipe } from '@angular/common';
import { Area, Person } from 'src/app/guides/model/interfaces';
import { PeopleService } from 'src/app/guides/services/people.service';
import { environment } from 'src/environments/environment';
import { Observable, ReplaySubject, combineLatest } from 'rxjs';
import { BreadcrumbService } from '@luhuiguo/xng-breadcrumb';
import { StateService } from 'src/app/services/state.service';
import { SharedEventService } from 'src/app/common/services/shared-event.service';
import { AreasService } from 'src/app/guides/services/areas.service';
import { TaPhonePipe } from 'projects/ngx-ta-ui-library/src/lib/ngx-ta-ui-library.pipes';

@Component({
  selector: 'app-add-person',
  templateUrl: './add-person.component.html',
  styleUrls: ['./add-person.component.css'],
  host: { 'style': 'max-width:1000px;margin-left:auto;margin-right:auto', 'class': 'h-100 w-75 pt-5' }
})
export class AddPersonComponent implements OnInit {

  imgpref = environment.imagesPrefix;

  configLoaded$: ReplaySubject<any>;
  person: Person;
  activities = [];
  submitting = false;
  tcase = new TitleCasePipe();
  areas: Area[] = [];

  phonePipe = new TaPhonePipe();

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private peopleService: PeopleService,
    private breadcrumbService: BreadcrumbService,
    private stateService: StateService,
    private sharedEvent: SharedEventService,
    private areasService: AreasService
  ) {
    this.configLoaded$ = this.sharedEvent.configLoadedEvent;
  }

  ngOnInit(): void {
    let params$: Observable<Params> = this.route.params;
    let area$: Observable<Area> = this.stateService.selectedArea$;
    let areas$: Observable<Area[]> = this.areasService.getAreas() as Observable<Area[]>;
    combineLatest([params$, area$, areas$]).subscribe(([params, area, areas]) => {
      if (params.id === '0') {
        this.breadcrumbService.set('areas/:id/people/:id', `Nuova persona`);
        this.person = new Person(area._id);
        this.populateActivities();
      } else {
        this.peopleService.getPersonById(params.id).subscribe((person) => {
          this.person = person;
          this.breadcrumbService.set('areas/:id/people/:id', `Persona: ${this.tcase.transform(person.name)} ${this.tcase.transform(person.surname)}`);
          this.populateActivities();
        })
      }
      this.areas = areas;
    });
  }

  populateActivities() {
    this.configLoaded$.subscribe(({ ui }) => {
      this.activities = ui.components.people.enums.activities;
      this.activities.map((activity) => {
        activity._id = activity.id;
        activity.name = this.tcase.transform(activity.languages.it);
        activity.isChecked = this.person.activities.includes(activity.id);
        activity.isHidden = false;
      });
      this.activities.sort((a, b) => {
        if (a.isChecked && !b.isChecked) {
          return -1; // `a` comes before `b`
        } else if (!a.isChecked && b.isChecked) {
          return 1; // `b` comes before `a`
        } else {
          // If isChecked properties are the same, sort alphabetically
          return a.id.localeCompare(b.id);
        }
      });
    });
  }

  cancel() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  /*filterActivities(event: any) {
    this.activities.map((activity) => {
      if (activity.languages.it.toLowerCase().includes(event.target.value.toLowerCase())) {
        activity.isHidden = false;
      } else {
        activity.isHidden = true;
      }
    });
  }

  onCheckboxChange(placeId: string) {
    if (this.person.activities.includes(placeId)) {
      // If item already selected, remove it
      this.person.activities = this.person.activities.filter(p => p !== placeId);
    } else {
      // If item not selected, add it
      this.person.activities.push(placeId);
    }
  }*/

  tagWarning() {
    this.stateService.message$.next({ text: "Tag Rimosso", type: `${environment.MESSAGES.ERROR}` })
  }

  tagError(error) {
    this.stateService.message$.next({ text: `Errore dal Suggeritore AI: ${error.statusText}`, type: `${environment.MESSAGES.ERROR}` });
  }

  onContactInputError(event) {
    this.stateService.message$.next({ text: `Errore: ${event}`, type: `${environment.MESSAGES.ERROR}` });
  }

  onImageWarning(state: string) {
    if (state == 'cancelled') {
      this.stateService.message$.next({ text: `Operazione Annullata.`, type: environment.MESSAGES.WARNING });
    }
  }

  onImageSuccess() {
    this.savePerson();
    this.stateService.message$.next({ text: `Immagine Salvata.`, type: environment.MESSAGES.SUCCESS });
  }

  onImageDeleted() {
    this.stateService.message$.next({ text: `Immagine Eliminata.`, type: environment.MESSAGES.WARNING });
    this.savePerson();
  }

  savePerson() {
    this.submitting = true;
    if (this.person._id) {
      this.peopleService.updatePerson(this.person).subscribe((res) => {
        this.stateService.message$.next({ text: `Persona Aggiornata.`, type: environment.MESSAGES.SUCCESS });
        this.submitting = false;
      });
    } else {
      this.peopleService.createPerson(this.person).subscribe((res) => {
        this.person._id = res._id;
        this.stateService.message$.next({ text: `Persona Creata.`, type: environment.MESSAGES.SUCCESS });
        this.submitting = false;
      });
    }
  }
}
