<p>activity-item works!</p>
<div class="card-sl mb-1 d-flex align-items-start flex-column p-0 position-relative overflow-hidden bg-white">
    <div class="carousel-box card-image w-100 ">
        <div class="position-relative">
            <div class="row">
                <div style="border-width: 0px !important;" class="col-md-5 map-container border h-100 opacity-gradient">
                    <ngb-carousel *ngIf="experience.images&&experience.coverImage">
                        <!-- Show coverImage slide -->
                        <ng-template ngbSlide *ngIf="event.coverImage">
                            <div class="row g-0">
                                <div class="col-md-12">
                                    <img class="card-img-top img-fluid"
                                        [src]="imagesBaseDir + experience.coverImage.url"
                                        title="{{ experience.coverImage.name }}"
                                        style="height: 12rem; object-fit: cover" loading="lazy"
                                        onerror="this.onerror=null;this.src='assets/placeholder.jpg';" />
                                </div>
                            </div>
                        </ng-template>
                    </ngb-carousel>
                </div>
                <div class="card-body position-relative col-md-7" style="z-index:0; height: 12rem">
                    <h3 class="card-title fw-semibold pt-2">{{ experience.name }}</h3>
                    <h4 class="card-text">{{ experience.name }}</h4>
                    <strong class="card-text">{{ experience.description }}</strong>
                    <div class="d-flex w-100 mb-2">
                        <ng-container *ngIf="experience.tags">
                            <div class="tag" *ngFor="let tag of event.tags">{{ tag.name }}</div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <app-modify-delete [target]="['experience/',experience._id]"
            (onDelete)="onDelete(experience._id)"></app-modify-delete>
    </div>
</div>