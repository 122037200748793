import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GbSharedService } from 'src/app/guides/services/gb-shared.service';

@Component({
  selector: 'app-guidebook-external-service',
  templateUrl: './guidebook-external-service.component.html',
  styleUrls: ['./guidebook-external-service.component.css']
})
export class GuidebookExternalServiceComponent implements OnInit {
  @Input() public config: any = {};
  @Output() public onSave! : EventEmitter<void>;
  constructor(
    private service:GbSharedService
  ) { 
    this.config = this.service.configuration;
    this.onSave = this.service.onSave;
  }
  ngOnInit(): void {
    console.log(this.config);
  }

}
