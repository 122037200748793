import { Component, EventEmitter, Input, OnInit, Output, ViewChild, } from '@angular/core';
import { combineLatest } from 'rxjs';
import { Area, Place, Latlng, Itinerary } from 'src/app/guides/model/interfaces';
import { PlaceService } from 'src/app/guides/services/place.service';
import { Location } from '@angular/common';
import { ActivatedRoute, Params } from '@angular/router';
import * as _ from 'lodash';
import { BreadcrumbService } from '@luhuiguo/xng-breadcrumb';
import { StateService } from 'src/app/services/state.service';
import { Observable, tap } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MultiImgModalComponent } from 'src/app/common/components/multi-img-modal/multi-img-modal.component';
import { environment } from 'src/environments/environment';
import { _DEBUG } from 'src/app/app.component';
import { v4 as uuidv4 } from 'uuid';
import { SharedEventService } from 'src/app/common/services/shared-event.service';

@Component({
  selector: 'app-itinerary-image-upload',
  templateUrl: './itinerary-image-upload.component.html',
  styleUrls: ['./itinerary-image-upload.component.css'],
  host: { 'style': 'max-width:1000px;margin-left:auto;margin-right:auto', 'class': 'w-75 pt-5' }
})
export class ItineraryImageUploadComponent implements OnInit {

  @Input() itinerary: Itinerary = new Itinerary();
  @Output() itineraryChange: EventEmitter<Itinerary> = new EventEmitter<Itinerary>();

  @Output() saveItineraryEvent: EventEmitter<any>;
  imagesBaseDir = environment.IMAGES;
  coverImage: string[] = [];
  uuid: string;
  images: string[] = [];
  isUpdate: boolean = false;

  placeSelected: boolean = false;
  coverImageLoadError = false;

  updating: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private placeService: PlaceService,
    private breadcrumbService: BreadcrumbService,
    private stateService: StateService,
    private modalService: NgbModal,
    private sharedEvent: SharedEventService
  ) {
    this.saveItineraryEvent = this.sharedEvent.saveItineraryEvent;
  }

  ngOnInit(): void {
    this.uuid = this.itinerary.uuid;
    let params$: Observable<Params> = this.route.params;
    let area$: Observable<Area> = this.stateService.selectedArea$;
    combineLatest([params$, area$]).subscribe(([params, area]) => {
      if (params.id === 'new') {

      } else {
        this.isUpdate = true;
      }
    });
  }

  loadCoverPhoto() {
    if (this.itinerary.coverImage && !this.coverImageLoadError) {
      this.coverImage[0] = this.itinerary.coverImage.url
    }
    const modalRef = this.modalService.open(MultiImgModalComponent);
    modalRef.componentInstance.images = this.coverImage;
    modalRef.componentInstance.uploadDir = this.uuid;
    modalRef.componentInstance.multiImage = false;
    modalRef.dismissed.subscribe((state) => {
      if (state == 'EMPTY') {
        this.itinerary.coverImage = null;
        this.itineraryChange.emit(this.itinerary);
      }
      this.stateService.message$.next({ text: `Operazione annullata.`, type: environment.MESSAGES.WARNING });
    })
    modalRef.result.then((imagesUpdated) => {
      if (_DEBUG) console.log('imagesUpdated', imagesUpdated);
      const images = this.getImageDetails(imagesUpdated);
      this.itinerary.coverImage = images[0];
      this.itineraryChange.emit(this.itinerary);
      //if (this.isUpdate) { this.saveItineraryEvent.emit(); }  
      this.coverImage = [];
      this.stateService.message$.next({ text: `Immagine Salvata.`, type: environment.MESSAGES.SUCCESS });
    });
    modalRef.componentInstance.imagesChange.subscribe((imagesUpdated: any) => {
      const images = this.getImageDetails(imagesUpdated);
      this.itinerary.coverImage = images[0];
      this.itineraryChange.emit(this.itinerary);
      //this.saveItineraryEvent.emit();
      this.coverImage = [];
      this.stateService.message$.next({ text: `Immagine Salvata.`, type: environment.MESSAGES.SUCCESS });
    })
  }

  loadImages() {
    const imaglist = this.itinerary.images;
    for (const img of imaglist) {
      this.images.push(img.url);
    }
    const modalRef = this.modalService.open(MultiImgModalComponent);
    modalRef.componentInstance.images = this.images;
    modalRef.componentInstance.uploadDir = this.uuid;
    modalRef.componentInstance.multiImage = true;
    modalRef.dismissed.subscribe(() => {
      this.stateService.message$.next({ text: `Operazione annullata.`, type: environment.MESSAGES.WARNING });
    })
    modalRef.result.then((imagesUpdated) => {
      if (_DEBUG) console.log('imagesUpdated', imagesUpdated);
      const images = this.getImageDetails(imagesUpdated);
      this.itinerary.images = images;
      this.itineraryChange.emit(this.itinerary);
      this.images = [];
      console.log('plaec object after image upload', this.itinerary);
      // this.placeService.savePlace(this.place).subscribe(() => {
      //   this.stateService.message$.next({ text: `Hai aggiornato le foto.`, type: environment.MESSAGES.SUCCESS });
      // })
    });
    modalRef.componentInstance.imagesChange.subscribe((imagesUpdated: any) => {
      const images = this.getImageDetails(imagesUpdated);
      this.itinerary.images = images;
      this.itineraryChange.emit(this.itinerary);
      this.images = [];
    })
  }

  getImageDetails(images: any) {
    let imageArray = [];
    for (const image of images) {
      const segments = image.split('/');
      const imageName = segments[segments.length - 1];
      imageArray.push({ name: imageName, url: image });
    }
    return imageArray;
  }

  setCoverImgLoadError($event) {
    this.coverImageLoadError = true;
  }


}