<div class="p-3 h-100">
  <div class="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6 mx-auto p-4 bg-white rounded-3" style="height: 90%;">
    <ng-stepper class="h-100 pt-2" #cdkStepper [linear]="false">
      <cdk-step [optional]="true">
        <ng-template cdkStepLabel>
          <div class="step-bullet"><i class="bi bi-map"></i></div>
          <div class="step-title d-md-block">Posizione</div>
        </ng-template>
        <div class="inner-step">
          <div class="step-form">
            <app-position class="" [(place)]="place" #stepOne></app-position>
          </div>
          <div class="bottom-button">
            <button type="button" class="ms-auto button btn btn-light" (click)="cdkStepper.next()">
              <i class="bi bi-arrow-right fs-1"></i>
            </button>
          </div>
        </div>
      </cdk-step>

      <cdk-step [optional]="true">
        <ng-template cdkStepLabel>
          <div class="step-bullet"><i class="bi bi-image"></i></div>
          <div class="step-title d-md-block">Dettagli</div>
        </ng-template>
        <div class="inner-step" #stepFour>
          <div class="step-form">
            <app-details [(place)]="place" #stepFour></app-details>
          </div>
          <div class="bottom-button">
            <button class="button btn btn-light" (click)="cdkStepper.previous()"><i
                class="bi bi-arrow-left fs-1"></i></button>
            <button class="ms-auto button btn btn-light" (click)="cdkStepper.next()"><i
                class="bi bi-arrow-right fs-1"></i></button>
          </div>
        </div>
      </cdk-step>

      <cdk-step [optional]="true">
        <ng-template cdkStepLabel>
          <div class="step-bullet"><i class="bi bi-file-text"></i></div>
          <div class="step-title d-md-block">Opzioni</div>
        </ng-template>
        <div class="inner-step">
          <div class="step-form">
            <app-options [(place)]="place" #stepThree></app-options>
          </div>
          <div class="bottom-button">
            <button class="button btn btn-light" (click)="cdkStepper.previous()"><i
                class="bi bi-arrow-left fs-1"></i></button>
            <button class="ms-auto button btn btn-light" (click)="cdkStepper.next()"><i
                class="bi bi-arrow-right fs-1"></i></button>
          </div>
        </div>
      </cdk-step>

      <cdk-step [optional]="true">
        <ng-template cdkStepLabel>
          <div class="step-bullet"><i class="bi bi-images"></i></div>
          <div class="step-title d-md-block">Immagini</div>
        </ng-template>
        <div class="inner-step">
          <div class="step-form">
            <app-image-upload class="" [(place)]="place" #stepTwo></app-image-upload>
          </div>
          <div class="bottom-button">
            <button class="button btn btn-light" (click)="cdkStepper.previous()"><i
                class="bi bi-arrow-left fs-1"></i></button>
            <button class="ms-auto button btn btn-light" (click)="cdkStepper.next()"><i
                class="bi bi-arrow-right fs-1"></i></button>
          </div>
        </div>
      </cdk-step>

      <cdk-step [optional]="true">
        <ng-template cdkStepLabel>
          <div class="step-bullet"><i class="bi bi-image"></i></div>
          <div class="step-title d-md-block">AI</div>
        </ng-template>
        <div class="inner-step">
          <div class="step-form">
            <app-ai-active class="" [(place)]="place" [(aiSubject)]="aiSubject" #stepFive></app-ai-active>
            <!-- <div class="d-flex flex-column h-100 w-100 mx-auto mt-3">
            <div class="form-check p-4" *ngIf="place">
              <input class="form-check-input" type="checkbox" id="aiActive" [(ngModel)]="place.active">
              <label class="form-check-label" for="aiActive">
                AI Active
              </label>
            </div>
          </div> -->
          </div>
          <div class="bottom-button">
            <button class="button btn btn-light" (click)="cdkStepper.previous()"><i
                class="bi bi-arrow-left fs-1"></i></button>
            <button class="ms-auto button btn btn-light" (click)="cdkStepper.next()"><i
                class="bi bi-arrow-right fs-1"></i></button>
          </div>
        </div>
      </cdk-step>

      <cdk-step [optional]="true">
        <ng-template cdkStepLabel>
          <div class="step-bullet"><i class="bi bi-image"></i></div>
          <div class="step-title d-md-block">Riepilogo</div>
        </ng-template>
        <div class="inner-step" #stepSix>
          <div class="step-form">
            <app-place-summary [(place)]="place"></app-place-summary>
          </div>
          <div class="bottom-button">
            <button class="button btn btn-light" (click)="cdkStepper.previous()"><i
                class="bi bi-arrow-left fs-1"></i></button>
            <button class="ms-auto button btn btn-light" (click)="saveData()"><i
                class="bi bi-cloud-upload fs-1"></i></button>
          </div>
        </div>
      </cdk-step>
    </ng-stepper>
  </div>
</div>


<!--https://via.placeholder.com/512x346?text=Visit+Blogging.com+Now -->