import { Component, EventEmitter, OnInit, ViewChild, } from '@angular/core';
import { combineLatest, of } from 'rxjs';
import { Area, Place, AISubject } from 'src/app/guides/model/interfaces';
import { PlaceService } from 'src/app/guides/services/place.service';
import { Location } from '@angular/common';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BreadcrumbService } from '@luhuiguo/xng-breadcrumb';
import { StateService } from 'src/app/services/state.service';
import { Observable, tap } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { CdkStepper } from '@angular/cdk/stepper';
import { _DEBUG } from 'src/app/app.component';
import { AIService } from 'src/app/guides/services/ai.service';
import { switchMap, catchError } from 'rxjs/operators';
import { SharedEventService } from 'src/app/common/services/shared-event.service';

@Component({
  selector: 'app-add-place',
  templateUrl: './add-place.component.html',
  styleUrls: ['./add-place.component.css']
}
)
export class AddPlaceComponent implements OnInit {

  place$: Observable<Place | undefined>;
  aiSubject$: Observable<AISubject | undefined>;
  // place: Place | any = {};
  place: Place = new Place();
  aiSubject: AISubject = new AISubject();
  areaUUID: string;

  savePlaceEvent: EventEmitter<any>;

  // place: Place; 

  placeSelected: boolean = false;

  updating: boolean = false;
  onUpdate: boolean = false;


  @ViewChild('cdkStepper') cdkStepper: CdkStepper;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private placeService: PlaceService,
    private aiService: AIService,
    private breadcrumbService: BreadcrumbService,
    private stateService: StateService,
    private modalService: NgbModal,
    private router: Router,
    private sharedEvent: SharedEventService
  ) {
    this.savePlaceEvent = this.sharedEvent.savePlaceEvent;
  }

  ngOnInit(): void {
    let params$: Observable<Params> = this.route.params;
    let area$: Observable<Area> = this.stateService.selectedArea$;
    combineLatest([params$, area$]).subscribe(([params, area]) => {
      console.log('params', params);
      this.areaUUID = area.uuid;
      if (params.id === 'new') {
        this.place.area = area._id;
        this.breadcrumbService.set('areas/:id/places/:id', `Aggiungi luogo`);
        this.place$ = new Observable<Place | undefined>(observer => {
          let place = new Place();
          place.area = area._id;
          place.options.visitDuration = { hours: 0, minutes: 60 };
          place.details.cost = [];
          observer.next(place);
        });
      } else {
        this.onUpdate = true;
        this.breadcrumbService.set('areas/:id/places/:id', `Aggiungi luogo`);
        this.placeService.getPlaceById(params.id).subscribe(async (place: Partial<Place>) => {
          this.breadcrumbService.set('areas/:id/places/:id', `Luogo: ${place.name}`);
          this.place = place as Place;
          if (!this.place.area) { this.place.area = area._id; }
          if (!this.place.options) { this.place.options = { arrivalPoint: false, suitableForSpareTime: false, canSleepOver: false, visitDuration: { hours: 0, minutes: 60 } } }
          if (!this.place.details) { this.place.details = { description: '', notes: '', type: [], class: [], cost: [] } }
          if (this.place.aiSubject) {
            await this.aiService.getAiById(this.place.aiSubject).subscribe((ai) => {
              this.aiSubject = ai;
            })
          }
          debugger
          if (this.place.active == false) {
            this.place.active = true;
          }
        });
      }
    });
    this.savePlaceEvent.subscribe(() => {
      this.saveData();
    });
  }


  saveData() {
    debugger
    if (this.onUpdate) {
      this.updating = true;
      this.place.aiSubject ? this.updateAISubject() : this.updatePlace();
    } else {
      this.updating = true;
      this.place.aiSubject ? this.saveAISubject() : this.savePlace('');
    }
  }

  updateAISubject() {
    this.aiService
      .updateAI(this.aiSubject, this.aiSubject._id)
      .pipe(
        switchMap(async (result) => this.updatePlace()),
        catchError(error => {
          console.log('err', error);
          this.stateService.message$.next({ text: `Ops.. qualcosa è andato storto..(${error.message})`, type: environment.MESSAGES.ERROR });
          this.updating = false;
          return of(null);
        })
      )
      .subscribe();
  }

  saveAISubject() {
    this.aiService
      .saveAI(this.aiSubject)
      .pipe(
        switchMap(async (result) => this.savePlace(result._id)),
        catchError(error => {
          console.log('err', error);
          this.stateService.message$.next({ text: `Ops.. qualcosa è andato storto..(${error.message})`, type: environment.MESSAGES.ERROR });
          this.updating = false;
          return [];
        })
      )
      .subscribe();
  }

  savePlace(aiSubjectId: string) {
    this.place.aiSubject = aiSubjectId != '' && aiSubjectId != null ? aiSubjectId : undefined;
    this.placeService
      .savePlace(this.place)
      .pipe(
        switchMap(result => {
          console.log('result', result);
          this.stateService.message$.next({ text: "Informazioni salvate correttamente", type: environment.MESSAGES.SUCCESS });
          return this.navigateToPlaces();
        }),
        catchError(error => {
          this.stateService.message$.next({ text: `Ops.. qualcosa è andato storto..(${error.message})`, type: environment.MESSAGES.ERROR });
          this.updating = false;
          return [];
        })
      )
      .subscribe();
  }

  updatePlace() {
    this.placeService
      .updatePlace(this.place, this.place._id)
      .pipe(
        switchMap(result => {
          console.log('result', result);
          this.stateService.message$.next({ text: "Informazioni aggiornate correttamente", type: environment.MESSAGES.SUCCESS });
          return this.navigateToPlaces();
        }),
        catchError(error => {
          this.stateService.message$.next({ text: `Ops.. qualcosa è andato storto..(${error.message})`, type: environment.MESSAGES.ERROR });
          this.updating = false;
          return [];
        })
      )
      .subscribe();
  }

  navigateToPlaces() {
    return this.router.navigate([`/areas/${this.areaUUID}/places`]);
  }
}