import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { Place, Image, Setting, AISubject } from '../model/interfaces';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { ConfigService } from './config.service';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class AIService {
  //   private placeApiUrl = `${environment.dbApiBaseDir}places`;
  private crudAIApiUrl = `${environment.dbApiBaseDir}aisubjects`;

  constructor(
    private http: HttpClient,
    private configService: ConfigService
  ) { }

  //   getPlaceByCat(placeCat: string): Observable<Place[]> {
  //     const url = `${this.placeApiUrl}&filter=placeType,cs,${placeCat}`;
  //     return this.http.get<Place[]>(url).pipe(
  //       take(1)
  //     );
  //   }

  //   getPlaceByUuid(uuid: string): Observable<Place> {
  //     const url = `${this.placeApiUrl}/uuid/${uuid}`;
  //     return this.http.get<any>(url).pipe(
  //       take(1),
  //     );
  //   }

  getAiById(id: string): Observable<AISubject> {
    const url = `${this.crudAIApiUrl}/${id}`;
    return this.http.get<AISubject>(url).pipe(
      take(1)
    );
  }

  //   getAreaPlaces(area: string): Observable<Place[]> {
  //     const url = `${this.placeApiUrl}/area/${area}`;
  //     return this.http.get<Place[]>(url).pipe(
  //       take(1)
  //     );
  //   }

  getAllSubjects(): Observable<AISubject[]> {
    return this.http.get<any>(this.crudAIApiUrl).pipe(
      take(1)
    );
  }

  getAllSubjectsData(areaId: string): Observable<AISubject[]> {
    return this.http.get<any>(`${this.crudAIApiUrl}/namedSubSubjects/subjectArea/${areaId}/subSubjectsAreas/:subSubjectsAreas`).pipe(
      take(1)
    );
  }

  //   getSelectedPlaces(places: number[]): Observable<Place[]> {
  //     const placesSerialized = places.join(',');
  //     const url = `${this.placeApiUrl}&filter=id,in,${placesSerialized}`;
  //     return this.http.get<Place[]>(url);
  //   }

  //   getSelectedAreaPlaces(area: string): Observable<Place[]> {
  //     const url = `${this.placeApiUrl}&filter=area,eq,${area}`;
  //     return this.http.get<Place[]>(url);
  //   }

  deleteAI(id: string): Observable<string[]> {
    return this.http.delete<any>(`${this.crudAIApiUrl}/${id}`, httpOptions).pipe(take(1));
  }

  saveAI(aiData: AISubject): Observable<any> {
    return this.http.post<any>(`${this.crudAIApiUrl}`, aiData, httpOptions).pipe(take(1));
    // return EMPTY
  }

  updateAI(aiData: AISubject, id: string): Observable<any> {
    return this.http.patch<any>(`${this.crudAIApiUrl}/${id}`, aiData, httpOptions).pipe(take(1));
  }
}
