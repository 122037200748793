import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from 'src/app/common/components/confirm-modal/confirm-modal.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-event-item',
  templateUrl: './event-item.component.html',
  styleUrl: './event-item.component.css'
})
export class EventItemComponent {
  @Input() event: any;
  @Output() onDeleteEvent = new EventEmitter<any>();
  imagesBaseDir = environment.imagesPrefix;
  /*event: any = {
    _id: '123',
    name: 'event name',
    coverImage: { url: '' },
    images: [{ url: '' }],
    description: 'event description',
    tags: [{ name: 'tag1' }, { name: 'tag2' }]
  }*/
  constructor(private modalService: NgbModal) {
  }


  ngOnInit(): void {

  }

  onDelete(event) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.alertTarget = 'event';

    modalRef.result.then(() => {
      this.onDeleteEvent.emit(event);
    });
  }

}
