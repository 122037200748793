import { Component, EventEmitter, Input, OnInit, Output, ViewChild, } from '@angular/core';
import { Observable, Subject, combineLatest } from 'rxjs';
import { Area, Place, Itinerary } from 'src/app/guides/model/interfaces';
import * as _ from 'lodash';
import { _DEBUG } from 'src/app/app.component';
import { StateService } from 'src/app/services/state.service';
import { PlaceService } from 'src/app/guides/services/place.service';
import { Params, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import {debounceTime, distinctUntilChanged, filter, map, merge} from 'rxjs/operators';



@Component({
  selector: 'app-place-tab',
  templateUrl: './place-tab.component.html',
  styleUrls: ['./place-tab.component.css'],
  host: { 'style': 'max-width:1000px;margin-left:auto;margin-right:auto', 'class': 'w-75 pt-5' }
})
export class PlaceTabComponent implements OnInit {

  // @Input() place!: Place | any;
  @Input() itinerary: Itinerary = new Itinerary();
  @ViewChild('instance') instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  place: Place | any;
  imagesBaseDir = environment.IMAGES;
  @Output() itineraryChange: EventEmitter<Itinerary> = new EventEmitter<Itinerary>();

  public model: any;

  // search = (text$: Observable<string>) =>
  // text$.pipe(
  //   debounceTime(200),
  //   distinctUntilChanged(),
  //   merge(this.focus$),
  //   merge(this.click$.pipe(filter(() => !this.instance.isPopupOpen()))),
  //   map(term => (term === '' ? this.place
  //     : this.place.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
  // );

searchResults: any[] = []; // Initialize an empty array to store search results in your component.

search = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    merge(this.focus$),
    merge(this.click$.pipe(filter(() => !this.instance.isPopupOpen()))),
    map(term => {
      if (term === '') {
        this.searchResults = this.place; // Display all places if the search term is empty.
        return this.place;
      } else {
        const results = this.place.filter(v => v.name.toLowerCase().includes(term.toLowerCase())).slice(0, 10);
        this.searchResults = results; // Store the search results in the searchResults array.
        return results;
      }
    })
  );

  formatter = (x: {name: string}) => x.name;

  constructor(
    private stateService: StateService,
    private placeService: PlaceService,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    // this.searchResults = this.place;
    console.log('place in place tab', this.place);
    this.itinerary.places = [];
    let params$: Observable<Params> = this.route.params;
    let area$: Observable<Area> = this.stateService.selectedArea$;
    combineLatest([params$, area$]).subscribe(([params, area]) => {
      this.placeService.getAllPlaces().subscribe((data) => {
        this.place = data;
      });
    });
    // console.log('place items', this.place);
  };

  isSelected(item: any): boolean {
    return this.itinerary.places.includes(item);
  }

  onSelectPlace(event: any) {
    console.log('event name', event.item.name);
    const results = this.place.filter(v => v.name.toLowerCase().includes(event.item.name.toLowerCase())).slice(0, 10);
    this.searchResults = results; 
  }

  toggleSelection(item: any): void {
    console.log('model', this.model);
    if (this.isSelected(item)) {
      this.itinerary.places = this.itinerary.places.filter((selectedItem: any) => selectedItem !== item);
    } else {
      this.itinerary.places.push(item);
    }
    console.log('all selected places', this.itinerary.places);
  }
}
