import { Component, Input, OnInit, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pending-shops',
  templateUrl: './pending-shops.component.html',
  styleUrl: './pending-shops.component.css'
})
export class PendingShopsComponent implements OnInit {
  @Input() pendingShops: any[];
  modalService = inject(NgbActiveModal);
  imgpref = environment.imagesPrefix;

  constructor() {
  }

  ngOnInit(): void {
    console.log(this.pendingShops)
  }
}
