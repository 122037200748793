<section>
    <div class="p-4">
        <div class="row">
            <div class="col-12 col-sm-8">
                <div class="form-group col-12">
                    <label for="questionText">Domanda</label>
                    <textarea type="text" class="form-control form-control-sm" id="questionText" required
                        [(ngModel)]="question.content" name="questionText" #questionText="ngModel"
                        style="height:150px;"></textarea>
                    <div [hidden]="questionText.valid || questionText.pristine" class="alert alert-danger small">
                        Inserisci la domanda
                    </div>
                </div>
                <div class="row col-12 mt-4">
                    <div class="form-group col-12 col-md">
                        <label for="questionCategory">Categoria</label>
                        <select class="form-control form-control-sm" id="questionCategory" required
                            [(ngModel)]="question.category" name="questionCategory" #questionCategory="ngModel">
                            <!-- <option *ngFor="let city of cities" [value]="city">
                                {{ city }}
                            </option> -->
                            <option value='art'>arte</option>
                            <option value='history'>storia</option>
                            <option value='geography'>geografia</option>
                            <option value='math'>matematica</option>
                            <option value='science'>scienze</option>
                            <option value='literature'>letteratura</option>
                            <option value='music'>musica</option>
                            <option value='sport'>sport</option>
                            <option value='cinema'>cinema</option>
                            <option value='tv'>tv</option>
                            <option value='food'>cibo</option>
                            <option value='tech'>tecnologia</option>
                            <option value='politics'>politica</option>
                            <option value='religion'>religione</option>
                            <option value='other'>altro</option>
                        </select>
                        <div [hidden]="questionCategory.valid || questionType.pristine"
                            class="alert alert-danger small">
                            Inserisci la categoria
                        </div>
                    </div>
                    <div class="form-group col-12 col-md ms-0 ms-md-2">
                        <label for="questionType">Tipo</label>
                        <select class="form-control form-control-sm" id="questionType" [(ngModel)]="question.type"
                            required name="questionType" #questionType="ngModel">
                            <option value="true-false">Risposta Multipla</option>
                            <option value="multiple-choice">Vero/Falso</option>
                        </select>
                        <div [hidden]="questionType.valid || questionType.pristine" class="alert alert-danger small">
                            Inserisci il tipo
                        </div>
                    </div>
                    <div class="form-group col-12 col-md ms-0 ms-md-2">
                        <label for="questionDifficulty">Difficoltà</label>
                        <select class="form-control form-control-sm" id="questionDifficulty" required
                            [(ngModel)]="question.difficulty" name="questionDifficulty" #questionDifficulty="ngModel">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                        </select>
                        <div [hidden]="questionDifficulty.valid || questionDifficulty.pristine"
                            class="alert alert-danger small">
                            Inserisci la difficoltà
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="question.images" class="col-12 col-sm-4 d-flex border p-1 mt-4 mt-sm-2"
                style="max-height: 245px; aspect-ratio: 1/1; background-color: gray">
                <img [src]="question.images.length?imagesBaseDir+question.images[0].url:'assets/placeholder.jpg'"
                    (click)="openModalImage()" onerror="this.onerror=null;this.src='assets/placeholder.jpg';"
                    class="img-fluid border w-100" style="overflow: hidden;object-fit: cover;" />
            </div>
        </div>
    </div>
    <!-- <ng-template [ngIf]="lgScreen" [ngIfElse]="answersModal">
        <app-answers></app-answers>
    </ng-template> 
    <ng-template #answersModal >
        <div class="btn btn-light">Risposte</div>
    </ng-template>  -->
    <app-answers [ngClass]="lgScreen?'d-block':'d-none'"></app-answers>
    <div [ngClass]="lgScreen?'d-none':'d-block'" (click)="openModalAnswers()" class="btn btn-outline-light text-dark">
        Risposte</div>
    <div class="mt-3 d-block btn btn-light text-dark" (click)="save()">Salva</div>
    <!-- <div class="mt-3 d-block btn btn-light text-dark" (click)="saveBatch()">SalvaTutti</div> -->
</section>