import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContestRoutingModule } from './contest-routing.module';
//import { NgxsModule } from '@ngxs/store';
import { SessionsComponent } from './components/sessions/sessions.component';
import { QuestionsComponent } from './components/questions/questions.component';
import { ContestWrapperComponent } from './components/contest-wrapper/contest-wrapper.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QuestionDetailsComponent } from './components/questions/question-details/question-details.component';
import { FormsModule } from '@angular/forms';
import { FaModule } from '../common/fa/fa.module';
import { AnswersComponent } from './components/questions/answers/answers.component';

@NgModule({
  declarations: [
    SessionsComponent,
    QuestionsComponent,
    ContestWrapperComponent,
    QuestionDetailsComponent,
    AnswersComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    ContestRoutingModule,
    FaModule,
    //NgxsModule.forFeature([]),
    NgbModule
  ]
})
export class ContestModule { }
