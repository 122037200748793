import { Component, OnInit } from '@angular/core';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { MomentDateFormatter } from 'src/app/common/providers/parser/momentParserFormatter';
import { GuidebookService } from 'src/app/guides/services/guidebook.service';

@Component({
  selector: 'app-guidebook-experience',
  templateUrl: './guidebook-experience.component.html',
  styleUrls: ['./guidebook-experience.component.css'],
  providers: [{ provide: NgbDateParserFormatter, useClass: MomentDateFormatter }]
})
export class GuidebookExperienceComponent implements OnInit {
  guidebooks: [];
  constructor(private guidebookService: GuidebookService) { }

  ngOnInit(): void {
    this.guidebookService.getAllGuidebooks().subscribe((guidebooks: any) => {
      this.guidebooks = guidebooks.filter(guidebook => guidebook.type === 'experience');
    })
  }

  deleteExperience(i: number, id: string) {
    this.guidebookService.deleteGuidebook(id).subscribe((res) => {
      this.guidebooks.splice(i, 1);
    });
  }
}
