import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Area } from '../guides/model/interfaces';
import { EventEmitter } from '@angular/core';
import { Question } from '../contest/store/states/contest-model';


@Injectable({
  providedIn: 'root'
})
export class StateService {

  public lgScreen$ = new ReplaySubject<boolean>(1);
  public user$ = new BehaviorSubject<any>({});
  public userDetails$ = new BehaviorSubject<any>({})
  public message$ = new ReplaySubject<{ text: string, type: string }>(1);
  public selectedArea$ = new ReplaySubject<Area>(1);
  public questionAdded = new EventEmitter<Question>();
  public peopleChanged = new EventEmitter<void>();

  constructor(private responsive: BreakpointObserver,
    private http: HttpClient) {
    this.responsive.observe('(min-width: 1200px)')
      .subscribe(result => {
        if (result.matches) {
          this.lgScreen$.next(true);
        } else {
          this.lgScreen$.next(false);
        }
      });
  }
}