<div class="card-button overflow-hidden position-relative">
    <div class="btn btn-sm flex-grow-1 text-white" [routerLink]="target" (click)="$event.stopPropagation()">
        <span class="me-1">Modifica</span>
        <fa-icon [icon]="['fas', 'pen']"></fa-icon>
    </div>
    <!-- <div class="btn btn-sm flex-grow-1 text-white" [routerLink]="['./',target]" (click)="$event.stopPropagation()">
        <span class="me-1">Modifica</span>
        <fa-icon [icon]="['fas', 'pen']"></fa-icon>
    </div> -->
    <div class="btn btn-sm text-white bg-danger position-absolute end-0"
        (click)="emitDeleteAction();$event.stopPropagation()">
        <fa-icon [icon]="['fas', 'trash']"></fa-icon>
    </div>
</div>