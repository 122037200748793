import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { GbSharedService } from 'src/app/guides/services/gb-shared.service';
import { GuidebookService } from 'src/app/guides/services/guidebook.service';
import { StoreImgsService } from 'src/app/common/services/store-imgs.service';
import { DeleteStoredImgsService } from 'src/app/common/services/delete-stored-imgs.service';
import { MultiImgModalComponent } from 'src/app/common/components/multi-img-modal/multi-img-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PlaceService } from 'src/app/guides/services/place.service';

@Component({
  selector: 'app-guidebook-activity',
  templateUrl: './guidebook-activity.component.html',
  styleUrls: ['./guidebook-activity.component.css'],
})
export class GuidebookActivityComponent implements OnInit {
  guidebooks: [];
  constructor(private guidebookService: GuidebookService) { }

  ngOnInit(): void {
    this.guidebookService.getAllGuidebooks().subscribe((guidebooks: any) => {
      this.guidebooks = guidebooks.filter(guidebook => guidebook.type === 'activity');
    })
  }

  deleteActivity(i: number, id: string) {
    this.guidebookService.deleteGuidebook(id).subscribe((res) => {
      this.guidebooks.splice(i, 1);
    });
  }
}
