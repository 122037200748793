import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {NgbDate, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-datepicker-range',
  templateUrl: './datepicker-range.html',
  styleUrls: ['./datepicker-range.css']
})
export class DatepickerRange  {
  @Input() fromDate?:NgbDate;
  @Input() toDate?:NgbDate | null;
  @Output() fromDateChange = new EventEmitter<NgbDate>();
  @Output() toDateChange = new EventEmitter<NgbDate|null>();

  hoveredDate: NgbDate | null = null;

  constructor(calendar: NgbCalendar) {
    console.log(this.fromDate);
    console.log(this.toDate);
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    
    this.fromDateChange.emit(this.fromDate);
    this.toDateChange.emit(this.toDate?this.toDate:null);
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }
}
