import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GbSharedService {
  public listSearch!:any;
  public configuration!: any;
  public uuid!: any;
  public onSave = new EventEmitter();
  public onCancel = new EventEmitter();
  public onShowToast = new EventEmitter<any>();
  constructor() { }
}
