import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Component, ViewEncapsulation, Input, NgModule } from '@angular/core';

/** @internal */
const _c0 = (a0, a1) => ({
  "collapse": a0,
  "expand": a1
});
function JsonViewItemComponent_span_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 8)(1, "span");
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "span");
    i0.ɵɵtext(4, ": ");
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r0.key);
  }
}
function JsonViewItemComponent_span_5_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1("[ ", ctx_r0.data == null ? null : ctx_r0.data.length, " ]");
  }
}
function JsonViewItemComponent_span_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵtemplate(2, JsonViewItemComponent_span_5_span_2_Template, 2, 1, "span", 5);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r0.dataType, " ");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.isArray);
  }
}
function JsonViewItemComponent_span_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 9);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", ctx_r0.valueType);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.value);
  }
}
function JsonViewItemComponent_div_7_nxt_json_view_item_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "nxt-json-view-item", 12);
  }
  if (rf & 2) {
    const _key_r2 = ctx.$implicit;
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("data", ctx_r0.data[_key_r2])("key", _key_r2)("level", ctx_r0.level + 1)("levelOpen", ctx_r0.levelOpen)("levelLabels", ctx_r0.levelLabels);
  }
}
function JsonViewItemComponent_div_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 10);
    i0.ɵɵtemplate(1, JsonViewItemComponent_div_7_nxt_json_view_item_1_Template, 1, 5, "nxt-json-view-item", 11);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", ctx_r0.childrenKeys);
  }
}
function isObject(value) {
  return value !== null && typeof value === 'object';
}
/** @internal */
function isArray(value) {
  return Array.isArray(value);
}
/** @internal */
function isUndefined(value) {
  return typeof value === 'undefined';
}
/** @internal */
function isString(value) {
  return typeof value === 'string';
}
/** @internal */
function isNumber(value) {
  return typeof value === 'number';
}
/** @internal */
function isBoolean(value) {
  return typeof value === 'boolean';
}

/** @internal */
class JsonViewItemComponent {
  constructor() {
    this.level = 0;
    this.isOpen = false;
    this.hasChildren = false;
    this.isObject = false;
    this.isArray = false;
    this.isInit = false;
    this._levelLabels = {};
  }
  set data(data) {
    this._data = data;
    if (this.isInit) {
      this.init();
    }
  }
  get data() {
    return this._data;
  }
  ngOnInit() {
    this.init();
    this.isInit = true;
  }
  init() {
    this.levelLabelHandle();
    this.levelOpenHandle();
    this.childrenKeysHandle();
    this.dataHandle();
  }
  levelLabelHandle() {
    if (this.levelLabels !== undefined) {
      this._levelLabels = this.levelLabels[this.level] || {};
    }
  }
  levelOpenHandle() {
    if (!isUndefined(this.levelOpen) && this.level <= this.levelOpen) {
      this.isOpen = true;
    }
  }
  childrenKeysHandle() {
    if (isObject(this.data)) {
      this.childrenKeys = Object.keys(this.data);
      if (this.childrenKeys && this.childrenKeys.length) {
        this.hasChildren = true;
      }
    }
  }
  dataHandle() {
    if (isObject(this.data)) {
      this.isObject = true;
      this.dataType = 'Object';
      if (isArray(this.data)) {
        this.isArray = true;
        this.dataType = 'Array';
      }
      if (this.key && this._levelLabels[this.key]) {
        this.dataType = this._levelLabels[this.key];
      }
    } else {
      this.value = this.data;
      if (isString(this.data)) {
        this.valueType = 'string';
      } else if (isNumber(this.data)) {
        this.valueType = 'number';
      } else if (isBoolean(this.data)) {
        this.valueType = 'boolean';
      } else if (null === this.data) {
        this.valueType = 'null';
        this.value = 'null';
      }
    }
  }
  toggle() {
    if (!(this.childrenKeys && this.childrenKeys.length)) {
      return;
    }
    this.isOpen = !this.isOpen;
  }
  static {
    this.ɵfac = function JsonViewItemComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || JsonViewItemComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: JsonViewItemComponent,
      selectors: [["nxt-json-view-item"]],
      inputs: {
        data: "data",
        key: "key",
        level: "level",
        levelOpen: "levelOpen",
        levelLabels: "levelLabels"
      },
      decls: 8,
      vars: 10,
      consts: [[1, "json-view"], [3, "click"], [1, "toggler", 3, "ngClass"], ["class", "key", 4, "ngIf"], [1, "value"], [4, "ngIf"], [3, "ngClass", 4, "ngIf"], ["class", "children", 4, "ngIf"], [1, "key"], [3, "ngClass"], [1, "children"], [3, "data", "key", "level", "levelOpen", "levelLabels", 4, "ngFor", "ngForOf"], [3, "data", "key", "level", "levelOpen", "levelLabels"]],
      template: function JsonViewItemComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0)(1, "a", 1);
          i0.ɵɵlistener("click", function JsonViewItemComponent_Template_a_click_1_listener() {
            return ctx.toggle();
          });
          i0.ɵɵelement(2, "span", 2);
          i0.ɵɵtemplate(3, JsonViewItemComponent_span_3_Template, 5, 1, "span", 3);
          i0.ɵɵelementStart(4, "span", 4);
          i0.ɵɵtemplate(5, JsonViewItemComponent_span_5_Template, 3, 2, "span", 5)(6, JsonViewItemComponent_span_6_Template, 2, 2, "span", 6);
          i0.ɵɵelementEnd()();
          i0.ɵɵtemplate(7, JsonViewItemComponent_div_7_Template, 2, 1, "div", 7);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵadvance(2);
          i0.ɵɵstyleProp("visibility", ctx.hasChildren ? "visible" : "hidden");
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction2(7, _c0, ctx.isOpen, !ctx.isOpen));
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.key);
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("ngIf", ctx.isObject);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", !ctx.isObject && ctx.valueType);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.hasChildren && ctx.isOpen);
        }
      },
      dependencies: [i1.NgClass, i1.NgForOf, i1.NgIf, JsonViewItemComponent],
      styles: [".json-view[_ngcontent-%COMP%]   a[_ngcontent-%COMP%]{cursor:pointer;text-decoration:none}.json-view[_ngcontent-%COMP%]   .toggler[_ngcontent-%COMP%]{width:1em;padding:0;display:block;text-align:center;float:left}.json-view[_ngcontent-%COMP%]   .collapse[_ngcontent-%COMP%]:before{content:\"-\"}.json-view[_ngcontent-%COMP%]   .expand[_ngcontent-%COMP%]:before{content:\"+\"}.json-view[_ngcontent-%COMP%]   .key[_ngcontent-%COMP%]{color:var(--nxt-json-view-color-key, #92278f)}.json-view[_ngcontent-%COMP%]   .value[_ngcontent-%COMP%]{color:var(--nxt-json-view-color-value, #000000)}.json-view[_ngcontent-%COMP%]   .value[_ngcontent-%COMP%]   .string[_ngcontent-%COMP%]{color:var(--nxt-json-view-color-string, #3ab54a)}.json-view[_ngcontent-%COMP%]   .value[_ngcontent-%COMP%]   .number[_ngcontent-%COMP%]{color:var(--nxt-json-view-color-number, #25aae2)}.json-view[_ngcontent-%COMP%]   .value[_ngcontent-%COMP%]   .boolean[_ngcontent-%COMP%]{color:var(--nxt-json-view-color-boolean, #aa0d91)}.json-view[_ngcontent-%COMP%]   .value[_ngcontent-%COMP%]   .null[_ngcontent-%COMP%]{color:var(--nxt-json-view-color-null, #f1592a)}.json-view[_ngcontent-%COMP%]   .children[_ngcontent-%COMP%]{margin-left:1em}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(JsonViewItemComponent, [{
    type: Component,
    args: [{
      selector: 'nxt-json-view-item',
      encapsulation: ViewEncapsulation.Emulated,
      template: "<div class=\"json-view\">\r\n    <a (click)=\"toggle()\">\r\n        <span class=\"toggler\"\r\n            [ngClass]=\"{'collapse': isOpen, 'expand': !isOpen}\"\r\n            [style.visibility]=\"hasChildren?'visible':'hidden'\"></span>\r\n        <span class=\"key\"\r\n            *ngIf=\"key\">\r\n            <span>{{key}}</span>\r\n            <span>: </span>\r\n        </span>\r\n        <span class=\"value\">\r\n            <span *ngIf=\"isObject\">\r\n                {{ dataType }}\r\n                <span *ngIf=\"isArray\">[ {{ $any(data)?.length }} ]</span>\r\n            </span>\r\n            <span *ngIf=\"!isObject && valueType\"\r\n                [ngClass]=\"valueType\">{{ value }}</span>\r\n        </span>\r\n    </a>\r\n    <div class=\"children\"\r\n        *ngIf=\"hasChildren && isOpen\">\r\n        <nxt-json-view-item *ngFor=\"let _key of childrenKeys\"\r\n            [data]=\"data[_key]\"\r\n            [key]=\"_key\"\r\n            [level]=\"level+1\"\r\n            [levelOpen]=\"levelOpen\"\r\n            [levelLabels]=\"levelLabels\"></nxt-json-view-item>\r\n    </div>\r\n</div>\r\n",
      styles: [".json-view a{cursor:pointer;text-decoration:none}.json-view .toggler{width:1em;padding:0;display:block;text-align:center;float:left}.json-view .collapse:before{content:\"-\"}.json-view .expand:before{content:\"+\"}.json-view .key{color:var(--nxt-json-view-color-key, #92278f)}.json-view .value{color:var(--nxt-json-view-color-value, #000000)}.json-view .value .string{color:var(--nxt-json-view-color-string, #3ab54a)}.json-view .value .number{color:var(--nxt-json-view-color-number, #25aae2)}.json-view .value .boolean{color:var(--nxt-json-view-color-boolean, #aa0d91)}.json-view .value .null{color:var(--nxt-json-view-color-null, #f1592a)}.json-view .children{margin-left:1em}\n"]
    }]
  }], null, {
    data: [{
      type: Input
    }],
    key: [{
      type: Input
    }],
    level: [{
      type: Input
    }],
    levelOpen: [{
      type: Input
    }],
    levelLabels: [{
      type: Input
    }]
  });
})();
class JsonViewComponent {
  static {
    this.ɵfac = function JsonViewComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || JsonViewComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: JsonViewComponent,
      selectors: [["nxt-json-view"]],
      inputs: {
        data: "data",
        levelOpen: "levelOpen",
        levelLabels: "levelLabels"
      },
      decls: 2,
      vars: 4,
      consts: [[1, "nxt-json-view"], [1, "json-view", 3, "data", "level", "levelOpen", "levelLabels"]],
      template: function JsonViewComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵelement(1, "nxt-json-view-item", 1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵproperty("data", ctx.data)("level", 0)("levelOpen", ctx.levelOpen)("levelLabels", ctx.levelLabels);
        }
      },
      dependencies: [JsonViewItemComponent]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(JsonViewComponent, [{
    type: Component,
    args: [{
      selector: 'nxt-json-view',
      encapsulation: ViewEncapsulation.Emulated,
      template: "<div class=\"nxt-json-view\">\r\n    <nxt-json-view-item [data]=\"data\"\r\n        [level]=\"0\"\r\n        [levelOpen]=\"levelOpen\"\r\n        [levelLabels]=\"levelLabels\"\r\n        class=\"json-view\"></nxt-json-view-item>\r\n</div>\r\n"
    }]
  }], null, {
    data: [{
      type: Input
    }],
    levelOpen: [{
      type: Input
    }],
    levelLabels: [{
      type: Input
    }]
  });
})();
class JsonViewModule {
  static {
    this.ɵfac = function JsonViewModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || JsonViewModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: JsonViewModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(JsonViewModule, [{
    type: NgModule,
    args: [{
      declarations: [JsonViewItemComponent, JsonViewComponent],
      imports: [CommonModule],
      exports: [JsonViewComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { JsonViewComponent, JsonViewModule };
