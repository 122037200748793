<div class="p-3 h-100" *ngIf="product;else loading">
    <div class="col-12 col-md-11 col-lg-10 col-xl-9 col-xxl-8 mx-auto p-4 bg-white rounded-3" style="height: 90%;">
        <ng-stepper class="h-100 pt-2" #cdkStepper [linear]="false">
            <cdk-step [optional]="true">
                <ng-template cdkStepLabel>
                    <div class="step-bullet"><i class="bi bi-map"></i></div>
                    <div class="step-title d-md-block">Descrizione</div>
                </ng-template>
                <div class="inner-step">
                    <div class="step-form">
                        <div>
                            <div class="d-flex flex-column w-100 p-3">
                                <ta-input label='Nome' inputGroupClass="w-50" [(value)]="product.name"></ta-input>
                                <div class="btn-group mt-2" role="group" style="width:580px">
                                    <input type="radio" class="btn-check" name="btnradio" id="btnradio1"
                                        autocomplete="off" [(ngModel)]="product.market" value="food">
                                    <label class="btn btn-outline-primary btn-sm" for="btnradio1">Cibo</label>

                                    <input type="radio" class="btn-check" name="btnradio" id="btnradio2"
                                        autocomplete="off" [(ngModel)]="product.market" value="wine">
                                    <label class="btn btn-outline-primary btn-sm" for="btnradio2">Vino</label>

                                    <input type="radio" class="btn-check" name="btnradio" id="btnradio3"
                                        autocomplete="off" [(ngModel)]="product.market" value="craft">
                                    <label class="btn btn-outline-primary btn-sm" for="btnradio3">Artigianato</label>
                                </div>
                                <div class="form-floating w-100 mb-3 mt-2">
                                    <textarea [(ngModel)]="product.description" class="form-control"
                                        placeholder="Nome del Luogo" style="height:150px"></textarea>
                                    <label for="floatingInput">Descrizione</label>
                                </div>
                                <div class="form-floating w-100 mb-3">
                                    <textarea [(ngModel)]="product.notes" class="form-control" placeholder="Note"
                                        style="height:150px"></textarea>
                                    <label for="floatingInput">Note</label>
                                </div>
                                <div class="form-floating w-100 mb-3">
                                    <textarea [(ngModel)]="product.history" class="form-control" placeholder="Note"
                                        style="height:150px"></textarea>
                                    <label for="floatingInput">Storia</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bottom-button">
                        <button type="button" class="ms-auto button btn btn-light" (click)="cdkStepper.next()"><i
                                class="bi bi-arrow-right fs-1"></i></button>
                    </div>
                </div>
            </cdk-step>

            <cdk-step [optional]="true">
                <ng-template cdkStepLabel>
                    <div class="step-bullet"><i class="bi bi-file-text"></i></div>
                    <div class="step-title d-md-block">Dettagli</div>
                </ng-template>
                <div class="inner-step">
                    <div class="step-form">
                        <div class="p-1">
                            <div class="p-1" style="height: 90%;" *ngIf="product">
                                <ta-single-image *ngIf="product" [(image)]="product.coverImage" [uuid]="product.uuid"
                                    (onImageSuccess)="onImageSuccess($event)"
                                    (onImageWarning)="(onImageWarning($event))" (onImageDeleted)="onImageDeleted()">
                                </ta-single-image>
                            </div>
                            <div class="">
                                <div class="lead">Tags</div>
                                <ta-tags *ngIf="product" label="tags" [(tags)]="product.tags" class="mt-3"
                                    (onForbiddenTag)="tagWarning()" (onError)="tagError($event)"></ta-tags>
                            </div>
                        </div>
                    </div>
                    <div class="bottom-button">
                        <button type="button" class="button btn btn-light" (click)="cdkStepper.previous()"><i
                                class="bi bi-arrow-left"></i></button>
                        <button type="button" class="button btn btn-light" (click)="cdkStepper.next()"><i
                                class="bi bi-arrow-right"></i></button>
                    </div>
                </div>
            </cdk-step>


            <cdk-step [optional]="true">
                <ng-template cdkStepLabel>
                    <div class="step-bullet"><i class="bi bi-image"></i></div>
                    <div class="step-title d-md-block">Luoghi</div>
                </ng-template>
                <div class="inner-step">
                    <div class="step-form">
                        <div class="p-1" style="height: 90%;" *ngIf="product">
                            <div class="h-50 overflow-auto">
                                <ta-filterable-list *ngIf="product" [selectable]="producers"
                                    [(selected)]="product.producers" [label]="'Produttori'"></ta-filterable-list>
                            </div>
                            <div class="h-50 overflow-auto">
                                <ta-filterable-list *ngIf="product" [selectable]="resellers"
                                    [(selected)]="product.resellers" [label]="'Rivenditori'"></ta-filterable-list>
                            </div>
                        </div>
                        <div class="bottom-button">
                            <button class="button btn btn-light" (click)="cdkStepper.previous()"><i
                                    class="bi bi-arrow-left fs-1"></i></button>
                            <button class="ms-auto button btn btn-light" (click)="cdkStepper.next()"><i
                                    class="bi bi-arrow-right fs-1"></i></button>
                        </div>
                    </div>
                </div>
            </cdk-step>

            <cdk-step [optional]="true">
                <ng-template cdkStepLabel>
                    <div class="step-bullet"><i class="bi bi-file-text"></i></div>
                    <div class="step-title d-md-block">Riepilogo</div>
                </ng-template>
                <div class="inner-step">
                    <div class="step-form">
                        <div class="p-1">
                            <div class="d-flex flex-column">
                                <div class="lead">Nome
                                    <div class="fw-bold">{{product.name}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bottom-button">
                        <button type="button" class="button btn btn-light" (click)="cdkStepper.previous()"><i
                                class="bi bi-arrow-left"></i></button>
                        <button type="button" class="button btn btn-light" (click)="saveProduct()"><i
                                class="bi bi-cloud-upload fs-1"></i></button>
                    </div>
                </div>
            </cdk-step>
        </ng-stepper>
    </div>
</div>
<ng-template #loading>
    <div class="d-flex justify-content-center align-items-center h-100">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</ng-template>
<ng-template #t let-c="close" let-o="open">
    <div class="overflow-hidden aspect-ratio-1">
        <img [src]="tooltipImage" class="rounded" alt="" style="width: 150px;height: 150px;object-fit: cover;"
            loading="lazy">
    </div>
</ng-template>