import { OnInit, AfterViewInit, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Area } from 'src/app/guides/model/interfaces';
import { AreasService } from 'src/app/guides/services/areas.service';
import { StateService } from 'src/app/services/state.service';
import { BreadcrumbService } from '@luhuiguo/xng-breadcrumb';
import Map from 'ol/Map';
import View from 'ol/View';
import Overlay from 'ol/Overlay';
import TileLayer from 'ol/layer/Tile';
import { fromLonLat } from 'ol/proj';
import OSM from 'ol/source/OSM';



@Component({
  selector: 'app-selected-area',
  templateUrl: './selected-area.component.html',
  styleUrls: ['./selected-area.component.css'],
  host: { 'class': 'h-100 position-relative' }
})
export class SelectedAreaComponent implements OnInit, AfterViewInit {

  area: Area;
  menuVisible: boolean = true;

  map!: Map;
  overlay!: Overlay;


  constructor(
    private breadcrumbService: BreadcrumbService,
    private route: ActivatedRoute,
    private areaServeice: AreasService,
    private stateService: StateService) {
    this.breadcrumbService.set('areas/:id', `.`);
  }

  ngOnInit(): void {
    let loading = true;
    let breadcrumb = '.';
    const waitingBreadcrumb = setInterval(() => {
      if (loading) {
        breadcrumb += '.';
        this.breadcrumbService.set('areas/:id', breadcrumb);
      }
    }, 1000);

    this.route.paramMap.subscribe(
      params => {
        this.areaServeice.getAreas().subscribe(
          (data: any) => {
            loading = false;
            clearInterval(waitingBreadcrumb);
            this.area = data.find((area: Area) => area.uuid === params.get('id'));
            this.breadcrumbService.set('areas/:id', `Area: ${this.area.name}`);
            this.stateService.selectedArea$.next(this.area);
          })
      }
    )
  }

  ngAfterViewInit(): void {
    // this.initMap();
    setTimeout(() => {
      this.initMap();
    }, 3000);
  }

  toggleMenu() {
    this.menuVisible = !this.menuVisible;
  }

  initMap() {
    this.map = new Map({
      view: new View({
        center: fromLonLat([this.area.latlng.lng, this.area.latlng.lat]),
        zoom: 13,
      }),
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      target: `ol-map-${this.area.name}`,
      controls: [],
    });
  }
}

