import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/guides/services/config.service';
import { Setting } from 'src/app/guides/model/interfaces';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css'],
})
export class SettingsComponent implements OnInit {
  constructor(private configService: ConfigService) {}

  settings: Setting[];
  
  ngOnInit(): void {
    this.configService.getSettingParams().subscribe((data) => {
      this.settings = <Setting[]>data;
    });
  }

  onEnter(e,i){
    this.settings[i].valsArray.push(e.target.value);
  }

  remove(param: string, index: number) {
    const i = this.settings.findIndex((s) => {
      return s.param === param}) 
    this.settings[i].valsArray.splice(index, 1);
    console.log(this.settings);
      
  }

  private getObjectValues(any){
    console.log(any);
    let returning = Object.values(any); 
    return returning;
  }

  /*getKeyValue(pair:{key:any,val:any}):{k: string,v: string}{
    
    //return this.configService.splitPairToKeyValue(pair,separator);
  }*/
}
