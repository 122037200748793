import { Component, EventEmitter, Input, OnInit, Output, ViewChild, } from '@angular/core';
import { Observable, OperatorFunction, combineLatest, debounceTime, distinctUntilChanged, map, filter } from 'rxjs';
import { Area, Place, Address, AISubject, Intent } from 'src/app/guides/model/interfaces';
import * as _ from 'lodash';
import { _DEBUG } from 'src/app/app.component';
import { AIService } from 'src/app/guides/services/ai.service';

// type _intent = { name: string };
// const intentsList: _intent[] = [
//   { name: 'storia' },
//   { name: 'durata' },
//   { name: 'costo' },
// ]

const intentsList = [
  'storia',
  'durata',
  'costo',
]


@Component({
  selector: 'app-area-ai-active',
  templateUrl: './area-ai-active.component.html',
  styleUrls: ['./area-ai-active.component.css'],
  host: { 'style': 'max-width:1000px;margin-left:auto;margin-right:auto', 'class': 'w-75 pt-5' }
})
export class AreaAiActiveComponent implements OnInit {
  // formatter = (intent: Intent) => intent.name;

  @Input() area: Area = new Area();
  @Input() aiSubject: AISubject = new AISubject();
  @Output() areaChange: EventEmitter<Area> = new EventEmitter<Area>();
  @Output() aiSubjectChange: EventEmitter<AISubject> = new EventEmitter<AISubject>();

  settings = {
    singleSelection: false,
    idField: '_id',
    textField: 'name',
    enableCheckAll: false,
    allowSearchFilter: true,
    limitSelection: -1,
    clearSearchFilter: true,
    maxHeight: 250,
    itemsShowLimit: 3,
    searchPlaceholderText: 'Cerca',
    noDataAvailablePlaceholderText: 'Nessun risultato',
    closeDropDownOnSelection: false,
    showSelectedItemsAtTop: false,
    defaultOpen: false,
  };

  subSubjects = [];


  // search: OperatorFunction<string, readonly { name }[]> = (text$: Observable<string>) =>
  //   text$.pipe(
  //     debounceTime(200),
  //     distinctUntilChanged(),
  //     filter((term) => term.length >= 2),
  //     map((term) => intentsList.filter((intent) => new RegExp(term, 'mi').test(intent.name)).slice(0, 10)),
  //   );
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      filter((term) => term.length >= 2),
      map(term =>
        term === ''
          ? []
          : intentsList.filter(option => option.toLowerCase().indexOf(term.toLowerCase()) > -1)
      )
    );

  constructor(
    private aiService: AIService
  ) {
  }

  ngOnInit(): void {
    this.aiService.getAllSubjects().subscribe((subjects) => {
      this.subSubjects = subjects;
    });
    console.log('get subjects lis', this.subSubjects);
    // console.log('place get data', this.place);

  };

  onToggleChange() {
    this.aiSubject.name = this.area.name;
    this.aiSubject.active = true;
    this.aiSubjectChange.emit(this.aiSubject);
    if (this.area.active) {
      this.addIntent('');
    } else {
      this.aiSubject.intents = [];
    }
  }

  onModelChange(item: any) {
    console.log('onItemSelect', this.aiSubject);
    this.aiSubjectChange.emit(this.aiSubject);
  };

  addIntent(k: string) {

    this.aiSubject.intents.push({
      name: k,
      answer: '',
      active: true
    });
    this.aiSubjectChange.emit(this.aiSubject);
  }

  remIntent(i: number) {
    this.aiSubject.intents.splice(i, 1);
    this.aiSubjectChange.emit(this.aiSubject);
  }

}