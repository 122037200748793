import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from '@luhuiguo/xng-breadcrumb';
import { combineLatest } from 'rxjs';
import { Activity } from 'src/app/guides/model/interfaces';
import { GuidebookService } from 'src/app/guides/services/guidebook.service';
import { PlaceService } from 'src/app/guides/services/place.service';
import { StateService } from 'src/app/services/state.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-activity',
  templateUrl: './create-activity.component.html',
  styleUrl: './create-activity.component.css'
})
export class CreateActivityComponent implements OnInit {
  params$ = this.route.params;
  area$ = this.stateService.selectedArea$;
  tooltipImage: string = '';
  imagesBaseDir = environment.imagesPrefix;
  activity: Activity;
  places: any;

  constructor(private guidebookService: GuidebookService,
    private route: ActivatedRoute,
    private placeService: PlaceService,
    private breadcrumbService: BreadcrumbService,
    private stateService: StateService) {
  }

  ngOnInit(): void {
    combineLatest([this.params$, this.area$]).subscribe(([params, area]) => {
      if (params.id == 0) {
        this.breadcrumbService.set('areas/:id/guidebooks/dyn/activity/:id', `Aggiungi Attività`);
        this.activity = new Activity(area._id);
        this.populatePlaces(area._id);

      } else {
        this.guidebookService.getGuidebookById(params.id).subscribe((activity: any) => {
          this.activity = activity;
          this.breadcrumbService.set('areas/:id/guidebooks/dyn/activity/:id', `Arrività: ${activity.name}`);
          this.populatePlaces(area._id);
        });
      }
    });
  }

  populatePlaces(area: string) {
    this.placeService.getMinPlaces(area).subscribe((places: any) => {
      places.map((place) => {
        place.isChecked = this.activity.places.includes(place._id);
        place.isHidden = false;
      });
      places.sort((a, b) => {
        if (a.isChecked && !b.isChecked) {
          return -1; // `a` comes before `b`
        } else if (!a.isChecked && b.isChecked) {
          return 1; // `b` comes before `a`
        } else {
          // If isChecked properties are the same, sort alphabetically
          return a.name.localeCompare(b.name);
        }
      });
      this.places = places;
    })
  }

  filterPlaces(event: any) {
    this.places.map((place) => {
      if (place.name.toLowerCase().includes(event.target.value.toLowerCase())) {
        place.isHidden = false;
      } else {
        place.isHidden = true;
      }
    });
  }

  saveActivity() {
    if (this.activity._id) {
      this.updateActivity();
    } else {
      this.guidebookService.createGuidebook(this.activity).subscribe((res) => {
        this.activity._id = res._id;
        this.stateService.message$.next({ text: `Attività Creata.`, type: environment.MESSAGES.SUCCESS });
      });
    }
  }

  updateActivity() {
    this.guidebookService.updateGuidebook(this.activity).subscribe((res) => {
      this.stateService.message$.next({ text: `Attività Aggiornata.`, type: environment.MESSAGES.SUCCESS });
    });
  }

  onCheckboxChange(placeId: string) {
    if (this.activity.places.includes(placeId)) {
      // If item already selected, remove it
      this.activity.places = this.activity.places.filter(p => p !== placeId);
    } else {
      // If item not selected, add it
      this.activity.places.push(placeId);
    }
  }

  onImageWarning(state: string) {
    if (state == 'cancelled') {
      this.stateService.message$.next({ text: `Operazione Annullata.`, type: environment.MESSAGES.WARNING });
    }
  }

  onImageSuccess(state: string) {
    this.saveActivity();
    this.stateService.message$.next({ text: `Immagine Salvata.`, type: environment.MESSAGES.SUCCESS });
  }

  onImageDeleted() {
    this.stateService.message$.next({ text: `Immagine Eliminata.`, type: environment.MESSAGES.WARNING });
    this.saveActivity();
  }

  setTooltipImage(imageUrl, tooltip) {
    tooltip.container = 'body';
    this.tooltipImage = `${this.imagesBaseDir}${imageUrl}`;
  }

  removeTooltipImage(tooltip) {
    tooltip.close(false);
    this.tooltipImage = '';
  }
}
