import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { AreasService } from 'src/app/guides/services/areas.service';
import { Area, Place } from 'src/app/guides/model/interfaces';
import { ActivatedRoute, NavigationExtras, Params, Router } from '@angular/router';
import { PlaceService } from 'src/app/guides/services/place.service';
import { BreadcrumbService } from '@luhuiguo/xng-breadcrumb';
import { Observable, combineLatest, switchMap, take } from 'rxjs';
import { StateService } from 'src/app/services/state.service';
import { DeleteStoredImgsService } from 'src/app/common/services/delete-stored-imgs.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RequestsComponent } from './requests/requests.component';
import { PendingShopsComponent } from './pending-shops/pending-shops.component';

@Component({
  selector: 'app-places',
  templateUrl: './places.component.html',
  styleUrls: ['./places.component.css'],
})
export class PlacesComponent implements OnInit {

  area: string = '';
  places: Place[] = [];
  loading: boolean = true;
  datalist: Element;
  private modalService = inject(NgbModal);
  placeRequests$: Observable<any[]>;
  pendingShops$: Observable<any[]>;

  constructor(
    private placeService: PlaceService,
    private breadcrumbService: BreadcrumbService,
    private stateService: StateService,
    private route: ActivatedRoute,
    private deleteStoredImgsService: DeleteStoredImgsService,
  ) {
  }

  ngOnInit(): void {
    this.breadcrumbService.set('areas/:id/places', `Luoghi`);
    this.loading = true;

    // this.placeService.getAllPlaces().subscribe((data) => {
    //   this.places = data;
    // });

    let params$: Observable<Params> = this.route.params;
    let area$: Observable<Area> = this.stateService.selectedArea$;
    combineLatest([params$, area$]).subscribe(([params, area]) => {
      console.log('params area???', area._id);
      this.placeService.getAreaPlaces(area._id).subscribe((data) => {
        this.places = data;
        this.placeRequests$ = this.placeService.getPlaceRequests$(area._id);
        this.pendingShops$ = this.placeService.getPendingShops$(area._id);
      });
    });

    this.loading = false;
    //this.placeService.getPlace().subscribe((data) => {this.places = data['records']});
  }

  /*onSelectArea(area: string) {
    if (area || area != '') {
      this.placeService.getSelectedAreaPlaces(area).subscribe((data) => {
        this.places = data['records'];
      });
    } else {
      this.placeService.getAllPlaces().subscribe((data) => {
        this.places = data['records'];
      });
    }
  }*/

  /*onFilterByName(e) {
    console.log(e);
    console.log(this.datalist);
    let elem:Element = document.getElementById('datalistOptions');
    let elem1:Element = document.getElementById('PlacesDataList');
    console.log(elem);
    console.log(elem1);
    }*/

  // old place delete flow 
  // deletePlace(place: Place) {
  //   this.placeService.deletePlace(place._id).subscribe((data) => {
  //     console.log(data);
  //     this.places = this.places.filter((t) => t._id !== place._id);
  //     //TODO ALERT
  //   });
  // }

  deletePlace(place: Place) {
    if (place.coverImage || place.images.length > 0) {
      this.deleteStoredImgsService.deleteFolder(place.uuid).subscribe();
    }
    return this.placeService.deletePlace(place._id).subscribe({
      next: (data) => {
        this.places = this.places.filter((t) => t._id !== place._id);
        this.stateService.message$.next({ type: 'success', text: 'Luogo Eliminato' });
      },
      error: (error) => {
        this.stateService.message$.next({ type: 'error', text: 'Errore durante l\'eliminazione' });
      }
    });
  }

  /*toggleReminder(place: Place) {
    //place.reminder = !place.reminder;
    this.placeService.savePlace(place).subscribe();
  }*/

  /*addPlace(place: Place) {
    this.placeService
      .savePlace(place)
      .subscribe((placeId) => (place.id = placeId));
  }*/

  openPlacesRequests(p: any) {
    const modalRef = this.modalService.open(RequestsComponent, { size: 'xl' });
    modalRef.componentInstance.placeRequests = p;
  }

  openPendingShops(s: any) {
    const modalRef = this.modalService.open(PendingShopsComponent, { size: 'xl' });
    modalRef.componentInstance.pendingShops = s;
  }
}
