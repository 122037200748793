<div id="fab-button" class="btn btn-primary position-fixed bottom-0 end-0" [routerLink]="['./','new']">
  <fa-icon [icon]="['fas', 'plus']" size="2x" style="margin: 1%"></fa-icon>
</div>
<div *ngIf="loading" class="spinner-border text-primary" role="status">
  <span class="visually-hidden">Loading...</span>
</div>
<div class="container pt-4">
  <div class="row">
    <div *ngFor="let subject of subjects" class="col-12 col-sm-6 col-md-4 col-lg-3 pb-3" style="min-width: 25rem;">
      <app-subject-item [subject]="subject" (onDeleteSubject)="deleteSubject(subject)">
      </app-subject-item>
    </div>
  </div>
</div>