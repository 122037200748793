<div class="p-3 h-100">
  <div class="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6 mx-auto p-4 bg-white rounded-3" style="height: 90%;">
    <ng-stepper class="h-100 pt-2" #cdkStepper [linear]="false">
      <cdk-step [optional]="true">
        <ng-template cdkStepLabel>
          <div class="step-bullet"><i class="bi bi-map"></i></div>
          <div class="step-title d-md-block">Nome</div>
        </ng-template>
        <div class="inner-step">
          <div class="step-form">
            <div class="p-1" *ngIf="person">
              <ta-input label="Nome" [(value)]="person.name"></ta-input>
              <ta-input label="Cognome" [(value)]="person.surname"></ta-input>
              <div class="mx-auto mt-5 mb-2 h5">E' un professionista del settore?</div>
              <ta-checkbox [(value)]="person.isProfessional" label="Professionista" checkedIcon="bi-tools"
                checkedColor="green"></ta-checkbox>
              <div class="mx-auto mt-5 mb-2 h5">E' possibile fissare un appuntamento?</div>
              <ta-checkbox [(value)]="person.isBookable" label="Prenotabile" checkedIcon="bi-calendar"
                checkedColor="green"></ta-checkbox>
              <div class="form-floating w-100 mt-5">
                <textarea id="notes" [(ngModel)]="person.notes" class="form-control" placeholder="Note"
                  style="height:200px"></textarea>
                <label for="notes">Note</label>
              </div>
            </div>
          </div>
          <div class="bottom-button">
            <button type="button" class="ms-auto button btn btn-light" (click)="cdkStepper.next()"><i
                class="bi bi-arrow-right fs-1"></i></button>
          </div>
        </div>
      </cdk-step>

      <cdk-step [optional]="true">
        <ng-template cdkStepLabel>
          <div class="step-bullet"><i class="bi bi-file-text"></i></div>
          <div class="step-title d-md-block">Contatti</div>
        </ng-template>
        <div class="inner-step">
          <div class="step-form">
            <div class="p-1" *ngIf="person">
              <ta-contact-infos [(value)]="person.contacts"
                (onInputError)="onContactInputError($event)"></ta-contact-infos>
            </div>
          </div>
          <div class="bottom-button">
            <button type="button" class="button btn btn-light" (click)="cdkStepper.previous()"><i
                class="bi bi-arrow-left"></i></button>
            <!-- <button type="button" class="btn btn-default" (click)="cdkStepper.next()"><i class="bi bi-arrow-right"></i></button> -->
            <button type="button" class="button btn btn-light" (click)="cdkStepper.next()"><i
                class="bi bi-arrow-right"></i></button>
          </div>
        </div>
      </cdk-step>

      <cdk-step [optional]="true">
        <ng-template cdkStepLabel>
          <div class="step-bullet"><i class="bi bi-envelope"></i></div>
          <div class="step-title d-md-block">Attività</div>
        </ng-template>
        <div class="inner-step">
          <div class="step-form">
            <div class="p-1">
              <ta-filterable-list *ngIf="person" [(selected)]="person.activities" [selectable]="activities"
                label="Seleziona le Attività"></ta-filterable-list>
              <ta-tags *ngIf="person" label="tags" [(tags)]="person.tags" class="" (onForbiddenTag)="tagWarning()"
                (onError)="tagError($event)"></ta-tags>
            </div>
          </div>
          <div class="bottom-button">
            <button type="button" class="button btn btn-light" (click)="cdkStepper.previous()"><i
                class="bi bi-arrow-left"></i></button>
            <button type="button" class="button btn btn-light" (click)="cdkStepper.next()"><i
                class="bi bi-arrow-right"></i></button>
          </div>
        </div>
      </cdk-step>

      <cdk-step [optional]="true">
        <ng-template cdkStepLabel>
          <div class="step-bullet"><i class="bi bi-file-text"></i></div>
          <div class="step-title d-md-block">Aree</div>
        </ng-template>
        <div class="inner-step">
          <div class="step-form">
            <ta-filterable-list *ngIf="person" [(selected)]="person.areas" [selectable]="areas"
              label="Seleziona le Aree"></ta-filterable-list>
          </div>
          <div class="bottom-button">
            <button type="button" class="button btn btn-light" (click)="cdkStepper.previous()"><i
                class="bi bi-arrow-left"></i></button>
            <button type="button" class="button btn btn-light" (click)="cdkStepper.next()"><i
                class="bi bi-arrow-right"></i></button>
          </div>
        </div>
      </cdk-step>

      <cdk-step [optional]="true">
        <ng-template cdkStepLabel>
          <div class="step-bullet"><i class="bi bi-file-text"></i></div>
          <div class="step-title d-md-block">Immagini</div>
        </ng-template>
        <div class="inner-step">
          <div class="step-form">
            <ta-single-image *ngIf="person" [(image)]="person.coverImage" [uuid]="person.uuid"
              (onImageSuccess)="onImageSuccess()" (onImageWarning)="(onImageWarning($event))"
              (onImageDeleted)="onImageDeleted()"></ta-single-image>
          </div>
          <div class="bottom-button">
            <button type="button" class="button btn btn-light" (click)="cdkStepper.previous()"><i
                class="bi bi-arrow-left"></i></button>
            <button type="button" class="button btn btn-light" (click)="cdkStepper.next()"><i
                class="bi bi-arrow-right"></i></button>
          </div>
        </div>
      </cdk-step>

      <cdk-step [optional]="true">
        <ng-template cdkStepLabel>
          <div class="step-bullet"><i class="bi bi-file-text"></i></div>
          <div class="step-title d-md-block">Riepilogo</div>
        </ng-template>
        <div class="inner-step">
          <div class="step-form">
            <div class="p-1">
              <div *ngIf="person" class="d-flex flex-column">
                <div class="d-flex flex-row">
                  <div class="me-3">
                    <div class="h5">Nome</div>
                    <div>{{person.name}}</div>
                  </div>
                  <div class="me-3">
                    <div class="h5">Cognome</div>
                    <div>{{person.surname}}</div>
                  </div>
                </div>
                <div class="d-flex flex-row">
                  <div class="me-3">
                    <div class="h5">Professionista</div>
                    <div>{{person.isProfessional}}</div>
                  </div>
                  <div class="me-3">
                    <div class="h5">Prenotabile</div>
                    <div>{{person.isBookable}}</div>
                  </div>
                </div>
                <div class="mt-3">
                  <div class="h5">Note</div>
                  <div>{{person.notes}}</div>
                </div>
                <div class="mt-3">
                  <div class="h5">Contatti</div>
                  <div class="d-flex flex-wrap">Email:<ta-labeled-badge *ngFor="let e of person.contacts.emails"
                      [deletable]="false" [label]="e.label" [content]="e.address"></ta-labeled-badge></div>
                  <div class="d-flex flex-wrap">Numeri:<ta-labeled-badge *ngFor="let p of person.contacts.phones"
                      [deletable]="false" [label]="p.label" [content]="phonePipe.transform(p.number)"
                      color="secondary"></ta-labeled-badge>
                  </div>
                  <div class="d-flex flex-wrap">Link:<ta-labeled-badge *ngFor="let s of person.contacts.socials"
                      [deletable]="false" [label]="s.label" [content]="s.link" color="success"></ta-labeled-badge></div>
                  <div class="d-flex flex-wrap">Siti Web:<ta-labeled-badge *ngFor="let w of person.contacts.websites"
                      [deletable]="false" [label]="w.label" [content]="w.url" color="danger"></ta-labeled-badge></div>
                </div>
                <div class="mt-3">
                  <div class="h5">Attività</div>
                  <ta-labeled-badge *ngFor="let a of person.activities" [deletable]="false"
                    [content]="a"></ta-labeled-badge>
                </div>
                <div class="mt-3">
                  <div class="h5">Tags</div>
                  <ta-labeled-badge *ngFor="let t of person.tags" [deletable]="false" [content]="t"></ta-labeled-badge>
                </div>
                <div class="mt-3">
                  <div class="h5">Immagine</div>
                  <div *ngIf="person.coverImage"><img [src]="imgpref+person.coverImage.url" alt="..."
                      style="width: 150px;object-fit: cover;aspect-ratio:1" loading="lazy"
                      onerror="this.onerror=null;this.src='assets/placeholder.jpg';" /></div>
                </div>
              </div>
            </div>
          </div>
          <div class="bottom-button">
            <button type="button" class="button btn btn-light" (click)="cdkStepper.previous()"><i
                class="bi bi-arrow-left"></i></button>
            <!-- <button type="button" class="btn btn-default" (click)="cdkStepper.next()"><i class="bi bi-arrow-right"></i></button> -->
            <button class="ms-auto button btn btn-light" (click)="savePerson()"><i
                class="bi bi-cloud-upload fs-1"></i></button>
          </div>
        </div>
      </cdk-step>
    </ng-stepper>
  </div>
</div>
<!--https://via.placeholder.com/512x346?text=Visit+Blogging.com+Now -->