export const environment = {

  production: false,

  _BAD_WORDS: 'https://raw.githubusercontent.com/napolux/paroleitaliane/master/paroleitaliane/lista_badwords.txt',

  xferApi: {
    _AMADEUS_AUTH: 'tourismapp/api/amadeusAccessToken.php',
    _TRENITALIA: 'tourismapp/api/trenitaliaApi.php',
  },

  API_URL: "https://treeql.devel.tourimapp.it",

  IMAGES: "https://images.devel.tourismapp.it/",
  FILES: "https://files.devel.tourismapp.it/",

  dbApiBaseDir: 'https://be.devel.tourismapp.it/rest/',
  imagesBaseDir: '/',
  imagesPrefix: 'https://images.devel.tourismapp.it/',
  MESSAGES: {
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'danger',
    LOG: 'dark'
  },

  API: "https://be.devel.tourismapp.it/",
  TREEQL: "https://be.devel.tourismapp.it/treeql/",

  APP_LOGO: "/assets/logo.png",
  SUPERTOKENS: {
    ENABLE_DEBUG_MODE: true,
    APP_INFO: {
      APP_NAME: "TourismApp",
      API_DOMAIN: "https://be.devel.tourismapp.it",
      API_BASE_PATH: "auth/",
      WEBSITE_DOMAIN: "https://admin.devel.tourismapp.it",
      WEBSITE_BASE_PATH: "auth",
    },
    CLIENT_TYPE: "tourimsmapp-web",
    RECIPE_LIST: {
      REDIRECT: {
        NEW_USER: "areas",
        EXISTING_USER: "areas",
        DEFAULT: "areas"
      }
    },
    STYLE: {
      PALETTE_PRIMARY: "110, 177, 85",
      PALETTE_PRIMARY_BORDER: "63, 120, 56",
    },
    LANGUAGE: {
      DEFAULT: "it",
    }
  }
};