import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AISubject, Place } from 'src/app/guides/model/interfaces';
import { NavigationExtras, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from 'src/app/common/components/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-subject-item',
  templateUrl: './subject-item.component.html',
  styleUrls: ['./subject-item.component.css'],
})
export class SubjectItemComponent implements OnInit {
  @Input() subject: AISubject;
  @Output() onDeleteSubject: EventEmitter<AISubject> = new EventEmitter();

  constructor(private router: Router,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {

  }

  onDelete(place) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.alertTarget = 'aisubjects';

    modalRef.result.then(() => {
      this.onDeleteSubject.emit(place);
    })
  }
}
