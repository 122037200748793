<div class="h-100" *ngIf="menuVisible">
  <div id="fab-button" class="btn btn-primary position-fixed bottom-0 end-0" [routerLink]="['./','0']">
    <fa-icon [icon]="['fas', 'plus']" size="2x" style="margin: 1%"></fa-icon>
  </div>
  <div *ngIf="loading" class="h-100 d-flex">
    <div class="spinner-border text-primary m-auto" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <div class="container pt-4">
    <div class="row">
      <div *ngFor="let person of people" class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3" style="min-width: 25rem;">
        <!--app-person-item [person]="person" (onDeletePerson)="deletePerson(person)">
        </app-person-item-->
        <ta-card [value]="person" entity="person" (onDelete)="deletePerson(person)"></ta-card>
      </div>
    </div>
  </div>
</div>
<router-outlet (activate)="toggleMenu()" (deactivate)="toggleMenu()"></router-outlet>