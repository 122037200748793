import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from "@angular/common";
import { FaModule } from './common/fa/fa.module';
import { MaterialModule } from './common/material/material.module';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomComponentsModule } from './common/components/custom-components.module';
import { ContestModule } from './contest/contest.module';
import { GuidesModule } from './guides/guides.module';
import { BreadcrumbModule } from '@luhuiguo/xng-breadcrumb';
import { NgxGpAutocompleteModule } from "@angular-magic/ngx-gp-autocomplete";
import { Loader } from '@googlemaps/js-api-loader';
import { SettingsModule } from './settings/settings.module';
import { TaLibraryModule } from 'projects/ngx-ta-ui-library/src/public-api';
import { environment } from 'src/environments/environment';
import { MarkdownModule } from 'ngx-markdown';
import { NgxSliderModule } from '@angular-slider/ngx-slider';

const appRoutes: Routes = [];

@NgModule({
    declarations: [
        AppComponent,
        //DevelComponent,
        //LoginComponent,
    ],
    bootstrap: [AppComponent],
    exports: [], imports: [FormsModule,
        BrowserModule,
        AppRoutingModule,
        FontAwesomeModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, { enableTracing: true }),
        DragDropModule,
        CommonModule,
        NgxSliderModule,
        FaModule,
        MaterialModule,
        CustomComponentsModule,
        NgbModule,
        ContestModule,
        BreadcrumbModule,
        NgxGpAutocompleteModule,
        AppRoutingModule,
        GuidesModule,
        TaLibraryModule.forRoot({
            imagesEndpoint: environment.IMAGES,
            filesEndpoint: environment.FILES,
            dbApiBaseDir: environment.dbApiBaseDir,
            badWordsUrl: environment._BAD_WORDS
        }),
        SettingsModule,
        MarkdownModule.forRoot()], providers: [
            NgbActiveModal,
            {
                provide: Loader,
                useValue: new Loader({
                    apiKey: 'AIzaSyCV6gh1nQmAZUtmjrxOLLix8LSE02SyfbI',
                    libraries: ['places']
                })
            },
            provideHttpClient(withInterceptorsFromDi())
        ]
})
export class AppModule { }
