<div *ngIf="menuVisible" class="container">
  <div class="row" *ngIf="settings as s; else loading">
    <div class="col-12" *ngFor="let c of s.ux.guidebookGroups.categories">
      <div class="card mt-5">
        <div class="card-header h6">
          {{c.languages.it|uppercase}}
        </div>
        <div class="card-body position-relative">
          <div class="position-absolute top-0 end-0 p-2">
            <div class="form-floating me-auto">
              <select class="form-select" id="relatedGame" [(ngModel)]="c.relatedGame">
                <option *ngFor="let g of s.ui.components.guidebooks.games" [value]="g.id">{{g.languages.it}}</option>
              </select>
              <label for="relatedGame">Gioco Associato:</label>
            </div>
          </div>
          <div class="card-text mt-5 mb-3 h6">Sezioni</div>
          <div class="d-flex">
            <a [routerLink]="['dyn']" [queryParams]="{catid:c.id,gid:g.id}" class="btn" *ngFor="let g of c.guidebooks"
              style="margin-right: 15px; height: 40px;">{{g.languages.it|uppercase}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #loading>
  <div class="d-flex h-100 w-100 justify-content-center">
    <div class="spinner-border m-auto" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</ng-template>
<router-outlet (activate)="toggleMenu()" (deactivate)="toggleMenu()"></router-outlet>