{
  "name": "tourismapp-admin",
  "version": "0.15.10",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test"
  },
  "private": true,
  "dependencies": {
    "@angular-magic/ngx-gp-autocomplete": "^2.0.2",
    "@angular/animations": "^18.1.4",
    "@angular/cdk": "^18.1.4",
    "@angular/common": "^18.1.4",
    "@angular/compiler": "^18.1.4",
    "@angular/core": "^18.1.4",
    "@angular/forms": "^18.1.4",
    "@angular/localize": "^18.1.4",
    "@angular/material": "^18.1.4",
    "@angular/platform-browser": "^18.1.4",
    "@angular/platform-browser-dynamic": "^18.1.4",
    "@angular/router": "^18.1.4",
    "@fortawesome/angular-fontawesome": "^0.15.0",
    "@fortawesome/fontawesome-free": "^6.6.0",
    "@fortawesome/fontawesome-svg-core": "^6.6.0",
    "@fortawesome/free-brands-svg-icons": "^6.6.0",
    "@fortawesome/free-regular-svg-icons": "^6.6.0",
    "@fortawesome/free-solid-svg-icons": "^6.6.0",
    "@googlemaps/js-api-loader": "^1.16.8",
    "@luhuiguo/xng-breadcrumb": "^17.0.1",
    "@ng-bootstrap/ng-bootstrap": "^17.0.0",
    "@popperjs/core": "^2.11.8",
    "@types/google.maps": "^3.55.12",
    "ang-jsoneditor": "^3.1.1",
    "angular-ng-stepper": "^2.0.0",
    "angular-split": "^17.2.0",
    "bootstrap": "^5.3.3",
    "bootstrap-icons": "^1.11.3",
    "jsoneditor": "^9.10.5",
    "lodash": "^4.17.21",
    "luxon": "^3.5.0",
    "marked": "^14.0.0",
    "moment": "^2.30.1",
    "ng-multiselect-dropdown": "^1.0.0",
    "ngx-file-drop": "^16.0.0",
    "ngx-image-compress": "^18.1.5",
    "ngx-markdown": "^18.0.0",
    "@angular-slider/ngx-slider": "^18.0.0",
    "nxt-json-view": "^18.0.0",
    "ol": "^10.0.0",
    "prismjs": "^1.29.0",
    "rxjs": "~7.8.1",
    "supertokens-auth-react": "^0.45.1",
    "tslib": "^2.6.3",
    "uuid": "^10.0.0",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.1.4",
    "@angular/cli": "^18.1.4",
    "@angular/compiler-cli": "^18.1.4",
    "@types/jasmine": "~5.1.4",
    "@types/lodash": "^4.17.7",
    "@types/luxon": "^3.4.2",
    "@types/node": "^22.2.0",
    "@types/react-dom": "^18.3.0",
    "jasmine-core": "~5.2.0",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "ng-packagr": "^18.1.0",
    "typescript": "~5.5.4"
  },
  "overrides": {
    "@luhuiguo/xng-breadcrumb": {
      "@angular/common": "^18.0.0",
      "@angular/core": "^18.0.0",
      "@angular/router": "^18.0.0"
    },
    "ngx-markdown": {
      "marked": "^14.0.0"
    }
  }
}