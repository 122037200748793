import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { AISubject, Area, Itinerary, Place } from 'src/app/guides/model/interfaces';
import { AreasService } from 'src/app/guides/services/areas.service';
import { PlaceService } from 'src/app/guides/services/place.service';
import { UiService } from 'src/app/services/ui.service';
import { ItinerariesService } from 'src/app/guides/services/itineraries.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Duration } from 'luxon';
import { environment } from 'src/environments/environment';
import { CdkStepper } from '@angular/cdk/stepper';
import { BreadcrumbService } from '@luhuiguo/xng-breadcrumb';
import { StateService } from 'src/app/services/state.service';
import { Observable, combineLatest } from 'rxjs';
import { SharedEventService } from 'src/app/common/services/shared-event.service';

@Component({
  selector: 'app-add-itinerary',
  templateUrl: './add-itinerary.component.html',
  styleUrls: ['./add-itinerary.component.css'],
  host: { 'style': 'max-width:1000px;margin-left:auto;margin-right:auto', 'class': 'h-100 w-75 pt-5' },
})
export class AddItineraryComponent implements OnInit {

  itinerary$: Observable<Itinerary | undefined>;
  // itinerary: Itinerary = {} as Itinerary;
  itinerary: Itinerary = new Itinerary();
  aiSubject: AISubject = new AISubject();
  place: Place = new Place();
  updating: boolean = false;
  onUpdate: boolean = false;
  areaUUID: any;

  saveItineraryEvent: EventEmitter<any>;

  @ViewChild('cdkStepper') cdkStepper: CdkStepper;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private placeService: PlaceService,
    private breadcrumbService: BreadcrumbService,
    private stateService: StateService,
    private itinerariesService: ItinerariesService,
    private uiService: UiService,
    private areasService: AreasService,
    private router: Router,
    private sharedEvent: SharedEventService
  ) {
    this.saveItineraryEvent = this.sharedEvent.saveItineraryEvent;
  }

  ngOnInit(): void {
    let params$: Observable<Params> = this.route.params;
    let area$: Observable<Area> = this.stateService.selectedArea$;
    combineLatest([params$, area$]).subscribe(([params, area]) => {
      this.areaUUID = area.uuid;
      if (params.id === 'new') {
        this.place.area = area._id;
        this.breadcrumbService.set('areas/:id/itineraries/new', `Aggiungi Itinerario`);
        this.itinerary$ = new Observable<Itinerary | undefined>(observer => {
          let itinerary = new Itinerary();
          itinerary.area = area._id;
        });
      } else {
        this.onUpdate = true;
        this.itinerariesService.getItineraryById(params.id).subscribe((data) => {
          this.itinerary = data;
          this.breadcrumbService.set('areas/:id/itineraries/:id', `Itinerario: ${this.itinerary.name}`);
          console.log('get data', this.itinerary);
        });
      }
    });
    this.saveItineraryEvent.subscribe(() => {
      this.saveItinerary();
    })
  }

  allData() {
    // this.place.active = this.isActive;
    console.log('in parent compoen', this.itinerary);
    console.log('AI add iterinery compoen', this.aiSubject);

    this.saveItinerary();
  }

  saveItinerary() {
    console.log('in parent compoen', this.itinerary);
    if (this.onUpdate) {
      this.updating = true;
      this.itinerariesService.updateItinerary(this.itinerary).subscribe({
        next: (result) => {
          console.log('result', result);
          this.stateService.message$.next({ text: "Informazioni aggiornate correttamente", type: environment.MESSAGES.SUCCESS });
        },
        error: ({ error }) => {
          this.stateService.message$.next({ text: `Ops.. qualcosa è andato storto..(${error.message})`, type: environment.MESSAGES.ERROR });
        },
        complete: () => {
          this.updating = false;
          this.router.navigate([`/areas/${this.areaUUID}/itineraries`]);
        }
      })
    } else {
      this.updating = true;
      this.itinerariesService.addItinerary(this.itinerary).subscribe({
        next: (result) => {
          console.log('result', result);
          this.stateService.message$.next({ text: "Informazioni salvate correttamente", type: environment.MESSAGES.SUCCESS });
        },
        error: ({ error }) => {
          this.stateService.message$.next({ text: `Ops.. qualcosa è andato storto..(${error.message})`, type: environment.MESSAGES.ERROR });
        },
        complete: () => {
          this.updating = false;
          this.router.navigate([`/areas/${this.areaUUID}/itineraries`]);
        }
      });
    }
  }
}
