import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from 'src/app/common/components/confirm-modal/confirm-modal.component';
import { Itinerary, Place } from 'src/app/guides/model/interfaces';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-itinerary-item',
  templateUrl: './itinerary-item.component.html',
  styleUrls: ['./itinerary-item.component.css'],
})
export class ItineraryItemComponent implements OnInit {
  @Input() itinerary: Itinerary;
  @Output() onDeleteItinerary: EventEmitter<Itinerary> = new EventEmitter();
  @Output() onToggleReminder: EventEmitter<Itinerary> = new EventEmitter();

  imgpref = environment.imagesPrefix;
  imagesBaseDir = environment.IMAGES;

  constructor(
    private modalService: NgbModal
  ) { }

  itineraryPlaces = [];
  places: Place[] = [];

  ngOnInit(): void {
  }

  onDelete(itinerary) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.alertTarget = 'itinerary';

    modalRef.result.then(() => {
      this.onDeleteItinerary.emit(itinerary);

    })
  }

  onToggle(itinerary) {
    this.onToggleReminder.emit(itinerary);
  }
}
