import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, take } from 'rxjs';
import { Area } from 'src/app/guides/model/interfaces';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class AreasService {
  private apiUrl = `${environment.dbApiBaseDir}areas`;

  constructor(private http: HttpClient) { }

  getAreas(): Observable<Area[]> {
    return this.http.get<Area[]>(this.apiUrl).pipe(take(1));

  }

  getAreaById(id): Observable<Area> {
    const url = `${this.apiUrl}/${id}`;
    return this.http.get<Area>(url).pipe(take(1));
  }

  deleteArea(areaId: string): Observable<any> {
    const url = `${this.apiUrl}/${areaId}`;
    return this.http.delete<any>(url).pipe(take(1));
  }

  updateArea(area: any): Observable<any> {
    const url = `${this.apiUrl}/${area.id}`;
    return this.http.put<any>(url, area, httpOptions).pipe(take(1));
  }

  createArea(area: any): Observable<any> {
    return this.http.post<any>(this.apiUrl, area, httpOptions).pipe(take(1));
  }
}
