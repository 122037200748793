<div id="fab-button" class="btn btn-primary position-fixed bottom-0 end-0" [routerLink]="['./','new']">
  <fa-icon [icon]="['fas', 'plus']" size="2x" style="margin: 1%"></fa-icon>
</div>
<div *ngIf="loading" class="spinner-border text-primary" role="status">
  <span class="visually-hidden">Loading...</span>
</div>
<div class="container pt-4">
  <div class="d-flex gap-2">
    @if(placeRequests$ | async; as p){
    <ngb-alert *ngIf="p.length" style="cursor: pointer;" [dismissible]="false" (click)="openPlacesRequests(p)">
      Luoghi da aggiungere: {{p.length}}
    </ngb-alert>
    }
    @if(pendingShops$ | async;as s){
    <ngb-alert *ngIf="s.length" style="cursor: pointer;" [dismissible]="false" (click)="openPendingShops(s)">
      Attività da convalidare: {{s.length}}
    </ngb-alert>
    }
  </div>
  <div class="row">
    <div *ngFor="let place of places" class="col-12 col-sm-6 col-md-4 col-lg-3 pb-3" style="min-width: 25rem;">
      <app-place-item [place]="place" (onDeletePlace)="deletePlace(place)">
      </app-place-item>
    </div>
  </div>
</div>