<div>
  <div class="d-flex flex-column w-100 p-3">
    <div class="d-flex flex-column h-100 w-100 mx-auto mt-3">
      <div class="form-check p-4" *ngIf="area">
        <input class="form-check-input" type="checkbox" id="aiActive" [(ngModel)]="area.active"
          (ngModelChange)="onToggleChange()">
        <label class="form-check-label h1 fs-4" for="aiActive">
          Attiva L' Assistente Virtuale per questo luogo
        </label>

      </div>
    </div>
    <div class="form-group" style="max-height: 600px; overflow-y: auto; overflow-x: hidden;" *ngIf="area.active">
      <label class="mt-3 h1 fs-5" for="floatingInput">Crea gli intenti</label>
      <p class="">Cerca l'intento che desideri aggiungere e scrivi la risposta che deve essere fornita dall'Assistente
        Virtuale.</p>
      <div *ngIf="!aiSubject.intents.length" class="d-flex align-items-center">
        <button class="btn btn-sm btn-success me-1" (click)="addIntent('')"> <fa-icon [icon]="['fas', 'plus-circle']"
            size="1x"></fa-icon></button>
      </div>
      <div class="row mt-3" *ngFor="let intent of aiSubject.intents; let i = index; let l = last">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="col position-relative">
                <div class="form-floating w-100 mb-3">
                  <input class="form-control" aria-label="Intento" id="intent{{ i }}intent" [(ngModel)]="intent.name"
                    [ngbTypeahead]="search" name="intent{{ i }}intent" placeholder="Cerca" />
                  <label for="intent{{ i }}intent">Intento</label>
                </div>
                <div class="form-floating w-100 mb-5">
                  <textarea class="form-control" aria-label="Risposta" id="intent{{ i }}answer" rows="2"
                    style="height: 80px;" [(ngModel)]="intent.answer" placeholder="Aggiungi il testo"></textarea>
                  <label for="notes">Risposta</label>
                </div>
                <div class="col-sm-12 mt-3 position-absolute" style="bottom: -43px;">
                  <div class="d-flex align-items-center" style="float: right;">
                    <button class="btn btn-sm btn-success me-1" (click)="addIntent('')"> <fa-icon
                        [icon]="['fas', 'plus-circle']" size="1x"></fa-icon></button>
                    <button class="btn btn-sm btn-danger" (click)="remIntent(i)"><fa-icon
                        [icon]="['fas', 'minus-circle']" size="1x"></fa-icon></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="border-top p-2" *ngIf="area.active">
      <label class="mt-5 mb-1 h1 fs-5" for="floatingInput">Collega altri Soggetti</label>
      <p class="">Se desideri che questo soggetto sia considerato Padre e che quindi l'assistente virtuale possa
        parlare di soggetti relativi a questo, selezionali nel form che segue:</p>
      <div class="col-md-12 mt-3" *ngIf="area.active">
        <label class="fs-6 h3">Soggetti collegati:</label>
        <ng-multiselect-dropdown [placeholder]="'Seleziona'" [settings]="settings" [data]="subSubjects"
          [(ngModel)]="aiSubject.subSubjects" (ngModelChange)="onModelChange('subSubjects')">
        </ng-multiselect-dropdown>
      </div>
    </div>
  </div>
</div>