import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StoreImgsService {
  /*private storeImgApiUrl =
    'https://tourismapp.mynoomi.com/api/saveImgToPath.php';*/
  private storeImgsApiUrl = environment.IMAGES;

  constructor(private http: HttpClient) {}

  saveImgsToPath(imgs: {
    files: string[];
    path: string;
  }): Observable<string[]> {
    if (imgs.files.length) {
      return this.http.post<string[]>(`${this.storeImgsApiUrl}save`, imgs, {
        headers: new HttpHeaders({
          'Content-type': 'application/x-www-form-urlencoded',
          Accept: 'application/json',
        }),
      });
    } else {
      return new Observable<string[]>((observer) => {
        observer.next([]);
      });
    }
  }
}


/*   sudo chcon -t httpd_sys_rw_content_t |FOLDER| -R   */ 