import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from 'src/app/common/components/confirm-modal/confirm-modal.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-experience-item',
  templateUrl: './experience-item.component.html',
  styleUrl: './experience-item.component.css'
})
export class ExperienceItemComponent {
  @Input() experience: any;
  @Output() onDeleteActivity = new EventEmitter<any>();
  imagesBaseDir = environment.imagesPrefix;

  constructor(private modalService: NgbModal) {
  }

  onDelete(experience) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.alertTarget = 'activity';

    modalRef.result.then(() => {
      this.onDeleteActivity.emit(experience);
    });
  }
}
