<div class="h-100 d-flex flex-column align-content-between">
    <div class="h-100 w-100 mx-auto" *ngIf="place">
        <div class="d-grid gap-2">
            <button class="btn btn-light" type="button" (click)="loadCoverPhoto()"><i class="bi bi-image"></i><span
                    class="ms-2">Seleziona la foto di copertina</span></button>
        </div>
        <div *ngIf="place.coverImage" class="h-100" [class]="place.coverImage ?'':'d-none'">
            <img [src]="imagesBaseDir+place.coverImage.url" class="" style="width: 100%;height: 100%;object-fit: cover;"
                onerror="this.onerror=null;this.src='assets/placeholder.jpg';" (error)="setCoverImgLoadError($event)">
        </div>
        <!-- <div class="h-100 w-100 bg-light overflow-hidden" [class]="coverImage.length ?'':'d-none'">
          <img [src]="imagesBaseDir+coverImage[0]" class="img-fluid p-2 border border-3 w-100 h-100" style="object-fit:cover;">
      </div> -->
    </div>
    <div class="h-100 w-100 mx-auto" *ngIf="place">
        <div class="d-grid gap-2">
            <button class="btn btn-light" type="button" (click)="loadImages()"><i class="bi bi-images"></i><span
                    class="ms-2">Seleziona altre foto</span></button>
        </div>
        <ngb-carousel *ngIf="place.images" class="h-100 w-100 bg-light overflow-hidden"
            [class]="place.images.length ?'':'d-none'">
            <ng-template *ngFor="let p of place.images" ngbSlide>
                <div class="picsum-img-wrapper overflow-hidden" style="background-color: black;">
                    <img [src]="imagesBaseDir+p.url" class="" style="width: 100%;height: 100%;object-fit: cover;"
                        onerror="this.onerror=null;this.src='assets/placeholder.jpg';">
                </div>
            </ng-template>
        </ngb-carousel>
        <!-- <ngb-carousel class="h-100 w-100 bg-light overflow-hidden" [class]="images.length ?'':'d-none'">
          <ng-template *ngFor="let p of images" ngbSlide>
              <div class="picsum-img-wrapper overflow-hidden">
                  <img [src]="imagesBaseDir+p" class="img-fluid p-2 border border-3 w-100 h-100" style="object-fit:cover;">
              </div>
          </ng-template>
      </ngb-carousel> -->
    </div>
</div>