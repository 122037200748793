<div class="h-100">
  <div id="fab-button" class="btn btn-primary position-fixed bottom-0 end-0" [routerLink]="['./add/','new']">
    <fa-icon [icon]="['fas', 'plus']" size="2x" style="margin: 1%"></fa-icon>
  </div>
  <div *ngIf="loading" class="h-100 d-flex">
    <div class="spinner-border text-primary m-auto" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <div class="p-4 position-relative h-100" *ngIf="areas$|async as areas">
    <div id="ol-map-{{mapId}}" style="z-index:0;border-width: 0px !important; opacity: 0.2;"
      class="map-container position-absolute end-0 top-0 border w-100 h-100"></div>
    <div class="row">
      <div *ngFor="let area of areas" class="col-12 col-sm-6 col-md-4 col-lg-3 pb-3" style="min-width: 25rem;">
        <app-area-item [area]="area" (onDeleteArea)="deleteArea(area)">
        </app-area-item>
      </div>
    </div>
  </div>
</div>