import { Component, ElementRef, OnInit } from '@angular/core';
import { BreadcrumbService } from '@luhuiguo/xng-breadcrumb';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TaConfirmModalComponent } from 'projects/ngx-ta-ui-library/src/public-api';

@Component({
  selector: 'app-ui-library',
  templateUrl: './ui-library.component.html',
  styleUrl: './ui-library.component.css',
})
export class UiLibraryComponent implements OnInit {
  constructor(private modalService: NgbModal,
    private breadcrumbService: BreadcrumbService) { }

  ngOnInit() {
    this.breadcrumbService.set('library', 'Libraria UI');
  }

  input = 'Hello World';
  inputCode = `<ta-input [(value)]="input" label="input" placeholder="input"></ta-input>`;
  inputVar = `input = 'Hello World';`;
  labeledBadgeCode = `<ta-labeled-badge label="label" content="content" color="pink"></ta-labeled-badge>
<ta-labeled-badge label="label" content="content" color="indigo"></ta-labeled-badge>`;
  period = { start: '2024-01-01T00:00:00.000Z', end: '2024-01-01T00:00:00.000Z' };
  periodCode = `<ta-period-picker [(period)]="period"></ta-period-picker>`;
  periodVar = `period = { start: '2024-01-01T23:00:00.000Z', end: '2024-01-03T23:00:00.000Z' };`;
  advPeriod = { start: '2024-01-01T23:00:00.000Z', end: '2024-01-03T23:00:00.000Z', off: ['2024-01-02T23:00:00.000Z'] };
  advPeriodCode = `<ta-advanced-period-picker [(period)]="period"></ta-advanced-period-picker>`;
  advPeriodVar = `advPeriod = { start: '2024-01-01T23:00:00.000Z', end: '2024-01-03T23:00:00.000Z',off:['2024-01-02T23:00:00.000Z'] };`;
  schedule = { frequency: 'monthly', selected: ['1', '3'] }
  scheduleCode = `<ta-schedule [(schedule)]="schedule"></ta-schedule>`
  scheduleVar = `schedule = { frequency: 'monthly', selected: ['1', '3'] }`
  costsCode = `<ta-costs [(costs)]="costs"></ta-costs>`;
  costsVar = `costs = { "costo1": "valore1", "costo2": "valore2" };`;
  costs = { "costo1": "valore1", "costo2": "valore2" };
  timeframeCode = `<ta-timeframe-picker [(timeframe)]="timeframe"></ta-timeframe-picker>`;
  timeframeVar = `timeframe = { "start": { hour: 12, minute: 30 }, "end": { hour: 2, minute: 30 } };`;
  timeframe = { "start": { hour: 12, minute: 30 }, "end": { hour: 2, minute: 30 } };
  labeledTimeframeCode = `<ta-labeled-timeframe-picker [(labeledTimeframe)]="labeledTimeframe"></ta-labeled-timeframe-picker>`;
  labeledTimeframeVar = `labeledTimeframe = { "etichetta1": { "start": { hour: 12, minute: 30 }, "end": { hour: 2, minute: 30 } }, "etichetta2": { "start": { hour: 12, minute: 30 }, "end": { hour: 2, minute: 30 } } };`;
  labeledTimeframe = { "etichetta1": { "start": { hour: 9, minute: 30 }, "end": { hour: 14, minute: 30 } }, "etichetta2": { "start": { hour: 6, minute: 30 }, "end": { hour: 19, minute: 30 } } };
  checkbox = true;
  checkboxCode = `<ta-checkbox [(value)]="checkbox" 
label="checkbox" checkedColor="green" uncheckedColor="red"
checkedIcon="bi-flag" uncheckedIcon="bi-trash">
</ta-checkbox>`;
  checkboxVar = `checkbox = true;`;
  tags = ['pizza', 'bici'];
  tagsCode = `<ta-tags [(tags)]="tags"></ta-tags>`;
  tagsVar = `tags = ['pizza', 'bici'];`;
  contacts = { emails: [], phones: [{ label: "work", number: "0000000000" }], websites: [], socials: [] };
  contactsCode = `<ta-contact-infos [(value)]="contacts"></ta-contact-infos>`;
  contactsVar = `contacts = {
  emails: [],
  phones: [{ 
    label: "label",
    number: "0000000000" 
  }],
  websites: [],
  socials: []
};`;
  confirmModalCode = `<div class="d-flex flex-row">
<div class="btn btn-primary" (click)="openConfirmModal()">Apri Conferma</div>
</div>`;
  confirmModalVar = `openConfirmModal() {
  const modalRef = this.modalService.open(TaConfirmModalComponent);
  modalRef.componentInstance.alertTarget = 'file';
}`;
  singleImage = { url: 'a6437f8b-e848-4e74-a9eb-86b05748a2e2/torinoHome.jpeg' };
  singleImageCode = `<ta-single-image [(image)]="singleImage"></ta-single-image>`;
  singleImageVar = `singleImage = { url: '', name: '' };`;
  multiImage = [{ url: 'a6437f8b-e848-4e74-a9eb-86b05748a2e2/torinoHome.jpeg' }, { url: 'b3199f10-4933-4d59-95b3-eb5bf05eb2da/65bbc75aa1caf.jpeg' }];
  multiImageCode = `<ta-multi-image [(images)]="multiImage"></ta-multi-image>`;
  multiImageVar = `multiImage = [];`;
  openConfirmModal() {
    const modalRef = this.modalService.open(TaConfirmModalComponent);
    modalRef.componentInstance.alertTarget = 'file';
  }
  personCard = { name: 'Nome', surname: 'Cognome', coverImage: { url: '0e332677-e080-4152-b76f-6f7cd33a2adf/65dc8908788f9.jpeg' }, tags: ['tag1', 'tag2'] }
  personCardCode = `<ta-card entity="person" [(value)]="card"></ta-card>`;
  personCardVar = `card = { name: 'Nome', tags: ['tag1', 'tag2'] }`;
  cityCard = { name: 'Torino', image: { url: 'a6437f8b-e848-4e74-a9eb-86b05748a2e2/torinoHome.jpeg' }, tags: ['tag1', 'tag2'] }
  cityCardCode = `<ta-card entity="city" [(value)]="card"></ta-card>`;
  cityCardVar = `card = { name: 'Torino', tags: ['tag1', 'tag2'] }`;
  placeCard = { name: 'Collegiata di S. Maria della Scala', coverImage: { url: 'b3199f10-4933-4d59-95b3-eb5bf05eb2da/65bbc733c248f.png' }, images: [{ url: 'b3199f10-4933-4d59-95b3-eb5bf05eb2da/65bbc75aa1caf.jpeg' }], tags: ['tag1', 'tag2'], address: { street: 'Piazza Vittorio Emanuele, 10024 Moncalieri TO, Italia' } }
  placeCardCode = `<ta-card entity="place" [(value)]="card"></ta-card>`;
  placeCardVar = `card = { name: 'Nome', tags: ['tag1', 'tag2'] }`;
  files: []
  filesCode: ''
  filesVar: ''

  copyCode(c: ElementRef) {
    debugger
  }

  collapse(c: ElementRef) {
    debugger
  }

  getMarkdown(input: { code, lang }[]) {
    let breakline = '---\n';
    let content = input.map(i => `###### ${i.lang}\n\`\`\`${i.lang}\n${i.code}\n\`\`\``).join('\n');
    return `${breakline}${content}`;
  }
}



