<div class="modal-header">
    <h4 class="modal-title">Luoghi da Aggiungere</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modalService.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
    <div class="d-grid overflow-auto">
        @for(p of placeRequests;track p){
        <div class="card mb-3" style="width: 540px;">
            <div class="row g-0 h-100">
                <div class="col-md-4">
                    @if(p.place.coverImage&&p.place.coverImage.url!=""){
                    <img [src]="p.place.coverImage.url" class="w-100 h-100 rounded-start" style="object-fit: cover;"
                        alt="...">
                    }
                    @else{
                    <img src="assets/placeholder.jpg" class="w-100 h-100 rounded-start" style="object-fit: cover;"
                        alt="...">
                    }
                </div>
                <div class="col-md-8">
                    <div class="card-body">
                        <h5 class="card-title">{{p.place.name}}</h5>
                        <p class="card-text">{{p.place.address.street}}</p>
                        <div class="d-flex gap-1">
                            @for(t of p.place.tags;track t){
                            <span class="badge text-bg-success">{{t}}</span>
                            }
                        </div>
                        <p class="card-text"><small class="text-body-secondary">Inserita il {{p.createdAt| date :
                                'dd/MM/yyyy'}}</small></p>
                        <div class="w-100 mt-1 d-flex gap-1">
                            <button class="btn btn-success w-100" (click)="acceptRequest(p)">Aggiungi</button>
                            <button class="btn btn-danger w-100" (click)="deleteRequest(p)">Elimina</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        }
    </div>
    <div class="mt-1" style="height: 58px;">
        <ngb-alert class="p-2 p-md-3" #selfClosingAlert [dismissible]="false" [animation]="true" *ngIf="message"
            [type]="message.type" (closed)="message=undefined "><span class="text-truncate">{{ message.text }}</span>
        </ngb-alert>
    </div>
</div>