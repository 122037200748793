import { EventEmitter, Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { Area, Place } from 'src/app/guides/model/interfaces';


@Injectable({
  providedIn: 'root'
})
export class SharedEventService {

  areaSelected: ReplaySubject<Area> = new ReplaySubject<any>(1);
  savePlaceEvent: EventEmitter<any> = new EventEmitter;
  saveItineraryEvent: EventEmitter<any> = new EventEmitter;
  configLoadedEvent: ReplaySubject<any> = new ReplaySubject<any>(1);
  constructor() { }
}

