<!--div role="button" class="btn btn-primary sticky-top m-auto m-3" (click)="null"
    style="left: 100%;width: 40px;border-radius: 35px;">+</div-->
<div id="fab-button" class="btn btn-primary position-fixed bottom-0 end-0" [routerLink]="'event/0'">
    <fa-icon [icon]="['fas', 'plus']" size="2x" style="margin: 1%"></fa-icon>
</div>
<div class="container pt-4" style="height: 90vh ;">
    <div class="row">
        <div *ngFor="let g of guidebooks;let i = index" class="col-12 col-sm-6 col-md-4 col-lg-3 pb-3"
            style="min-width: 25rem;">
            <app-event-item [event]="g" (onDeleteEvent)="deleteEvent(i,g._id)"></app-event-item>
        </div>
    </div>
</div>