import { Component, EventEmitter, Input, OnInit, Output, ViewChild, } from '@angular/core';
import { combineLatest } from 'rxjs';
import { Area, Place, Latlng } from 'src/app/guides/model/interfaces';
import { PlaceService } from 'src/app/guides/services/place.service';
import { Location } from '@angular/common';
import { ActivatedRoute, Params } from '@angular/router';
import * as _ from 'lodash';
import { BreadcrumbService } from '@luhuiguo/xng-breadcrumb';
import { StateService } from 'src/app/services/state.service';
import { Observable, tap } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MultiImgModalComponent } from 'src/app/common/components/multi-img-modal/multi-img-modal.component';
import { environment } from 'src/environments/environment';
import { CdkStepper } from '@angular/cdk/stepper';
import { _DEBUG } from 'src/app/app.component';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.css'],
  host: { 'style': 'max-width:1000px;margin-left:auto;margin-right:auto', 'class': 'w-75 pt-5' }
})
export class OptionsComponent implements OnInit {


  // @Input() place!: Place | any;
  @Input() place: Place = new Place();
  @Output() placeChange: EventEmitter<Place> = new EventEmitter<Place>();

  // options: any = {
  //   arrivalPoint: false,
  //   suitableForSpareTime: false,
  //   canSleepOver: false,
  //   visitDuration: 0,
  //   costs: [],
  // }

  placeTypeList = [
    "type 1",
    "type 2",
    "type 3",
  ]

  placeClassList = [
    "class 1",
    "class 2",
    "class 3",
  ]

  place$: Observable<Place | undefined>;
  // place: Place | any = {};

  placeSelected: boolean = false;

  updating: boolean = false;


  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private placeService: PlaceService,
    private breadcrumbService: BreadcrumbService,
    private stateService: StateService,
    private modalService: NgbModal
  ) {
  }

  ngOnInit(): void {
  }

  addCostCat(k: string) {
    // this.options.costs.push({ key: k, value: '' });
    // console.log('placeOption', this.options);
    // this.place.options = this.options;
    // this.placeChange.emit(this.place);
    this.place.details.cost.push({ key: k, value: '' });
    console.log('placeOption', this.place.details);
    this.place.options = this.place.options;
    this.placeChange.emit(this.place);
  }

  remCostCat(i: number) {
    // this.options.costs.splice(i, 1);
    // this.place.options = this.options;
    // this.placeChange.emit(this.place);
    this.place.details.cost.splice(i, 1);
    this.place.options = this.place.options;
    this.placeChange.emit(this.place);
  }
}