import { Pipe, PipeTransform } from "@angular/core";
import { NgbDate, NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { Injectable } from '@angular/core';
import moment from 'moment';
import { NgxTaUiLibraryConfigService } from "./ngx-ta-ui-library.service";
@Pipe({
    name: 'phone'
})
export class TaPhonePipe implements PipeTransform {
    transform(rawNum: any): any {
        let newStr = "";
        for (let i = 0; i < rawNum.length; i++) {
            if (i === 3 || i === 5 || i === 7) {
                newStr += " ";
            }
            newStr += rawNum.charAt(i);
        }
        return newStr;
    }
}

@Injectable()
export class TaDateFormatter extends NgbDateParserFormatter {
    readonly DELIMITER = '/';

    parse(value: string): NgbDateStruct | null {
        if (value) {
            const date = value.split(this.DELIMITER);
            return {
                day: parseInt(date[0], 10),
                month: parseInt(date[1], 10),
                year: parseInt(date[2], 10),
            };
        }
        return null;
    }

    format(date: NgbDateStruct | null): string {
        //add 0 to day and month if they are less than 10
        return date ? (date.day < 10 ? '0' + date.day : date.day) + this.DELIMITER + (date.month < 10 ? '0' + date.month : date.month) + this.DELIMITER + date.year : '';
        //return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : '';
    }
}

/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable()
export class TaDateAdapter extends NgbDateAdapter<string> {

    readonly DELIMITER = '-';

    fromModel(value: string | null): NgbDateStruct | null {
        if (value !== 'Invalid date' && value !== null) {
            const date = moment(value).format();

            if (date) {
                return {
                    year: moment(date).year(),
                    month: moment(date).month() + 1,
                    day: moment(date).date()
                };
            }
        }
    }

    toModel(date: NgbDateStruct | null): string | null {
        //add 0 to day and month if they are less than 10
        const d = date ? date.year + this.DELIMITER + (date.month < 10 ? '0' + date.month : date.month) + this.DELIMITER + (date.day < 10 ? '0' + date.day : date.day) : null;
        return moment(d).format('yyyy-MM-DDTHH:mm:ss.SSSZ');
    }
}

