import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contest-wrapper',
  templateUrl: './contest-wrapper.component.html',
  styleUrls: ['./contest-wrapper.component.css']
})
export class ContestWrapperComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
