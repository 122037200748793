import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Area } from 'src/app/guides/model/interfaces';
import { AreasService } from '../../services/areas.service';
import { StateService } from 'src/app/services/state.service';
import { BreadcrumbService } from '@luhuiguo/xng-breadcrumb';
import { Observable, tap } from 'rxjs';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import { fromLonLat } from 'ol/proj';
import OSM from 'ol/source/OSM';
import { DeleteStoredImgsService } from 'src/app/common/services/delete-stored-imgs.service';
@Component({
  selector: 'app-areas',
  templateUrl: './areas.component.html',
  styleUrls: ['./areas.component.css'],
  host: { 'class': 'h-100 position-relative' }
})
export class AreasComponent implements OnInit, AfterViewInit {

  constructor(private router: Router,
    private breadcrumbService: BreadcrumbService,
    private areasService: AreasService,
    private stateService: StateService,
    private deleteStoredImgsService: DeleteStoredImgsService,
  ) {
    this.breadcrumbService.set('areas', `Aree`);
  }

  areas$: Observable<Area[]> = this.areasService.getAreas().pipe(
    tap((data) => {
      this.loading = false;
    })
  );
  loading: boolean = true;
  map!: Map;
  mapId = 'italy';


  ngOnInit(): void {
    /*this.areasService.getAreas().subscribe(
      (data:any) => {
        this.areas = data;
        console.log(this.areas)
      }
    )*/
  }

  ngAfterViewInit() {
    setTimeout(() => {
      console.log('set time out');
      this.initializeMap();
    }, 3000);
  }

  initializeMap() {
    this.map = new Map({
      view: new View({
        center: fromLonLat([12.496366, 41.902782]),
        zoom: 7,
      }),
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      target: `ol-map-${this.mapId}`,
      controls: [],
    });
  }

  createNew() {
    this.router.navigate(['/addAreas']);
  }

  deleteArea(area: Area) {
    if (area.image) {
      this.deleteStoredImgsService.deleteFolder(area.uuid).subscribe();
    }
    this.areasService.deleteArea(area._id).subscribe({
      next: (data) => {
        this.areas$ = this.areasService.getAreas();
        this.stateService.message$.next({ type: 'success', text: 'Area Eliminata' });
      },
      error: (error) => {
        this.stateService.message$.next({ type: 'error', text: 'Errore durante l\'eliminazione' });
      }
    })
  }
}
