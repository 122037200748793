import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Place, PlaceClassType } from '../model/interfaces';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class PlaceService {
  private placeApiUrl = `${environment.dbApiBaseDir}places`;
  private crudPlaceApiUrl = `${environment.dbApiBaseDir}places`;
  private settingsUrl = `${environment.dbApiBaseDir}settings/default`;
  private shopApiUrl = `${environment.dbApiBaseDir}shops`;

  constructor(
    private http: HttpClient,

  ) { }

  getPlaceByCat(placeCat: string): Observable<Place[]> {
    const url = `${this.placeApiUrl}&filter=placeType,cs,${placeCat}`;
    return this.http.get<Place[]>(url).pipe(
      take(1)
    );
  }

  getPlaceByUuid(uuid: string): Observable<Place> {
    const url = `${this.placeApiUrl}/uuid/${uuid}`;
    return this.http.get<any>(url).pipe(
      take(1),
    );
  }

  getPlaceById(id: number): Observable<Place> {
    const url = `${this.placeApiUrl}/${id}`;
    return this.http.get<Place>(url).pipe(
      take(1)
    );
  }

  getAreaPlaces(area: string): Observable<Place[]> {
    const url = `${this.placeApiUrl}/area/${area}`;
    return this.http.get<Place[]>(url).pipe(
      take(1)
    );
  }

  getAllPlaces(): Observable<Place[]> {
    return this.http.get<any>(this.placeApiUrl).pipe(
      take(1)
    );
  }

  getMinPlaces(area?: string, productInfos?: 'producers' | 'resellers'): Observable<Place[]> {
    let suffix = area ? `${area}/minimal` : `minimal`;
    if (productInfos == 'producers') {
      suffix = `${suffix}?product_info=producers`
    } else if (productInfos == 'resellers') {
      suffix = `${suffix}?product_info=resellers`
    }
    const url = `${this.placeApiUrl}/${suffix}`;
    return this.http.get<Place[]>(url).pipe(
      take(1)
    );
  }

  getSelectedPlaces(places: number[]): Observable<Place[]> {
    const placesSerialized = places.join(',');
    const url = `${this.placeApiUrl}&filter=id,in,${placesSerialized}`;
    return this.http.get<Place[]>(url);
  }

  getSelectedAreaPlaces(area: string): Observable<Place[]> {
    const url = `${this.placeApiUrl}&filter=area,eq,${area}`;
    return this.http.get<Place[]>(url);
  }

  deletePlace(id: string): Observable<string[]> {
    return this.http.delete<any>(`${this.crudPlaceApiUrl}/${id}`, httpOptions).pipe(take(1));
  }

  savePlace(place: Place): Observable<any> {
    return this.http.post<any>(`${this.crudPlaceApiUrl}`, place, httpOptions).pipe(take(1));
    // return EMPTY
  }

  updatePlace(place: Place, id: string): Observable<any> {
    return this.http.patch<any>(`${this.crudPlaceApiUrl}/${id}`, place, httpOptions).pipe(take(1));
  }

  getSettings(): Observable<PlaceClassType[]> {
    return this.http.get<any>(this.settingsUrl).pipe(
      take(1)
    );
  }

  getPlaceRequests$(areaId: string): Observable<any[]> {
    return this.http.get<any>(`${this.shopApiUrl}/pendingplaces?area=${areaId}`).pipe(
      take(1),
    );
  }

  deletePlaceRequest$(request: any): Observable<any> {
    return this.http.delete<any>(`${this.shopApiUrl}/pendingplaces?req=${request._id}&place=${request.place._id}`).pipe(
      take(1),
    );
  }

  getPendingShops$(areaId: string): Observable<any[]> {
    return this.http.get<any>(`${this.shopApiUrl}/unconfirmedshops?area=${areaId}`).pipe(
      take(1),
    );
  }

}
