<div class="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6 mx-auto p-4 h-100">
  <ng-stepper class="h-100 pt-2" #cdkStepper [linear]="false">
    <cdk-step [optional]="true">
      <ng-template cdkStepLabel>
        <div class="step-bullet"><i class="bi bi-map"></i></div>
        <div class="step-title d-md-block">Area</div>
      </ng-template>
      <div class="inner-step">
        <div class="step-form">
          <app-area-data class="" [(area)]="area" #stepOne></app-area-data>
        </div>
        <div class="bottom-button">
          <button type="button" class="ms-auto button btn btn-light" (click)="cdkStepper.next()">
            <i class="bi bi-arrow-right fs-1"></i>
          </button>
        </div>
      </div>
    </cdk-step>

    <cdk-step [optional]="true">
      <ng-template cdkStepLabel>
        <div class="step-bullet"><i class="bi bi-images"></i></div>
        <div class="step-title d-md-block">Immagini</div>
      </ng-template>
      <div class="inner-step">
        <div class="step-form">
          <app-area-image-upload class="" [(area)]="area" #stepTwo></app-area-image-upload>
        </div>
        <div class="bottom-button">
          <button class="button btn btn-light" (click)="cdkStepper.previous()"><i
              class="bi bi-arrow-left fs-1"></i></button>
          <button class="ms-auto button btn btn-light" (click)="cdkStepper.next()"><i
              class="bi bi-arrow-right fs-1"></i></button>
        </div>
      </div>
    </cdk-step>

    <cdk-step [optional]="true">
      <ng-template cdkStepLabel>
        <div class="step-bullet"><i class="bi bi-image"></i></div>
        <div class="step-title d-md-block">Assistente</div>
      </ng-template>
      <div class="inner-step">
        <div class="step-form">
          <app-area-ai-active class="" [(area)]="area" [(aiSubject)]="aiSubject" #stepFive></app-area-ai-active>
        </div>
        <div class="bottom-button">
          <button class="button btn btn-light" (click)="cdkStepper.previous()"><i
              class="bi bi-arrow-left fs-1"></i></button>
          <button class="ms-auto button btn btn-light" (click)="cdkStepper.next()"><i
              class="bi bi-arrow-right fs-1"></i></button>
        </div>
      </div>
    </cdk-step>

    <cdk-step [optional]="true">
      <ng-template cdkStepLabel>
        <div class="step-bullet"><i class="bi bi-image"></i></div>
        <div class="step-title d-md-block">Riepilogo</div>
      </ng-template>
      <div class="inner-step" #stepSix>
        <div class="step-form">
          <app-area-summary></app-area-summary>
        </div>
        <div class="bottom-button">
          <button class="button btn btn-light" (click)="cdkStepper.previous()"><i
              class="bi bi-arrow-left fs-1"></i></button>
          <button class="ms-auto button btn btn-light" (click)="saveData()"><i
              class="bi bi-cloud-upload fs-1"></i></button>
        </div>
      </div>
    </cdk-step>
  </ng-stepper>
</div>




<!--https://via.placeholder.com/512x346?text=Visit+Blogging.com+Now -->