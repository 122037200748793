import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable, combineLatest, switchMap } from 'rxjs';
import { DeleteStoredImgsService } from 'src/app/common/services/delete-stored-imgs.service';
import { Area, Itinerary } from 'src/app/guides/model/interfaces';
import { AreasService } from 'src/app/guides/services/areas.service';
import { ItinerariesService } from 'src/app/guides/services/itineraries.service';
import { PlaceService } from 'src/app/guides/services/place.service';
import { StateService } from 'src/app/services/state.service';
import { BreadcrumbService } from '@luhuiguo/xng-breadcrumb';

@Component({
  selector: 'app-itineraries',
  templateUrl: './itineraries.component.html',
  styleUrls: ['./itineraries.component.css'],
})
export class ItinerariesComponent implements OnInit {

  area: string = '';
  availAreas = [];
  loading: boolean = true;
  itineraries: Itinerary[] = [];

  toastHeader: string;
  toastBody: string;

  constructor(
    private router: Router,
    private areasService: AreasService,
    private itinerariesService: ItinerariesService,
    private breadcrumbService: BreadcrumbService,
    private stateService: StateService,
    private route: ActivatedRoute,
    private deleteStoredImgsService: DeleteStoredImgsService,
  ) { }

  ngOnInit(): void {
    this.breadcrumbService.set('areas/:id/itineraries', `Itinerari`);
    this.loading = true;

    // this.areasService.getAreas().subscribe((data) => {
    //   this.availAreas = data['records'];
    // });

    // this.itinerariesService.getItineraries().subscribe((data) => {
    //   this.itineraries = data['records'];
    // });

    let params$: Observable<Params> = this.route.params;
    let area$: Observable<Area> = this.stateService.selectedArea$;
    combineLatest([params$, area$]).subscribe(([params, area]) => {
      console.log('params area???', area._id);
      this.itinerariesService.getItineraries().subscribe((data) => {
        this.itineraries = data;
        console.log('iterinery', this.itineraries);
      });
    });

    this.loading = false;
    //this.placeService.getPlace().subscribe((data) => {this.places = data['records']});
  }

  // old iterinery delete flow
  // deleteItinerary(itinerary: Itinerary) {
  //   this.itinerariesService.deleteItinerary(itinerary).subscribe((data) => {
  //     this.itineraries = this.itineraries.filter((t) => t._id !== itinerary._id);
  //     this.toastBody = 'Itinerary Deleted';
  //   });
  // }

  deleteItinerary(itinerary: Itinerary) {

    this.deleteStoredImgsService.deleteFolder(itinerary.uuid)
      .pipe(
        switchMap(() => {
          return this.itinerariesService.deleteItinerary(itinerary);
        })
      )
      .subscribe(
        (data) => {
          console.log('Place deleted:', data);
          this.itineraries = this.itineraries.filter((t) => t._id !== itinerary._id);
          this.toastBody = 'Itinerary Deleted';
        },
        (error) => {
          console.error('Error deleting place:', error);
        }
      );
  }

  onSelectArea(area: string) {
    if (area || area != '') {
      //this.placeService.getSelectedAreaPlaces(area).subscribe((data) => {this.places = data['records']});
    } else {
      //this.placeService.getAllPlaces().subscribe((data) => {this.places = data['records']});
    }
  }

  createNew() {
    this.router.navigate(['/addItinerary']);
  }
}
