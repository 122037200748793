import { Component, EventEmitter, Input, OnInit, Output, ViewChild, } from '@angular/core';
import { Itinerary } from 'src/app/guides/model/interfaces';
import * as _ from 'lodash';
import { _DEBUG } from 'src/app/app.component';


@Component({
  selector: 'app-itineraries-summary',
  templateUrl: './itineraries-summary.component.html',
  styleUrls: ['./itineraries-summary.component.css'],
  host: { 'style': 'max-width:1000px;margin-left:auto;margin-right:auto', 'class': 'w-75 pt-5' }
})
export class ItinerariesSummaryComponent implements OnInit {

  @Input() itinerary: Itinerary = new Itinerary();
  @Output() itineraryChange: EventEmitter<Itinerary> = new EventEmitter<Itinerary>();

  placeSummary = [
    { 
      title: 'Description',
      description: 'Contains basic details of Itinerary such as name, desription, notes and also sets AI to active.'
    },
    {
      title: 'Places',
      description: 'Can select places from places list for Itinerary item.'  
    },
    {
      title: 'Images',
      description: 'Upload cover image and more images of the selected Itinerary.'
    },
  ]

  constructor() {
  }

  ngOnInit(): void {

    // console.log('place get data', this.place);

  };

}