<div class="d-flex flex-column w-100 p-3" *ngIf="place.details">

  <div class="form-floating w-100 mb-3">
    <textarea class="form-control" aria-label="Descrizione" rows="2" id="description"
      [(ngModel)]="place.details.description" (change)="onDescriptionChange($event)" style="height: 100px"
      placeholder="Aggiungi la descrizione"></textarea>
    <label for="description">Descrizione</label>
  </div>


  <div class="form-floating w-100 mb-3">
    <textarea class="form-control" aria-label="Note" rows="2" id="notes" [(ngModel)]="place.details.notes"
      (change)="onNotesChange($event)" style="height: 100px" placeholder="Aggiungi le note"></textarea>
    <label for="notes">Note</label>
  </div>

  <!--label class="mt-3">Rating</label>
  <div class="form-floating w-100">
    <textarea class="form-control" aria-label="Notes" rows="2" [(ngModel)]="place.details.rating"
      (change)="onNotesChange($event)" placeholder="add a note"></textarea>
  </div-->

  <!--label class="mt-3">Rank</label>
  <div class="form-floating w-100">
    <textarea class="form-control" aria-label="Notes" rows="2" [(ngModel)]="place.details.ranking"
      (change)="onNotesChange($event)" placeholder="add a note"></textarea>
  </div-->

  <div class="mb-2"></div>
  <!-- <div *ngFor="let i of placeTypeList">
      <div>{{i.name}}</div>
    </div> -->
  <label class="">Tipologia</label>
  <div *ngIf="placeTypeList.length > 0">
    <ng-multiselect-dropdown [placeholder]="'Seleziona la tipologia'" [settings]="settings" [data]="placeTypeList"
      [(ngModel)]="place.details.type" (onSelect)="onItemSelect($event)">
    </ng-multiselect-dropdown>
  </div>

  <!-- <ng-multiselect-dropdown [placeholder]="'Seleziona la tipologia'" [settings]="settings" [data]="placeTypeList"
    [(ngModel)]="place.details.type" (onSelect)="onItemSelect($event)">
  </ng-multiselect-dropdown> -->

  <label class="mt-3">Categoria</label>
  <div *ngIf="placeClassList.length > 0">
    <ng-multiselect-dropdown [placeholder]="'Seleziona la categoria'" [settings]="settings" [data]="placeClassList"
      [(ngModel)]="place.details.class" (onSelect)="onItemSelect($event)">
    </ng-multiselect-dropdown>
  </div>

  <label class="mt-3">Tags</label>
  <div class="input-group pt-1" *ngIf="place.tags">
    <!--tag-input class="form-control input-lg" [(ngModel)]='place.tags' [editable]="true" [displayBy]="'name'">
    </tag-input-->
    <ta-tags [(tags)]="place.tags"></ta-tags>
  </div>
</div>