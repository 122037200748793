<!--div class="card" (click)="selectArea(area.uuid)">
  <div class="card-body d-flex align-items-start flex-column p-0">
    <div class="w-100 p-1">
      <img src="{{imgpref + area.imgName}}"
      class="card-img-top img-fluid overflow-hidden"
      style="height: 10rem; overflow: hidden; object-fit: cover" 
    />
    </div>
    <div class="mb-auto">
    <div class="card-title fs-5">
      {{ area.area_name }}
    </div>
    <div class="card-subtitle fs-6">
      {{ area.desc }}
    </div>
  </div>
  <app-modify-delete [target]="area.uuid" (onDelete)="onDelete(area);" ></app-modify-delete>
  </div>
</div-->

<ng-template #rt let-r="result" let-t="term">
  {{ r.name}}
</ng-template>
<div class="form-floating w-100 mb-3">
  <input class="form-control" aria-label="Cerca" id="searchPlace"  name="searchPlace"
  [(ngModel)]="model" 
  #instance="ngbTypeahead"
  [ngbTypeahead]="search" 
  [resultTemplate]="rt"
  [inputFormatter]="formatter"
  (focus)="focus$.next($event.target.value)"
  (click)="click$.next($event.target.value)"
  (selectItem)="onSelectPlace($event)"
  placeholder="Cerca" />
  <label for="searchPlace">Cerca</label>
</div>

<!-- onload place array is displayed -->
<div class="d-flex flex-row flex-wrap p-3" *ngIf="!model">
  <div class="card mb-3 me-3 overflow-hidden" style="max-width: 250px;height: 100px;" 
    *ngFor="let item of place"
    [ngClass]="{'border-5 border-success': isSelected(item._id)}">
    <div class="row g-0" (click)="toggleSelection(item._id)">
      <div class="col-md-4">
        <img *ngIf="item.coverImage" src="{{imagesBaseDir+item.coverImage?.url}}" class="img-fluid overflow-hidden" alt="..."
          style="height: 100%;object-fit: cover; border-right: 1px solid grey;" loading="lazy" />
        <img *ngIf="!item.coverImage" src="../../../../../assets//placeholder.jpg" class="img-fluid overflow-hidden" alt="..." style="height: 100%;object-fit: cover; border-right: 1px solid grey;" loading="lazy" />
      </div>
      <div class="card-body position-relative col-md-7" style="z-index:0; height: 8rem">
        <h3 class="card-title fw-semibold pt-2 text-truncate">{{ item.name }}</h3>
      </div>
    </div>
    <!--div class="card-button overflow-hidden position-relative" [ngClass]="{'selected': isSelected(item._id)}">
      <input class="form-check-input" type="checkbox" [ngModel]="isSelected(item._id)"
        (ngModelChange)="toggleSelection(item._id)" style="margin-left: 5px;" />
    </div-->
  </div>
</div>

<!-- if search done then display this array -->
<div class="d-flex flex-row flex-wrap p-3" *ngIf="model">
  <div class="card mb-3 me-3 overflow-hidden" style="max-width: 250px;height: 100px;" 
    *ngFor="let item of searchResults"
    [ngClass]="{'border-5 border-success': isSelected(item._id)}">
    <div class="row g-0" (click)="toggleSelection(item._id)">
      <div class="col-md-4">
        <img *ngIf="item.coverImage" src="{{imagesBaseDir+item.coverImage?.url}}" class="img-fluid overflow-hidden" alt="..."
          style="height: 100%;object-fit: cover; border-right: 1px solid grey;" loading="lazy" />
        <img *ngIf="!item.coverImage" src="../../../../../assets//placeholder.jpg" class="img-fluid overflow-hidden" alt="..." style="height: 100%;object-fit: cover; border-right: 1px solid grey;" loading="lazy" />
      </div>
      <div class="card-body position-relative col-md-7" style="z-index:0; height: 8rem">
        <h3 class="card-title fw-semibold pt-2 text-truncate">{{ item.name }}</h3>
      </div>
    </div>
  </div>
</div>