<div>
  <div class="d-flex flex-column w-100" *ngIf="itinerary">
    <div class="form-floating w-100 mt-3">
      <input type="text" [(ngModel)]="itinerary.name" class="form-control" class="form-control" placeholder="Name">
      <label for="floatingInput" (ngModelChange)="onModelChange('name')">Name</label>
    </div>
    <div class="form-floating w-100 mt-3">
      <textarea class="form-control" [(ngModel)]="itinerary.description" class="form-control" placeholder="Descp" style="height: 100px"></textarea>
      <label for="floatingTextarea2" (ngModelChange)="onModelChange('description')">Description</label>
    </div>
    <div class="form-floating w-100 mt-3">
      <textarea class="form-control" [(ngModel)]="itinerary.notes" class="form-control" placeholder="notes" style="height: 100px"></textarea>
      <label for="floatingTextarea2" (ngModelChange)="onModelChange('notes')">Notes</label>
    </div>
    <!-- <div class="form-floating w-100 mt-3" style="position: relative;">
      <tag-input class="form-control input-lg" 
        [(ngModel)]='itinerary.tags' 
        [editable]="true" 
        [displayBy]="'name'"
        (ngModelChange)="onModelChange('tags')"
        >
      </tag-input>
      <label for="floatingTextarea2">Tags</label>
    </div> -->
  </div>
</div>  