import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from '@luhuiguo/xng-breadcrumb';
import { combineLatest } from 'rxjs';
import { Product } from 'src/app/guides/model/interfaces';
import { GuidebookService } from 'src/app/guides/services/guidebook.service';
import { PeopleService } from 'src/app/guides/services/people.service';
import { PlaceService } from 'src/app/guides/services/place.service';
import { StateService } from 'src/app/services/state.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-product',
  templateUrl: './create-product.component.html',
  styleUrl: './create-product.component.css'
})
export class CreateProductComponent {

  imagesBaseDir = environment.imagesPrefix;
  producers: any;
  resellers: any;
  product: Product;
  params$ = this.route.params;
  area$ = this.stateService.selectedArea$;
  tooltipImage: string = '';
  coverImageLoadError = false;


  constructor(private guidebookService: GuidebookService,
    private placeService: PlaceService,
    private peopleService: PeopleService,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private stateService: StateService) {
  }

  ngOnInit(): void {
    combineLatest([this.params$, this.area$]).subscribe(([params, area]) => {
      if (params.id == 0) {
        this.breadcrumbService.set('areas/:id/guidebooks/dyn/product/:id', `Aggiungi Prodotto`);
        this.product = new Product(area._id)
        this.populateResellers(area._id);
        this.populateProducers(area._id);
      } else {
        this.guidebookService.getGuidebookById(params.id).subscribe((product: any) => {
          this.product = product;
          this.breadcrumbService.set('areas/:id/guidebooks/dyn/product/:id', `Prodotto: ${product.name}`);
          this.populateProducers(area._id);
          this.populateResellers(area._id);
        });
      }
    });
  }

  populateProducers(area: string) {
    this.placeService.getMinPlaces(area, 'producers').subscribe((places: any) => {
      places.map((place) => {
        place.isChecked = this.product.producers.includes(place._id);
        place.isHidden = false;
      });
      places.sort((a, b) => {
        if (a.isChecked && !b.isChecked) {
          return -1; // `a` comes before `b`
        } else if (!a.isChecked && b.isChecked) {
          return 1; // `b` comes before `a`
        } else {
          // If isChecked properties are the same, sort alphabetically
          return a.name.localeCompare(b.name);
        }
      });
      this.producers = places;
    })
  }

  populateResellers(area: string) {
    this.placeService.getMinPlaces(area, 'resellers').subscribe((places: any) => {
      places.map((place) => {
        place.isChecked = this.product.resellers.includes(place._id);
        place.isHidden = false;
      });
      places.sort((a, b) => {
        if (a.isChecked && !b.isChecked) {
          return -1; // `a` comes before `b`
        } else if (!a.isChecked && b.isChecked) {
          return 1; // `b` comes before `a`
        } else {
          // If isChecked properties are the same, sort alphabetically
          return a.name.localeCompare(b.name);
        }
      });
      this.resellers = places;
    })
  }

  saveProduct() {
    debugger
    if (this.product._id) {
      this.updateProduct();
    } else {
      this.guidebookService.createGuidebook(this.product).subscribe((res) => {
        this.product._id = res._id;
        this.stateService.message$.next({ text: `Prodotto Creato.`, type: environment.MESSAGES.SUCCESS });
      });
    }
  }

  updateProduct() {
    this.guidebookService.updateGuidebook(this.product).subscribe((res) => {
      this.stateService.message$.next({ text: `Prodotto Aggiornato.`, type: environment.MESSAGES.SUCCESS });
    });
  }

  onProducersCheckboxChange(placeId: string) {
    if (this.product.producers.includes(placeId)) {
      // If item already selected, remove it
      this.product.producers = this.product.producers.filter(p => p !== placeId);
    } else {
      // If item not selected, add it
      this.product.producers.push(placeId);
    }
  }

  onResellersCheckboxChange(placeId: string) {
    if (this.product.resellers.includes(placeId)) {
      // If item already selected, remove it
      this.product.resellers = this.product.resellers.filter(p => p !== placeId);
    } else {
      // If item not selected, add it
      this.product.resellers.push(placeId);
    }
  }

  onImageWarning(state: string) {
    if (state == 'cancelled') {
      this.stateService.message$.next({ text: `Operazione Annullata.`, type: environment.MESSAGES.WARNING });
    }
  }

  onImageSuccess(state: string) {
    this.saveProduct();
    this.stateService.message$.next({ text: `Immagine Salvata.`, type: environment.MESSAGES.SUCCESS });
  }

  onImageDeleted() {
    this.stateService.message$.next({ text: `Immagine Eliminata.`, type: environment.MESSAGES.WARNING });
    this.saveProduct();
  }

  setTooltipImage(imageUrl, tooltip) {
    tooltip.container = 'body';
    this.tooltipImage = `${this.imagesBaseDir}${imageUrl}`;
  }

  removeTooltipImage(tooltip) {
    tooltip.close(false);
    this.tooltipImage = '';
  }

  tagWarning() {
    this.stateService.message$.next({ text: "Tag Rimosso", type: `${environment.MESSAGES.ERROR}` })
  }

  tagError(error) {
    this.stateService.message$.next({ text: `Errore dal Suggeritore AI: ${error.statusText}`, type: `${environment.MESSAGES.ERROR}` });
  }
}
