import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, Subject, combineLatest } from 'rxjs';
import { Place, AISubject, Area } from 'src/app/guides/model/interfaces';
import * as _ from 'lodash';
import { _DEBUG } from 'src/app/app.component';
import { AIService } from 'src/app/guides/services/ai.service';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, filter, map, merge } from 'rxjs/operators';
import { ActivatedRoute, Params } from '@angular/router';
import { StateService } from 'src/app/services/state.service';
import { PlaceService } from 'src/app/guides/services/place.service';

// type _intent = { name: string };
// const intentsList: _intent[] = [
//   { name: 'storia' },
//   { name: 'durata' },
//   { name: 'costo' },
// ]

const intentsList = [
  'storia',
  'durata',
  'costo',
]


@Component({
  selector: 'app-ai-subject-active',
  templateUrl: './ai-subject-active.component.html',
  styleUrls: ['./ai-subject-active.component.css'],
  host: { 'style': 'max-width:1000px;margin-left:auto;margin-right:auto', 'class': 'h-100 w-75 pt-5' }
})
export class AiSubjectActiveComponent implements OnInit {
  // formatter = (intent: Intent) => intent.name;

  // @Input() place!: Place | any;
  // @Input() place: Place = new Place();
  place: Place | any;
  @ViewChild('instance') instance: NgbTypeahead;
  @Input() aiSubject: AISubject = new AISubject();
  @Output() placeChange: EventEmitter<Place> = new EventEmitter<Place>();
  @Output() aiSubjectChange: EventEmitter<AISubject> = new EventEmitter<AISubject>();
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  public model: any;

  settings = {
    singleSelection: false,
    idField: '_id',
    textField: 'name',
    enableCheckAll: false,
    allowSearchFilter: true,
    limitSelection: -1,
    clearSearchFilter: true,
    maxHeight: 250,
    itemsShowLimit: 3,
    searchPlaceholderText: 'Cerca',
    noDataAvailablePlaceholderText: 'Nessun risultato',
    closeDropDownOnSelection: false,
    showSelectedItemsAtTop: false,
    defaultOpen: false,
  };

  subSubjects = [];

  // search: OperatorFunction<string, readonly { name }[]> = (text$: Observable<string>) =>
  //   text$.pipe(
  //     debounceTime(200),
  //     distinctUntilChanged(),
  //     filter((term) => term.length >= 2),
  //     map((term) => intentsList.filter((intent) => new RegExp(term, 'mi').test(intent.name)).slice(0, 10)),
  //   );
  // search = (text$: Observable<string>) =>
  // text$.pipe(
  //   debounceTime(200),
  //   distinctUntilChanged(),
  //   filter((term) => term.length >= 2),
  //   map(term =>
  //     term === ''
  //       ? []
  //       : intentsList.filter(option => option.toLowerCase().indexOf(term.toLowerCase()) > -1)
  //   )
  // );

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focus$),
      merge(this.click$.pipe(filter(() => !this.instance.isPopupOpen()))),
      map(term =>
        term === ''
          ? []
          : this.place.filter(v => v.name.toLowerCase().includes(term.toLowerCase())).slice(0, 10)
      )
    );
  formatter = (x: { name: string }) => x.name;

  constructor(
    private aiService: AIService,
    private route: ActivatedRoute,
    private stateService: StateService,
    private placeService: PlaceService,
  ) {
  }

  ngOnInit(): void {
    console.log('place in place tab', this.place);
    let params$: Observable<Params> = this.route.params;
    let area$: Observable<Area> = this.stateService.selectedArea$;
    combineLatest([params$, area$]).subscribe(([params, area]) => {
      this.placeService.getAllPlaces().subscribe((data) => {
        this.place = data;
      });
    });

    this.aiService.getAllSubjects().subscribe((subjects) => {
      this.subSubjects = subjects;
    });
    this.aiSubject.active = true;
  };

  // onToggleChange() {
  //   this.aiSubjectChange.emit(this.aiSubject);
  //   if (this.place.active) {
  //     this.addIntent('');
  //   } else {
  //     this.aiSubject.intents = [];
  //   }
  // }

  addPlaceName() {
    if (this.model && this.model.name) {
      this.aiSubject.name = this.model.name;
    } else {
      this.aiSubject.name = this.model;
    }
  }

  onModelChange(item: any) {
    this.aiSubjectChange.emit(this.aiSubject);
  };

  addIntent(k: string) {

    this.aiSubject.intents.push({
      name: k,
      answer: '',
      active: true
    });
    this.aiSubjectChange.emit(this.aiSubject);
  }

  remIntent(i: number) {
    this.aiSubject.intents.splice(i, 1);
    this.aiSubjectChange.emit(this.aiSubject);
  }

}