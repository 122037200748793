<div class="card-sl mb-1 d-flex align-items-start flex-column p-0 position-relative overflow-hidden bg-white">
  <div class="carousel-box card-image w-100 ">
    <div class="position-relative">
      <div class="row">
        <div style="border-width: 0px !important;" class="col-md-5 map-container border h-100 opacity-gradient">
          <ngb-carousel *ngIf="place.images&&place.coverImage">
            <!-- Show coverImage slide -->
            <ng-template ngbSlide *ngIf="place.coverImage">
              <div class="row g-0">
                <div class="col-md-12">
                  <img class="card-img-top img-fluid"
                    [src]="place.coverImage.external?place.coverImage.url:imagesBaseDir + place.coverImage.url"
                    title="{{ place.coverImage.name }}" style="height: 12rem; object-fit: cover" loading="lazy"
                    onerror="this.onerror=null;this.src='assets/placeholder.jpg';" />
                </div>
              </div>
            </ng-template>
            <!-- Show image slide -->
            <ng-template ngbSlide *ngFor="let img of place.images">
              <div class="row g-0">
                <div class="col-md-12">
                  <img class="card-img-top img-fluid" [src]="imagesBaseDir+img.url" title="{{ img.name }}"
                    style="height: 12rem; object-fit: cover" loading="lazy"
                    onerror="this.onerror=null;this.src='assets/placeholder.jpg';" />
                </div>
              </div>
            </ng-template>
          </ngb-carousel>
        </div>
        <div class="card-body position-relative col-md-7" style="z-index:0; height: 12rem">
          <h3 class="card-title fw-semibold pt-2">{{ place.name }}</h3>
          <!-- <h4 class="card-subtitle fw-medium">{{ place.address.street }}</h4> -->
          <h4 class="card-text">{{ place.name }}</h4>
          <strong class="card-text">{{ place.address.city }}</strong>
          <div class="d-flex w-100 mb-2">
            <ng-container *ngIf="place.tags">
              <div class="tag" *ngFor="let tag of place.tags">{{ tag }}</div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <app-modify-delete [target]="place._id" (onDelete)="onDelete(place._id)"></app-modify-delete>
  </div>