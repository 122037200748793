<div class="p-3 h-100" *ngIf="activity;else loading">
    <div class="col-12 col-md-11 col-lg-10 col-xl-9 col-xxl-8 mx-auto p-4 bg-white rounded-3" style="height: 90%;">
        <ng-stepper class="h-100 pt-2" #cdkStepper [linear]="false">
            <cdk-step [optional]="true">
                <ng-template cdkStepLabel>
                    <div class="step-bullet"><i class="bi bi-map"></i></div>
                    <div class="step-title d-md-block">Descrizione</div>
                </ng-template>
                <div class="inner-step">
                    <div class="step-form">
                        <div>
                            <div class="d-flex flex-column w-100 p-3">
                                <ta-input label='Nome' inputGroupClass="w-50" [(value)]="activity.name"></ta-input>
                                <div class="form-floating w-100 mb-3 mt-2">
                                    <textarea [(ngModel)]="activity.description" class="form-control"
                                        placeholder="Nome del Luogo" style="height:200px"></textarea>
                                    <label for="floatingInput">Descrizione</label>
                                </div>
                                <div class="form-floating w-100 mb-3">
                                    <textarea [(ngModel)]="activity.notes" class="form-control" placeholder="Note"
                                        style="height:200px"></textarea>
                                    <label for="floatingInput">Note</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bottom-button">
                        <button type="button" class="ms-auto button btn btn-light" (click)="cdkStepper.next()"><i
                                class="bi bi-arrow-right fs-1"></i></button>
                    </div>
                </div>
            </cdk-step>

            <cdk-step [optional]="true">
                <ng-template cdkStepLabel>
                    <div class="step-bullet"><i class="bi bi-envelope"></i></div>
                    <div class="step-title d-md-block">Riferimenti</div>
                </ng-template>
                <div class="inner-step">
                    <div class="step-form">
                        <div class="p-1 h-100">
                            <div class="h-50 overflow-auto">
                                <div class="p-3 text-center sticky-top bg-light rounded">
                                    <div class="input-group input-group-sm w-25 position-absolute" style="left:1rem;">
                                        <input type="text" (input)="filterPlaces($event)" class="form-control"
                                            placeholder="Filtra...">
                                    </div>
                                    <div class="lead fw-bold justify-self-center">Seleziona i luoghi</div>
                                </div>
                                <div *ngFor="let p of places" class="border-bottom p-2" [hidden]="p.isHidden"
                                    (mouseenter)="setTooltipImage(p.coverImage.url,tooltip)" #tooltip="ngbTooltip"
                                    tooltipClass="custom-tooltip" (mouseleave)="removeTooltipImage(tooltip)"
                                    [ngbTooltip]="t" [openDelay]="500" [positionTarget]="target">
                                    <div class="w-100 d-flex flex-row">
                                        <div #target class="">{{p.name}}</div>
                                        <div class="ms-auto form-check">
                                            <input class="form-check-input" type="checkbox" [value]="p._id"
                                                id="flexCheckChecked" [checked]="p.isChecked"
                                                (change)="onCheckboxChange(p._id)">
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="bottom-button">
                        <button type="button" class="button btn btn-light" (click)="cdkStepper.previous()"><i
                                class="bi bi-arrow-left"></i></button>
                        <button type="button" class="button btn btn-light" (click)="cdkStepper.next()"><i
                                class="bi bi-arrow-right"></i></button>
                    </div>
                </div>
            </cdk-step>

            <cdk-step [optional]="true">
                <ng-template cdkStepLabel>
                    <div class="step-bullet"><i class="bi bi-file-text"></i></div>
                    <div class="step-title d-md-block">Immagini</div>
                </ng-template>
                <div class="inner-step">
                    <div class="step-form">
                        <div class="p-1" style="height: 90%;" *ngIf="activity">
                            <ta-single-image *ngIf="activity" [(image)]="activity.coverImage" [uuid]="activity.uuid"
                                (onImageSuccess)="onImageSuccess($event)" (onImageWarning)="(onImageWarning($event))"
                                (onImageDeleted)="onImageDeleted()">
                            </ta-single-image>
                        </div>
                        <div class="bottom-button">
                            <button type="button" class="button btn btn-light" (click)="cdkStepper.previous()"><i
                                    class="bi bi-arrow-left"></i></button>
                            <button type="button" class="button btn btn-light" (click)="cdkStepper.next()"><i
                                    class="bi bi-arrow-right"></i></button>
                        </div>
                    </div>
                </div>
            </cdk-step>

            <cdk-step [optional]="true">
                <ng-template cdkStepLabel>
                    <div class="step-bullet"><i class="bi bi-file-text"></i></div>
                    <div class="step-title d-md-block">Riepilogo</div>
                </ng-template>
                <div class="inner-step">
                    <div class="step-form">
                        <div class="p-1">
                            <div class="d-flex flex-column">
                                <div class="lead">Nome
                                    <div class="fw-bold">{{activity.name}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bottom-button">
                        <button type="button" class="button btn btn-light" (click)="cdkStepper.previous()"><i
                                class="bi bi-arrow-left"></i></button>
                        <button type="button" class="button btn btn-light" (click)="saveEvent()"><i
                                class="bi bi-cloud-upload fs-1"></i></button>
                    </div>
                </div>
            </cdk-step>

        </ng-stepper>
    </div>
</div>
<ng-template #loading>
    <div class="d-flex justify-content-center align-items-center h-100">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</ng-template>
<ng-template #t let-c="close" let-o="open">
    <div class="overflow-hidden aspect-ratio-1">
        <img [src]="tooltipImage" class="rounded" alt="" style="width: 150px;height: 150px;object-fit: cover;"
            loading="lazy">
    </div>
</ng-template>