import { Component, EventEmitter, Input, OnInit, Output, ViewChild, } from '@angular/core';
import { combineLatest } from 'rxjs';
import { Area } from 'src/app/guides/model/interfaces';
import * as _ from 'lodash';
import { _DEBUG } from 'src/app/app.component';
import { BreadcrumbService } from '@luhuiguo/xng-breadcrumb';
import Overlay from 'ol/Overlay';
import { Zoom, defaults as defaultControls } from 'ol/control';
import { StateService } from 'src/app/services/state.service';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { fromLonLat } from 'ol/proj';
import Map from 'ol/Map';
import View from 'ol/View';


@Component({
  selector: 'app-area-data',
  templateUrl: './area-data.component.html',
  styleUrls: ['./area-data.component.css'],
  host: { 'style': 'max-width:1000px;margin-left:auto;margin-right:auto', 'class': 'w-75 pt-5' }
})
export class AreaDataComponent implements OnInit {

  @Input() area: Area = new Area();
  @Output() areaChange: EventEmitter<Area> = new EventEmitter<Area>();

  map!: Map;
  overlay!: Overlay;
  loading = false;
  firstTime = true;

  options = {
    types: ['(cities)'],
    componentRestrictions: { country: "IT" }
  };

  constructor(
    private breadcrumbService: BreadcrumbService,
  ) {
  }

  ngOnInit(): void {
    console.log('on init', this.area);
    setTimeout(() => {
      console.log('set time out');
      this.initializeMap();
    }, 700);
    // this.initializeMap();
  };
  ngAfterViewInit(): void {
    console.log('view init');
    this.loading = true;
    this.area.name = 'Italy';
    this.area.latlng = {
      lat: 41.902782,
      lng: 12.496366,
    }
  }

  handleAddressChange(address: any) {
    console.log('address', address);
    this.area.name = address.name;
    const lat = address?.geometry?.location.lat();
    const lng = address?.geometry?.location.lng();
    this.area.latlng = {
      lat: lat,
      lng: lng,
    }
    this.areaChange.emit(this.area);
    setTimeout(() => {
      this.initializeMap();
    }, 200);
  }

  initializeMap() {
    this.loading = true;
    if (this.area.name) {

      if (this.map) {
        this.firstTime = false;
        this.map.setTarget(null);
        this.map.dispose();
      }

      console.log('area name initailize', this.area.name)
      console.log('area name initailize', this.area.latlng.lng, this.area.latlng.lat)

      this.map = new Map({
        view: new View({
          center: fromLonLat([this.area.latlng.lng, this.area.latlng.lat]),
          zoom: this.firstTime ? 5 : 12,
        }),
        layers: [
          new TileLayer({
            source: new OSM(),
          }),
        ],
        target: `ol-map-${this.area.name}`,
        controls: [
          new Zoom({
            zoomInTipLabel: 'Zoom in',
            zoomOutTipLabel: 'Zoom out',
            className: 'ol-custom-zoom',
            zoomInLabel: this.createZoomButtonLabel('plus.svg'),
            zoomOutLabel: this.createZoomButtonLabel('minus.svg'),
          }),
        ],
      });
    }
    this.loading = false;
  }


  createZoomButtonLabel(imagePath) {
    const icon = document.createElement('img');
    icon.src = `assets/icons/${imagePath}`;
    return icon;
  }

}