import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-submit',
  templateUrl: './submit.component.html',
  styleUrls: ['./submit.component.css']
})
export class SubmitComponent implements OnInit {
  @Input() section: string;
  @Input() isNew: boolean;
  @Input() disabled: boolean;
  @Output() save = new EventEmitter();
  @Output() cancel = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onCancel(){
    this.cancel.emit();
  }

  onSave(){
    this.save.emit();
  }

}
