import { Component, OnInit, ViewChild } from '@angular/core';
import { AreasService } from 'src/app/guides/services/areas.service';
import { AISubject, Area, Place } from 'src/app/guides/model/interfaces';
import { ActivatedRoute, NavigationExtras, Params, Router } from '@angular/router';
import { PlaceService } from 'src/app/guides/services/place.service';
import { BreadcrumbService } from '@luhuiguo/xng-breadcrumb';
import { Observable, combineLatest } from 'rxjs';
import { StateService } from 'src/app/services/state.service';
import { AIService } from '../../services/ai.service';

@Component({
  selector: 'app-ai-subjects',
  templateUrl: './ai-subjects.component.html',
  styleUrls: ['./ai-subjects.component.css'],
})
export class AiSubjectsComponent implements OnInit {

  area: string = '';
  places: Place[] = [];
  subjects: AISubject[] = [];
  loading: boolean = true;
  datalist: Element;


  constructor(
    private router: Router,
    private placeService: PlaceService,
    private aiService: AIService,
    private breadcrumbService: BreadcrumbService,
    private stateService: StateService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.breadcrumbService.set('areas/:id/aisubjects', `Assistente Virtuale`);
    this.loading = true;
    let params$: Observable<Params> = this.route.params;
    let area$: Observable<Area> = this.stateService.selectedArea$;
    combineLatest([params$, area$]).subscribe(([params, area]) => {
      this.aiService.getAllSubjectsData(area._id).subscribe((subjects) => {
        this.subjects = subjects;
      })
    });
    console.log('get subjects lis', this.subjects);
    this.loading = false;
    //this.placeService.getPlace().subscribe((data) => {this.places = data['records']});
  }

  deleteSubject(subject: AISubject) {
    this.aiService.deleteAI(subject._id).subscribe((data) => {
      console.log(data);
      this.subjects = this.subjects.filter((t) => t._id !== subject._id);
      //TODO ALERT
    });
  }

}
