import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { GbSharedService } from 'src/app/guides/services/gb-shared.service';
import { GuidebookService } from 'src/app/guides/services/guidebook.service';
import { StoreImgsService } from 'src/app/common/services/store-imgs.service';
import { DeleteStoredImgsService } from 'src/app/common/services/delete-stored-imgs.service';
import { PlaceService } from 'src/app/guides/services/place.service';

@Component({
  selector: 'app-guidebook-contact',
  templateUrl: './guidebook-contact.component.html',
  styleUrls: ['./guidebook-contact.component.css']
})
export class GuidebookContactComponent implements OnInit {


  ngOnInit(): void {

  }



}
