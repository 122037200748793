import { Component, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest } from 'rxjs';
import { GuidebookService } from 'src/app/guides/services/guidebook.service';
import { PlaceService } from 'src/app/guides/services/place.service';
import { StateService } from 'src/app/services/state.service';
import { environment } from 'src/environments/environment';
import { BreadcrumbService } from '@luhuiguo/xng-breadcrumb';
import { Event } from 'src/app/guides/model/interfaces';
import { PeopleService } from 'src/app/guides/services/people.service';

@Component({
  selector: 'app-create-event',
  providers: [GuidebookService, PlaceService, NgbModal],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './create-event.component.html',
  styleUrl: './create-event.component.css'
})
export class CreateEventComponent implements OnInit {

  calendar = inject(NgbCalendar);
  formatter = inject(NgbDateParserFormatter);
  hoveredDate: NgbDate | null = null;
  //fromDate: NgbDate | null = this.calendar.getToday();
  //toDate: NgbDate | null = this.calendar.getNext(this.calendar.getToday(), 'd', 10);
  tooltipImage: string = '';
  coverImageLoadError = false;
  imagesBaseDir = environment.imagesPrefix;
  places: any;
  event: Event;
  params$ = this.route.params;
  area$ = this.stateService.selectedArea$;
  people: any;
  events: any;

  constructor(private guidebookService: GuidebookService,
    private placeService: PlaceService,
    private peopleService: PeopleService,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private stateService: StateService) {
  }

  /*onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }*/



  ngOnInit(): void {
    combineLatest([this.params$, this.area$]).subscribe(([params, area]) => {
      if (params.id == 0) {
        this.breadcrumbService.set('areas/:id/guidebooks/dyn/event/:id', `Aggiungi Evento`);
        this.event = new Event(area._id)
        this.populatePlaces(area._id);
        this.populatePeople(area._id);
        this.populateEvents();
      } else {
        this.guidebookService.getGuidebookById(params.id).subscribe((event: any) => {
          this.event = event;
          this.breadcrumbService.set('areas/:id/guidebooks/dyn/event/:id', `Evento: ${event.name}`);
          this.populatePlaces(area._id);
          this.populatePeople(area._id);
          this.populateEvents();
        });
      }
    });
  }

  populatePeople(area: string) {
    this.peopleService.getPeopleByArea(area).subscribe((people: any) => {
      people.map((person) => {
        person.name = `${person.name} ${person.surname}`;
        person.isChecked = this.event.contacts.includes(person._id);
        person.isHidden = false;
      });
      people.sort((a, b) => {
        if (a.isChecked && !b.isChecked) {
          return -1; // `a` comes before `b`
        } else if (!a.isChecked && b.isChecked) {
          return 1; // `b` comes before `a`
        } else {
          // If isChecked properties are the same, sort alphabetically
          return a.name.localeCompare(b.name);
        }
      });
      this.people = people;
    });
  }

  populatePlaces(area: string) {
    this.placeService.getMinPlaces(area).subscribe((places: any) => {
      places.map((place) => {
        place.isChecked = this.event.places.includes(place._id);
        place.isHidden = false;
      });
      places.sort((a, b) => {
        if (a.isChecked && !b.isChecked) {
          return -1; // `a` comes before `b`
        } else if (!a.isChecked && b.isChecked) {
          return 1; // `b` comes before `a`
        } else {
          // If isChecked properties are the same, sort alphabetically
          return a.name.localeCompare(b.name);
        }
      });
      this.places = places;
    })
  }

  populateEvents() {
    this.guidebookService.getAllGuidebooks().subscribe((events: any) => {
      events.map((event) => {
        event.isChecked = this.event.eventdays.includes(event._id);
        event.isHidden = false;
      });
      events.sort((a, b) => {
        if (a.isChecked && !b.isChecked) {
          return -1; // `a` comes before `b`
        } else if (!a.isChecked && b.isChecked) {
          return 1; // `b` comes before `a`
        } else {
          // If isChecked properties are the same, sort alphabetically
          return a.name.localeCompare(b.name);
        }
      });
      this.events = events;
    });
  }

  filterPlaces(event: any) {
    this.places.map((place) => {
      if (place.name.toLowerCase().includes(event.target.value.toLowerCase())) {
        place.isHidden = false;
      } else {
        place.isHidden = true;
      }
    });
  }

  saveEvent() {
    if (this.event._id) {
      this.updateEvent();
    } else {
      this.guidebookService.createGuidebook(this.event).subscribe((res) => {
        this.event._id = res._id;
        this.stateService.message$.next({ text: `Evento Creato.`, type: environment.MESSAGES.SUCCESS });
      });
    }
  }

  updateEvent() {
    this.guidebookService.updateGuidebook(this.event).subscribe((res) => {
      this.stateService.message$.next({ text: `Evento Aggiornato.`, type: environment.MESSAGES.SUCCESS });
    });
  }

  deleteEvent() {
    this.guidebookService.deleteGuidebook(this.event.uuid).subscribe((res) => {
      this.stateService.message$.next({ text: `Evento Eliminato.`, type: environment.MESSAGES.WARNING });
    });
  }

  onCheckboxChange(placeId: string) {
    if (this.event.places.includes(placeId)) {
      // If item already selected, remove it
      this.event.places = this.event.places.filter(p => p !== placeId);
    } else {
      // If item not selected, add it
      this.event.places.push(placeId);
    }
  }

  onImageWarning(state: string) {
    if (state == 'cancelled') {
      this.stateService.message$.next({ text: `Operazione Annullata.`, type: environment.MESSAGES.WARNING });
    }
  }

  onImageSuccess(state: string) {
    this.saveEvent();
    this.stateService.message$.next({ text: `Immagine Salvata.`, type: environment.MESSAGES.SUCCESS });
  }

  onImageDeleted() {
    this.stateService.message$.next({ text: `Immagine Eliminata.`, type: environment.MESSAGES.WARNING });
    this.saveEvent();
  }

  setTooltipImage(imageUrl, tooltip) {
    tooltip.container = 'body';
    this.tooltipImage = `${this.imagesBaseDir}${imageUrl}`;
  }

  removeTooltipImage(tooltip) {
    tooltip.close(false);
    this.tooltipImage = '';
  }

  onFileSaved(message: string) {
    this.stateService.message$.next({ text: message, type: environment.MESSAGES.SUCCESS });
  }

  onFileRemoved(message: string) {
    this.stateService.message$.next({ text: message, type: environment.MESSAGES.WARNING });
  }

  onFileError(message: string) {
    this.stateService.message$.next({ text: message, type: environment.MESSAGES.ERROR });
  }

  tagWarning() {
    this.stateService.message$.next({ text: "Tag Rimosso", type: `${environment.MESSAGES.ERROR}` })
  }

  tagError(error) {
    this.stateService.message$.next({ text: `Errore dal Suggeritore AI: ${error.statusText}`, type: `${environment.MESSAGES.ERROR}` });
  }

  onContactInputError(event) {
    this.stateService.message$.next({ text: `Errore: ${event}`, type: `${environment.MESSAGES.ERROR}` });
  }
}

