<div class="card-sl mb-1 d-flex align-items-start flex-column p-0 position-relative overflow-hidden bg-white">
  <div class="carousel-box card-image w-100 ">
    <div class="position-relative">
      <div class="row" style="width: 90%;margin-left: auto;margin-right: auto;">
        <div class="card-body position-relative col-md-12" style=" height: 12rem">
          <div class="d-flex flex-row w-100 align-items-center">
            <h3 class="card-title fw-semibold pt-2">{{ subject.name }}</h3>
            <h4 class="card-subtitle fw-medium ms-auto" [class]="subject.active?'text-success':'text-danger'">{{
              subject.active?'Attivo':'Non attivo' }}</h4>
          </div>
          <div class="d-flex w-100 mb-2 flex-column">
            <ng-container *ngIf="subject.intents.length > 0">
              <div class="lead fs-6 mb-1">Intenti</div>
              <div class="d-flex flex-ro flex-wrap">
                <div class="tag" *ngFor="let intent of subject.intents">{{ intent.name }}</div>
              </div>
            </ng-container>
          </div>
          <div class="d-flex w-100 mb-2 flex-column">
            <ng-container *ngIf="subject.subSubjects.length > 0">
              <div class="lead fs-6 mb-1">Soggetti collegati</div>
              <div class="d-flex flex-ro flex-wrap">
                <div class="tag" *ngFor="let subject of subject.subSubjects">{{ subject.name }}</div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <app-modify-delete [target]="subject._id" (onDelete)="onDelete(subject._id)"></app-modify-delete>
  </div>