<h1 class="mb-4">SETTINGS</h1>
<div *ngIf="settings" class="row g-3 mt-3">
  <div *ngFor="let set of settings; let j = index" class="col-sm-6 col-md-4 col-lg-3 border m-1">
    <div class="" [ngSwitch]="set.type">
      <div class="" *ngSwitchCase="1">
        <div class="">
          <div class="d-flex w-100 mb-3 justify-content-center">
            <span class=""><strong>Area: {{ set.area }} - {{ set.param }}</strong></span>
          </div>
          <div class="d-flex border mt-1" *ngFor="let val of set.vals; let i = index">
            <span class="align-items-start w-100">{{ val }}</span>
            <div class="btn btn-outline-primary small-btn" (click)="remove(set.param, i)">
              <fa-icon [icon]="['fas', 'trash']" size="xs" class="ml-auto" style="color: red"></fa-icon>
            </div>
          </div>
          <input type="text" class="form-control" placeholder="val" aria-label="val" (keyup.enter)="onEnter($event, j)"
            onfocus="this.value=''" />
        </div>
      </div>
      <div class="" *ngSwitchCase="2">
        <div>
          <div class="d-flex w-100 mb-3 justify-content-center">
            <span><strong>Area: {{ set.area }} - {{ set.param }}</strong></span>
          </div>
          <div *ngFor="let val of set.vals; let i = index">
            <div class="d-flex border m-1">
              <div *ngFor="let v of val | keyvalue" class="d-flex w-100 align-items-start flex-column">
                <span class=""><strong>{{ v.key }}:</strong></span>
                <span class="">{{ v.value }}</span>
              </div>
              <div (click)="remove(set.param, i)" class="
                  ml-auto
                  align-self-center
                  btn btn-outline-primary
                  small-btn
                ">
                <fa-icon [icon]="['fas', 'trash']" size="xs" class="" style="color: red"></fa-icon>
              </div>
            </div>
          </div>
          <input type="text" class="form-control" placeholder="val" aria-label="val" (keyup.enter)="onEnter($event, j)"
            onfocus="this.value=''" />
        </div>
      </div>
      <div class="" *ngSwitchCase="3">
        <div>
          <div class="d-flex w-100 mb-3 justify-content-center">
            <span><strong>Area: {{ set.area }} - {{ set.param }}</strong></span>
          </div>
          <div *ngFor="let val of set.vals; let i = index">
            <div class="d-flex flex-row justify-content-between border m-1">
              <span class=""><strong>{{ val.name }} | {{ val.type }}</strong></span>
              <!--div *ngFor="let content of val.content | keyvalue">
                <span class=""
                  ><strong>{{ content.key }}</strong></span
                >
              </div-->

              <div (click)="remove(set.param, i)" class="
                  btn btn-outline-primary
                  small-btn
                ">
                <fa-icon [icon]="['fas', 'trash']" size="xs" class="" style="color: red"></fa-icon>
              </div>
            </div>
          </div>
          <input type="text" class="form-control" placeholder="val" aria-label="val" (keyup.enter)="onEnter($event, j)"
            onfocus="this.value=''" />
        </div>
      </div>
    </div>
  </div>
</div>