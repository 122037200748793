<div class="container d-flex flex-column h-100 w-100 mx-auto p-3">
  <!--  -->

  <!-- <div class="container pt-3 d-flex  flex-wrap border mt-3 rounded" style="border: 1px solid #ced4da!important" *ngIf="place.options"      > -->
  <div class="row mt-2 mb-4 ">

    <label class="mt-3 mb-3 h1 fs-3" for="floatingInput">Seleziona le opzioni dispobinibili per questo luogo:</label>

    <div class="flex-shrink-1 p-3 px-sm-5 p-lg-0 mt-1">
      <div class="mx-auto mt-5 mb-2 h5">Può essere usato come punto di arrivo, se inserito in un itinerario?</div>
      <div class="w-100 mx-auto d-flex align-items-center justify-content-start">
        <div class="form-check">
          <input class="form-check-input" style="max-width: 16px; max-height: 16px" type="checkbox"
            [(ngModel)]="place.options.arrivalPoint" [ngModelOptions]="{standalone: true}" id="arrivalPoint">
        </div>
        <label class="form-check-label" for="arrivalPoint">
          <div class="d-flex flex-row align-items-center">
            <div class="">Punto di Arrivo</div>
            <div class="ms-2 me-auto position-relative"
              [title]="place.options.arrivalPoint?'Punto di arrivo':'Tappa intermedia'"
              [style.color]="place.options.arrivalPoint?'red':'orange'">
              <i class="fs-4 bi bi-geo-alt"></i>
            </div>
          </div>
        </label>
      </div>
    </div>

    <div class="flex-shrink-1 p-3 px-sm-5 p-lg-0 mt-1">
      <div class="mx-auto mt-5 mb-2 h5">Vale la pena passare del tempo libero in questo luogo?</div>
      <div class="w-100 mx-auto d-flex align-items-center justify-content-start">
        <div class="form-check">
          <input class="form-check-input" style="max-width: 16px; max-height: 16px" type="checkbox"
            [(ngModel)]="place.options.suitableForSpareTime" [ngModelOptions]="{standalone: true}"
            id="suitableForSpareTime">
        </div>
        <label class="form-check-label" for="suitableForSpareTime">
          <div class="d-flex flex-row align-items-center">
            <div class="">Tempo Libero</div>
            <div class="ms-2 me-auto position-relative"
              [title]="place.options.suitableForSpareTime?'Adatto a passare del tempo libero':'Non adatto a passare del tempo libero'"
              [style.color]="place.options.suitableForSpareTime?'green':'red'">
              <i class="fs-4 bi" [class]="place.options.suitableForSpareTime?'bi-person-arms-up':'bi-person-lock'"></i>
            </div>
          </div>
        </label>
      </div>
    </div>

    <div class="flex-shrink-1 p-3 px-sm-5 p-lg-0 mt-1">
      <div class="mx-auto mt-5 mb-2 h5">C'è possibilità di pernottare?</div>
      <div class="w-100 mx-auto d-flex align-items-center justify-content-start">
        <div class="form-check">
          <input class="form-check-input" style="max-width: 16px; max-height: 16px" type="checkbox"
            [(ngModel)]="place.options.canSleepOver" [ngModelOptions]="{standalone: true}" id="canSleepOver">
        </div>
        <label class="form-check-label" for="canSleepOver">
          <div class="d-flex flex-row align-items-center">
            <div class="">Posti Letto</div>
            <div class="ms-2 me-auto position-relative"
              [title]="place.options.canSleepOver?'Posti letto disponibili':'Posti letto non disponibili'"
              [style.color]="place.options.canSleepOver?'green':'red'">
              <i class="fs-4 bi" [class]="place.options.canSleepOver?'bi-luggage-fill':'bi-ban'"></i>
            </div>
          </div>
        </label>
      </div>
    </div>

    <div class="col-md-12 input-group d-flex flex-column mb-3 mt-3">
      <label class="form-check-label" for="time2visit">Durata della Visita</label>
      <!--app-duration class="flex-grow-1" id="time2visit"
        [(visitDuration)]='place.options.visitDuration'></app-duration-->
      <ngb-timepicker [(ngModel)]="place.options.visitDuration"></ngb-timepicker>
    </div>
  </div>
  <!-- <div class="col-12 container d-flex flex-row" style="justify-content: space-between;">
      <div class=" form-check form-switch h6">
        <input class="form-check-input" type="checkbox" role="switch" name="arrivalPoint"
          [(ngModel)]="place.options.arrivalPoint"
           id="arrival" />
        <label class="form-check-label pt-1" for="arrival">Arrival Point</label>
      </div>
      <div class=" form-check form-switch h6">
        <input class="form-check-input" type="checkbox" role="switch" name="spareTime"
           id="spareTime" [(ngModel)]="place.options.suitableForSpareTime"/>
        <label class="form-check-label" for="spareTime">Suitable for sparetime</label>
      </div>
      <div class=" form-check form-switch h6 mb-3">
        <input class="form-check-input" type="checkbox" role="switch" name="vacancies"
           id="vacancies" [(ngModel)]="place.options.canSleepOver" />
        <label class="form-check-label" for="vacancies">Can sleep over</label>
      </div>
    </div> -->
  <!-- </div> -->

  <!--  -->


  <table class="table table-bordered">
    <thead>
      <tr>
        <th scope="col">Categoria</th>
        <th scope="col">Prezzo</th>
        <th scope="col">
          <button class="btn btn-success" (click)="addCostCat('')" type="button">
            <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon>
          </button>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let cost of place.details.cost; let i = index; let l = last">
        <td>
          <input type="text" aria-label="Cat" [(ngModel)]="cost.key" name="cost{{ i }}key" class="form-control"
            style="width: 100%" />
        </td>
        <td>
          <input type="text" aria-label="Price" [(ngModel)]="cost.value" name="cost{{ i }}value" class="form-control"
            style="width: 100%" />
        </td>
        <td>
          <button class="btn btn-danger" (click)="remCostCat(i)" type="button">
            <fa-icon [icon]="['fas', 'minus-circle']"></fa-icon>
          </button>
        </td>
      </tr>
    </tbody>
  </table>




  <!--  -->
  <!-- <div class="container pt-2 d-flex flex-row flex-wrap" *ngIf="place.options">
    <div class="pt-2" style="max-width:400px" *ngIf="place?.options?.costs">
      <div class="input-group input-group-sm">
        <span class="input-group-text"
          [ngClass]="{ 'dyn-tbl-first': place.options.costs?place.options.costs.length:0 }"
          style="flex: 1">Cat</span>
        <span class="input-group-text"
          [ngClass]="{ 'dyn-tbl-first': place.options.costs?place.options.costs.length:0 }"
          style="flex: 1">Price</span>
        <button class="btn btn-outline-secondary"
          [ngClass]="{ 'dyn-tbl-first': place.options.costs?place.options.costs.length:0 }"
          (click)="addCostCat('')" type="button" style="
          background-color: green;
          color: white;
          padding: 0px 10px;
          width: 35px;
        ">
          <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon>
        </button>
      </div>
      <div *ngFor="let cost of place.options.costs; let i = index; let l = last; let f = first"
        class="input-group input-group-sm">
        <input type="text" aria-label="Cat" [(ngModel)]="cost.key" name="cost{{ i }}key" class="form-control"
          [ngClass]="{ 'dyn-tbl-last': l, 'dyn-tbl': !l }" style="flex: 1" />
        <input type="text" aria-label="Prise" [(ngModel)]="cost.val" name="cost{{ i }}val" class="form-control"
          [ngClass]="{ 'dyn-tbl-last': l, 'dyn-tbl': !l }" style="flex: 1" />
        <button class="btn btn-outline-secondary" (click)="remCostCat(i)" [ngClass]="{ 'dyn-tbl-last': l, 'dyn-tbl': !l }"
          type="button" style="
          background-color: red;
          color: white;
          padding: 0px 10px;
          width: 35px;
        ">
          <fa-icon [icon]="['fas', 'minus-circle']"></fa-icon>
        </button>
      </div>
    </div>
  </div> -->
</div>