<div id="fab-button" class="btn btn-primary position-fixed bottom-0 end-0" [routerLink]="'product/0'">
    <fa-icon [icon]="['fas', 'plus']" size="2x" style="margin: 1%"></fa-icon>
</div>
<div class="container pt-4" style="height: 90vh ;">
    <div class="row">
        <div *ngFor="let g of guidebooks;let i = index" class="col-12 col-sm-6 col-md-4 col-lg-3 pb-3"
            style="min-width: 25rem;">
            <ta-card [value]="g" entity="product" [target]="['product',g._id]"
                (onDelete)="deleteProduct(product)"></ta-card>
        </div>
    </div>
</div>