<div class="h-100 d-flex flex-column align-content-between">
    <div class="h-100 w-100 mx-auto" *ngIf="area">
        <div class="d-grid gap-2">
            <button class="btn btn-light" type="button" (click)="loadCoverPhoto()"><i class="bi bi-image"></i><span
                    class="ms-2">Seleziona la foto di copertina</span></button>
        </div>
        <div *ngIf="area.image" class="h-100" [class]="area.image ?'':'d-none'">
            <img [src]="imagesBaseDir+area.image.url" class="" style="width: 100%;height: 100%;object-fit: cover;">
        </div>
    </div>
</div>