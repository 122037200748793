import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { StoreImgsService } from 'src/app/common/services/store-imgs.service';
import { DeleteStoredImgsService } from 'src/app/common/services/delete-stored-imgs.service';
import { ConfigService } from 'src/app/guides/services/config.service';
import { Person, Setting } from 'src/app/guides/model/interfaces';
import { map, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class PeopleService {
  private peopleEndpoint = `${environment.API}rest/people`;

  constructor(
    private http: HttpClient,
    private configService: ConfigService
  ) { }

  __getPersonByUuid(uuid: string): Observable<Person> {
    return this.http.get<any>(`${this.peopleEndpoint}?filter=uuid,eq,${uuid}`).pipe(
      take(1),
      map(({ records }) => { return records[0] })
    )
  }

  getPeople(): Observable<Person[]> {
    return this.http.get<Person[]>(this.peopleEndpoint);
  }

  getPeopleByArea(area: string): Observable<Person[]> {
    return this.http.get<Person[]>(`${this.peopleEndpoint}/area/${area}`);
  }

  __getPeopleByActivity(activity: string): Observable<Person[]> {
    activity = activity.replace(' ', '%20');
    return this.http.get<Person[]>(`${this.peopleEndpoint}?filter=activity,eq,${activity}`)
  }

  __getTaxinNcc(): Observable<Person[]> {
    const url = `${this.peopleEndpoint}?filter=activity,in,ncc,taxi`;
    console.log(url);
    return this.http.get<Person[]>(url);
  }

  __getPeopleImagesStringArray$(): Observable<string[]> {
    return this.http.get<string[]>(`${this.peopleEndpoint}?include=profileImgPath`).pipe(
      map(data => {
        let kvArray = data['records'];
        let response: string[] = [];
        kvArray.forEach(elem => {
          response.push(elem['profileImgPath'])
        })
        return response;
      })
    )
  }

  __pruneStorage(personUuid: any) {
    //this.deleteStoredImgsService.deleteFolder(personUuid).subscribe();
  }

  __removeImage(filePath: string): Observable<string[]> {
    //return this.deleteStoredImgsService.deleteImgs([filePath]);
    return EMPTY
  }

  getPersonById(id: string): Observable<any> {
    return this.http.get(`${this.peopleEndpoint}/${id}`);
  }

  createPerson(person: Person): Observable<any> {
    return this.http.post<any>(this.peopleEndpoint, person);
  }

  updatePerson(person: Person): Observable<any> {
    return this.http.patch<any>(`${this.peopleEndpoint}/${person._id}`, person);
  }

  deletePerson(id: string): Observable<any> {
    return this.http.delete<any>(`${this.peopleEndpoint}/${id}`);
  }






}