import { Injectable, ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EMPTY, Observable, catchError, map, of, take, tap } from 'rxjs';
import { TaLibraryConfig } from './ngx-ta-ui-library.model';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

const HttpOptionsText: Object = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
  responseType: 'text',
  observe: 'response',
};

@Injectable({
  providedIn: 'root'
})
export class NgxTaUiLibraryConfigService {
  imagesEndpoint: string;
  filesEndpoint: string;
  dbApiBaseDir: string;
  badWordsUrl: string;
  constructor(private config: TaLibraryConfig) {
    this.imagesEndpoint = config.imagesEndpoint;
    this.filesEndpoint = config.filesEndpoint;
    this.dbApiBaseDir = config.dbApiBaseDir;
    this.badWordsUrl = config.badWordsUrl;
  }
}

@Injectable({
  providedIn: 'root'
})
export class NgxTaTagsService {
  private badwordsUrl;

  constructor(private http: HttpClient, private config: NgxTaUiLibraryConfigService) {
    this.badwordsUrl = config.badWordsUrl;
  }

  // Method to generate a random array of strings
  private generateRandomArray(): string[] {
    const randomArray: string[] = [];
    const arrayLength = Math.floor(Math.random() * 10) + 1; // Random array length between 1 and 10
    for (let i = 0; i < arrayLength; i++) {
      randomArray.push(this.generateRandomString());
    }
    return randomArray; // Emit the random array
  }

  // Method to generate a random string
  private generateRandomString(): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const stringLength = Math.floor(Math.random() * 10) + 1; // Random string length between 1 and 10
    let randomString = '';
    for (let i = 0; i < stringLength; i++) {
      randomString += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return randomString;
  }

  // Method to get the observable of random arrays
  suggestTags(tags): Observable<string[]> {
    return of(this.generateRandomArray());
  }

  analyzeTags(tags: string[]) {

  }

  getBlacklistedTags(): Observable<string> {
    return this.http.get(this.badwordsUrl, { responseType: 'text' })
      .pipe(tap(() => { }))
  }

  saveTag(tag): Observable<void> {
    return EMPTY;
  }
}

@Injectable({
  providedIn: 'root'
})
export class OpenAIService {
  private openaiEndpoint;

  constructor(private http: HttpClient, config: NgxTaUiLibraryConfigService) {
    this.openaiEndpoint = `${config.dbApiBaseDir}openai/suggestTags`;
  }

  suggestTags(currentTags: string[]): Observable<string[]> {
    if (!currentTags || currentTags.length === 0) {
      return EMPTY;
    }
    return this.http.post<any>(this.openaiEndpoint, currentTags, httpOptions).pipe();
  }
}

@Injectable({
  providedIn: 'root'
})
export class ImagesStorageService {
  private imagesEndpoint;
  constructor(private http: HttpClient, config: NgxTaUiLibraryConfigService) {
    this.imagesEndpoint = `${config.imagesEndpoint}`;
  }

  saveImages(files: string[], path: string): Observable<string[]> {
    if (files.length) {
      return this.http.post<string[]>(`${this.imagesEndpoint}save`, { files: files, path: path }, {
        headers: new HttpHeaders({
          'Content-type': 'application/x-www-form-urlencoded',
          Accept: 'application/json',
        }),
      }).pipe(take(1));
    } else {
      return new Observable<string[]>((observer) => {
        observer.next([]);
      });
    }
  }

  deleteImages(files: string[]): Observable<string[]> {
    if (files.length) {
      console.log('file object when delete', files);
      return this.http.post<string[]>(`${this.imagesEndpoint}delete`, { files: files }, {
        headers: new HttpHeaders({
          'Content-type': 'application/json',
          Accept: 'application/json',
        }),
      }).pipe(take(1));
    } else {
      return new Observable<string[]>(observer => {
        observer.next([])
      });
    }
  }

  deleteFolder(uuid: string): Observable<any> {
    return this.http.post<any>(`${this.imagesEndpoint}deleteDir`, { dir: uuid }, {
      headers: new HttpHeaders({
        'Content-type': 'application/json',
        Accept: 'application/json',
      })
    }).pipe(take(1));
  }
}

@Injectable({
  providedIn: 'root'
})
export class FilesStorageService {
  private filesEndpoint;
  constructor(private http: HttpClient, config: NgxTaUiLibraryConfigService) {
    this.filesEndpoint = `${config.filesEndpoint}`;
  }

  saveFiles(files: any, path: string): Observable<string[]> {
    if (files.length) {
      const formData = new FormData();

      // Append files to the virtual form.
      for (const file of files) {
        formData.append(file.name, file)
      }

      // Optional, append other kev:val rest data to the form.
      /*Object.keys(restObj).forEach(key => {
        formData.append(key, restObj[key]);
      });*/
      formData.append('path', path);

      // Send it.
      return this.http.post<string[]>(`${this.filesEndpoint}save`, formData);
    } else {
      return new Observable<string[]>((observer) => {
        observer.next([]);
      });
    }

  }

  deleteFiles(files: string[]): Observable<string[]> {
    if (files.length) {
      return this.http.post<string[]>(`${this.filesEndpoint}delete`, { files: files }, {
        headers: new HttpHeaders({
          'Content-type': 'application/json',
          Accept: 'application/json',
        }),
      }).pipe(take(1));
    } else {
      return new Observable<string[]>(observer => {
        observer.next([])
      });
    }
  }

  deleteFolder(uuid: string): Observable<any> {
    return this.http.post<any>(`${this.filesEndpoint}deleteDir`, { dir: uuid }, {
      headers: new HttpHeaders({
        'Content-type': 'application/json',
        Accept: 'application/json',
      })
    }).pipe(take(1));
  }
}

