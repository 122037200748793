import { Component, EventEmitter, Input, OnInit, Output, ViewChild, } from '@angular/core';
import { combineLatest } from 'rxjs';
import { Area, Place, Address } from 'src/app/guides/model/interfaces';
import * as _ from 'lodash';
import { _DEBUG } from 'src/app/app.component';
import { BreadcrumbService } from '@luhuiguo/xng-breadcrumb';


@Component({
  selector: 'app-position',
  templateUrl: './position.component.html',
  styleUrls: ['./position.component.css'],
  host: { 'style': 'max-width:1000px;margin-left:auto;margin-right:auto', 'class': 'w-75 pt-5' }
})
export class PositionComponent implements OnInit {

  // @Input() place!: Place | any;
  @Input() place: Place = new Place();
  @Output() placeChange: EventEmitter<Place> = new EventEmitter<Place>();

  // address = {
  //   countryCode: '',
  //   street: '',
  //   city: '',
  //   zip: '',    
  //   latlng: {
  //     lat: '',
  //     lng: '',
  //   }  
  // }

  constructor(
    private breadcrumbService: BreadcrumbService,
  ) {
  }

  ngOnInit(): void {

    // console.log('place get data', this.place);

  };

  handleAddressChange(address: any) {

    this.place.name = address.name;
    this.place.googleName = address.formatted_address;
    this.place.googleId = address.place_id;
    const country = address.address_components.find(component => component.types.includes('country'));
    const countryCode = country.short_name;
    const countryName = country.long_name;
    const city = address.address_components.find(component => component.types.includes('locality'))?.long_name;
    // const street = address.address_components.find(component => component.types.includes('street_number')).long_name;
    const street = address.formatted_address
    const zip = address.address_components.find(component => component.types.includes('postal_code'))?.short_name;
    if (countryCode) {
      this.place.address.country.code = countryCode;
    }
    if (countryName) {
      this.place.address.country.name = countryName;
    }
    if (city) {
      this.place.address.city = city;
    }
    if (street) {
      this.place.address.street = street
    }
    if (zip) {
      this.place.address.zip = zip;
    }
    this.place.address.latlng.lat = address.geometry.location.lat();
    this.place.address.latlng.lng = address.geometry.location.lng();
    // this.place.address = this.address;

    // console.log('addres', this.address);

    this.placeChange.emit(this.place);
    this.breadcrumbService.set('areas/:id/places/:id', `Luogo: ${this.place.name}`);
  }

}