<div class="h-100">
  <div class="d-flex flex-column w-100 p-3">
    <div class="form-group" style="max-height: 600px; overflow-y: auto; overflow-x: hidden;">
      <label class="mt-3 h1 fs-5" for="floatingInput">Crea gli intenti</label>
      <p class="">Cerca l'intento che desideri aggiungere e scrivi la risposta che deve essere fornita dall'Assistente
        Virtuale.</p>
      <!-- Place Name search -->
      <ng-template #rt let-r="result" let-t="term">
        {{ r.name}}
      </ng-template>

      <div class="form-floating w-100 mb-3">
        <input *ngIf="!aiSubject._id" class="form-control" aria-label="Cerca" id="searchPlace" name="searchPlace"
          [(ngModel)]="model" #instance="ngbTypeahead" [ngbTypeahead]="search" [resultTemplate]="rt"
          [inputFormatter]="formatter" (focus)="focus$.next($event.target.value)"
          (click)="click$.next($event.target.value)" (ngModelChange)="addPlaceName()" placeholder="Cerca" />
        <label *ngIf="!aiSubject._id" for="searchPlace">Cerca</label>
        <span class="input-group-text ms-auto mt-3">Luogo: {{aiSubject.name}}</span>
      </div>
      <!--  -->
      <div *ngIf="!aiSubject.intents.length" class="d-flex align-items-center">
        <button class="btn btn-sm btn-success me-1" (click)="addIntent('')"> <fa-icon [icon]="['fas', 'plus-circle']"
            size="1x"></fa-icon></button>
      </div>
      <div class="row mt-3" style="max-height: 350px; overflow-y: auto;">
        <div class="row mt-3" *ngFor="let intent of aiSubject.intents; let i = index; let l = last">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="col position-relative">
                  <div class="form-floating w-100 mb-3">
                    <input class="form-control" aria-label="Intento" id="intent{{ i }}intent" [(ngModel)]="intent.name"
                      [ngbTypeahead]="search" name="intent{{ i }}intent" placeholder="Cerca" />
                    <label for="intent{{ i }}intent">Intento</label>
                  </div>
                  <div class="form-floating w-100 mb-5">
                    <textarea class="form-control" aria-label="Risposta" id="intent{{ i }}answer" rows="2"
                      style="height: 80px;" [(ngModel)]="intent.answer" placeholder="Aggiungi il testo"></textarea>
                    <label for="notes">Risposta</label>
                  </div>
                  <div class="col-sm-12 mt-3 position-absolute" style="bottom: -43px;">
                    <div class="d-flex align-items-center" style="float: right;">
                      <button *ngIf="l" class="btn btn-sm btn-success me-1" (click)="addIntent('')"> <fa-icon
                          [icon]="['fas', 'plus-circle']" size="1x"></fa-icon></button>
                      <button class="btn btn-sm btn-danger" (click)="remIntent(i)"><fa-icon
                          [icon]="['fas', 'minus-circle']" size="1x"></fa-icon></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="border-top p-2">
      <label class="mt-5 mb-1 h1 fs-5" for="floatingInput">Collega altri Soggetti</label>
      <p class="">Se desideri che questo soggetto sia considerato Padre e che quindi l'assistente virtuale possa
        parlare di soggetti relativi a questo, selezionali nel form che segue:</p>
      <div class="col-md-12 mt-3">
        <label class="fs-6 h3">Soggetti collegati:</label>
        <ng-multiselect-dropdown [placeholder]="'Seleziona'" [settings]="settings" [data]="subSubjects"
          [(ngModel)]="aiSubject.subSubjects" (ngModelChange)="onModelChange('subSubjects')">
        </ng-multiselect-dropdown>
      </div>
    </div>
  </div>
</div>