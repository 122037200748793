import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, firstValueFrom, Observable } from 'rxjs';
import { Person } from 'src/app/guides/model/interfaces';
import { PeopleService } from 'src/app/guides/services/people.service';
import { StateService } from 'src/app/services/state.service';
import { BreadcrumbService } from '@luhuiguo/xng-breadcrumb';
@Component({
  selector: 'app-people',
  templateUrl: './people.component.html',
  styleUrls: ['./people.component.css']
})
export class PeopleComponent implements OnInit {

  people: Person[] = [];
  loading: boolean = true;
  menuVisible: boolean = true;
  area: any;
  params: any;

  constructor(
    private stateService: StateService,
    private peopleService: PeopleService,
    private breadcrumbService: BreadcrumbService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.breadcrumbService.set('areas/:id/people', `Persone`);
    this.loading = true;
    let area$ = this.stateService.selectedArea$;
    let params$ = this.route.params;

    combineLatest([area$, params$]).subscribe(([area, params]) => {
      this.area = area;
      this.params = params;
      this.loadPeople();

    })

    this.stateService.peopleChanged.subscribe(() => {
      this.loadPeople();
    });

  }

  async loadPeople() {
    this.peopleService.getPeopleByArea(this.area._id).subscribe((people) => {
      this.people = people;
      this.loading = false;
    })
  }

  deletePerson(person: Person) {
    this.peopleService.deletePerson(person._id).subscribe(data => {
      this.people = this.people.filter(t => t._id !== person._id);
      //TODO: ALERT
    })
  }

  toggleMenu() {
    this.menuVisible = !this.menuVisible;
  }
}
