<div class="p-3 h-100" *ngIf="guidebook;else loading">
  <div class="col-12 col-md-11 col-lg-10 col-xl-9 col-xxl-8 mx-auto p-4 bg-white rounded-3" style="height: 90%;">
    <ng-stepper class="h-100 pt-2" #cdkStepper [linear]="false">
      <cdk-step [optional]="true">
        <ng-template cdkStepLabel>
          <div class="step-bullet"><i class="bi bi-map"></i></div>
          <div class="step-title d-md-block">Descrizione</div>
        </ng-template>
        <div class="inner-step">
          <div class="step-form">
            <div>
              <div class="d-flex flex-column w-100 p-3">
                <ta-input label='Nome' inputGroupClass="w-50" [(value)]="guidebook.name"></ta-input>
                <div class="form-floating w-100 mb-3 mt-2">
                  <textarea [(ngModel)]="guidebook.description" class="form-control" placeholder="Nome del Luogo"
                    style="height:200px"></textarea>
                  <label for="floatingInput">Descrizione</label>
                </div>
                <div class="form-floating w-100 mb-3">
                  <textarea [(ngModel)]="guidebook.notes" class="form-control" placeholder="Note"
                    style="height:200px"></textarea>
                  <label for="floatingInput">Note</label>
                </div>
              </div>
            </div>
          </div>
          <div class="bottom-button">
            <button type="button" class="ms-auto button btn btn-light" (click)="cdkStepper.next()"><i
                class="bi bi-arrow-right fs-1"></i></button>
          </div>
        </div>
      </cdk-step>



      <cdk-step [optional]="true">
        <ng-template cdkStepLabel>
          <div class="step-bullet"><i class="bi bi-file-text"></i></div>
          <div class="step-title d-md-block">{{listName}}</div>
        </ng-template>
        <div class="inner-step">
          <div class="step-form">
            <div class="p-1 h-100">
              <div class="overflow-auto" style="height: 50vh;">
                <ta-filterable-list *ngIf="itemsList" [selectable]="itemsList" [(selected)]="guidebook.content"
                  [label]="listLabel"></ta-filterable-list>
              </div>
            </div>
            <div class="bottom-button">
              <button type="button" class="button btn btn-light" (click)="cdkStepper.previous()"><i
                  class="bi bi-arrow-left"></i></button>
              <button type="button" class="button btn btn-light" (click)="cdkStepper.next()"><i
                  class="bi bi-arrow-right"></i></button>
            </div>
          </div>
        </div>
      </cdk-step>

      <cdk-step [optional]="true">
        <ng-template cdkStepLabel>
          <div class="step-bullet"><i class="bi bi-file-text"></i></div>
          <div class="step-title d-md-block">Dettagli</div>
        </ng-template>
        <div class="inner-step">
          <div class="step-form">
            <div class="p-1">
              <div class="p-1" style="height: 90%;" *ngIf="guidebook">
                <ta-single-image *ngIf="guidebook" [(image)]="guidebook.coverImage" [uuid]="guidebook.uuid"
                  (onImageSuccess)="onImageSuccess($event)" (onImageWarning)="(onImageWarning($event))"
                  (onImageDeleted)="onImageDeleted()">
                </ta-single-image>
              </div>
              <div class="">
                <div class="lead">Tags</div>
                <ta-tags *ngIf="guidebook" label="tags" [(tags)]="guidebook.tags" class="mt-3"
                  (onForbiddenTag)="tagWarning()" (onError)="tagError($event)"></ta-tags>
              </div>
            </div>
          </div>
          <div class="bottom-button">
            <button type="button" class="button btn btn-light" (click)="cdkStepper.previous()"><i
                class="bi bi-arrow-left"></i></button>
            <button type="button" class="button btn btn-light" (click)="cdkStepper.next()"><i
                class="bi bi-arrow-right"></i></button>
          </div>
        </div>
      </cdk-step>

      <cdk-step [optional]="true">
        <ng-template cdkStepLabel>
          <div class="step-bullet"><i class="bi bi-file-text"></i></div>
          <div class="step-title d-md-block">Riepilogo</div>
        </ng-template>
        <div class="inner-step">
          <div class="step-form">
            <div class="p-1">
              <div class="d-flex flex-column">
                <div class="lead">Nome
                  <div class="fw-bold">{{guidebook.name}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="bottom-button">
            <button type="button" class="button btn btn-light" (click)="cdkStepper.previous()"><i
                class="bi bi-arrow-left"></i></button>
            <button type="button" class="button btn btn-light" (click)="save()"><i
                class="bi bi-cloud-upload fs-1"></i></button>
          </div>
        </div>
      </cdk-step>
    </ng-stepper>
  </div>
</div>
<ng-template #loading>
  <div class="d-flex justify-content-center align-items-center h-100">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</ng-template>