import { Component, OnInit, inject } from '@angular/core';
import { switchMap } from 'rxjs';
import { GuidebookService } from 'src/app/guides/services/guidebook.service';
import { StateService } from 'src/app/services/state.service';
@Component({
  selector: 'app-guidebook-event',
  templateUrl: './guidebook-event.component.html',
  styleUrls: ['./guidebook-event.component.css']
})
export class GuidebookEventComponent implements OnInit {
  guidebooks: [];
  stateService = inject(StateService);
  guidebookService = inject(GuidebookService)
  constructor() { }

  ngOnInit(): void {
    this.stateService.selectedArea$.pipe(
      switchMap((area: any) => {
        return this.guidebookService.getGuidebooksByTypeAndArea('event', area._id)
      })
    ).subscribe((events: any) => {
      this.guidebooks = events
    })
  }

  deleteEvent(i: number, id: string) {
    this.guidebookService.deleteGuidebook(id).subscribe((res) => {
      this.guidebooks.splice(i, 1);
    });
  }

}