<div style="position: absolute;bottom: 0px;left: 0px;">V:{{version}}</div>
<div class="d-flex mh-100" id="wrapper">
  <!-- Sidebar-->
  <div class="border-end bg-white" id="sidebar-wrapper">
    <div class="sidebar-heading border-bottom bg-light p-0" role="button" [routerLink]="['areas']"
      (click)="unselectArea()">
      <img src="assets/logoAlpha.png" class="textSideMenu img-fluid" alt="" />
    </div>
    <div class="list-group list-group-flush">
      <a class="list-group-item list-group-item-action list-group-item-light p-3" [routerLink]="['areas']"
        (click)="unselectArea()" routerLinkActive="activeSection">
        <span class="textSideMenu" i18n="@@areas">Aree</span>
        <fa-icon [icon]="['fas', 'globe']" class="iconSideMenu" size=""></fa-icon>
      </a>
      <a class="list-group-item list-group-item-action list-group-item-light p-3" [class]="isAreaSelected?'':'disabled'"
        [routerLink]="['areas',isAreaSelected?selectedArea.uuid:null,'guidebooks']" routerLinkActive="activeSection">
        <span class="textSideMenu" i18n="@@guidebooks">Guide</span>
        <fa-icon [icon]="['fas', 'map']" class="iconSideMenu" size=""></fa-icon>
      </a>
      <a class="list-group-item list-group-item-action list-group-item-light p-3" [class]="isAreaSelected?'':'disabled'"
        [routerLink]="['areas',isAreaSelected?selectedArea.uuid:null,'guidebooks','dyn']"
        [queryParams]="{catid:'free_time',gid:'events'}" routerLinkActive="activeSection">
        <span class="textSideMenu" i18n="@@guidebooks">Eventi</span>
        <fa-icon [icon]="['fas', 'map']" class="iconSideMenu" size=""></fa-icon>
      </a>
      <a class="list-group-item list-group-item-action list-group-item-light p-3" [class]="isAreaSelected?'':'disabled'"
        [routerLink]="['areas',isAreaSelected?selectedArea.uuid:null,'itineraries']" routerLinkActive="activeSection">
        <span class="textSideMenu" i18n="@@itineraries">Itinerari</span>
        <fa-icon [icon]="['fas', 'street-view']" class="iconSideMenu" size=""></fa-icon>
      </a>
      <a class="list-group-item list-group-item-action list-group-item-light p-3" [class]="isAreaSelected?'':'disabled'"
        [routerLink]="['areas',isAreaSelected?selectedArea.uuid:null, 'places']" routerLinkActive="activeSection">
        <span class="textSideMenu" i18n="@@places">Luoghi</span>
        <fa-icon [icon]="['fas', 'map-marker']" class="iconSideMenu" size=""></fa-icon>
      </a>
      <a class="list-group-item list-group-item-action list-group-item-light p-3" [class]="isAreaSelected?'':'disabled'"
        [routerLink]="['areas',isAreaSelected?selectedArea.uuid:null,'people']" routerLinkActive="activeSection">
        <span class="textSideMenu" i18n="@@people">Persone</span>
        <fa-icon [icon]="['fas', 'users']" class="iconSideMenu" size=""></fa-icon>
      </a>
      <a class="list-group-item list-group-item-action list-group-item-light p-3" [class]="isAreaSelected?'':'disabled'"
        [routerLink]="['areas',isAreaSelected?selectedArea.uuid:null,'aisubjects']" routerLinkActive="activeSection">
        <span class="textSideMenu">Assistente Virtuale</span>
        <fa-icon [icon]="['fas', 'cog']" class="iconSideMenu" size=""></fa-icon>
      </a>
      <a class="list-group-item list-group-item-action list-group-item-light p-3" [class]="isAreaSelected?'':'disabled'"
        [routerLink]="[isAreaSelected?'questions':null]" routerLinkActive="activeSection">
        <span class="textSideMenu">Quiz</span>
        <fa-icon [icon]="['fas', 'cog']" class="iconSideMenu" size=""></fa-icon>
      </a>
      <a class="list-group-item list-group-item-action list-group-item-light p-3" [routerLink]="['settings']"
        routerLinkActive="activeSection">
        <span class="textSideMenu">Impostazioni</span>
        <fa-icon [icon]="['fas', 'cog']" class="iconSideMenu" size=""></fa-icon>
      </a>
      <a class="list-group-item list-group-item-action list-group-item-light p-3" [routerLink]="['library']"
        routerLinkActive="activeSection">
        <span class="textSideMenu">Libreria UI</span>
        <fa-icon [icon]="['fas', 'hashtag']" class="iconSideMenu" size=""></fa-icon>
      </a>
    </div>
    <div *ngIf="isAreaSelected" class="mt-3 sideInfo">
      <router-outlet name="info"></router-outlet>
    </div>
  </div>
  <!-- Sidebar end-->
  <!-- Page content wrapper-->
  <div id="page-content-wrapper" class="d-flex flex-column mh-100 overflow-hidden position-relative">
    <div *ngIf="message" class="w-100 px-1 position-absolute mb-3" style="height: 2.4rem;bottom:0;z-index:100;">
      <ngb-alert class="w-50 mx-auto" #selfClosingAlert [dismissible]="false" [animation]="true" [type]="message.type"
        (closed)="message=undefined "><span class="text-truncate">{{ message.text }}</span>
      </ngb-alert>
    </div>
    <div class="navbar navbar-light bg-light border-bottom">
      <!-- Breadcrumb -->
      <div class="ms-4">
        <nav aria-label="breadcrumb">
          <xng-breadcrumb separator=">"></xng-breadcrumb>
        </nav>
      </div>
      <!-- Breadcrumb end -->
      <!-- logout button-->
      <div class="ms-auto me-1 h-100 d-flex flex-row">

        <button class="btn btn-light fs-inherit" title="ADMIN" type="button"><i
            class="bi bi-person-circle"></i></button>
        <button class="btn btn-light fs-inherit ms-2" title="LOGOUT" type="button" (click)="logout()"><i
            class="bi bi-box-arrow-right"></i></button>
      </div>

    </div>
    <!-- Top navigation -->
    <!--nav class="navbar navbar-expand-lg navbar-light bg-light border-bottom">
      <div class="container-fluid"-->
    <!--button class="btn btn-primary" id="sidebarToggle">Toggle Menu</button-->
    <!--div role="button" [routerLink]="['areas']">
          <img style="height: 50px" src="assets/logoAlpha.png" class="iconSideMenu img-fluid" alt="" />
        </div>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ms-auto mt-2 mt-lg-0">
            <li class="nav-item active">
              <a class="nav-link" href="#!">Home</a>
            </li>
            <li class="nav-item"><a class="nav-link" href="#!">Link</a></li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" id="navbarDropdown" href="#" role="button" data-bs-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">Dropdown</a>
              <div class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                <a class="dropdown-item" href="#!">Action</a>
                <a class="dropdown-item" href="#!">Another action</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="#!">Something else here</a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav-->
    <!-- Top navigation end -->
    <!-- Page content-->
    <div class="container-fluid d-flex flex-column h-100 overflow-scroll flex-shrink-1 px-0">
      <!--h1 class="mt-4">Simple Sidebar</h1>
          <p>The starting state of the menu will appear collapsed on smaller screens, and will appear non-collapsed on larger screens. When toggled using the button below, the menu will change.</p>
          <p>
              Make sure to keep all page content within the
              <code>#page-content-wrapper</code>
              . The top navbar is optional, and just for demonstration. Just create an element with the
              <code>#sidebarToggle</code>
              ID which will toggle the menu when clicked.
          </p-->
      <router-outlet></router-outlet>
    </div>
  </div>
</div>