<div class="p-3 h-100">
  <div class="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6 mx-auto p-4 bg-white rounded-3" style="height: 90%;">
    <ng-stepper class="h-100 pt-2" #cdkStepper [linear]="false">
      <cdk-step [optional]="true">
        <ng-template cdkStepLabel>
          <div class="step-bullet"><i class="bi bi-map"></i></div>
          <div class="step-title d-md-block">Descrizione</div>
        </ng-template>
        <div class="inner-step">
          <div class="step-form">
            <app-description class="" [(itinerary)]="itinerary" #stepOne></app-description>
          </div>
          <div class="bottom-button">
            <button type="button" class="ms-auto button btn btn-light" (click)="cdkStepper.next()"><i
                class="bi bi-arrow-right fs-1"></i></button>
          </div>
        </div>
      </cdk-step>

      <cdk-step [optional]="true">
        <ng-template cdkStepLabel>
          <div class="step-bullet"><i class="bi bi-envelope"></i></div>
          <div class="step-title d-md-block">Luoghi</div>
        </ng-template>
        <div class="inner-step">
          <div class="step-form">
            <app-place-tab class="" [(itinerary)]="itinerary" #stepTwo></app-place-tab>
          </div>
          <div class="bottom-button">
            <button type="button" class="button btn btn-light" (click)="cdkStepper.previous()"><i
                class="bi bi-arrow-left"></i></button>
            <button type="button" class="button btn btn-light" (click)="cdkStepper.next()"><i
                class="bi bi-arrow-right"></i></button>
          </div>
        </div>
      </cdk-step>

      <cdk-step [optional]="true">
        <ng-template cdkStepLabel>
          <div class="step-bullet"><i class="bi bi-file-text"></i></div>
          <div class="step-title d-md-block">Immagini</div>
        </ng-template>
        <div class="inner-step">
          <div class="step-form">
            <app-itinerary-image-upload class="" [(itinerary)]="itinerary" #stepThree></app-itinerary-image-upload>
          </div>
          <div class="bottom-button">
            <button type="button" class="button btn btn-light" (click)="cdkStepper.previous()"><i
                class="bi bi-arrow-left"></i></button>
            <!-- <button type="button" class="btn btn-default" (click)="cdkStepper.next()"><i class="bi bi-arrow-right"></i></button> -->
            <button type="button" class="button btn btn-light" (click)="cdkStepper.next()"><i
                class="bi bi-arrow-right"></i></button>
          </div>
        </div>
      </cdk-step>

      <cdk-step [optional]="true">
        <ng-template cdkStepLabel>
          <div class="step-bullet"><i class="bi bi-image"></i></div>
          <div class="step-title d-md-block">AI</div>
        </ng-template>
        <div class="inner-step">
          <div class="step-form">
            <app-itinerary-ai-active class="" [(itinerary)]="itinerary" [(aiSubject)]="aiSubject"
              #stepFour></app-itinerary-ai-active>
          </div>
          <div class="bottom-button">
            <button class="button btn btn-light" (click)="cdkStepper.previous()"><i
                class="bi bi-arrow-left fs-1"></i></button>
            <button class="ms-auto button btn btn-light" (click)="cdkStepper.next()"><i
                class="bi bi-arrow-right fs-1"></i></button>
          </div>
        </div>
      </cdk-step>

      <cdk-step [optional]="true">
        <ng-template cdkStepLabel>
          <div class="step-bullet"><i class="bi bi-file-text"></i></div>
          <div class="step-title d-md-block">Riepilogo</div>
        </ng-template>
        <div class="inner-step">
          <div class="step-form">
            <app-itineraries-summary class="" [(itinerary)]="itinerary" #stepFive></app-itineraries-summary>
          </div>
          <div class="bottom-button">
            <button type="button" class="button btn btn-light" (click)="cdkStepper.previous()"><i
                class="bi bi-arrow-left"></i></button>
            <button type="button" class="button btn btn-light" (click)="allData()"><i
                class="bi bi-cloud-upload fs-1"></i></button>
          </div>
        </div>
      </cdk-step>
    </ng-stepper>
  </div>
</div>


<!--https://via.placeholder.com/512x346?text=Visit+Blogging.com+Now -->