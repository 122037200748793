import { Component, OnInit, ViewChild, } from '@angular/core';
import { combineLatest } from 'rxjs';
import { Area, AISubject } from 'src/app/guides/model/interfaces';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as _ from 'lodash';
import { BreadcrumbService } from '@luhuiguo/xng-breadcrumb';
import { StateService } from 'src/app/services/state.service';
import { Observable } from 'rxjs';
import { CdkStepper } from '@angular/cdk/stepper';
import { _DEBUG } from 'src/app/app.component';
import { AIService } from 'src/app/guides/services/ai.service';
import { AreasService } from 'src/app/guides/services/areas.service';

@Component({
  selector: 'app-add-area',
  templateUrl: './add-area.component.html',
  styleUrls: ['./add-area.component.css'],
  host: { 'class': 'h-100 pt-5' }
})
export class AddAreaComponent implements OnInit {

  aiSubject$: Observable<AISubject | undefined>;
  area: Area = new Area();
  aiSubject: AISubject = new AISubject();

  updating: boolean = false;
  onUpdate: boolean = false;


  @ViewChild('cdkStepper') cdkStepper: CdkStepper;

  constructor(
    private route: ActivatedRoute,
    private aiService: AIService,
    private breadcrumbService: BreadcrumbService,
    private stateService: StateService,
    private areaService: AreasService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    let params$: Observable<Params> = this.route.params;
    // let area$: Observable<Area> = this.stateService.selectedArea$;
    combineLatest([params$]).subscribe(([params]) => {
      if (params.id === 'new') {
        console.log('parma in addd', params);
        this.breadcrumbService.set('areas/add/new', `Aggiungi Area`);
      } else {
        this.onUpdate = true;
        // this.breadcrumbService.set('areas/:id', `Area`);
        this.areaService.getAreaById(params.id).subscribe(async (area) => {
          this.breadcrumbService.set('areas/add/:id', `Area: ${area.name}`);
          // this.breadcrumbService.set('areas/:id/places/:id', `Area: ${area.name}`);
          this.area = area;
          console.log('get area data', this.area);
          if (this.area.aiSubject) {
            this.aiSubject = this.area.aiSubject as AISubject;
          }
        });
      }
    });
  }

  saveData() {
    debugger
    this.areaService.createArea(this.area).subscribe({
      next: (data) => {
        console.log('data', data);
        this.area = data;
        this.breadcrumbService.set('areas/add/:id', `Area: ${this.area.name}`);
        this.stateService.message$.next({ type: 'success', text: 'Area Creata' });
        //this.onUpdate = true;
        this.router.navigate([`/areas/`]);
      },
      error: (error) => {
        console.log('error', error);
      }
    })
  }

}