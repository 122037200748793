import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from 'src/app/common/components/confirm-modal/confirm-modal.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-activity-item',
  templateUrl: './activity-item.component.html',
  styleUrl: './activity-item.component.css'
})
export class ActivityItemComponent {
  @Input() activity: any;
  @Output() onDeleteActivity = new EventEmitter<any>();
  imagesBaseDir = environment.imagesPrefix;

  constructor(private modalService: NgbModal) {
  }

  onDelete(activity) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.alertTarget = 'activity';

    modalRef.result.then(() => {
      this.onDeleteActivity.emit(activity);
    });
  }
}
