import { Component, EventEmitter, Input, OnInit, Output, ViewChild, } from '@angular/core';
import { combineLatest } from 'rxjs';
import { Area, Place, Latlng } from 'src/app/guides/model/interfaces';
import { PlaceService } from 'src/app/guides/services/place.service';
import { Location } from '@angular/common';
import { ActivatedRoute, Params } from '@angular/router';
import * as _ from 'lodash';
import { BreadcrumbService } from '@luhuiguo/xng-breadcrumb';
import { StateService } from 'src/app/services/state.service';
import { Observable, tap } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MultiImgModalComponent } from 'src/app/common/components/multi-img-modal/multi-img-modal.component';
import { environment } from 'src/environments/environment';
import { CdkStepper } from '@angular/cdk/stepper';
import { _DEBUG } from 'src/app/app.component';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-area-image-upload',
  templateUrl: './area-image-upload.component.html',
  styleUrls: ['./area-image-upload.component.css'],
  host: { 'style': 'max-width:1000px;margin-left:auto;margin-right:auto', 'class': 'w-75 pt-5' }
})
export class AreaImageUploadComponent implements OnInit {

  @Input() area: Area = new Area();
  @Output() areaChange: EventEmitter<Area> = new EventEmitter<Area>();

  imagesBaseDir = environment.IMAGES;
  coverImage: string[] = [];
  uuid: string;
  images: string[] = [];
  isUpdate: boolean = false;

  placeSelected: boolean = false;

  updating: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private placeService: PlaceService,
    private breadcrumbService: BreadcrumbService,
    private stateService: StateService,
    private modalService: NgbModal
  ) {
  }

  ngOnInit(): void {
    this.uuid = uuidv4();
    let params$: Observable<Params> = this.route.params;
    let area$: Observable<Area> = this.stateService.selectedArea$;
    combineLatest([params$, area$]).subscribe(([params, area]) => {
      if (params.id === 'new') {

      } else {
        this.isUpdate = true;
        // this.uuid = this.place.uuid;
        // this.coverImage[0] = this.place.coverImage.url
        // const imaglist = this.place.images;
        // for (const img of imaglist) {
        //   this.images.push(img.url);
        // }
      }
    });
  }

  loadCoverPhoto() {
    if (this.isUpdate) {
      this.coverImage[0] = this.area.image.url
    }
    const modalRef = this.modalService.open(MultiImgModalComponent);
    modalRef.componentInstance.images = this.coverImage;
    modalRef.componentInstance.uploadDir = this.uuid;
    modalRef.componentInstance.multiImage = false;
    modalRef.dismissed.subscribe(() => {
      console.log('dismissed');
      this.stateService.message$.next({ text: `Operazione annullata.`, type: environment.MESSAGES.WARNING });
    },
      (error) => {
        console.error('Dismissed event error:', error);
      })
    modalRef.result.then((imagesUpdated) => {
      console.log('result modal');
      if (_DEBUG) console.log('imagesUpdated', imagesUpdated);
      const images = this.getImageDetails(imagesUpdated);
      this.area.image = images[0];
      this.areaChange.emit(this.area);
      this.coverImage = [];
    });
    modalRef.componentInstance.imagesChange.subscribe((imagesUpdated: any) => {
      console.log('update modal');
      const images = this.getImageDetails(imagesUpdated);
      this.area.image = images[0];
      this.areaChange.emit(this.area);
      this.coverImage = [];
    })
  }

  getImageDetails(images: any) {
    let imageArray = [];
    for (const image of images) {
      const segments = image.split('/');
      const imageName = segments[segments.length - 1];
      imageArray.push({ name: imageName, url: image });
    }
    return imageArray;
  }



}