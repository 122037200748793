import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Options } from '@angular-slider/ngx-slider';

@Component({
  selector: 'app-duration',
  templateUrl: './duration.component.html',
  styleUrls: ['./duration.component.css']
})
export class DurationComponent implements OnInit {
  @Input() visitDuration: number;
  // Output prop name must be Input prop name + 'Change'
  // Use in your component to write an updated value back out to the parent
  @Output() visitDurationChange = new EventEmitter<number>();


  options: Options = {
    floor: 0,
    ceil: 12,
    step: 0.5
  };

  constructor() { }

  ngOnInit(): void {
    console.log('duaration', this.visitDuration);
    //this.time2visit = {min:1.5, max:2.5};
  }

  onVisitDurationChange(newValue: number) {
    this.visitDuration = newValue;
    this.visitDurationChange.emit(newValue);
  }

  /*resetForm(): void {
    this.sliderForm.reset({sliderControl: [20, 80]});
  }*/
}