import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsRoutingModule } from './settings-routing.module';
import { SettingsComponent } from './components/settings/settings.component';
import { JsonViewModule } from 'nxt-json-view'
import { TaLibraryModule } from 'projects/ngx-ta-ui-library/src/public-api';
import { UiLibraryComponent } from './components/ui-library/ui-library.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MarkdownModule } from 'ngx-markdown';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { AngularSplitModule } from 'angular-split';

@NgModule({
  declarations: [
    SettingsComponent,
    UiLibraryComponent
  ],
  imports: [
    CommonModule,
    SettingsRoutingModule,
    JsonViewModule,
    TaLibraryModule,
    NgbModule,
    MarkdownModule.forChild(),
    NgJsonEditorModule,
    AngularSplitModule
  ],
})
export class SettingsModule { }
