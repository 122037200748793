import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MultiImgModalComponent } from 'src/app/common/components/multi-img-modal/multi-img-modal.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BreakpointObserver } from '@angular/cdk/layout';
import { AnswersComponent } from '../answers/answers.component';
import { QuizService } from 'src/app/contest/services/quiz.service';
import { v4 as uuidv4 } from 'uuid';
import { environment } from 'src/environments/environment';
import { StateService } from 'src/app/services/state.service';
import { Question } from 'src/app/contest/store/states/contest-model';
import { BreadcrumbService } from '@luhuiguo/xng-breadcrumb';

@Component({
  selector: 'app-question-details',
  templateUrl: './question-details.component.html',
  styleUrls: ['./question-details.component.css']
})
export class QuestionDetailsComponent implements OnInit {
  @ViewChild(AnswersComponent) AnswersComponent!: AnswersComponent;
  lgScreen: boolean = false;
  question: Question = {} as Question;
  id: any = null;
  imagesBaseDir = environment.IMAGES;

  constructor(private route: ActivatedRoute,
    private stateService: StateService,
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private responsive: BreakpointObserver,
    private breadcrumbService: BreadcrumbService,
    private quizService: QuizService) {
    this.responsive.observe('(min-width: 1200px)')
      .subscribe(result => {
        if (result.matches) {
          this.lgScreen = true
        } else {
          this.lgScreen = false;
        }
        console.log("lgScreen " + this.lgScreen);
      });
  }

  ngOnInit(): void {
    this.route.params.subscribe(param => {
      this.getQuestion(param['id']);
    });
  }

  getQuestion(id: any) {
    if (id != '0') {
      this.quizService.getQuestion(id).subscribe((question: any) => {
        this.question = question;
        this.AnswersComponent.answers = this.question.answers;
        this.breadcrumbService.set('questions/details/:id', this.question.content);
      })
    } else {
      this.question = {
        uuid: uuidv4(),
        content: 'testo della domanda',
        difficulty: 1,
        category: 'other',
        type: 'true-false',
        images: [],
        answers: [],
        active: true
      }
      this.AnswersComponent.answers = this.question.answers;
      this.breadcrumbService.set('questions/details/:id', 'Nuova Domanda');
    }
  }

  openModalImage() {
    let images = this.question.images.map((img: any) => img.url);
    const modalRef = this.modalService.open(MultiImgModalComponent);
    modalRef.componentInstance.images = images;
    modalRef.componentInstance.uploadDir = this.question.uuid;
    modalRef.componentInstance.imagesBaseDir = this.imagesBaseDir;
    modalRef.componentInstance.multiImage = false;
    modalRef.dismissed.subscribe((state) => {
      if (state == 'EMPTY') {
        this.question.images = [];
        this.save();
      }
      this.stateService.message$.next({ text: `Operazione annullata.`, type: environment.MESSAGES.WARNING });
    })
    modalRef.result.then((images) => {
      this.question.images = this.getImageDetails(images);
      this.save();
      this.stateService.message$.next({ text: `Immagine Salvata.`, type: environment.MESSAGES.SUCCESS });
    });
    modalRef.componentInstance.imagesChange.subscribe((images) => {
      console.log('changed');
      this.question.images = this.getImageDetails(images);
      this.save();
      this.stateService.message$.next({ text: `Immagine Salvata.`, type: environment.MESSAGES.SUCCESS });
    })
  }

  openModalAnswers() {
    const modalRef = this.modalService.open(AnswersComponent);
    modalRef.componentInstance.activeModal = this.activeModal;
    modalRef.componentInstance.answers = this.question.answers;
    modalRef.dismissed.subscribe(() => {

    })
    modalRef.result.then((answers) => {
      this.question.answers = answers;
    });
  }

  save() {
    this.quizService.saveQuestion(this.question).subscribe(question => {
      if (this.question._id == null) {
        this.stateService.message$.next({ text: `Domanda Salvata.`, type: environment.MESSAGES.SUCCESS });
      } else {
        this.stateService.message$.next({ text: `Domanda Aggiornata.`, type: environment.MESSAGES.SUCCESS });
      }
      this.stateService.questionAdded.emit(question);
    })

  }

  saveBatch() {
    let questions = []
    this.quizService.saveQuestion(questions).subscribe(result => {
      console.log("batch succeded");
    })
  }

  getImageDetails(images: any) {
    let imageArray = [];
    for (const image of images) {
      const segments = image.split('/');
      const imageName = segments[segments.length - 1];
      imageArray.push({ name: imageName, url: image });
    }
    return imageArray;
  }
}
