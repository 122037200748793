<div class="modal-header">
    <h4 class="modal-title">Scegli le immagini:</h4>
    <button [disabled]="!images.length" class="btn btn-light ms-auto me-2" type="button" aria-label="Close" (click)="activeModal.close(images)">
        <i class="bi bi-cloud-upload fs-1 m-auto"></i>
    </button>
    <button class="btn btn-light" ngbAutofocus type="button" aria-label="Dismiss" (click)="cancel()">
        <i class="bi bi-x-square fs-1 m-auto"></i>
    </button>
</div>
<div class="modal-body">
    <div class="card">
        <div *ngIf="images.length" id="imgsDetails" class="carousel slide" data-bs-ride="carousel" data-bs-interval="false">
            <div class="carousel-inner">

                <div *ngFor="let image of images;let i=index;" [ngClass]="{'active': i == activeSlide}"
                    class="carousel-item">
                    <img class="d-block w-100" style="height: 35vh;object-fit: cover;" [src]="imagesBaseDir+image" alt=""
                    onerror="this.onerror=null;this.src='assets/placeholder.jpg';">
                    <button class="btn bg-white btn-light position-absolute top-0 end-0 m-1"
                        (click)="deleteImg(i);$event.stopPropagation()" type="button" style="z-index: 999;">
                        <i class="bi bi-trash"></i>
                    </button>
                    <div class="">
                        <p>{{i + 1}}/{{images.length}}</p>
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#imgsDetails" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#imgsDetails" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>

            </div>
        </div>
        <div class="d-flex flex-row mw-100 p-1" style="height: 100px;overflow-x: scroll;overflow-y: hidden;">
            <img *ngFor='let preview of images; let i = index' [src]="imagesBaseDir+preview" class="me-1"
                style="min-width:94px;width:94px;max-width:94px;height:94px;object-fit:cover;"
                (click)="setActiveSlide(i)"
                onerror="this.onerror=null;this.src='assets/placeholder.jpg';">

            <div *ngIf="multiImage||images.length==0" class="d-flex align-items-center bg-white" style="height: 94px;width: 94px;"
                (click)="imgsInputClick()">
                <i class="bi bi-plus-square fs-1 m-auto"></i>
            </div>
        </div>
    </div>
</div>
<!-- <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
</div> -->