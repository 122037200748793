import { Component } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { Area } from 'src/app/guides/model/interfaces';
import { StateService } from 'src/app/services/state.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-area-side-details',
  templateUrl: './area-side-details.component.html',
  styleUrls: ['./area-side-details.component.css']
})
export class AreaSideDetailsComponent {
  selectedArea$: Observable<Area | undefined>;
  imagesBaseDir = environment.IMAGES;

  constructor(
    private stateService: StateService,
  ) { }

  ngOnInit(): void {
    this.selectedArea$ = this.stateService.selectedArea$.pipe(
      tap((area) => {

      }
      ))
  }

}
