<div>
  <div class="d-flex flex-column w-100 p-3">
    <div class="form-floating w-100 mb-3">
      <input type="text" class="form-control" placeholder="Place on Google" aria-label="Place"
        aria-describedby="basic-addon2" ngx-gp-autocomplete #placesRef="ngx-places"
        (onAddressChange)="handleAddressChange($event)" />
      <label for="floatingInput">Ricerca Indirizzo (Google Maps)</label>
      <!-- <span class="input-group-text ms-auto">{{place.name?"Google Place Search":"Id Google: "+place.googleId}}</span> -->
    </div>
    <div class="form-floating w-100 mb-3">
      <input type="text" [(ngModel)]="place.googleId" class="form-control" placeholder="Google Id" disabled>
      <label for="floatingInput">Google Id</label>
    </div>
    <div class="form-floating w-100 mb-3">
      <input type="text" [(ngModel)]="place.name" class="form-control" placeholder="Nome del Luogo">
      <label for="floatingInput">Nome</label>
    </div>
    <!--div class="form-floating w-100 mb-3">
      <input type="text" [(ngModel)]="place.area" class="form-control" placeholder="Id Area" disabled>
      <label for="floatingInput">Area</label>
    </div-->
    <div class="form-floating w-100 mb-3">
      <input type="text" [(ngModel)]="place.googleName" class="form-control" placeholder="Nome in Google">
      <label for="floatingPassword">Nome in Google</label>
    </div>
  </div>
</div>