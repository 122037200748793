import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { GbSharedService } from 'src/app/guides/services/gb-shared.service';
import { GuidebookService } from 'src/app/guides/services/guidebook.service';

@Component({
  selector: 'app-guidebook-api-string',
  templateUrl: './guidebook-api-string.component.html',
  styleUrls: ['./guidebook-api-string.component.css']
})
export class GuidebookApiStringComponent implements OnInit {
  @Input() public config: any = {};
  @Output() public onSave! : EventEmitter<void>;
  constructor(
    private service:GbSharedService,
    private guidebookService:GuidebookService
  ) { 
    this.config = this.service.configuration;
    this.onSave = this.service.onSave;
  }

  ngOnInit(): void {
    console.log(this.config);
    this.config.inputString = ["prova1","prova2"]
    
  }

}
