<div class="p-3 h-100" *ngIf="event;else loading">
    <div class="col-12 col-md-11 col-lg-10 col-xl-9 col-xxl-8 mx-auto p-4 bg-white rounded-3" style="height: 90%;">
        <ng-stepper class="h-100 pt-2" #cdkStepper [linear]="false">
            <cdk-step [optional]="true">
                <ng-template cdkStepLabel>
                    <div class="step-bullet"><i class="bi bi-map"></i></div>
                    <div class="step-title d-md-block">Descrizione</div>
                </ng-template>
                <div class="inner-step">
                    <div class="step-form">
                        <div>
                            <div class="d-flex flex-column w-100 p-3">
                                <ta-input label='Nome' inputGroupClass="w-50" [(value)]="event.name"></ta-input>
                                <div class="form-floating w-100 mb-3 mt-2">
                                    <textarea [(ngModel)]="event.description" class="form-control"
                                        placeholder="Nome del Luogo" style="height:200px"></textarea>
                                    <label for="floatingInput">Descrizione</label>
                                </div>
                                <div class="form-floating w-100 mb-3">
                                    <textarea [(ngModel)]="event.notes" class="form-control" placeholder="Note"
                                        style="height:200px"></textarea>
                                    <label for="floatingInput">Note</label>
                                </div>
                                <div class="form-check mt-3">
                                    <input class="form-check-input" type="checkbox" [(ngModel)]="event.multiEvent"
                                        value="" id="flexCheckChecked">
                                    <label class="form-check-label lead " for="flexCheckChecked">
                                        Evento su piu giorni/luoghi
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bottom-button">
                        <button type="button" class="ms-auto button btn btn-light" (click)="cdkStepper.next()"><i
                                class="bi bi-arrow-right fs-1"></i></button>
                    </div>
                </div>
            </cdk-step>

            <cdk-step [optional]="true">
                <ng-template cdkStepLabel>
                    <div class="step-bullet"><i class="bi bi-envelope"></i></div>
                    <div class="step-title d-md-block">Info</div>
                </ng-template>
                <div class="inner-step">
                    <div class="step-form">
                        <div class="p-1">
                            <ta-input label='Sito Web' inputGroupClass="w-50" [(value)]="event.links[0].url"></ta-input>
                            <div>
                                <div class="d-flex flex-column mb-4">
                                    <div class="lead">Periodo</div>
                                    <ta-period-picker [(period)]="event.period"></ta-period-picker>
                                </div>
                            </div>
                            <div>
                                <div class="d-flex flex-column mb-4">
                                    <div class="lead">Orario</div>
                                    <ta-timeframe-picker [(timeframe)]="event.timeframes[0]"></ta-timeframe-picker>
                                </div>
                            </div>
                            <div class="">
                                <div class="d-flex flex-column mb-4">
                                    <div class="lead">Costo</div>
                                    <ta-cost [(costs)]="event.costs" class="border rounded"></ta-cost>
                                </div>
                            </div>
                            <div class="">
                                <div class="lead">Tags</div>
                                <ta-tags *ngIf="event" label="tags" [(tags)]="event.tags" class="mt-3"
                                    (onForbiddenTag)="tagWarning()" (onError)="tagError($event)"></ta-tags>
                            </div>
                        </div>
                    </div>
                    <div class="bottom-button">
                        <button type="button" class="button btn btn-light" (click)="cdkStepper.previous()"><i
                                class="bi bi-arrow-left"></i></button>
                        <button type="button" class="button btn btn-light" (click)="cdkStepper.next()"><i
                                class="bi bi-arrow-right"></i></button>
                    </div>
                </div>
            </cdk-step>

            <cdk-step [optional]="true">
                <ng-template cdkStepLabel>
                    <div class="step-bullet"><i class="bi bi-envelope"></i></div>
                    <div class="step-title d-md-block">Riferimenti</div>
                </ng-template>
                <div class="inner-step">
                    <div class="step-form">
                        <div class="p-1 h-100">
                            <div class="h-50 overflow-auto">
                                <div class="p-3 text-center sticky-top bg-light rounded">
                                    <div class="input-group input-group-sm w-25 position-absolute" style="left:1rem;">
                                        <input type="text" (input)="filterPlaces($event)" class="form-control"
                                            placeholder="Filtra...">
                                    </div>
                                    <div class="lead fw-bold justify-self-center">Seleziona i luoghi</div>
                                </div>
                                <div *ngFor="let p of places" class="border-bottom p-2" [hidden]="p.isHidden"
                                    (mouseenter)="setTooltipImage(p.coverImage.url,tooltip)" #tooltip="ngbTooltip"
                                    tooltipClass="custom-tooltip" (mouseleave)="removeTooltipImage(tooltip)"
                                    [ngbTooltip]="t" [openDelay]="500" [positionTarget]="target">
                                    <div class="w-100 d-flex flex-row">
                                        <div #target class="">{{p.name}}</div>
                                        <div class="ms-auto form-check">
                                            <input class="form-check-input" type="checkbox" [value]="p._id"
                                                id="flexCheckChecked" [checked]="p.isChecked"
                                                (change)="onCheckboxChange(p._id)">
                                        </div>
                                    </div>
                                    <!--div class="form-check">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault"
                                        id="flexRadioDefault1" [value]='p._id' [(ngModel)]="event.place">
                                    <label class="form-check-label" for="flexRadioDefault1">
                                        {{p.name}}
                                    </label>
                                </div-->
                                </div>
                            </div>
                            <ta-filterable-list *ngIf="event&&people" [(selected)]="event.contacts"
                                [selectable]="people" label="Seleziona i Contatti"></ta-filterable-list>
                        </div>
                    </div>
                    <div class="bottom-button">
                        <button type="button" class="button btn btn-light" (click)="cdkStepper.previous()"><i
                                class="bi bi-arrow-left"></i></button>
                        <button type="button" class="button btn btn-light" (click)="cdkStepper.next()"><i
                                class="bi bi-arrow-right"></i></button>
                    </div>
                </div>
            </cdk-step>

            <cdk-step [optional]="true" *ngIf="event.multiEvent">
                <ng-template cdkStepLabel>
                    <div class="step-bullet"><i class="bi bi-file-text"></i></div>
                    <div class="step-title d-md-block">Eventi</div>
                </ng-template>
                <div class="inner-step">
                    <div class="step-form">
                        <div class="p-1" style="height: 90%;" *ngIf="event">
                            <ta-filterable-list *ngIf="event&&events" [(selected)]="event.eventdays"
                                [selectable]="events" label="Seleziona gli Eventi"></ta-filterable-list>
                        </div>
                        <div class="bottom-button">
                            <button type="button" class="button btn btn-light" (click)="cdkStepper.previous()"><i
                                    class="bi bi-arrow-left"></i></button>
                            <button type="button" class="button btn btn-light" (click)="cdkStepper.next()"><i
                                    class="bi bi-arrow-right"></i></button>
                        </div>
                    </div>
                </div>
            </cdk-step>

            <cdk-step [optional]="true">
                <ng-template cdkStepLabel>
                    <div class="step-bullet"><i class="bi bi-file-text"></i></div>
                    <div class="step-title d-md-block">Contatti</div>
                </ng-template>
                <div class="inner-step">
                    <div class="step-form">
                        <div class="p-1" style="height: 90%;" *ngIf="event">
                            <ta-contact-infos [(value)]="event.contactInfos"
                                (onInputError)="onContactInputError($event)">
                            </ta-contact-infos>
                        </div>
                        <div class="bottom-button">
                            <button type="button" class="button btn btn-light" (click)="cdkStepper.previous()"><i
                                    class="bi bi-arrow-left"></i></button>
                            <button type="button" class="button btn btn-light" (click)="cdkStepper.next()"><i
                                    class="bi bi-arrow-right"></i></button>
                        </div>
                    </div>
                </div>
            </cdk-step>

            <cdk-step [optional]="true">
                <ng-template cdkStepLabel>
                    <div class="step-bullet"><i class="bi bi-file-text"></i></div>
                    <div class="step-title d-md-block">Immagini</div>
                </ng-template>
                <div class="inner-step">
                    <div class="step-form">
                        <div class="p-1" style="height: 90%;" *ngIf="event">
                            <ta-single-image *ngIf="event" [(image)]="event.coverImage" [uuid]="event.uuid"
                                (onImageSuccess)="onImageSuccess($event)" (onImageWarning)="(onImageWarning($event))"
                                (onImageDeleted)="onImageDeleted()">
                            </ta-single-image>
                        </div>
                        <div class="bottom-button">
                            <button type="button" class="button btn btn-light" (click)="cdkStepper.previous()"><i
                                    class="bi bi-arrow-left"></i></button>
                            <button type="button" class="button btn btn-light" (click)="cdkStepper.next()"><i
                                    class="bi bi-arrow-right"></i></button>
                        </div>
                    </div>
                </div>
            </cdk-step>

            <cdk-step [optional]="true">
                <ng-template cdkStepLabel>
                    <div class="step-bullet"><i class="bi bi-image"></i></div>
                    <div class="step-title d-md-block">Allegati</div>
                </ng-template>
                <div class="inner-step">
                    <div class="step-form">
                        <div class="p-1" style="height: 90%;" *ngIf="event">
                            <div class="d-flex flex-column">
                                <ta-file-drop *ngIf="event" [(files)]="event.files" [uuid]="event.uuid"
                                    (onFileRemoved)="onFileRemoved($event)" (onError)="onFileError($event)"
                                    (onFileSaved)="onFileSaved($event)"></ta-file-drop>
                            </div>
                        </div>
                        <div class="bottom-button">
                            <button class="button btn btn-light" (click)="cdkStepper.previous()"><i
                                    class="bi bi-arrow-left fs-1"></i></button>
                            <button class="ms-auto button btn btn-light" (click)="cdkStepper.next()"><i
                                    class="bi bi-arrow-right fs-1"></i></button>
                        </div>
                    </div>
                </div>
            </cdk-step>

            <cdk-step [optional]="true">
                <ng-template cdkStepLabel>
                    <div class="step-bullet"><i class="bi bi-file-text"></i></div>
                    <div class="step-title d-md-block">Riepilogo</div>
                </ng-template>
                <div class="inner-step">
                    <div class="step-form">
                        <div class="p-1">
                            <div class="d-flex flex-column">
                                <div class="lead">Nome
                                    <div class="fw-bold">{{event.name}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bottom-button">
                        <button type="button" class="button btn btn-light" (click)="cdkStepper.previous()"><i
                                class="bi bi-arrow-left"></i></button>
                        <button type="button" class="button btn btn-light" (click)="saveEvent()"><i
                                class="bi bi-cloud-upload fs-1"></i></button>
                    </div>
                </div>
            </cdk-step>
        </ng-stepper>
    </div>
</div>
<ng-template #loading>
    <div class="d-flex justify-content-center align-items-center h-100">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</ng-template>
<ng-template #t let-c="close" let-o="open">
    <div class="overflow-hidden aspect-ratio-1">
        <img [src]="tooltipImage" class="rounded" alt="" style="width: 150px;height: 150px;object-fit: cover;"
            loading="lazy">
    </div>
</ng-template>