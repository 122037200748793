import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Duration } from 'luxon';


/**
 * @title Drag&Drop horizontal sorting
 */
@Component({
  selector: 'app-sortable-stepper',
  templateUrl: './sortable-stepper.component.html',
  styleUrls: ['./sortable-stepper.component.css'],
})
export class SortableStepperComponent implements OnInit {
  @Input() places:{totTime:Duration, steps:{id?:number,ordinal:number,placeId:number,color?:string,duration:Duration}[];};
  // Output prop name must be Input prop name + 'Change'
  // Use in your component to write an updated value back out to the parent
  @Output() placesChange = new EventEmitter<{totTime:Duration, steps:{id?:number,ordinal:number,placeId:number,color?:string,duration:Duration}[];}>();
  
  color = '#39ca69';
  
  ngOnInit(): void {
    this.places.steps?
      this.places.steps.map(s=>{
        
      }):
      this.places.steps = [];
    
    
    /*= [
      {n:1, l:7.5, c:"red"},
      {n:2, l:20, c:"blue"},
      {n:3, l:15, c:"green"},
      {n:4, l:5, c:"orange"},
      {n:5, l:7.5, c:"red"},
      {n:6, l:20, c:"blue"},
      {n:7, l:15, c:"green"},
      {n:8, l:5, c:"orange"}  
    ];*/
  }
  
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.places.steps, event.previousIndex, event.currentIndex);
  }
}
