import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, map, take, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Guidebook, Setting } from 'src/app/guides/model/interfaces';
import { ConfigService } from 'src/app/guides/services/config.service';
import { DeleteStoredImgsService } from 'src/app/common/services/delete-stored-imgs.service';
import { StoreImgsService } from 'src/app/common/services/store-imgs.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class GuidebookService {
  private apiUrl = `${environment.dbApiBaseDir}guidebooks`;
  /* !!! REFACTOR remove setting from name !!! */
  private guideBookSectionApiUrl = `${environment.dbApiBaseDir}guidebookSections`;
  private guideBookSectionContentApiUrl = `${environment.dbApiBaseDir}guidebookSectionContent`;
  private guidebookEndpoint = `${environment.API}rest/guidebooks`;
  constructor(
    private http: HttpClient,
    private storeImgsService: StoreImgsService,
    private deleteStoredImgsService: DeleteStoredImgsService,
    private configService: ConfigService
  ) { }

  getAllGuidebooks(): Observable<any> {
    return this.http.get(this.guidebookEndpoint).pipe(take(1));
  }

  getGuidebookById(id: string): Observable<any> {
    return this.http.get(`${this.guidebookEndpoint}/${id}`).pipe(take(1));
  }

  getGuidebooksByTypeAndArea(type: string, areas: string): Observable<any> {
    return this.http.get(`${this.guidebookEndpoint}/${type}/area/${areas}`).pipe(take(1));
  }

  createGuidebook(guidebook: any): Observable<any> {
    return this.http.post(this.guidebookEndpoint, guidebook).pipe(take(1));
  }

  updateGuidebook(guidebook: any): Observable<any> {
    return this.http.patch(`${this.guidebookEndpoint}/${guidebook._id}`, guidebook).pipe(take(1));
  }

  deleteGuidebook(id: string): Observable<any> {
    return this.http.delete(`${this.guidebookEndpoint}/${id}`).pipe(take(1));
  }
}
