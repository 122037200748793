import * as EmailPassword from "supertokens-auth-react/recipe/emailpassword";
import * as ThirdParty from "supertokens-auth-react/recipe/thirdparty";
import { Google, Facebook } from "supertokens-auth-react/recipe/thirdparty";
import Session from "supertokens-auth-react/recipe/session";
import { environment } from "src/environments/environment";
import { style } from "@angular/animations";

export const SuperTokensConfig = {
    //enableDebugLogs: true,
    appInfo: {
        appName: environment.SUPERTOKENS.APP_INFO.APP_NAME,
        apiDomain: environment.SUPERTOKENS.APP_INFO.API_DOMAIN,
        apiBasePath: environment.SUPERTOKENS.APP_INFO.API_BASE_PATH,
        websiteDomain: environment.SUPERTOKENS.APP_INFO.WEBSITE_DOMAIN,
        websiteBasePath: environment.SUPERTOKENS.APP_INFO.WEBSITE_BASE_PATH
    },
    getRedirectionURL: async (context: any) => {
        if (context.action === "SUCCESS") {
            if (context.redirectToPath !== undefined) {
                // we are navigating back to where the user was before they authenticated
                return context.redirectToPath;
            }
            if (context.isNewRecipeUser) {
                return environment.SUPERTOKENS.RECIPE_LIST.REDIRECT.NEW_USER;
            } else {
                return environment.SUPERTOKENS.RECIPE_LIST.REDIRECT.EXISTING_USER;
            }
            return environment.SUPERTOKENS.RECIPE_LIST.REDIRECT.DEFAULT;
        }
        return undefined;
    },
    clientType: environment.SUPERTOKENS.CLIENT_TYPE,
    // recipeList contains all the modules that you want to
    // use from SuperTokens. See the full list here: https://supertokens.com/docs/guides
    recipeList: [
        EmailPassword.init({}),
        /*ThirdParty.init({
            signInAndUpFeature: {
                providers: [],
            },
        }),*/
        Session.init(),
    ],
    languageTranslations: {
        translations: {
            it: {
                AUTH_PAGE_HEADER_TITLE_SIGN_IN_AND_UP: "Accedi / Registrati",
                AUTH_PAGE_HEADER_TITLE_SIGN_IN: "Accedi",
                AUTH_PAGE_HEADER_TITLE_SIGN_UP: "Registrati",

                AUTH_PAGE_HEADER_SUBTITLE_SIGN_IN_START: "Non hai un account?",
                AUTH_PAGE_HEADER_SUBTITLE_SIGN_IN_SIGN_UP_LINK: "Registrati",
                AUTH_PAGE_HEADER_SUBTITLE_SIGN_IN_END: "",

                AUTH_PAGE_HEADER_SUBTITLE_SIGN_UP_START: "Hai un account?",
                AUTH_PAGE_HEADER_SUBTITLE_SIGN_UP_SIGN_IN_LINK: "Accedi",
                AUTH_PAGE_HEADER_SUBTITLE_SIGN_UP_END: "",

                AUTH_PAGE_FOOTER_START: "Accedendo, accetti i nostri ",
                AUTH_PAGE_FOOTER_TOS: "Termini e Condizioni",
                AUTH_PAGE_FOOTER_AND: " e ",
                AUTH_PAGE_FOOTER_PP: "la Privacy Policy",
                AUTH_PAGE_FOOTER_END: "",

                DIVIDER_OR: "oppure",

                BRANDING_POWERED_BY_START: "Powered by ",
                BRANDING_POWERED_BY_END: "",
                SOMETHING_WENT_WRONG_ERROR: "Qualcosa è andato storto. Riprova",
                SOMETHING_WENT_WRONG_ERROR_RELOAD: "Qualcosa è andato storto. Riprova o ricarica la pagina",

                EMAIL_PASSWORD_EMAIL_LABEL: "Email",
                EMAIL_PASSWORD_EMAIL_PLACEHOLDER: "Email",

                EMAIL_PASSWORD_PASSWORD_LABEL: "Password",
                EMAIL_PASSWORD_PASSWORD_PLACEHOLDER: "Password",

                EMAIL_PASSWORD_SIGN_IN_FORGOT_PW_LINK: "Password dimenticata?",
                EMAIL_PASSWORD_SIGN_IN_SUBMIT_BTN: "ACCEDI",
                EMAIL_PASSWORD_SIGN_IN_WRONG_CREDENTIALS_ERROR: "Credenziali errate. Riprova",

                EMAIL_PASSWORD_SIGN_UP_SUBMIT_BTN: "REGISTRATI",

                EMAIL_PASSWORD_EMAIL_ALREADY_EXISTS: "Questo indirizzo email esiste già",

                EMAIL_PASSWORD_RESET_HEADER_TITLE: "Reimposta la password",
                EMAIL_PASSWORD_RESET_HEADER_SUBTITLE: "Ti manderemo una email per reimpostare la tua password",
                EMAIL_PASSWORD_RESET_SEND_FALLBACK_EMAIL: "tuo account",
                EMAIL_PASSWORD_RESET_SEND_BEFORE_EMAIL: "Abbiamo inviato una email al ",
                EMAIL_PASSWORD_RESET_SEND_AFTER_EMAIL: ", se presente nel sistema ",
                EMAIL_PASSWORD_RESET_RESEND_LINK: "Reivia o cambia email",
                EMAIL_PASSWORD_RESET_SEND_BTN: "Invia Email",
                EMAIL_PASSWORD_RESET_SIGN_IN_LINK: "Accedi",

                EMAIL_PASSWORD_RESET_SUBMIT_PW_SUCCESS_HEADER_TITLE: "OK!",
                EMAIL_PASSWORD_RESET_SUBMIT_PW_SUCCESS_DESC: "Hai reimpostato la tua password correttamente.",
                EMAIL_PASSWORD_RESET_SUBMIT_PW_SUCCESS_SIGN_IN_BTN: "Accedi",

                EMAIL_PASSWORD_NEW_PASSWORD_LABEL: "Nuova password",
                EMAIL_PASSWORD_NEW_PASSWORD_PLACEHOLDER: "Nuova password",
                EMAIL_PASSWORD_CONFIRM_PASSWORD_LABEL: "Conferma password",
                EMAIL_PASSWORD_CONFIRM_PASSWORD_PLACEHOLDER: "Conferma password",

                EMAIL_PASSWORD_RESET_SUBMIT_PW_HEADER_TITLE: "Cambia password",
                EMAIL_PASSWORD_RESET_SUBMIT_PW_HEADER_SUBTITLE: "Inserisci una nuova password per cambiarla",
                EMAIL_PASSWORD_RESET_SUBMIT_PW_CHANGE_PW_BTN: "CAMBIA PASSWORD",
                EMAIL_PASSWORD_RESET_PASSWORD_INVALID_TOKEN_ERROR: "Token di reset non valido. Riprova",

                ERROR_EMAIL_NON_STRING: "Il campo email deve essere di tipo testuale",
                ERROR_EMAIL_INVALID: "Email non valida",

                ERROR_PASSWORD_NON_STRING: "Il campo password deve essere di tipo testuale",
                ERROR_PASSWORD_TOO_SHORT: "La password deve avere almeno 8 caratteri, con almeno un numero",
                ERROR_PASSWORD_TOO_LONG: "La password deve avere al massimo 100 caratteri",
                ERROR_PASSWORD_NO_ALPHA: "La password deve avere almeno un carattere alfabetico",
                ERROR_PASSWORD_NO_NUM: "La password deve avere almeno un carattere numerico",
                ERROR_CONFIRM_PASSWORD_NO_MATCH: "Le passwrord non coincidono",

                ERROR_NON_OPTIONAL: "Campo non opzionale",

                /*
                 * The following are error messages from our backend SDK.
                 * These are returned as full messages to preserver compatibilty, but they work just like the keys above.
                 * They are shown as is by default (setting the value to undefined will display the raw translation key)
                 */
                "This email already exists. Please sign in instead.": undefined,
                "Field is not optional": undefined,
                "Password must contain at least 8 characters, including a number": undefined,
                "Password's length must be lesser than 100 characters": undefined,
                "Password must contain at least one alphabet": undefined,
                "Password must contain at least one number": undefined,
                "Email is invalid": undefined,
                "Reset password link was not created because of account take over risk. Please contact support. (ERR_CODE_001)":
                    undefined,
                "Cannot sign up due to security reasons. Please try logging in, use a different login method or contact support. (ERR_CODE_007)":
                    undefined,
                "Cannot sign in due to security reasons. Please try resetting your password, use a different login method or contact support. (ERR_CODE_008)":
                    undefined,
                "Cannot sign in / up due to security reasons. Please contact support. (ERR_CODE_009)": undefined,
                "Cannot sign in / up due to security reasons. Please contact support. (ERR_CODE_010)": undefined,
                "Cannot sign in / up due to security reasons. Please contact support. (ERR_CODE_011)": undefined,
                "Cannot sign in / up due to security reasons. Please contact support. (ERR_CODE_012)": undefined,
                "Cannot sign in / up due to security reasons. Please contact support. (ERR_CODE_013)": undefined,
                "Cannot sign in / up due to security reasons. Please contact support. (ERR_CODE_014)": undefined,
                "Cannot sign in / up due to security reasons. Please contact support. (ERR_CODE_015)": undefined,
                "Cannot sign in / up due to security reasons. Please contact support. (ERR_CODE_016)": undefined,

                THIRD_PARTY_PROVIDER_DEFAULT_BTN_START: "Continua con ",
                THIRD_PARTY_PROVIDER_DEFAULT_BTN_END: "",

                THIRD_PARTY_ERROR_NO_EMAIL: "Non riesco a trovare la mail. Per favore prova un altro metodo",

                /*
                 * The following are error messages from our backend SDK.
                 * These are returned as full messages to preserver compatibilty, but they work just like the keys above.
                 * They are shown as is by default (setting the value to undefined will display the raw translation key)
                 */

                "Cannot sign in / up due to security reasons. Please try a different login method or contact support. (ERR_CODE_004)":
                    undefined,
                "Cannot sign in / up because new email cannot be applied to existing account. Please contact support. (ERR_CODE_005)":
                    undefined,

                "Cannot sign in / up due to security reasons. Please try a different login method or contact support. (ERR_CODE_006)":
                    undefined,
                "Cannot sign in / up due to security reasons. Please contact support. (ERR_CODE_020)": undefined,
                "Cannot sign in / up due to security reasons. Please contact support. (ERR_CODE_021)": undefined,
                "Cannot sign in / up due to security reasons. Please contact support. (ERR_CODE_022)": undefined,
                "Cannot sign in / up due to security reasons. Please contact support. (ERR_CODE_023)": undefined,

            }
        },
        defaultLanguage: environment.SUPERTOKENS.LANGUAGE.DEFAULT,
    },
    style: `[data-supertokens~=container] {
                --palette-background: 51, 51, 51;
                --palette-inputBackground: 41, 41, 41;
                --palette-inputBorder: 41, 41, 41;
                --palette-textTitle: 255, 255, 255;
                --palette-textLabel: 255, 255, 255;
                --palette-textPrimary: 255, 255, 255;
                --palette-error: 173, 46, 46;
                --palette-textInput: 169, 169, 169;
                --palette-textLink: 169, 169, 169;
                --palette-primary: ${environment.SUPERTOKENS.STYLE.PALETTE_PRIMARY};
                --palette-primaryBorder: ${environment.SUPERTOKENS.STYLE.PALETTE_PRIMARY_BORDER};
            }
            [data-supertokens~=headerTitle] {
                display: none;
            }
            [data-supertokens~=headerSubtitle] {
                display: none;
            }
            [data-supertokens~=divider] {
                display: none;
            }`/*,
            [data-supertokens~=thirdPartyEmailPasswordDividerOr] {
                margin-top: 0.55em;
            },
            [data-supertokens~=divider] {
                flex: 2 3;
            },`*/
};
