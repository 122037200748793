<div *ngIf="area?.name" id="ol-map-{{area.name}}" style="z-index:-1;border-width: 0px !important; opacity: 0.2;"
    class="map-container position-fixed end-0 top-0 border w-100 h-100"></div>
<div class="p-4 position-relative w-100 h-100" [hidden]="!menuVisible">
    <div class="row mx-auto col-12 col-lg-8">
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3" style="min-width: 20rem;">
            <div class="card">
                <div class="card-body d-flex flex-column">
                    <h5 class="card-title">GUIDE</h5>
                    <a href="#" class="btn btn-primary ta-green ms-auto" [routerLink]="['guidebooks']"><i
                            class="bi bi-arrow-right fs-5"></i></a>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3" style="min-width: 20rem;">
            <div class="card">
                <div class="card-body d-flex flex-column">
                    <h5 class="card-title">EVENTI</h5>
                    <a href="#" class="btn btn-primary ta-green ms-auto" [routerLink]="['guidebooks','dyn']"
                        [queryParams]="{catid:'free_time',gid:'events'}"><i class="bi bi-arrow-right fs-5"></i></a>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3" style="min-width: 20rem;">
            <div class="card">
                <div class="card-body d-flex flex-column">
                    <h5 class="card-title">LUOGHI</h5>
                    <a href="#" class="btn btn-primary ta-green ms-auto" [routerLink]="['places']"><i
                            class="bi bi-arrow-right fs-5"></i></a>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3" style="min-width: 20rem;">
            <div class="card">
                <div class="card-body d-flex flex-column">
                    <h5 class="card-title">ITINERARI</h5>
                    <a href="#" class="btn btn-primary ta-green ms-auto" [routerLink]="['itineraries']"><i
                            class="bi bi-arrow-right fs-5"></i></a>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3" style="min-width: 20rem;">
            <div class="card">
                <div class="card-body d-flex flex-column">
                    <h5 class="card-title">PERSONE</h5>
                    <a href="#" class="btn btn-primary ta-green ms-auto" [routerLink]="['people']"><i
                            class="bi bi-arrow-right fs-5"></i></a>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3" style="min-width: 20rem;">
            <div class="card">
                <div class="card-body d-flex flex-column">
                    <h5 class="card-title">ASSISTENTE VIRTUALE</h5>
                    <a href="#" class="btn btn-primary ta-green ms-auto" [routerLink]="['aisubjects']"><i
                            class="bi bi-arrow-right fs-5"></i></a>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3" style="min-width: 20rem;">
            <div class="card">
                <div class="card-body d-flex flex-column">
                    <h5 class="card-title">IMPOSTAZIONI</h5>
                    <a href="#" class="btn btn-primary ta-green ms-auto" [routerLink]="['settings']"><i
                            class="bi bi-arrow-right fs-5"></i></a>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3" style="min-width: 20rem;">
            <div class="card">
                <div class="card-body d-flex flex-column">
                    <h5 class="card-title">QUIZ</h5>
                    <a href="#" class="btn btn-primary ta-green ms-auto" [routerLink]="['/questions']"><i
                            class="bi bi-arrow-right fs-5"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>
<!--router-outlet (activate)="toggleMenu()" (deactivate)="toggleMenu()"></router-outlet-->
<router-outlet (activate)="toggleMenu()" (deactivate)="toggleMenu()"></router-outlet>