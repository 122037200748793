<div *ngFor="let inputField of config.fields" class="form-group col-lg-6 flex-grow-1 mb-1">
    <label [for]="inputField">{{inputField|uppercase}}</label>
    <input
      type="text"
      class="form-control"
      [id]="inputField"
      required
      [name]="inputField"
      
    />
    <!--div
      [hidden]="name.valid || name.pristine"
      class="alert alert-danger small"
    >
      Name is required
    </div-->
  </div>
