<div class="">
  <div class="card-sl mb-1 d-flex align-items-start flex-column p-0">
    <div class="card-image w-100 m-1">
      <img src="{{imgpref + person.notes}}" onerror="this.onerror=null;this.src='assets/placeholder.jpg';"
        class="card-img-top img-fluid overflow-hidden" style="height: 10rem; overflow: hidden; object-fit: cover" />
    </div>
    <div class="mb-auto border m-1" style="max-width: 18rem;width: 97%;">
      <div class="card-heading">
        <h5 class="card-title text-truncate text-uppercase">
          {{ person.name }} {{person.surname}}</h5>
      </div>
      <div class="card-text text-muted h-auto d-inline-block" style="overflow-y: auto;">
        {{ person.notes }}
      </div>
    </div>
    <app-modify-delete [target]="person._id" (onDelete)="onDelete(person)"></app-modify-delete>
  </div>
</div>