import { Component, EventEmitter, Input, OnInit, Output, ViewChild, } from '@angular/core';
import { ReplaySubject, combineLatest } from 'rxjs';
import { Area, Place, Latlng } from 'src/app/guides/model/interfaces';
import { PlaceService } from 'src/app/guides/services/place.service';
import { Location } from '@angular/common';
import { ActivatedRoute, Params } from '@angular/router';
import * as _ from 'lodash';
import { BreadcrumbService } from '@luhuiguo/xng-breadcrumb';
import { StateService } from 'src/app/services/state.service';
import { Observable, tap } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MultiImgModalComponent } from 'src/app/common/components/multi-img-modal/multi-img-modal.component';
import { environment } from 'src/environments/environment';
import { CdkStepper } from '@angular/cdk/stepper';
import { _DEBUG } from 'src/app/app.component';
import { v4 as uuidv4 } from 'uuid';
import { SharedEventService } from 'src/app/common/services/shared-event.service';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.css'],
  host: { 'style': 'max-width:1000px;margin-left:auto;margin-right:auto;', 'class': 'w-75 pt-5' }
})
export class ImageUploadComponent implements OnInit {

  // @Input() place!: Place | any;
  @Input() place: Place = new Place();
  @Output() placeChange: EventEmitter<Place> = new EventEmitter<Place>();
  @Output() savePlaceEvent: EventEmitter<any>;
  imagesBaseDir = environment.IMAGES;
  coverImage: string[] = [];
  uuid: string;
  images: string[] = [];
  isUpdate: boolean = false;
  // place$: Observable<Place | undefined>;
  // place: Place | any = {};
  placeSelected: boolean = false;
  coverImageLoadError = false;

  updating: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private placeService: PlaceService,
    private breadcrumbService: BreadcrumbService,
    private stateService: StateService,
    private modalService: NgbModal,
    private sharedEvent: SharedEventService
  ) {
    this.savePlaceEvent = this.sharedEvent.savePlaceEvent;
  }

  ngOnInit(): void {
    // this.uuid = uuidv4();
    this.uuid = this.place.uuid;
    console.log('place uuid ??????', this.place.uuid);
    let params$: Observable<Params> = this.route.params;
    let area$: Observable<Area> = this.stateService.selectedArea$;
    combineLatest([params$, area$]).subscribe(([params, area]) => {
      if (params.id === 'new') {

      } else {
        this.isUpdate = true;
        // this.uuid = this.place.uuid;
        // this.coverImage[0] = this.place.coverImage.url
        // const imaglist = this.place.images;
        // for (const img of imaglist) {
        //   this.images.push(img.url);
        // }
      }
    });
  }

  loadCoverPhoto() {
    if (this.place.coverImage && !this.coverImageLoadError) {
      this.coverImage[0] = this.place.coverImage.url;
    }
    const modalRef = this.modalService.open(MultiImgModalComponent);
    modalRef.componentInstance.images = this.coverImage;
    modalRef.componentInstance.uploadDir = this.uuid;
    modalRef.componentInstance.multiImage = false;
    modalRef.dismissed.subscribe((state) => {
      if (state == 'EMPTY') {
        this.place.coverImage = null;
        this.placeChange.emit(this.place);
      }
      this.stateService.message$.next({ text: `Operazione annullata.`, type: environment.MESSAGES.WARNING });
    })
    modalRef.result.then((imagesUpdated) => {
      if (_DEBUG) console.log('imagesUpdated', imagesUpdated);
      const images = this.getImageDetails(imagesUpdated);
      this.place.coverImage = images[0];
      this.placeChange.emit(this.place);
      //if (this.isUpdate) { this.savePlaceEvent.emit(); }
      this.coverImage = [];
      this.stateService.message$.next({ text: `Immagine Salvata.`, type: environment.MESSAGES.SUCCESS });
    });
    modalRef.componentInstance.imagesChange.subscribe((imagesUpdated: any) => {
      const images = this.getImageDetails(imagesUpdated);
      this.place.coverImage = images[0];
      this.placeChange.emit(this.place);
      //this.savePlaceEvent.emit();
      this.coverImage = [];
      this.stateService.message$.next({ text: `Immagine Salvata.`, type: environment.MESSAGES.SUCCESS });
    })
  }

  loadImages() {
    const imaglist = this.place.images;
    for (const img of imaglist) {
      this.images.push(img.url);
    }
    const modalRef = this.modalService.open(MultiImgModalComponent);
    modalRef.componentInstance.images = this.images;
    modalRef.componentInstance.uploadDir = this.uuid;
    modalRef.componentInstance.multiImage = true;
    modalRef.dismissed.subscribe(() => {
      this.stateService.message$.next({ text: `Operazione annullata.`, type: environment.MESSAGES.WARNING });
    })
    modalRef.result.then((imagesUpdated) => {
      if (_DEBUG) console.log('imagesUpdated', imagesUpdated);
      const images = this.getImageDetails(imagesUpdated);
      this.place.images = images;
      this.placeChange.emit(this.place);
      this.images = [];
      console.log('plaec object after image upload', this.place);
      // this.placeService.savePlace(this.place).subscribe(() => {
      //   this.stateService.message$.next({ text: `Hai aggiornato le foto.`, type: environment.MESSAGES.SUCCESS });
      // })
    });
    modalRef.componentInstance.imagesChange.subscribe((imagesUpdated: any) => {
      const images = this.getImageDetails(imagesUpdated);
      this.place.images = images;
      this.placeChange.emit(this.place);
      this.images = [];
    })
  }

  getImageDetails(images: any) {
    let imageArray = [];
    for (const image of images) {
      const segments = image.split('/');
      const imageName = segments[segments.length - 1];
      imageArray.push({ name: imageName, url: image });
    }
    return imageArray;
  }

  setCoverImgLoadError($event) {
    this.coverImageLoadError = true;
  }

}