<div class="modal-header">
    <h4 class="modal-title">Attività da Convalidare</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modalService.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
    <div class="d-flex gap-2">
        @for(s of pendingShops;track s){
        <div class="card mb-3" style="width: 540px;">
            <div class="row g-0 h-100">
                <div class="col-md-4">
                    @if(s.coverImage){
                    <img [src]="imgpref+s.coverImage.url" class="w-100 h-100 rounded-start" style="object-fit: cover;"
                        alt="..." onerror="this.onerror=null;this.src='assets/placeholder.jpg';">
                    }
                    @else{
                    <img [src]="'assets/placeholder.jpg'" class="w-100 h-100 rounded-start" style="object-fit: cover;"
                        alt="...">
                    }
                </div>
                <div class="col-md-8">
                    <div class="card-body">
                        <h5 class="card-title">{{s.companyName}}</h5>
                        <p class="card-text">{{s.legalAddress}}</p>
                        <p class="card-text"><small class="text-body-secondary">Inserita il {{s.createdAt| date :
                                'dd/MM/yyyy'}}</small></p>
                    </div>
                </div>
            </div>
        </div>
        }
    </div>
</div>