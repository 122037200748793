<div class="p-3 h-100">
  <div class="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6 mx-auto p-4 bg-white" style="height: 90%;">
    <div class="inner-step h-100">
      <div class="step-form h-100">
        <app-ai-subject-active class="" [(aiSubject)]="aiSubject"></app-ai-subject-active>
      </div>
      <div class="bottom-button">
        <button class="ms-auto button btn btn-light" (click)="saveData()"><i
            class="bi bi-cloud-upload fs-1"></i></button>
      </div>
    </div>
  </div>
</div>