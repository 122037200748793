import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { GuidebookService } from 'src/app/guides/services/guidebook.service';

@Component({
  selector: 'app-guidebook-product',
  templateUrl: './guidebook-product.component.html',
  styleUrls: ['./guidebook-product.component.css']
})
export class GuidebookProductComponent implements OnInit {
  guidebooks: [];
  constructor(private guidebookService: GuidebookService) { }

  ngOnInit(): void {
    this.guidebookService.getAllGuidebooks().subscribe((guidebooks: any) => {
      this.guidebooks = guidebooks.filter(guidebook => guidebook.type === 'product');
    })
  }

  deleteProduct(i: number, id: string) {
    this.guidebookService.deleteGuidebook(id).subscribe((res) => {
      this.guidebooks.splice(i, 1);
    });
  }

}
