<div id="fab-button" class="btn btn-primary position-fixed bottom-0 end-0" [routerLink]="['./','new']">
  <fa-icon [icon]="['fas', 'plus']" size="2x" style="margin: 1%"></fa-icon>
</div>

<!-- <div class="mt-1" style="position: sticky;top:0;z-index: 100;">
  <select
    class="form-select"
    name="selectArea"
    (change)="onSelectArea(area)"
    [(ngModel)]="area"
  >
    <option value="" selected>All Areas - Select to Filter</option>
    <option
      *ngFor="let area of availAreas; let i = index"
      [disabled]="!area.active"
      [value]="area.id"
    >
      {{ area.area_name }}
    </option>
  </select>
</div> -->

<div *ngIf="loading" class="spinner-border text-primary" role="status">
  <span class="visually-hidden">Loading...</span>
</div>

<div class="container pt-4">
  <div class="row">
    <div  *ngFor="let itinerary of itineraries" class="col-12 col-sm-6 col-md-4 col-lg-3 pb-3" style="min-width: 25rem;">
      <app-itinerary-item [itinerary]="itinerary" (onDeleteItinerary)="deleteItinerary(itinerary)">
      </app-itinerary-item>
    </div>
  </div>
</div>

<!-- <div class=" mt-4 mx-0">
  <div class="row">
    <div
      *ngFor="let itinerary of itineraries"
      class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3"
      style="min-width: 25rem;"
    >
      <app-itinerary-item
        [itinerary]="itinerary"
        (onDeleteItinerary)="deleteItinerary(itinerary)"
      >
      </app-itinerary-item>
    </div>
  </div>
</div> -->
