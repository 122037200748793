import { Component, Input, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, finalize } from 'rxjs';
import { Area } from 'src/app/guides/model/interfaces';
import { PlaceService } from 'src/app/guides/services/place.service';
import { StateService } from 'src/app/services/state.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-requests',
  templateUrl: './requests.component.html',
  styleUrl: './requests.component.css'
})
export class RequestsComponent implements OnInit {
  @Input() placeRequests: any[];
  modalService = inject(NgbActiveModal);
  router = inject(Router);
  stateService = inject(StateService);
  placeService = inject(PlaceService);
  imgpref = environment.imagesPrefix;
  area: Area;
  message: { text: string, type: string } | undefined = undefined;

  constructor() {
  }

  ngOnInit(): void {
    this.stateService.selectedArea$.subscribe((area) => {
      this.area = area;
    })
    console.log(this.placeRequests)
  }

  acceptRequest(request: any) {
    this.router.navigate([`/areas/${this.area.uuid}/places/${request.place._id}`]);
    this.modalService.close();
  }

  deleteRequest(request: any) {
    this.placeService.deletePlaceRequest$(request).pipe(
      finalize(() => {
        setTimeout(() => {
          this.message = undefined;
        }, 3000);
      })).subscribe((r) => {
        if (r.id === request._id) {
          this.placeRequests = this.placeRequests.filter((req) => req._id !== request._id);
        }
        else {
          this.message = { text: 'Ops.. qualcosa non ha funzionato', type: 'danger' };
        }
      });
  }
}
