<div class="card-sl mb-1 d-flex align-items-start flex-column p-0 bg-white position-relative">
  <div class="card-image w-100 m-1">
    <ngb-carousel *ngIf="itinerary.images&&itinerary.coverImage">
      <!-- Show coverImage slide -->
      <ng-template ngbSlide *ngIf="itinerary.coverImage">
        <div class="row g-0">
          <div class="col-md-12">
            <img class="card-img-top img-fluid" [src]="imagesBaseDir + itinerary.coverImage.url"
              title="{{ itinerary.coverImage.name }}" style="height: 12rem; object-fit: cover" loading="lazy" />
          </div>
        </div>
      </ng-template>
      <!-- Show image slide -->
      <ng-template ngbSlide *ngFor="let img of itinerary.images">
        <div class="row g-0">
          <div class="col-md-12">
            <img class="card-img-top img-fluid" [src]="imagesBaseDir+img.url" title="{{ img.name }}"
              style="height: 12rem; object-fit: cover" loading="lazy" />
          </div>
        </div>
      </ng-template>
    </ngb-carousel>
  </div>
  <div class="mb-auto border m-1 overflow-auto" style="max-width: 20rem; width: 97%; max-height: 20%">
    <div class="card-heading">
      <h5 class="card-title text-truncate text-uppercase">
        {{ itinerary.name }}
      </h5>
    </div>
    <div class="card-text text-muted h-auto d-inline-block">
      {{ itinerary.notes }}
    </div>
  </div>
  <app-modify-delete [target]="itinerary._id" (onDelete)="onDelete(itinerary._id)"></app-modify-delete>
</div>