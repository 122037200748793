<div *ngIf="settings$|async as settings;else loading" class="d-flex flex-column">
    <div class="p-2" style="height: 90vh;">
        <as-split gutterSize="4" direction="horizontal">
            <as-split-area [size]="20">
                <!--div class="p-2 w-100 h-100 border border-2">
                    <nxt-json-view [data]="s"></nxt-json-view>
                </div-->
                <div class="d-flex flex-column h-100">
                    <div class="d-flex flex-column h-100">
                        <div #l class="bg-light mb-1 setting-item" *ngFor="let s of settings;let first=first"
                            (click)="setJsonEditorData(s,l)" style="font-family: monospace;" [class.active]="first">
                            <div class="p-3">{{s.name}}</div>
                        </div>
                    </div>
                    <div class="d-flex flex-column mt-auto">
                        <div class="d-grid gap-2">
                            <button class="btn btn-sm btn-outline-primary" type="button">Aggiungi</button>
                        </div>
                    </div>
                </div>
            </as-split-area>
            <as-split-area>
                <div class="d-flex flex-column h-100">
                    <div class="p-2 w-100 h-100">
                        <json-editor [options]="editorOptions" [data]="data"></json-editor>
                    </div>
                    <div class="d-flex flex-column mt-auto">
                        <div class="d-grid gap-2">
                            <button class="btn btn-sm btn-outline-primary" type="button">Salva</button>
                        </div>
                    </div>
                </div>
            </as-split-area>
        </as-split>
    </div>
</div>
<ng-template #loading>
    <div class="d-flex justify-content-center align-items-center" style="height: 90vh;">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</ng-template>