<div class="modal-header">
  <h4 class="modal-title" id="modal-title">Sei sicuro?</h4>
  <button type="button" class="btn-close" aria-describedby="modal-title"
    (click)="modal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  <p><strong>Stai per cancellare {{message}}.</strong></p>
  <p><span class="text-danger">Questa operazione non è reversibile.</span></p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">Cancel</button>
  <button type="button" class="btn btn-danger" (click)="modal.close('Ok click')">Ok</button>
</div>