<div>
  <div class="d-flex flex-column w-100 p-3">
    <div class="form-floating w-100 mb-3">
      <input type="text" class="form-control" placeholder="Place on Google" aria-label="Place"
        aria-describedby="basic-addon2" ngx-gp-autocomplete #placesRef="ngx-places" [options]="options"
        (onAddressChange)="handleAddressChange($event)" [type]="'city'" />
      <label for="floatingInput">Ricerca Indirizzo (Google Maps)</label>
    </div>
    <div class="form-floating w-100 mb-3">
      <input type="text" [(ngModel)]="area.name" class="form-control" placeholder="Nome">
      <label for="floatingInput">Nome</label>
    </div>
    <div class="form-floating w-100 mb-3">
      <textarea class="form-control" [(ngModel)]="area.description" class="form-control" placeholder="Descp"
        style="height: 100px"></textarea>
      <label for="floatingTextarea2">Descrizione</label>
    </div>
    <!-- Load Map Preview -->
    <div class="col-md-8 position-relative w-100 border" style="height: 400px;">
      <div id="ol-map-{{area.name}}" style="z-index:1;border-width: 0px !important;"
        class="map-container position-absolute end-0 top-0 border w-100 h-100 opacity-gradient"
        (click)="$event.stopPropagation();"></div>
      <div class="text-center fs-4 lead" *ngIf="loading">
        <!-- Loading indicator (e.g., spinner, message) while the map is being loaded -->
        Loading map preview...
      </div>
    </div>
  </div>
</div>