import { Component, EventEmitter, Input, OnInit, Output, ViewChild, } from '@angular/core';
import { combineLatest } from 'rxjs';
import { Area, Place, Address, Itinerary } from 'src/app/guides/model/interfaces';
import * as _ from 'lodash';
import { _DEBUG } from 'src/app/app.component';


@Component({
  selector: 'app-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.css'],
  host: { 'style': 'max-width:1000px;margin-left:auto;margin-right:auto', 'class': 'w-75 pt-5' }
})
export class DescriptionComponent implements OnInit {

  // @Input() place!: Place | any;
  @Input() itinerary: Itinerary = new Itinerary();
  @Output() itineraryChange: EventEmitter<Itinerary> = new EventEmitter<Itinerary>();


  constructor() {
  }

  ngOnInit(): void {
  };

  onModelChange(field: string) {
    this.itineraryChange.emit(this.itinerary);
  }

}